import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    categories: [],
};

const adminCategories = createSlice({
    name: "categories",
    initialState,
    reducers: {
        getCategories(state, action) {
            state.categories = action.payload;
        },
    },
});

export default adminCategories;
