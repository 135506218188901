import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {link} from "../../host-store";
import {token} from "../../../App";

export const catalogQuery = createApi({
    reducerPath: 'catalogQuery',
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ['catalog', "catalogIndexAmount"],
    endpoints: (builder) => ({
        getCategories: builder.query({
            query: () => ({
                url: `/category?filter[depth]=0`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ['catalog']
        }),
        getAmountCategories: builder.query({
            query: () => ({
                url: `/category/get-count-objects-for-root-categories?city_id=${JSON.parse(localStorage.getItem('location'))?.city_id}`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ['catalog']
        }),
    })
})


export const {useGetCategoriesQuery, useGetAmountCategoriesQuery} = catalogQuery
