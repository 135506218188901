import Select from "react-select";
import { DropdownIndicator } from "../advertisement/create/CitySelects";
import time from "../../assets/images/cabinet/time.svg";
import { useState } from "react";

export const customStyles = {
    control: () => ({
        width: "100%",
        display: "flex",
        background: "#F6F7FE",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 10,
        height: 48,
        paddingLeft: 40,
        paddingRight: 18,
        border: "1px solid rgba(84, 110, 219, 0.2)",
    }),
    container: () => ({
        position: "relative",
    }),
    valueContainer: () => ({
        display: "flex",
        flexDirection: "row-reverse",
        overflow: "hidden",
        alignItems: "center",
    }),
    placeholder: () => ({
        fontFamily: "Roboto",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "18px",
        color: "rgba(0, 0, 0, 0.4)",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
};

const Timezone = (props) => {
    const [value, setValue] = useState();

    const options = [
        { value: 2, label: "Калининградское время (МСК -1)" },
        { value: 3, label: "Московское время" },
        { value: 4, label: "Самарское время (МСК +1)" },
        { value: 5, label: "Екатеринбургское время (МСК +2)" },
        { value: 6, label: "Омское время (МСК +3)" },
        { value: 7, label: "Красноярское время (МСК +4)" },
        { value: 8, label: "Иркутское время (МСК +5)" },
        { value: 9, label: "Якутское время (МСК +6)" },
        { value: 10, label: "Владивостокское время (МСК +7)" },
        { value: 11, label: "Магаданское время (МСК +8)" },
        { value: 12, label: "Камчатское время (МСК +9)" },
    ];

    const changeTimezone = (time) => {
        setValue(time);
        props.setTimezone(time.value);
        props.setStateChanged(true);
    };

    return (
        <div className="timezone__mobileStyle">
            <div className="info__block_title">Часовой пояс:</div>
            <div className="timezone_cabinet">
                <img src={time} alt="time" className="time_img" />
                <Select
                    key={props.timezone}
                    components={{ DropdownIndicator }}
                    styles={customStyles}
                    options={options}
                    menuPortalTarget={document.querySelector("body")}
                    value={value}
                    defaultValue={options.filter((val) => val.value === props.timezone)}
                    onChange={changeTimezone}
                    placeholder="Выберите Ваше время"
                />
            </div>
        </div>
    );
};

export default Timezone;
