import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import onCheck from "./svg/checking.svg"

const ModalAccountOnCheck = () => {
    const ctx = useContext(ModalsContext);
  return(
      <div className="modal modal__card3">
          <div className="closeModal" onClick={ctx.closeModal}>
              <ModalCloseSvg/>
          </div>
          <div className="modal_block">
              <h2 className="modal_title_ads">На проверке</h2>
          </div>
          <div className="modal_adv_cloud">идет проверка реквизитов</div>

          <img src={onCheck} alt="onCheck" style={{marginTop: "18px", marginBottom: "6px"}}/>

          <div className="modal_advers_text">
              <p><span>Ожидаем поступления оплаты</span> для подтверждения юридической информации.</p>
              <p>Процесс занимает в течение 48 часов.</p>
              <p>После проверки Вам откроется доступ к запуску рекламной компании!</p>
              <p>Вам придёт уведомление в Личный кабинет</p>
          </div>

          <button onClick={ctx.closeModal}
              type="button" className="modal__btn">Закрыть</button>
      </div>
  )
}

export default ModalAccountOnCheck;