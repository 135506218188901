import style from "../../cabinet/subscribe/subscribe.module.sass";
import AdversItems from "../ads/AdversItems";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {checkboxAction, statusAction} from "../../store/redux";
import {link} from "../../store/host-store";
import {token} from "../../App";

const LaunchItems = () => {
    const checkboxArr = useSelector((state) => state.checkboxes);
    const [arr, setArr] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.setItem('ads', "item=" + checkboxArr.checkedArr.map(item => item));
    }, [checkboxArr])


    useEffect(() => {
        dispatch(statusAction.toggleStatus(3))
        fetch(`${link}/advertisement/user-advertisements?filter[status]=3&pagination=0`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        }).then(res => res.json())
            .then(res => {
                setArr(res.data)
                console.log(res.data)
                dispatch(checkboxAction.resetStates(res.data.length))
            })
    }, [])

  return(
      <div className='cabinet__redesign_container'>
          <h3 className="cabinet__redesign_title">Выберите рекламу</h3>
          <div className={style.promotion_block}>
              <div className="adv_wrap">
                  <AdversItems arr={arr}/>
              </div>
          </div>
      </div>
  )
}

export default LaunchItems;