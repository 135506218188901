import { NavLink } from "react-router-dom";
import {useContext, useEffect, useState} from "react";

import { CustomScrollbarHorizontal } from "../../UI/Scrollbar/CustomScrollbar";
import AdvertisementCheckboxAll from "./AdvertisementCheckboxAll";
import DeviceContext from "../../store/device-context";
import AuthContext from "../../store/auth-context";
import TypeList from "./desktopPages/TypeList";
import AdsItems from "./desktopPages/AdsItems";
import getHost from "../../store/host-store";
import FilterStatus from "./FilterStatus";
import { token } from "../../App";
import { useSelector } from "react-redux";
import ModalsContext from "../../store/modals-context";
import SearchInput from "../../UI/SearchInput/SearchInput";

const MainPage = ({ setActiveToggle, category, type, setType, adId, setAdId, activeToggle }) => {
    const [arr, setArr] = useState([]);
    const selectorUrl = useSelector((state) => state.adsSearchLogic.urlCreate);
    const status = useSelector((state) => +state.status.status);
    const [search, setSearch] = useState("");
    const [fetchAd, setFetchAd] = useState('');
    const device = useContext(DeviceContext);
    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);

    const deactivateHandler = (event, status) => {
        let data = {
            status: status === 1 ? 3 : 1,
            active: status === 0 ? 0 : 1,
        };
        fetch(
            getHost({
                controller: "object",
                action: event,
            }),
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data),
            }
        );
        setActiveToggle(false);
    };

    const deleteHandler = async (event) => {
        await fetch(
            getHost({
                controller: "object",
                action: event,
            }),
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ status: 4 }),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                console.log(res.data);
                console.log(event);
            });
        await ctx.setRerender((prev) => !prev);
        setActiveToggle(false);
    };

    useEffect(() => {
        if (search.length === 0){
            setSearch('')
            setFetchAd(prevState => !prevState)
        }
    }, [search]);

    return (
        <div className="cabinet_for_mobile_btn cabinet__redesign_container">
            <h2 className="h2_cabinet_main">Мои объявления</h2>
            {device.isMobile && ctx.userData.mail_confirmed ? (
                <div className="advers_top_flex_right">
                    <NavLink to={selectorUrl} className="btn_save">
                        Разместить объявление
                    </NavLink>
                </div>
            ) : (
                device.isMobile && (
                    <div className="advers_top_flex_right" onClick={modal.unconfirm}>
                        <NavLink to="" className="btn_save">
                            Разместить объявление
                        </NavLink>
                    </div>
                )
            )}
            <div className="cabinet_ads">
                <div className="cabinet_ads__left">
                    {/*<ChapterStatus />*/}
                    <div className="ads__top_block">
                        <div className="advers___title">Категории объявлений:</div>
                        <TypeList category={category} type={type} onTypeChange={setType} />
                    </div>

                    <div className="ads__filters__block">
                        <div className="advers___title">Статус объявлений:</div>

                        <div className="advers__flex_top">
                            {!device.scrollHorizontal && <FilterStatus type={type} />}
                            {device.scrollHorizontal && (
                                <CustomScrollbarHorizontal style={{ height: "27px" }}>
                                    <FilterStatus type={type} />
                                </CustomScrollbarHorizontal>
                            )}
                            {!device.isMobile && ctx.userData.mail_confirmed ? (
                                <div className="advers_top_flex_right">
                                    <NavLink end to={selectorUrl} className="btn_save">
                                        Разместить объявление
                                    </NavLink>
                                </div>
                            ) : (
                                !device.isMobile && (
                                    <div className="advers_top_flex_right" onClick={modal.unconfirm}>
                                        <NavLink to="" className="btn_save">
                                            Разместить объявление
                                        </NavLink>
                                    </div>
                                )
                            )}
                        </div>

                        <div className="advers__flex__bottom">
                            {status !== 2 && <AdvertisementCheckboxAll arr={arr} id={adId} />}

                            {/* <div className="flex__advers__sorter">
                                Сортировка
                                <ArrowSvg />
                            </div> */}
                        </div>
                        <div style={{marginTop: '10px', maxWidth: '440px'}}>
                            <SearchInput setSearch={setSearch} fetch={setFetchAd}/>
                        </div>
                    </div>

                    <AdsItems
                        type={type}
                        getIdItem={setAdId}
                        id={adId}
                        deactivateHandler={deactivateHandler}
                        activeToggle={activeToggle}
                        setActiveToggle={setActiveToggle}
                        deleteHandler={deleteHandler}
                        getArr={setArr}
                        fetchAd={fetchAd}
                        search={search}
                    />
                </div>
            </div>
        </div>
    );
};

export default MainPage;
