import {PartnerProgramVideoBlock} from "./videoBlock/PartnerProgramVideoBlock";
import v1 from "./images/videoBlock/1.svg"
import v2 from "./images/videoBlock/2.svg"
import video from "./images/videoBlock/video.jpg"

export const PartnerProgramVideo = () => {
  return(
      <div className='partner_program_video'>
        <h2 className="partner_program_h2">Выберите удобный способ, чтобы рассказать <br/> о нас друзьям, коллегам, подписчикам:</h2>
        <div className="partner_program_video_flex">
          <div className="partner_program_video_left">
            <PartnerProgramVideoBlock title="01. По ссылке" src={v1} link="citywork.ru/referal_link"
                descr="Скопируйте свою партнерскую ссылку в личном кабинете и делитесь ей. Вам доступны любые способы привлечения пользователей!"/>
            <PartnerProgramVideoBlock title="02. По реферальному коду " src={v2} link="PROMOKOD_citywork"
                descr="Ваш реферальный код указан в личном кабинете. Делитесь им со своими друзьями, подписчиками либо другой аудиторией."/>
          </div>

          <div className="partner_program_video_right">
              <a href="" target="_blank">
                  <img src={video} alt=""/>
              </a>
          </div>
        </div>

      </div>
  )
}