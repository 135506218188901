import styles from '../subscribe.module.sass'
import SubscribeWorkerSvg from "../svg/SubscribeWorkerSvg";
import {NavLink} from "react-router-dom";

const SubscribeBottomEmpty = () => {
    return(
        <div className={styles.promotion_bottom}>
            <h2 className={styles.promotion_h2}>Создайте подписки</h2>
            <h3 className={styles.promotion_h3}>и получайте заявки!</h3>

            <SubscribeWorkerSvg />

            <div className={styles.promotion_bottom_p}>
                <p className={styles.promotion_main_p}>Выберите интересующие для Вас город и категории,</p>
                <p className={styles.promotion_main_p}>на которые Вам будет интересно получать заказы.</p>
            </div>

            <div className={`${styles.promotion_btn} ${styles.promotion_bottom_Btn}`}>
                <NavLink className={styles.promotion_btn_save} to={`/lc/subscribe/create`}>Создать подписку</NavLink>
            </div>
        </div>
    )
}

export default SubscribeBottomEmpty;