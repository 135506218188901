import React, {useContext, useState} from "react";
import AuthContext from "../../../store/auth-context";
import QuestionSvg from "./QuestionSvg";
import { CSSTransition } from "react-transition-group";
import useCopy from "use-copy";

const Points = () => {
    const [modal, setModal] = useState(false);
    const ctx = useContext(AuthContext);
    const [copySting, setCopyString] = useState('');

    const [copied, copy] = useCopy(copySting);

    const copyLink = async (e, string) => {
        await setCopyString(e.target.value)
        await copy();
        alert(string)
    }

    return (
        <div className="cabinet__points cabinet__right___block">
            <div className="open_modal">
                <div className="modal_wrap" onClick={() => setModal((prev) => !prev)}>
                    <QuestionSvg />
                </div>
                <div className="modal_info_point">
                    <CSSTransition in={modal} timeout={500} unmountOnExit classNames="my-node">
                        <div className="icon__modal" onClick={() => setModal((prev) => !prev)}>
                            Введите промокод друга, который уже работает на сервисе, и после первого пополнения кошелька
                            Вам вдвоём начислят
                            <br />
                            <span> по 1000 бонусов!</span>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className="cabinet__points_title">Ссылка и реф.код</div>
            <input
                type="text"
                onClick={(e) => copyLink( e,'Реферальная ссылка скопирована')
                }
                className="input_points"
                value={window.location.origin + "/login?ref=" + ctx.userData?.referal_code}
                onChange={() => console.log()}
            />
            <div className="points_promo">
                <div className="promo_points_title">Реф.код:</div>
                <input onClick={(e) => copyLink(e, 'Реферальный код cкопирован')}
                       type="text" className="input_points_2" defaultValue={ctx.userData.referal_code}/>
            </div>
            <div className="points_sub__text">
                Получай деньги за каждого активного пользователя. Просто поделись ссылкой или реферальным кодом.
            </div>
        </div>
    );
};

export default Points;