import { useContext } from "react";

import ModeratorContext from "../../store/moderator-context";
import s from "./style.module.sass";
import AdModeratorContext from "../../store/ad-moderator-context";

const OnPublic = ({ isCorrect }) => {
    const adModerator = window.location.href.indexOf('ad_moderator') > -1;
    const ctxAd = useContext(AdModeratorContext);
    const ctx = useContext(ModeratorContext);

    return (
        <div className={s.onPublic}>
            {isCorrect ? (
                <button disabled={adModerator ? ctxAd.loading : ctx.loading} type="button" onClick={adModerator ? ctxAd.onPublicObject : ctx.onPublicObject}>
                    Опубликовать
                </button>
            ) : (
                <button type="button" className={s.disabled} disabled>
                    Опубликовать
                </button>
            )}
        </div>
    );
};

export default OnPublic;
