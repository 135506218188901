export const WhiteStarIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.01798 16C3.68568 16 3.43646 15.9169 3.18724 15.7508C2.77187 15.4185 2.52265 14.92 2.60572 14.3551L3.35339 10.3178L0.479037 7.50987C0.0636683 7.0945 -0.102479 6.51298 0.0636683 6.03115C0.229816 5.53271 0.645184 5.11734 1.21009 5.03427L5.24747 4.45275L7.07509 0.830737C7.32431 0.332295 7.82275 0 8.38766 0C8.96917 0 9.451 0.332295 9.70022 0.830737L11.5112 4.45275L15.5486 5.03427C15.8809 5.11734 16.3793 5.53271 16.5455 6.03115C16.7116 6.5296 16.5455 7.0945 16.2132 7.50987L13.2558 10.3178L13.9204 14.2721C14.0034 14.8536 13.7542 15.3354 13.3389 15.6677C12.9235 16 12.342 16 11.8601 15.7508L8.30458 13.9398L4.76564 15.8339C4.51642 15.9169 4.2672 16 4.01798 16ZM8.30458 12.2949C8.5538 12.2949 8.80303 12.378 8.96917 12.4611L12.2589 14.189L11.6774 10.4839C11.5943 9.98546 11.7605 9.5701 12.0927 9.25441L14.7345 6.61267L11.0294 6.11423C10.531 6.03115 10.1156 5.78193 9.96606 5.28349L8.30458 1.9107L6.65972 5.28349C6.4105 5.69886 6.07821 6.03115 5.59638 6.11423L1.89129 6.69574L4.53304 9.33749C4.86533 9.66978 5.03148 10.1682 4.9484 10.567L4.28381 14.2721L7.34093 12.6604C7.45723 12.594 7.57353 12.5109 7.68984 12.4444C7.88921 12.2949 8.10521 12.2949 8.30458 12.2949Z"
                fill="white"
            />
        </svg>
    );
};
