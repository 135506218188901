import moment from "moment";
import uuid from "react-uuid";

const Orders = ({ data }) => {
    return (
        <>
            <div className="admin__list-titles admin__list-titles_statistic_order">
                <p>ID</p>
                <p>Текст заявки</p>
                <p>Место</p>
                <p>Изменено</p>
                <p>Статус</p>
            </div>
            <div>
                {data?.map((el) => (
                    <div className="admin__objects_object admin__objects_object_statistic_order" key={uuid()}>
                        <span>#{el.object?.id}</span>

                        <p>{el.object?.about}</p>
                        <p>{el.object?.address}</p>
                        <p>{moment(el?.date).format("DD.MM.YYYY")}</p>
                        {el.status === 1 ? (
                            <p className="admin__done">Подтверждено</p>
                        ) : el.status === 2 ? (
                            <p className="admin__rejected">Отклонено</p>
                        ) : (
                            ""
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default Orders;
