import img1 from "../images/statistic/1.svg";
import img2 from "../images/statistic/2.svg";

export const partnerStatisticMock = [
    {
        id: 1,
        img: img1,
        description: 'Чем больше активных пользователей вы приведете, тем больше Ваш заработок.'
    },
    {
        id: 2,
        img: img2,
        description: 'Огромная целевая аудитория, начиная от обычного репетитора, косметолога или строителя, заканчивая крупными компаниями.'
    },
]
