import { useContext } from "react";

import getHost from "../../../store/host-store";
import ObjectCard from "../../../UI/ObjectCard/ObjectCard";
import AuthContext from "../../../store/auth-context";
import s from "./style.module.sass";
import { token } from "../../../App";
import { useSelector } from "react-redux";
import Loader from "../../loader/Loader";
import ObjectOfferCard from "../../../UI/ObjectCard/ObjectOfferCard";

const ProductsList = (props) => {
    const ctx = useContext(AuthContext);

    const addFav = (id) => {
        let data = {
            object_id: id,
            user_id: ctx.isUserId,
        };

        fetch(
            getHost({
                controller: "favourites",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    ctx.setRerender((prev) => !prev);
                }
            });
    };

    const removeFav = (id) => {
        fetch(
            getHost({
                controller: "favourites",
                action: id,
            }),
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    // const onShare = (id, name) => {
    //     const data = {
    //         url: `${frontLink}/cart/${id}`,
    //         title: "CityWork",
    //         text: `Посмотри объявление "${name}" на CityWork`,
    //     };
    //     navigator.share(data);
    // };

    const selector = useSelector((state) => state.catalog.loader);
    return (
        <div className={s.catalog_wrapper}>
            {selector ? (
                <Loader />
            ) : (
                props.categoryProd.map((item) =>
                    item.seek ? (
                        <ObjectOfferCard
                            key={item.id}
                            id={item.id}
                            mark={item.user?.mark}
                            name={item.name}
                            about={item.about}
                            image={item.image}
                            final_date={item.final_date}
                            alias={item.alias}
                            fullAllias={item.category?.fullAlias}
                            toggleFav={
                                item.userFavourite !== undefined && item.userFavourite.length > 0 ? removeFav : addFav
                            }
                            isFavorite={item.userFavourite !== undefined && item.userFavourite.length > 0}
                            favId={
                                item.userFavourite !== undefined && item.userFavourite.length > 0
                                    ? item.userFavourite[0].id
                                    : ""
                            }
                        />
                    ) : (
                        <ObjectCard
                            key={item.id}
                            id={item.id}
                            mark={item.user?.mark}
                            name={item.name}
                            about={item.about}
                            image={item.image}
                            status_busy={item.status_busy}
                            price_1={item.price_1}
                            price_1_name={item.price_1_name}
                            price_2={item.price_2}
                            price_2_name={item.price_2_name}
                            fixation={item.activeFixation}
                            activeShow={item.activeShow}
                            alias={item.alias}
                            fullAllias={item.category?.fullAlias}
                            item={item}
                            toggleFav={
                                item.userFavourite !== undefined && item.userFavourite.length > 0 ? removeFav : addFav
                            }
                            isFavorite={item.userFavourite !== undefined && item.userFavourite.length > 0}
                            favId={
                                item.userFavourite !== undefined && item.userFavourite.length > 0
                                    ? item.userFavourite[0].id
                                    : ""
                            }
                        />
                    )
                )
            )}
        </div>
    );
};

export default ProductsList;
