import PromotionViewsSvg from "./svg/PromotionViewsSvg";
import PromotionPinSvg from "./svg/PromotionPinSvg";

const AdvertisementPromotion = ({promotionExtend, item, status}) => {

    return(
        <>
            <div className="advers_promotion">
                <div className="advers_promotion_left">
                    <div className="advers_promo_white">Продвижение:</div>
                    {item.activeShow !== null &&
                        <div className="advers_promotion_sub">
                            <PromotionViewsSvg />
                            <span className="advers_promotion_sub_title">Показы</span>
                        </div>
                    }
                    {item.activeFixation !== null &&
                        <div className="advers_promotion_sub">
                            <PromotionPinSvg />
                            <span className="advers_promotion_sub_title">Закрепление</span>
                        </div>
                    }
                </div>

                <div className="advers_promotion_right">
                    <button type="button" className="advers_promo_btn" onClick={() => promotionExtend(item.id)}>Продлить</button>
                </div>
            </div>
        </>
    )
}

export default AdvertisementPromotion;