import {partnerStatisticMock} from "./statistic/partnerStatisticMock";
import {PartnerProgramStatisticBlock} from "./statistic/PartnerProgramStatisticBlock";
import bg from "./images/statistic/bg.png";

export const PartnerProgramStatistic = () => {
  return(
      <div className="partner_program_statistic">
          <div className="partner_program_statistic_flex">
              <div className="partner_program_statistic_left">
                  <h2 className="partner_program_h2">Статистика</h2>
                  <p className="partner_program_statistic_text">
                      В личном кабинете пользователя на сайте CityWork, в разделе партнерская программа
                      Вы сможете детально изучить статистику:
                      всех зарегистрировавшихся пользователей, количество активных
                      пользователей и многая другая полезная информация.
                  </p>

                  {partnerStatisticMock.map(item => (
                      <PartnerProgramStatisticBlock key={item.id} className={item.id} img={item.img} description={item.description}/>
                  ))}
              </div>

              <div className="partner_program_statistic_right">
                  <img src={bg} alt=""/>
              </div>
          </div>
      </div>
  )
}