import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";

import objImg from "../../../assets/images/stubs/object-mob.png";
import PaginationPattern from "../../../UI/PaginationPattern";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import getHost, { url } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import { token } from "../../../App";

const ObjectsTab = ({ userId }) => {
    const [currentObjects, setCurrentObjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        fetch(
            getHost({
                controller: "object",
                filter: {
                    user_id: userId,
                },
                expand: "city, category",
                pagination: {
                    pageSize: 5,
                    page,
                },
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                setLoading(false);
                if (result.data.success) {
                    setCurrentObjects(result.data.data);
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                }
            });
    }, [page, userId]);

    return (
        <div className="admin_one-user_objects_tabs">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="admin__list-titles admin__list-titles_obj">
                        <p>ID</p>
                        <p>Фото</p>
                        <p>Категория</p>
                        <p>Название</p>
                        <p>Статус</p>
                    </div>
                    <div className="admin_objects_wrapper">
                        {currentObjects.length > 0 ? (
                            currentObjects.map((el) => (
                                <div className="admin__objects_object" key={el.id}>
                                    <span>#{el.id}</span>
                                    {el.image === null ? (
                                        <img src={objImg} alt="object" />
                                    ) : el.image.webpFilename ? (
                                        <img src={url + el.image.webpFilename} alt="object" />
                                    ) : (
                                        <img src={url + el.image.filename} alt="object" />
                                    )}
                                    <p>{el.category.title}</p>
                                    <p>{el.name}</p>
                                    {el.status === 0 ? (
                                        <p className="admin__waiting">Ожидает подтверждения</p>
                                    ) : el.status === 1 ? (
                                        <p className="admin__done">Подтверждено</p>
                                    ) : (
                                        <p className="admin__rejected">Отклонено</p>
                                    )}
                                    <NavLink to={`/admin/objects/${el.id}`}>
                                        <EyeIcon />
                                    </NavLink>
                                </div>
                            ))
                        ) : (
                            <>Нет объявлений</>
                        )}
                        <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ObjectsTab;
