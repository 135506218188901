export const BlueStarIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.6608 11C2.44075 11 2.27571 10.9429 2.11067 10.8287C1.8356 10.6002 1.67056 10.2575 1.72557 9.86916L2.2207 7.09346L0.31723 5.16303C0.0421627 4.87747 -0.0678642 4.47767 0.0421627 4.14642C0.15219 3.80374 0.427257 3.51817 0.801349 3.46106L3.475 3.06127L4.6853 0.571132C4.85034 0.228453 5.18042 0 5.55451 0C5.93961 0 6.25869 0.228453 6.42373 0.571132L7.62302 3.06127L10.2967 3.46106C10.5167 3.51817 10.8468 3.80374 10.9568 4.14642C11.0669 4.4891 10.9568 4.87747 10.7368 5.16303L8.7783 7.09346L9.21841 9.81205C9.27342 10.2118 9.10838 10.5431 8.83332 10.7715C8.55825 11 8.17315 11 7.85408 10.8287L5.4995 9.58359L3.15593 10.8858C2.99089 10.9429 2.82584 11 2.6608 11ZM5.4995 8.45275C5.66454 8.45275 5.82958 8.50986 5.93961 8.56698L8.11814 9.75493L7.73305 7.20768C7.67803 6.86501 7.78806 6.57944 8.00811 6.36241L9.75754 4.54621L7.30394 4.20353C6.97386 4.14642 6.69879 3.97508 6.59977 3.6324L5.4995 1.3136L4.41023 3.6324C4.24519 3.91796 4.02514 4.14642 3.70606 4.20353L1.25246 4.60332L3.00189 6.41952C3.22194 6.64797 3.33197 6.99065 3.27695 7.2648L2.83685 9.81205L4.86134 8.70405C4.93836 8.65836 5.01538 8.60124 5.0924 8.55555C5.22443 8.45275 5.36747 8.45275 5.4995 8.45275Z"
                fill="#546EDB"
            />
        </svg>
    );
};
