import {ProgramPartnerUsers} from "./partnerProgramWaiting/ProgramPartnerUsers";
import {ProgramPartnerUsersProcessed} from "./partnerProgramProcessed/ProgramPartnerUsersProcessed";
import FormModal from "../../../UI/Modal/FormModal/FormModal";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useGetPricesPartnerQuery, usePutPricesPartnerMutation} from "../../../store/redux/queries/partnerProgram/partner-program-prices";

export const PartnerProgramMain = () => {
    const [openModal, setOpenModal] = useState(false);
    const {register, handleSubmit} = useForm();
    const [setPriceProgram] = usePutPricesPartnerMutation()
    const {data: dataPercent} = useGetPricesPartnerQuery({id: 4})
    const {data: dataMin} = useGetPricesPartnerQuery({id: 5})
    const {data: dataCommission} = useGetPricesPartnerQuery({id: 6})

    console.log(dataPercent?.data.text)
    console.log(dataMin?.data.text)
    console.log(dataCommission?.data.text)

    const handlePrices = async (data) => {
        console.log(data)
        if (data?.text1.length > 0){
            setPriceProgram({id: 4, text: {text: data.text1}})
        }
        if (data?.text2.length > 0){
            setPriceProgram({id: 5, text: {text: data.text2}})
        }
        if (data?.text3.length > 0){
            setPriceProgram({id: 6, text: {text: data.text3}})
        }
        await setOpenModal(false)
    }
  return(
      <div className="admin__layout">
          <div className="flex" style={{justifyContent: 'space-between', alignItems: "center", marginBottom: '10px'}}>
              <h3 style={{marginBottom: 0}}>Партнёрская программа</h3>
                <button className="btnZXC" type="button" onClick={() => setOpenModal(prevState => !prevState)}>Условия партнерской программы</button>

              {openModal && (
                  <FormModal
                      title="Условия партнёрской программы"
                      button="Применить"
                      fn={handleSubmit(handlePrices)}
                      isOpenModal={setOpenModal}
                  >
                      <h4 className='h4_partner_modal'>Процент партнёру</h4>
                      <input {...register("text1")} defaultValue={dataPercent?.data.text} type="number"/>
                      <h4 className='h4_partner_modal'>Минимальная сумма к выводу</h4>
                      <input {...register("text2")} defaultValue={dataMin?.data.text} type="number"/>
                      <h4 className='h4_partner_modal'>Комиссия сервиса</h4>
                      <input {...register("text3")} defaultValue={dataCommission?.data.text} type="number"/>
                  </FormModal>
              )}
          </div>
          <p className='partner_program_sub'>Заявки на вывод:</p>
          <ProgramPartnerUsers />
          <p style={{marginTop: "40px", marginBottom: "20px"}} className='partner_program_sub'>Обработанные заявки:</p>
          <ProgramPartnerUsersProcessed />
      </div>
  )
}