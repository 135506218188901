const CalendarDesktopSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="none">
            <circle cx="14" cy="14" r="14" fill="#D3D9F3" />
            <path
                fill="#546EDB"
                d="M11.563 8c.31 0 .562.252.562.562v1.125a.562.562 0 1 1-1.125 0V8.562c0-.31.252-.562.563-.562ZM16.438 8c.31 0 .562.252.562.562v1.125a.562.562 0 1 1-1.125 0V8.562c0-.31.252-.562.563-.562Z"
            />
            <path
                fill="#546EDB"
                d="M8 10.813c0-1.138.923-2.062 2.063-2.062a.562.562 0 1 1 0 1.125.937.937 0 0 0-.938.938v7.124c0 .517.42.937.938.937h7.874c.518 0 .938-.42.938-.937v-7.124a.937.937 0 0 0-.938-.938.562.562 0 1 1 0-1.125c1.14 0 2.063.924 2.063 2.063v7.124A2.062 2.062 0 0 1 17.937 20h-7.875A2.062 2.062 0 0 1 8 17.938v-7.124Z"
            />
            <path
                fill="#546EDB"
                d="M12.5 9.314c0-.311.252-.563.563-.563h1.874a.562.562 0 1 1 0 1.125h-1.874a.562.562 0 0 1-.563-.562ZM11.75 14.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM11 17.75a.75.75 0 1 0 0-1.501.75.75 0 0 0 0 1.5ZM14.75 12.5a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14 15.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM14.75 16.999a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM17 13.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM17.75 14.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM17 17.75a.75.75 0 1 0 0-1.501.75.75 0 0 0 0 1.5Z"
            />
        </svg>
    )
}

export default CalendarDesktopSvg;