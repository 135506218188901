export const SettingsIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7923 7.31501L10.6596 6.45251C10.674 6.3195 10.6863 6.16402 10.6863 5.99951C10.6863 5.835 10.6745 5.67952 10.6596 5.54651L11.7933 4.68352C12.0038 4.52152 12.0613 4.23401 11.9273 3.99452L10.7494 2.01C10.6236 1.78601 10.3479 1.671 10.0681 1.7715L8.73154 2.29399C8.47533 2.11399 8.20678 1.962 7.92901 1.8405L7.72568 0.460008C7.69229 0.198 7.45816 0 7.18142 0H4.81952C4.54275 0 4.30915 0.198 4.27627 0.456L4.07243 1.84151C3.80337 1.95952 3.53896 2.10952 3.27144 2.295L1.93131 1.77101C1.68021 1.67651 1.37983 1.78249 1.25506 2.0055L0.0756645 3.99251C-0.0634747 4.22152 -0.00597541 4.51901 0.209172 4.68502L1.34185 5.54752C1.32387 5.71601 1.31516 5.86252 1.31516 6.00002C1.31516 6.13753 1.3239 6.28402 1.34185 6.45302L0.208137 7.31602C-0.00238922 7.47851 -0.0593831 7.76602 0.0746296 8.00501L1.25251 9.98951C1.37832 10.213 1.65147 10.329 1.93386 10.228L3.2704 9.70551C3.52611 9.88502 3.79464 10.037 4.07243 10.159L4.27576 11.539C4.30912 11.802 4.54275 12 4.82002 12H7.18192C7.45869 12 7.69282 11.802 7.72568 11.544L7.92951 10.159C8.19857 10.0405 8.46248 9.891 8.73051 9.705L10.0706 10.229C10.1353 10.2535 10.2026 10.266 10.2719 10.266C10.4711 10.266 10.6544 10.16 10.7469 9.99499L11.9299 7.99999C12.0613 7.76599 12.0038 7.47851 11.7923 7.31501ZM6.00046 7.99999C4.86777 7.99999 3.94663 7.10299 3.94663 6C3.94663 4.89701 4.86777 4.00001 6.00046 4.00001C7.13314 4.00001 8.05428 4.89701 8.05428 6C8.05428 7.10299 7.13314 7.99999 6.00046 7.99999Z"
                fill="white"
            />
        </svg>
    );
};
