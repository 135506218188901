import {isAppstore} from "../../App";
import "../cabinet.sass";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Wallet from "./walletAndPoints/Wallet";
import Points from "./walletAndPoints/Points";
import Promocode from "./walletAndPoints/Promocode"

const CabinetInfoWalletPoints = () => {

    const settings = {
        infinite: false,
        slidesToShow: isAppstore ? 2 : 3,
        slidesToScroll: isAppstore ? 2 : 3,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20%',
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 410,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '15%',
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '10%',
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };


    return (
        <div className="cabinet__wallet_wrap">
            <Slider {...settings}>
                {/*!isAppstore  && */ <Wallet/>}
                <Points />
                <Promocode />
            </Slider>
        </div>
    )
}

export default CabinetInfoWalletPoints;