import InputPhoto from "../../InputPhoto";

const CreateSearchAbout = ({register, setImages, images, errors}) => {
    return(
        <div className="create_ads_m_block create_ads_border">
            <div className="advers___title">Описание:</div>

            <div className="input_wrap input_wrap_100">
                <input className="input" type="text" placeholder='Введите заголовок' {...register('name')}/>
                <p className="error">{errors.name?.message}</p>
            </div>

            <div className="input_wrap input_wrap_100">
                <textarea placeholder='Описание требуемой услуги' className='textarea' {...register('about')}></textarea>
                <p className="error">{errors.about?.message}</p>
            </div>

            <InputPhoto
                images={images}
                onLoad={setImages}
                onDelete={setImages}
                maxCount={5}
                limitText={"До 5-ти фото."}
            />
        </div>
    )
}

export default CreateSearchAbout;