import { DatePicker } from 'react-nice-dates'
import {ru} from "date-fns/locale";
import moment from "moment/moment";

const CreateSearchTime = ({register, date, setDate, errors}) => {
    const promotion = window.location.href.indexOf("promocode") > -1;
    const modifiers = {
        disabled: ((date) => date > new Date(new Date().setDate(new Date().getDate() + 90))),
        highlight: (date => date < new Date(new Date().setDate(new Date().getDate() - 1)))
    }
    const modifiersPromotion = {
        highlight: (date => date < new Date(new Date().setDate(new Date().getDate() - 1)))
    }

    const modifiersClassNames = {
        disabled: '-disabled',
    }
    const today = !promotion ? moment(new Date(new Date().setDate(new Date().getDate() + 90))).format("DD.MM.YYYY") : 'дд.мм.гг';
    return(
        <div className={`create_ads_m_block create_ads_calendar ${!promotion ? 'create_ads_border ' : ''}`}>
            <div className="advers___title">Время:</div>
            <div className="create_search_time_flex">
                {!promotion && <div className="create_search_title">Срок выполнения до:</div>}
            <DatePicker
                date={date}
                onDateChange={setDate}
                modifiers={!promotion ? modifiers : modifiersPromotion}
                modifiersClassNames={modifiersClassNames}
                locale={ru}>
                {({ inputProps, focused }) => (
                    <input
                        className={'input' + (focused ? ' -focused' : '')}
                        {...inputProps}
                        placeholder={today}
                    />
                )}
            </DatePicker>

                <div className="create_search_ads_time">
                    <input type="time" defaultValue="12:00" {...register('time')}/>
                </div>

            </div>
            <p className="error">{errors.finalDate?.message}</p>
        </div>
    )
}

export default CreateSearchTime;