import {CSSTransition, TransitionGroup} from "react-transition-group";
import object from "../../assets/images/stubs/object-mob.png";
import {url} from "../../store/host-store";
import {useDispatch, useSelector} from "react-redux";
import {checkboxAction} from "../../store/redux";
import AdvText from "./itemsComponents/AdvText";
import AdvButtons from "./itemsComponents/AdvButtons";
import AdvStatistic from "./itemsComponents/AdvStatistic";
import {useEffect} from "react";

const AdversItems = ({arr, extendItem, editItem, deleteItem, launchView}) => {
    const launch = window.location.href.indexOf('launch') > -1
    const dispatch = useDispatch();
    const getCheckboxesState = useSelector((state) => state.checkboxes);
    const status = useSelector(state => state.status.status);

    useEffect(() => {
        localStorage.setItem('ads', "item=" + getCheckboxesState.checkedArr.map(item => item));
    }, [getCheckboxesState])

    const changeCheckbox = (id) => {
        dispatch(checkboxAction.changeCheckboxes(id));
    };

  return(
      <div className={`loads_advers ${!launch ? 'advers_wrap' : ''}`}>
          <TransitionGroup className="flex__advers_768">
              {arr.length > 0 && arr?.map((item, i) => (
                  <CSSTransition key={item.id} timeout={100} classNames="fade">
                      <div style={{zIndex: arr.length - i}} className={`cabinet_ads__item ${(+status !== 1 && item.status !== 2 ) ? '' : 'cabinet_ads__item_margin'}`}>
                          <div className="adver_wrap__flex adver_wrap__flex_custom">
                              <div className="img imgAdvers_adv">
                                  {item.image === null ? (
                                      <img src={object} alt="object" />
                                  ) : (
                                      <img src={url + item.image} alt="object" />
                                  )}
                                  {!launchView &&
                                      <label key={item.id} className="container_checkbox">
                                          <input
                                              datatype={item.id}
                                              type="checkbox"
                                              className="advers__settings advers__settings_checkbox"
                                              checked={
                                                  getCheckboxesState.checkedArr.find((el) => el === item.id) ||
                                                  getCheckboxesState.isCheckAll
                                              }
                                              onChange={() => {
                                                  changeCheckbox(item.id);
                                              }}
                                          />
                                          <span className="checkmark"></span>
                                      </label>
                                  }
                              </div>

                                <AdvText name={item?.title} description={item?.description} city={item?.cities}/>

                              {!launchView &&
                                  <AdvButtons
                                      id={item?.id}
                                      extendItem={extendItem}
                                      editItem={editItem}
                                      deleteItem={deleteItem}
                                      changeCheckbox={changeCheckbox}
                                      statusProps={item.status}
                                  />
                              }
                          </div>
                          {+status === 1 &&
                              <AdvStatistic
                                  final_date={item?.finalDate}
                                  views={item?.views}
                                  views_new={item?.views_new}
                                  tg={item?.tg}
                                  tg_new={item?.tg_new}
                              />
                          }

                          {item.status === 2 &&
                              <AdvStatistic
                                status={item.status}
                              />
                          }
                      </div>
                  </CSSTransition>
              ))}
          </TransitionGroup>

          {+status === 3 && launch &&
              (
                  getCheckboxesState.checkedArr.length > 0 ?
                  <a href='/advertiser/launch/ads_items' className='ads_items_link'>Продолжить</a>
                      :
                      <div style={{cursor: "pointer"}}
                           onClick={() => alert('Выберите хотя бы одно рекламное объявление')} className='ads_items_link'>Продолжить</div>
              )
          }
      </div>
  )
}

export default AdversItems;