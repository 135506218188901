import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import modalLock from "../../../assets/images/icons/modalimg3.webp";
import { CloseIcon } from "../../../components/icons/CloseIcon";
import ModalsContext from "../../../store/modals-context";
import ModalUnauth from "../Authentification/LoginModal";
import ModalOpenedContacts from "./ModalOpenedContacts";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import ModalNonConfirm from "../ModalNonConfirm";
import getHost from "../../../store/host-store";
import { token } from "../../../App";
import s from "./style.module.sass";

const ModalContacts = () => {
    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);

    const [userContacts, setUserContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPaid, setIsPaid] = useState(true);

    const location = useLocation();

    const openContacts = () => {
        modal.paymentRequest(+location.pathname.split('_').pop());
    };

    useEffect(() => {
        fetch(
            getHost({
                controller: "user",
                action: `get-contact-payment?objectId=${+location.pathname.split('_').pop()}`,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (result.success) {
                    if (result.data.status === 404) {
                        setIsPaid(true);
                    } else {
                        setUserContacts(result.data);
                        setIsPaid(true);
                    }
                }
            });
    }, [isPaid]);

    return loading ? (
        <Loader />
    ) : (
        <>
            {/* проверка на авторизацию у пользователя */}
            {ctx.userData?.id ? (
                // проверка на подтверждение почты
                ctx.userData.mail_confirmed === null || ctx.userData.mail_confirmed === 0 ? (
                    <ModalNonConfirm />
                 ) : isPaid ? (
                    // если контакты оплачены, сразу открывается контакт
                    <ModalOpenedContacts userPhone={userContacts} />
                ) : (
                    <div className={`modal ${s.modal}`}>
                        <div className={s.close} onClick={modal.closeModal}>
                            <CloseIcon />
                        </div>
                        <h3>Контакты</h3>
                        <p className={s.text}>Чтобы открыть контакты, оплатите средствами из кошелька или баллами</p>

                        <img width={233} height={123} src={modalLock} alt="open contacts" />
                        <p className={s.text}>
                            После успешной оплаты Вам будут
                            <span className={s.special}> доступны контакты заказчика, в течение 24 часов</span>
                        </p>
                        <button type="button" onClick={openContacts}>
                            Открыть контакты
                            <span>{modal.money} ₽</span>
                        </button>
                    </div>
                )
            ) : (
                <ModalUnauth />
            )}
        </>
    );
};

export default ModalContacts;
