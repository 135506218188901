import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { TabSearch } from "../../components/icons/TabSearch";
import { TabOffer } from "../../components/icons/TabOffer";
import { adsSearchAction } from "../../store/redux";
import s from "./style.module.sass";

const RoleTabs = ({ isSquare, withoutIcons, firstTitle, secondTitle }) => {
    const dispatch = useDispatch();
    const [tabHide, setTabHide] = useState(null);

    useEffect(() => {
        if (window.location.href.indexOf("advertisement/edit-search") > -1) {
            setTabHide(2);
        } else if (window.location.href.indexOf("advertisement/edit-offer") > -1) {
            setTabHide(1);
        }else if(window.location.href.indexOf("lc/subscribe") > -1){
            setTabHide(2);
        }
        else {
            setTabHide(null);
        }
    }, [window.location.href]);

    const onChangeTab = (status) => {
        dispatch(adsSearchAction.toggleStatus(status));
    };

    const adsSelector = useSelector((state) => state.adsSearchLogic.statusAds);
    const notification = useSelector((state) => state.notification.data);

    useEffect(() => {
        if(notification?.id !== 0){
            dispatch(adsSearchAction.toggleStatus(notification?.seek));
        }
    }, [notification])


    return (
        <div className={`${s.tabs} ${isSquare ? s.square : s.circle}`}>
            {tabHide !== 2 && (
                <div onClick={() => onChangeTab(0)} className={`${s.tab} ${adsSelector === 0 ? s.active : ""}`}>
                    {!withoutIcons && (
                        <div className={s.icon}>
                            <TabOffer />
                        </div>
                    )}
                    <p>{secondTitle}</p>
                </div>
            )}
            {tabHide !== 1 && (
                <div onClick={() => onChangeTab(1)} className={`${s.tab} ${adsSelector === 1 ? s.active : ""}`}>
                    {!withoutIcons && (
                        <div className={s.icon}>
                            <TabSearch />
                        </div>
                    )}

                    <p>{firstTitle}</p>
                </div>
            )}
        </div>
    );
};
export default RoleTabs;
