import { useContext } from "react";
import ModeratorContext from "../../../../store/moderator-context";
import AuthContext from "../../../../store/auth-context";
import s from "./style.module.sass";
import AdModeratorContext from "../../../../store/ad-moderator-context";

const ObjCounter = () => {
    const adModerator = window.location.href.indexOf('ad_moderator') > -1;
    const moder = useContext(ModeratorContext);
    const adModer = useContext(AdModeratorContext);
    const ctx = useContext(AuthContext);

    return (
        <div className={s.counter}>
            <div className={s.counter__queue}>
                <h4>В очереди на модерацию: </h4>
                <p className={s.counter__queue_count}>
                    {adModerator ? adModer.objectsAmount : moder.objectsAmount} <span>позиций</span>
                </p>
            </div>
            <div className={s.counter__created}>
                <h4>Выполнено</h4>
                <div className={s.counter__tabs}>
                    <span>{ctx.userData?.statistics?.objectsToday} за день</span>
                    <span>{ctx.userData?.statistics?.objectsPerPeriod} за период</span>
                </div>
            </div>
        </div>
    );
};

export default ObjCounter;
