import { NavLink } from "react-router-dom";
import s from "./style.module.sass";

const ChooseButton = ({ title, to }) => {
    return (
        <div className={s.link_wrapper}>
            <NavLink className={s.link} to={to}>
                {title}
            </NavLink>
        </div>
    );
};

export default ChooseButton;
