import {useContext, useEffect, useState} from "react";
import CabinetInfoWalletPoints from "./CabinetInfoWalletPoints";
import deviceContext from "../../store/device-context";
import {useIsMtsConfirmedQuery, useLazyMtsGetLinkQuery} from "../../store/redux/queries/mts/mts-queries";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {MtsButton} from "./MtsButton";

const CabinetInfoRight = () => {
    const device = useContext(deviceContext);
    const isMainCabinetPage = window.location.href.indexOf('/lc') > -1;

    return (
        <div className="right__cabinet__main_flex">
            {!device.isMobile && <CabinetInfoWalletPoints />}

            {isMainCabinetPage ? <MtsButton /> : ''}
        </div>
    );
};

export default CabinetInfoRight;
