export const QuestionIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 0C4.26138 0 0 4.26138 0 9.5C0 14.7386 4.26138 19 9.5 19C14.7386 19 19 14.7386 19 9.5C19 4.26138 14.7386 0 9.5 0ZM9.10415 15.8333C8.44932 15.8333 7.91665 15.3007 7.91665 14.6458C7.91665 13.991 8.44932 13.4583 9.10415 13.4583C9.75899 13.4583 10.2917 13.991 10.2917 14.6458C10.2917 15.3007 9.75899 15.8333 9.10415 15.8333ZM10.2917 11.0045V12.2708C10.2917 12.4896 10.1146 12.6667 9.89581 12.6667H8.3125C8.0937 12.6667 7.91665 12.4896 7.91665 12.2708V10.2917C7.91665 9.41884 8.62715 8.70835 9.5 8.70835C10.3728 8.70835 11.0833 7.99785 11.0833 7.125C11.0833 6.25215 10.3728 5.54165 9.5 5.54165C8.62715 5.54165 7.91665 6.25215 7.91665 7.125V7.52085C7.91665 7.73964 7.73961 7.91669 7.52081 7.91669H5.9375C5.7187 7.91669 5.54165 7.73964 5.54165 7.52085V7.125C5.54165 4.94249 7.31749 3.16665 9.5 3.16665C11.6825 3.16665 13.4583 4.94252 13.4583 7.125C13.4583 9.02455 12.1201 10.6342 10.2917 11.0045Z"
                fill="#fff"
            />
        </svg>
    );
};
