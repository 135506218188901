import s from "../../../moderator/main/object/components/style.module.sass";
import CorrectBtn from "../../../moderator/buttons/CorrectBtn";
import IncorrectBtn from "../../../moderator/buttons/IncorrectBtn";

const DescriptionAds = ({ object, incorrectClick, correctClick, isActive }) => {
  return(
      <div className={`${s.card} ${isActive ? s.active : ""}`}>
          <ul>
              <li>
                  <h5>Название</h5>
                  <p className={s.card__about}>{object.title}</p>
              </li>
              <li>
                  <h5>Описание</h5>
                  <p className={s.card__about}>{object.description}</p>
              </li>
          </ul>
          <div>
              <CorrectBtn isActive={isActive} correctClick={() => correctClick("descr")} />
              <IncorrectBtn incorrectClick={incorrectClick} />
          </div>
      </div>
  )
}

export default DescriptionAds;