import AsyncSelect from "react-select/async";
import { useContext, useEffect, useState } from "react";

import { customStyles, DropdownIndicator } from "../../../../cabinet/advertisement/create/CitySelects";
import ModeratorContext from "../../../../store/moderator-context";
import OnChangeBtn from "../../../buttons/OnChangeBtn";
import CorrectBtn from "../../../buttons/CorrectBtn";
import getHost, {link} from "../../../../store/host-store";
import { token } from "../../../../App";
import s from "./style.module.sass";

const CategoryAdv = ({ objectData, correctClick, isActive }) => {
    const ctx = useContext(ModeratorContext);
    const [isChangeState, setIsChangeState] = useState(false);

    const [type, setType] = useState({ value: null, label: "" });
    const [parentCategory, setParentCategory] = useState({ value: null, label: "" });
    const [category, setCategory] = useState({ value: null, label: "" });

    const toggleChangeBtn = () => {
        ctx.setIsCorrectAdv({ ...ctx.isCorrectAdv, category: false });
        if (isChangeState) {
            setIsChangeState(false);
        } else {
            setIsChangeState(true);
        }
    };

    useEffect(() => {
        if (objectData.type !== 2 || (objectData.type === 2 && objectData.category.equipment)) {
            // или НЕ недвижимость или аренда недвижимости
            fetch(
                getHost({
                    controller: "category",
                    action: objectData.category.parent_id,
                }),
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        console.log(result.data)
                        setType({ value: result.data.type, label: getLabelType(result.data.type) });
                        setParentCategory({ value: result.data.id, label: result.data.title });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            setCategory({ value: objectData.category.id, label: objectData.category.title });
        } else {
            // остальная недвижимость
            setType({ value: objectData.type, label: getLabelType(objectData.type) });
            setParentCategory({ value: objectData.category.id, label: objectData.category.title });
        }
    }, [objectData]);


    function onTypeChange(event) {
        setType(event);
        setCategory({ value: null, label: "" });
        setParentCategory({ value: null, label: "" });
    }

    function onCategoryChange(event) {
        setParentCategory(event);
        setCategory({ value: null, label: "" });
    }


    function loadType(inputValue, callback) {
        const options = [];
        fetch(`${link}/category/account-list`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    console.log(result.data)
                    result.data.forEach((permission) => {
                        options.push({
                            label: permission.title,
                            value: permission.type,
                        });
                    });
                    callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
                }
            });
    }

    function loadCategoryParent(inputValue, callback) {
        if (type.value === null) return;

        fetch(
            getHost({
                controller: "category",
                filter: {
                    parent_id: type.value + 1, // потому что на 1 только отличается
                },
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                callback(
                    result.data
                        .map(({ title, id }) => ({
                            label: title,
                            value: id,
                        }))
                        .filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))
                );
            });
    }

    function loadCategory(inputValue, callback) {
        if (parentCategory.value === null) {
            return;
        }

        fetch(
            getHost({
                controller: "category",
                pagination: 0,
                filter: {
                    parent_id: parentCategory.value,
                },
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                callback(
                    result.data
                        .map(({ title, id }) => ({
                            label: title,
                            value: id,
                        }))
                        .filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))
                );
            });
    }
    return (
        <div className={`${s.card} ${s.card__category_wrapper} ${isActive && s.active}`}>
            <div className={s.card__category}>
                <h5>Категория</h5>
                {!isChangeState ? (
                    <>
                        <p>{type.label}</p>
                        <p>{parentCategory.label}</p>
                        <p>{category.label}</p>
                    </>
                ) : (
                    <div className={s.card__selects}>
                        <AsyncSelect
                            key={category}
                            loadOptions={loadType}
                            onChange={onTypeChange}
                            value={type}
                            cacheOptions
                            defaultOptions
                            styles={customStyles}
                            noOptionsMessage={() => "Выбрать"}
                            components={{ DropdownIndicator }}
                            placeholder={"Выбрать"}
                        />

                        <AsyncSelect
                            key={type.value}
                            loadOptions={loadCategoryParent}
                            onChange={onCategoryChange}
                            cacheOptions
                            defaultOptions
                            value={parentCategory}
                            styles={customStyles}
                            noOptionsMessage={() => "Выбрать"}
                            components={{ DropdownIndicator }}
                            placeholder={"Выберите тип"}
                        />
                        {type.value !== 2 || (type.value === 2 && parentCategory.label === "Аренда оборудования") ? (
                            <AsyncSelect
                                key={parentCategory.value}
                                loadOptions={loadCategory}
                                onChange={setCategory}
                                cacheOptions
                                defaultOptions
                                value={category}
                                styles={customStyles}
                                noOptionsMessage={() => "Выбрать"}
                                components={{ DropdownIndicator }}
                                placeholder={"Выберите категорию"}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </div>

            <div>
                <CorrectBtn
                    isActive={isActive}
                    correctClick={() => {
                        setIsChangeState(false);
                        correctClick(
                            "category",
                            type.value,
                            type.value !== 2 || (type.value === 2 && parentCategory.label === "Аренда оборудования")
                                ? category.value
                                : parentCategory.value
                        );
                    }}
                />
                <OnChangeBtn title={"Редактировать"} toggle={toggleChangeBtn} />
            </div>
        </div>
    );


    function getLabelType(type) {
        return type === 0 ? "Техника" : type === 1 ? "Специалисты" : "Недвижимость и оборудование";
    }
};

export default CategoryAdv;
