const TimeSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            fill="none">
            <path
                fill="#B6BFE7"
                d="M4.5 0a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm1.8 4.95H4.5a.45.45 0 0 1-.45-.45V1.8a.45.45 0 1 1 .9 0v2.25H6.3a.45.45 0 1 1 0 .9Z"
            />
        </svg>
    )
}

export default TimeSvg;