import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useContext } from "react";

import ModalsContext from "../../../store/modals-context";
import logo from "../../../assets/images/icons/logo.svg";
import { SearcherRole } from "../ModalSvg/SearcherRole";
import { adsSearchAction } from "../../../store/redux";
import { CreatorRole } from "../ModalSvg/CreatorRole";
import s from "./style.module.sass";

const ModalChooseRole = () => {
    const modal = useContext(ModalsContext);
    const dispatch = useDispatch();

    const onCreateAd = (status) => {
        dispatch(adsSearchAction.toggleStatus(status));
        modal.closeModal();
    };

    return (
        <div className={`modal ${s.modal}`}>
            <img width={134} height={50} src={logo} alt="logo"></img>

            <h3>Выберите раздел:</h3>
            <div className={s.content_wrapper}>
                <div className={`${s.content} ${s.search}`}>
                    <div>
                        <SearcherRole />
                        <p>
                            Разместить объявление <br></br>
                            <span>о поиске специалистов</span>
                        </p>
                    </div>

                    <NavLink to="/lc/advertisement/create-search" onClick={() => onCreateAd(1)}>
                        Разместить
                    </NavLink>
                </div>
                <div className={`${s.content} ${s.get}`}>
                    <div>
                        <CreatorRole />
                        <p>
                            Разместить объявление <span>о своих услугах</span>
                        </p>
                    </div>

                    <NavLink to="/lc/advertisement/create-offer" onClick={() => onCreateAd(0)}>
                        Разместить
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ModalChooseRole;
