import {DottsIcon} from "../../../components/icons/DottsIcon";
import SettingsDeleteSvg from "../../../cabinet/advertisement/svg/SettingsDeleteSvg";
import AdvEditSvg from "./svg/AdvEditSvg";
import {useState} from "react";
import {useSelector} from "react-redux";

const AdvButtons = ({id, deleteItem, editItem, extendItem, changeCheckbox, statusProps}) => {
    const [isOpen, setIsOpen] = useState(null);
    const advertiser = window.location.href.indexOf('/advertiser/ad') > -1
    const status = useSelector(state => +state.status.status);

    console.log(statusProps)
  return(
      <div className={`adv_buttons`}>
          {!advertiser &&
              <>
                  {status !== 3 && <button type='button' className='adv_button adv_button_extend' onClick={() => extendItem(id)}>Продлить рекламу</button>}
              {status === 3 && <button type='button' className='adv_button adv_button_edit' onClick={
                  () => changeCheckbox(id)
              }>Выбрать</button>}
              </>
          }
          {window.innerWidth > 769 && !advertiser &&
              <>
          {status !== 3 && <button type='button' className='adv_button adv_button_edit' onClick={() => editItem(id)}>Редактировать</button>}
          {status !== 3 && <button type='button' className='adv_button adv_button_delete' onClick={() => deleteItem(id)}>Удалить</button>}
              </>
          }

          {advertiser && <>
              {status !== 2 && <button type='button' className='adv_button adv_button_extend' onClick={() => extendItem(id)}>Продлить рекламу</button>}
              {status === 3 && !advertiser && <button type='button' className='adv_button adv_button_edit adv_button_edit2' onClick={
                  () => changeCheckbox(id)
              }>Выбрать</button>}
          </>}

          {window.innerWidth > 769 && advertiser &&
              <>
              {status !== 1 && <button type='button' className='adv_button adv_button_edit' onClick={() => editItem(id)}>Редактировать</button>}
                  {statusProps !== 2 && <button type='button' className='adv_button adv_button_delete' onClick={() => deleteItem(id)}>Удалить</button>}
              </>
          }
          {window.innerWidth < 769 && status !== 3 && !advertiser &&
            <div className='adv_button_relative'>
                <div className="adv_button_for_mobile" onClick={() => {
                    if(isOpen !== null){
                        return setIsOpen(null)
                    }
                    setIsOpen(id)
                }}>
                    <DottsIcon />
                </div>

                {isOpen === id &&
                    <div className="adv_button_modal">
                        <div className="adv_modal_text adv_modal_text_edit" onClick={() => editItem(id)}>
                            <div className="adv_modal_svg">
                                <AdvEditSvg/>
                            </div>
                            <span>Редактировать</span>
                        </div>
                        <div className="adv_modal_text" onClick={() => deleteItem(id)}>
                            <div className="adv_modal_svg">
                                <SettingsDeleteSvg/>
                            </div>
                            <span>Удалить</span>
                        </div>
                    </div>
                }
            </div>
          }

          {window.innerWidth < 769 && status !== 2 && advertiser &&
              <div className='adv_button_relative'>
                  <div className={`adv_button_for_mobile`} onClick={() => {
                      if(isOpen !== null){
                          return setIsOpen(null)
                      }
                      setIsOpen(id)
                  }}>
                      <DottsIcon />
                  </div>

                  {isOpen === id &&
                      <div className="adv_button_modal" style={status === 1 && {bottom: '-40px'}}>
                          {status !== 1 && <div className="adv_modal_text adv_modal_text_edit" onClick={() => editItem(id)}>
                              <div className="adv_modal_svg">
                                  <AdvEditSvg/>
                              </div>
                              <span>Редактировать</span>
                          </div>}
                          <div className="adv_modal_text" onClick={() => deleteItem(id)}>
                              <div className="adv_modal_svg">
                                  <SettingsDeleteSvg/>
                              </div>
                              <span>Удалить</span>
                          </div>
                      </div>
                  }
              </div>
          }

          {window.innerWidth < 769 && status === 2 && advertiser &&
              <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap: '4px'}}>
              {(statusProps === 1 || (statusProps === 2 )) && <button style={{width: '100%'}} type='button' className='adv_button adv_button_edit' onClick={() => editItem(id)}>Редактировать</button>}
          {status !== 3 && <button style={{width: '100%'}} type='button' className='adv_button adv_button_delete' onClick={() => deleteItem(id)}>Удалить</button>}
              </div>
          }
      </div>
  )
}

export default AdvButtons;