export const RejectIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM18 10C18 11.8 17.4 13.5 16.3 14.9L5.1 3.7C8.6 1 13.6 1.6 16.3 5.1C17.4 6.5 18 8.2 18 10ZM2 10C2 8.2 2.6 6.5 3.7 5.1L14.9 16.3C11.4 19 6.4 18.4 3.7 14.9C2.6 13.5 2 11.8 2 10Z"
                fill="white"
            />
        </svg>
    );
};
