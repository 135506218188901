const QuestionMarkSvg = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none">
            <circle cx="9.5" cy="9.5" r="9.5" fill="#546EDB" fillOpacity=".1"/>
            <circle cx="9.5" cy="9.5" r="9" stroke="#546EDB" strokeOpacity=".25"/>
            <path fill="#010101" d="M10.41 10.92H8.8c0-.373.084-.723.252-1.05.168-.336.373-.62.616-.854.243-.243.485-.471.728-.686a3.75 3.75 0 0 0 .616-.728 1.46 1.46 0 0 0 .252-.798c0-.383-.135-.681-.406-.896-.27-.215-.635-.322-1.092-.322-.439 0-.817.112-1.134.336-.317.224-.546.541-.686.952L6.56 6.09a3.049 3.049 0 0 1 1.246-1.526c.588-.364 1.255-.546 2.002-.546.84 0 1.559.238 2.156.714.607.476.91 1.134.91 1.974 0 .383-.084.747-.252 1.092-.168.345-.373.64-.616.882-.243.243-.485.48-.728.714a3.75 3.75 0 0 0-.616.728 1.46 1.46 0 0 0-.252.798Zm-.798 1.148c.29 0 .537.103.742.308.205.205.308.453.308.742 0 .29-.103.537-.308.742a1.011 1.011 0 0 1-.742.308c-.29 0-.537-.103-.742-.308a1.011 1.011 0 0 1-.308-.742 1.028 1.028 0 0 1 1.05-1.05Z"/>
        </svg>
    )
}

export default QuestionMarkSvg;