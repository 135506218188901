import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    whichModerator: "moderator"
};

const moderators = createSlice({
    name: "moderators",
    initialState,
    reducers: {
        changeModerator(state, action) {
            state.whichModerator = action.payload;
        },
    },
});

export default moderators;
