import CabinetInfoRight from "../../cabinet/cabinetInfo/CabinetInfoRight";
import {useContext, useEffect, useState} from "react";
import AdvertiserOrganization from "./AdvertiserOrganization";
import {link} from "../../store/host-store";
import {token} from "../../App";
import AdvertiserForm from "./AdvertiserForm";
import DeviceContext from "../../store/device-context";
import CabinetInfoWalletPoints from "../../cabinet/cabinetInfo/CabinetInfoWalletPoints";
import AuthContext from "../../store/auth-context";
import AdvertiserText from "./AdvertiserText";
import ModalsContext from "../../store/modals-context";
import {useDispatch, useSelector} from "react-redux";
import adversStatus from "../../store/redux/adversStatus";
import {statusAction} from "../../store/redux";

const AdvertiserMainPage = () => {
    const [organization, setOrganization] = useState(0);
    const [legalForm, setLegalForm] = useState(false);
    const [image, setImage] = useState([]);
    const ctx = useContext(DeviceContext);
    const auth = useContext(AuthContext);
    const modal = useContext(ModalsContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if(auth.userData.organization?.confirmed === 0 && auth.userData?.organization.status === 1){
            modal.advertiserModerationConfirmed();
        }else if(auth.userData.organization?.confirmed === 2 && auth.userData?.organization.status === 1){
            modal.advertiserOnCheckingPayment();
        }
        window.scrollTo(0, 0)
    }, [auth])

    useEffect(() => {
        dispatch(statusAction.toggleOrganization(organization))
    }, [organization])


  return(
      <div key={organization}>
          <h2 className="h2_cabinet_main">Подтверждение реквизитов и личности</h2>
          <div
              className="person_data"
          >
              <div className="admin__main_cabinet__flex">
                  <div className="left__cabinet__main_flex">
                      <div className="adv_wrapper">

                          {ctx.isMobile && <div className='advertiser_wallet_wrap'>
                              <CabinetInfoWalletPoints />
                          </div>
                          }
                          <AdvertiserText organization={auth.userData.organization}/>
                            <h2 className="adv_h2">Юридическая информация <span style={{color: "red", display: "inline-block"}}>⃰</span></h2>
                            <div className="adv_cabinet_title">Ваш статус:</div>
                            <AdvertiserOrganization
                                    title0='Физ.лицо'
                                    title1='ИП'
                                    title2='ООО'
                                    title3='Другое'
                                    setOrganization={setOrganization}
                                    organization={organization}
                                    setLegalForm={setLegalForm}
                            />
                            <AdvertiserForm organization={organization} setImage={setImage} image={image} legalForm={legalForm}/>
                      </div>
                  </div>
                  {!ctx.isMobile && <CabinetInfoRight />}
              </div>
          </div>
      </div>
  )
}

export default AdvertiserMainPage;