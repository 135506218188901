import {useDispatch, useSelector} from "react-redux";
import {moderatorsAction} from "../../../store/redux";
import {NavLink, useNavigate} from "react-router-dom";

const ChangeModeratorRole = () => {
    const dispatch = useDispatch();

    return(
        <div className={`admin__statistic_top_btn`}>
            <NavLink type="button" className={`admin__btn-transparent`}
                     to='/admin/statistic'
                    onClick={() => {
                        dispatch(moderatorsAction.changeModerator("moderator"))
                    }}>
                Модератор
            </NavLink>
            <NavLink type="button" className={`admin__btn-transparent`}
                     to='/admin/ad_statistic'
                    onClick={() => {
                        dispatch(moderatorsAction.changeModerator("ad_moderator"))
                    }}>
                Модератор рекламы
            </NavLink>
        </div>
    )
}

export default ChangeModeratorRole;