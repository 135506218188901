import {useContext, useEffect} from "react";

import ModeratorContext from "../../../../store/moderator-context";
import Loader from "../../../../components/loader/Loader";
import CategoryAdv from "./CategoryAdv";
import s from "./style.module.sass";

import ImageAdv from "./ImageAdv";
import DescrAdv from "./DescrAdv";
import ModalsContext from "../../../../store/modals-context";
import AdModeratorContext from "../../../../store/ad-moderator-context";

const ObjectBites = () => {
    const ctx = useContext(ModeratorContext);
    const ctxAd = useContext(AdModeratorContext);
    const modal = useContext(ModalsContext);

    useEffect(() => {
        if(ctx.onDelete || ctxAd.onDelete){
            modal.moderatorNotFound()
        }
    }, [ctx.onDelete, ctxAd.onDelete])

    return ctx.loading ? (
        <div className={s.cards_wrapper}>
            <Loader />
        </div>
    ) : Object.keys(ctx.currentObj).length > 0 ? (
        <div className={s.cards_wrapper}>
            <ImageAdv
                data={ctx.currentObj}
                incorrectClick={ctx.incorrectClick}
                correctClick={ctx.correctClick}
                isActive={ctx.isCorrectAdv.img}
            />
            <DescrAdv
                object={ctx.currentObj}
                incorrectClick={ctx.incorrectClick}
                correctClick={ctx.correctClick}
                isActive={ctx.isCorrectAdv.descr}
            />
            <CategoryAdv
                objectData={ctx.currentObj}
                correctClick={ctx.correctClick}
                isActive={ctx.isCorrectAdv.category}
            />
        </div>
    ) : (
        <div className={s.cards_wrapper_empty}>Нет объявлений для модерации </div>
    );
};

export default ObjectBites;
