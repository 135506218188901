export const SearcherRole = () => {
    return (
        <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_60_36)">
                <path
                    d="M98.3874 50.1933C98.3874 77.1481 76.5356 98.9999 49.5808 98.9999C22.6253 98.9999 0.773458 77.1481 0.773458 50.1933C0.773458 23.2379 22.6253 1.38599 49.5808 1.38599C76.5356 1.38599 98.3874 23.2379 98.3874 50.1933Z"
                    fill="#63D3AB"
                />
                <path
                    d="M92.5005 26.9615C91.5481 21.8518 89.3343 16.9522 86.1937 12.8848C83.5139 9.41416 80.118 6.38764 76.2863 4.24331C72.6948 2.23343 68.6894 0.869343 64.6122 0.307393C59.9286 -0.338397 55.1172 0.037747 50.5876 1.39051C43.1909 3.59979 36.5313 8.38845 32.0871 15.4272C23.6087 28.8498 25.9774 46.0338 36.8742 56.7192C36.8735 56.7207 36.872 56.7222 36.8712 56.7237C35.6914 56.1082 34.2178 56.4888 33.495 57.6339L33.4376 57.7238C32.7185 58.8628 33.0003 60.3477 34.0486 61.1514L32.4534 63.6764C31.8439 64.6424 31.754 65.9551 32.2525 66.897L18.8495 88.1197C20.4704 89.4339 22.1759 90.6455 23.9592 91.7452C24.1004 91.8328 24.244 91.9204 24.3875 92.0058C25.491 92.6735 26.6247 93.2966 27.7841 93.8744L41.2324 72.5807L41.2384 72.5732C42.4182 72.7643 43.6554 72.2559 44.3344 71.1804C44.3344 71.1804 44.4417 71.0089 44.6252 70.7204C44.6615 70.6645 44.7408 70.5368 44.7431 70.5346C44.7521 70.5202 45.2703 69.7007 45.9296 68.6576C47.1117 69.283 48.5936 68.9046 49.3195 67.755L49.3769 67.6644C50.102 66.5156 49.8082 65.0155 48.7364 64.2172C48.7379 64.2157 48.7409 64.2104 48.7409 64.2104C49.0536 64.3259 49.3686 64.4347 49.685 64.5389C49.8248 64.5865 49.966 64.6303 50.1058 64.6764C64.2217 69.1901 80.473 63.5049 88.0616 50.7787C92.5942 43.1788 93.9568 34.7684 92.5005 26.9615Z"
                    fill="white"
                />
                <path
                    d="M74.5174 10.2337C61.8878 2.2569 45.1827 6.02891 37.2058 18.6592C29.229 31.2887 33.001 47.9939 45.6313 55.9708C58.2608 63.9476 74.966 60.1756 82.9429 47.546C90.9197 34.9157 87.1477 18.2105 74.5174 10.2337Z"
                    fill="#9BD8F9"
                />
                <path
                    d="M82.9429 47.546C74.966 60.1763 58.2593 63.9483 45.6305 55.9723C44.765 55.4254 43.9409 54.8386 43.1607 54.2147C55.3899 60.1567 70.3941 56.0773 77.8241 44.3133C85.2533 32.5509 82.4897 17.2498 71.8693 8.76013C72.7681 9.1967 73.6518 9.68841 74.5181 10.2353C87.1469 18.2113 90.9189 34.918 82.9429 47.546Z"
                    fill="#73C3F9"
                />
                <path
                    d="M92.4847 26.8769C91.3615 20.9999 88.7451 15.4786 84.7412 11.0162C82.7427 8.78879 80.4065 6.79703 77.7486 5.11798C77.7048 5.09003 77.6549 5.05982 77.599 5.02432C77.5915 5.01979 77.5854 5.01828 77.5794 5.01224C77.1171 4.73126 76.3105 4.26524 75.9895 4.0817C75.9676 4.06961 75.9479 4.05979 75.9321 4.04997C75.9003 4.03185 75.8784 4.01976 75.8641 4.01221C81.3703 8.01233 85.3085 13.4445 87.4906 19.4666C90.8593 28.7539 90.048 39.4438 84.3643 48.4441C75.886 61.8689 59.3492 67.1123 45.0218 61.8674C45.0165 61.881 40.6372 68.8457 40.6372 68.8457C39.9582 69.919 38.7247 70.4311 37.5434 70.24L23.9592 91.7452C24.1012 91.8328 24.2439 91.9204 24.3875 92.0058C25.491 92.6735 26.6247 93.2966 27.7841 93.8744L41.2331 72.5807L41.2392 72.5732C42.4182 72.7643 43.6554 72.256 44.3352 71.1804C44.3352 71.1804 45.9229 68.6705 45.9296 68.6592C47.0913 69.308 48.5823 68.9197 49.3187 67.7543L49.3761 67.6637C50.1118 66.4982 49.8218 64.9861 48.7379 64.2149C48.7394 64.2127 48.7409 64.2104 48.7417 64.2096C49.0544 64.3252 49.3686 64.4347 49.6858 64.5382C49.8248 64.5865 49.9668 64.6303 50.1058 64.6757C58.6385 67.3714 68.1584 66.4174 75.9441 62.1522C81.0576 59.353 85.205 55.4246 88.0616 50.7787C92.4129 43.6969 94.0693 35.1687 92.4847 26.8769Z"
                    fill="#D8DFE8"
                />
                <path
                    d="M18.8495 88.1198C20.4712 89.434 22.1759 90.6455 23.9592 91.7452L18.8495 88.1198Z"
                    fill="#B3C2CE"
                />
                <path
                    d="M41.2392 72.5731L41.2331 72.5807L27.7841 93.8744C24.6095 92.2928 21.6041 90.3524 18.8495 88.1197L32.2525 66.897L41.2392 72.5731Z"
                    fill="#FFC839"
                />
                <path
                    d="M41.2331 72.5807L27.7841 93.8744C26.6247 93.2965 25.491 92.6734 24.3875 92.0057L37.9619 70.5149L41.2331 72.5807Z"
                    fill="#FF9100"
                />
                <path
                    d="M53.9964 20.1652C53.9964 23.2477 51.497 25.747 48.4146 25.747C45.3322 25.747 42.8329 23.2477 42.8329 20.1652C42.8329 17.0828 45.3322 14.5835 48.4146 14.5835C51.497 14.5835 53.9964 17.0828 53.9964 20.1652Z"
                    fill="#D9EEFC"
                />
            </g>
            <defs>
                <clipPath id="clip0_60_36">
                    <rect width="99" height="99" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
