export const CreatorRole = () => {
    return (
        <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_60_14)">
                <path
                    d="M12.6737 81.4243L42.2336 98.4454C44.6024 98.81 47.0291 98.9999 49.5 98.9999C51.9709 98.9999 54.3976 98.81 56.7664 98.4456L86.3263 81.4245C92.9206 73.2851 96.8734 62.9181 96.8734 51.6265C96.8734 25.4628 75.6637 4.25305 49.5 4.25305C23.3364 4.25305 2.12657 25.4628 2.12657 51.6265C2.12657 62.9181 6.07942 73.2849 12.6737 81.4243Z"
                    fill="#546EDB"
                />
                <path
                    d="M85.337 40.7149L82.9763 39.6056C83.09 40.688 83.1548 41.5558 83.1904 42.1351C83.2219 42.6479 83.0885 43.1609 82.8145 43.5797C80.6415 46.9036 77.5965 49.2552 75.4249 50.6429C73.4916 51.8783 71.2525 52.5313 68.9496 52.5313H49.5H30.0502C27.7473 52.5313 25.5082 51.8783 23.575 50.6429C21.4031 49.2554 18.3583 46.9036 16.1853 43.5797C15.9113 43.1609 15.7779 42.6479 15.8094 42.1351C15.845 41.5556 15.91 40.688 16.0235 39.6056L13.6628 40.7149C11.5468 41.7091 10.4859 44.1051 11.1646 46.3422C12.0544 49.2757 13.5091 53.3043 15.5353 56.5122C16.4427 57.9488 17.5826 59.1536 18.7818 60.1525C22.0468 62.8723 26.2004 64.2943 30.4498 64.2943H68.5498C72.7994 64.2943 76.9528 62.8723 80.2178 60.1525C81.4171 59.1536 82.5569 57.9488 83.4643 56.5122C85.4904 53.3043 86.945 49.2757 87.835 46.3422C88.5139 44.1051 87.453 41.7091 85.337 40.7149Z"
                    fill="#FFE98D"
                />
                <path
                    d="M42.0916 52.8006L41.9789 52.5313H30.0504C27.7475 52.5313 25.5084 51.8783 23.5752 50.6429C21.4033 49.2554 18.3585 46.9036 16.1855 43.5797C15.9115 43.1609 15.7781 42.6479 15.8096 42.1351C15.8452 41.5556 15.9102 40.688 16.0237 39.6056L13.663 40.7149C11.547 41.7091 10.4861 44.1051 11.1648 46.3422C12.0546 49.2757 13.5093 53.3043 15.5355 56.5122C16.4429 57.9488 17.5828 59.1536 18.782 60.1525C22.047 62.8723 26.2006 64.2943 30.45 64.2943H42.0916C34.6833 58.0032 42.0916 52.8006 42.0916 52.8006Z"
                    fill="#FFDD40"
                />
                <path
                    d="M84.6176 77.8407C81.8119 75.239 75.9897 70.5858 70.2376 70.5858C62.2538 70.5858 49.5 70.5858 49.5 70.5858C49.5 70.5858 36.7462 70.5858 28.7624 70.5858C23.0104 70.5858 17.1881 75.239 14.3825 77.8407C13.3731 78.7765 12.7675 80.0602 12.6738 81.4244C14.3355 83.4755 16.166 85.3842 18.1425 87.1316L37.5556 96.291L44.2428 90.7329C47.2901 88.2001 51.7101 88.2001 54.7575 90.7329L62.5226 97.187C72.0096 94.4806 80.2788 88.8894 86.3265 81.4246C86.2325 80.0602 85.6271 78.7765 84.6176 77.8407Z"
                    fill="#E9EFFF"
                />
                <path
                    d="M37.5554 96.291L44.0142 90.9226C43.9895 90.4972 43.7423 90.1062 43.3554 89.8926C37.2934 86.5451 39.7311 79.2756 40.7547 76.8625C40.9514 76.399 40.8495 75.8653 40.5005 75.5026C38.7407 73.6726 39.6329 72.0772 40.403 71.2305C40.5709 71.0458 40.6673 70.8202 40.7008 70.5858C36.805 70.5858 32.3121 70.5858 28.762 70.5858C23.01 70.5858 17.1877 75.239 14.3821 77.8407C13.3727 78.7765 12.7671 80.0602 12.6734 81.4244C14.3351 83.4755 16.1656 85.3842 18.1421 87.1316L37.5554 96.291Z"
                    fill="#BEC8F7"
                />
                <path
                    d="M80.2604 81.2873C76.5549 78.0052 72.8487 76.066 70.2376 76.066H58.0341C56.837 76.066 55.6778 76.4849 54.7573 77.25C51.7099 79.7828 47.2899 79.7828 44.2426 77.25C43.322 76.4849 42.1628 76.066 40.9657 76.066H28.7622C26.1513 76.066 22.445 78.0052 18.7394 81.2873C18.3583 81.6247 18.1396 82.1105 18.1396 82.6194V87.1303C23.3569 91.7421 29.5964 95.2237 36.4773 97.1869L44.2424 90.7327C47.2897 88.1999 51.7097 88.1999 54.7571 90.7327L62.5222 97.1869C69.4031 95.2239 75.6426 91.7423 80.8598 87.1303V82.6194C80.8602 82.1105 80.6415 81.6249 80.2604 81.2873Z"
                    fill="#73C3F9"
                />
                <path
                    d="M44.0142 90.9226C43.9895 90.4972 43.7423 90.1062 43.3554 89.8926C37.2934 86.5451 39.7311 79.2756 40.7548 76.8625C40.8642 76.6047 40.8779 76.3259 40.8116 76.066H28.7622C26.1513 76.066 22.445 78.0052 18.7394 81.2873C18.3583 81.6247 18.1396 82.1105 18.1396 82.6194V87.1303C23.3569 91.7421 29.5964 95.2237 36.4773 97.1869L44.0142 90.9226Z"
                    fill="#4FABF7"
                />
                <path
                    d="M54.7572 90.3269C51.7099 87.794 47.2899 87.794 44.2426 90.3269L36.1176 97.0799C38.1053 97.6643 40.1462 98.1243 42.2334 98.4456L46.0478 95.2751C48.0486 93.612 50.9508 93.612 52.9517 95.2751L56.7661 98.4456C58.853 98.1243 60.8941 97.6643 62.8818 97.0801L54.7572 90.3269Z"
                    fill="#9BD8F9"
                />
                <path
                    d="M45.4 94.9957C43.7487 94.0863 43.7913 92.2979 43.9987 91.2176C44.0413 90.9964 44.0165 90.7771 43.9427 90.576L36.1176 97.0799C38.1053 97.6642 40.1462 98.1242 42.2334 98.4456L45.8583 95.4327C45.7471 95.2534 45.5914 95.101 45.4 94.9957Z"
                    fill="#73C3F9"
                />
                <path
                    d="M48.515 3.54175C45.233 3.6508 34.3923 4.66285 26.0335 13.0214C17.3016 21.7533 15.783 38.1807 15.5405 42.1189C15.5057 42.686 15.649 43.252 15.9599 43.7274C18.1164 47.0261 21.1067 49.3855 23.4299 50.8699C25.4069 52.1332 27.7042 52.8006 30.0502 52.8006H48.515V3.54175Z"
                    fill="#FFC839"
                />
                <path
                    d="M83.4593 42.1189C83.217 38.1809 81.6984 21.7535 72.9665 13.0216C64.264 4.31906 52.8699 3.58004 50.1182 3.53247V52.8006H68.9498C71.2957 52.8006 73.5932 52.1332 75.5701 50.8699C77.8933 49.3853 80.8834 47.0261 83.0401 43.7274C83.3509 43.252 83.4943 42.6858 83.4593 42.1189Z"
                    fill="#FFDD40"
                />
                <path
                    d="M37.3653 40.7837C40.894 37.4436 45.1001 32.289 45.1001 26.7002V2.51051C45.1001 2.47744 45.1034 2.44515 45.1049 2.41248C43.4328 2.64818 41.3905 3.05501 39.1669 3.75594C37.8923 4.15774 37.0219 5.33704 37.0219 6.67354V41.1261C37.1356 41.0111 37.2478 40.8951 37.3653 40.7837Z"
                    fill="#FF9100"
                />
                <path
                    d="M53.9001 2.51058V26.7004C53.9001 32.2895 58.1062 37.4439 61.6349 40.7839C61.7524 40.8951 61.8646 41.0111 61.9781 41.126V6.67341C61.9781 5.33691 61.108 4.15762 59.8331 3.75581C57.6095 3.05489 55.5674 2.64806 53.8953 2.41235C53.8968 2.44522 53.9001 2.47752 53.9001 2.51058Z"
                    fill="#FFB332"
                />
                <path
                    d="M61.8201 40.588C58.3317 37.2864 54.1692 32.1955 54.1692 26.7002V2.51056C54.1692 1.25218 53.2026 0.206294 51.9487 0.101107C50.3616 -0.0319245 48.6833 -0.0357917 47.0509 0.101107C45.797 0.206101 44.8304 1.25218 44.8304 2.51056V26.7002C44.8304 32.1953 40.6679 37.2862 37.1797 40.5879C34.1898 43.4177 32.4985 47.3533 32.4985 51.4701V52.8006H66.5009V51.4701C66.5013 47.3535 64.81 43.4179 61.8201 40.588Z"
                    fill="#FFC839"
                />
                <path
                    d="M47.5198 43.2929C39.9925 41.0799 43.2905 32.7162 43.2905 32.7162C41.7621 35.8076 39.3419 38.5417 37.1799 40.588C34.19 43.4179 32.4987 47.3535 32.4987 51.4703V52.8008H47.5198V43.2929Z"
                    fill="#FFB332"
                />
                <path
                    d="M56.9084 52.8006V50.4213C56.9084 46.5429 53.8688 43.0317 49.4172 43.0394C45.3623 43.0464 42.0914 46.3681 42.0914 50.4233V52.8006H56.9084Z"
                    fill="#FFDD40"
                />
                <path
                    d="M82.7888 36.0813C81.746 29.3767 79.2025 19.3251 73.0467 13.104C70.7278 14.1396 67.6758 16.3173 67.6758 20.6083C67.6758 27.5286 68.3881 41.8658 74.1889 41.8658C77.9289 41.866 81.045 38.9944 82.7888 36.0813Z"
                    fill="#FFE98D"
                />
                <path
                    d="M67.0615 95.6324C69.353 94.7168 71.5567 93.629 73.6552 92.382L57.3854 76.1122C56.4232 76.2352 55.51 76.6246 54.7573 77.2501C53.5105 78.2863 52.0338 78.8981 50.5155 79.0864L67.0615 95.6324Z"
                    fill="#9BD8F9"
                />
                <path
                    d="M62.2617 76.0662L76.6459 90.4506C77.6063 89.7777 78.5387 89.068 79.445 88.3275L67.1837 76.0662H62.2617Z"
                    fill="#9BD8F9"
                />
            </g>
            <defs>
                <clipPath id="clip0_60_14">
                    <rect width="99" height="99" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
