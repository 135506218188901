import { useLocation, useNavigate } from "react-router-dom";
import { adminAction } from "../../../store/redux";
import {useEffect, useRef, useState} from "react";
import { useDispatch } from "react-redux";

import InputPhoto from "../../../cabinet/advertisement/InputPhoto";
import { ArrowLeftIcon } from "../../../components/icons/ArrowLeftIcon";
import getHost from "../../../store/host-store";
import { token } from "../../../App";
import axios from "axios";
import {Editor} from "@tinymce/tinymce-react";

const CreateCategory = () => {
    const location = useLocation();
    const { state } = location;
    const history = useNavigate();
    const [seoInfo, setSeoInfo] = useState({seoTitle: '', seoDescription: ''})
    console.log(seoInfo)

    const editorRef = useRef(null);

    //данные из формы
    const [price1, setPrice1] = useState("");
    const [price2, setPrice2] = useState("");
    const [responsePrice, setResponsePrice] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categorySubTitle, setCategorySubTitle] = useState("");
    const [contactsPrice, setContactsPrice] = useState("");
    const [image, setImage] = useState([]);

    //обработка ошибок
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [titleValid, setTitleValid] = useState(true);
    const [imageValid, setImageValid] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch();
    function updateCategories() {
        fetch(
            getHost({
                controller: "category",
                action: "admin-list",
                filter: {
                    depth: 0,
                },
            }),
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    dispatch(adminAction.getCategories(result.data));
                }
            })
            .catch((e) => console.log(e));
    }

    const onSave = () => {
        //проверка на заполненное название
        console.log(seoInfo.seoDescription)
        if (categoryName.length > 0) {
            setTitleValid(true);
            //если у категории предусмотрено наличие изображения, проверка на то, что оно добавлено
            if (state.depth === 0) {
                if (image.length > 0) {
                    setImageValid(true);

                    const formData = new FormData();
                    formData.append("parent_id", state.parent_id);
                    formData.append("type", state.type);
                    formData.append("title", categoryName);
                    formData.append("subtitle", categorySubTitle);
                    formData.append("price_1_name", price1);
                    formData.append("price_2_name", price2);
                    formData.append("image", image[0]);
                    formData.append("order_cost", responsePrice);
                    formData.append("contact_cost", contactsPrice);
                    formData.append("description", editorRef.current.getContent());
                    formData.append("seoDescription", seoInfo.seoDescription);
                    formData.append("seoTitle", seoInfo.seoTitle);
                    axios
                        .post(
                            getHost({
                                controller: "category",
                            }),
                            formData,
                            {
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                    Authorization: token,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.data.success) {
                                updateCategories();
                                setSuccess(true);
                                setError(false);
                            } else {
                                setSuccess(false);
                                setError(true);
                            }
                        });
                } else {
                    setImageValid(false);
                }
            } else {
                //если у категории не предусмотрено изображение
                const formData = new FormData();
                formData.append("parent_id", state.parent_id);
                formData.append("type", state.type);
                formData.append("title", categoryName);
                formData.append("subtitle", categorySubTitle);
                formData.append("price_1_name", price1);
                formData.append("price_2_name", price2);
                formData.append("order_cost", responsePrice);
                formData.append("contact_cost", contactsPrice);
                formData.append("description", editorRef.current.getContent());
                formData.append("seoDescription", seoInfo.seoDescription);
                formData.append("seoTitle", seoInfo.seoTitle);
                axios
                    .post(
                        getHost({
                            controller: "category",
                        }),
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization: token,
                            },
                        }
                    )
                    .then((res) => {
                        console.log()
                        if (res.data.success) {
                            updateCategories();
                            setSuccess(true);
                            setError(false);
                        } else {
                            setSuccess(false);
                            setError(true);
                        }
                    });
            }
        } else {
            setTitleValid(false);
        }
    };

    return (
        <div className="admin__layout">
            <div className="admin__edit-category">
                <div className="admin__edit-category_top">
                    <div>
                        <button title="назад" type="button" className="admin__back-arrow" onClick={() => history(-1)}>
                            <ArrowLeftIcon />
                        </button>
                        <h3>Создать новую категорию</h3>
                    </div>

                    <button type="button" className="admin__btn-blue" onClick={onSave}>
                        Сохранить
                    </button>
                </div>
                <div>
                    {state.depth === 0 && (
                        <InputPhoto
                            images={image}
                            onLoad={setImage}
                            onDelete={setImage}
                            limitText={"Вы можете добавить 1 фото."}
                        />
                    )}
                    {!imageValid && <span className="admin__error">Добавьте изображение</span>}
                </div>

                <div className="admin__edit-category_inputs">
                    <div>
                        <input
                            value={categoryName}
                            placeholder="Название категории"
                            required
                            onChange={(e) => setCategoryName(e.target.value)}></input>
                        {!titleValid && (
                            <span className="admin__error">Поле с названием обязательно для заполнения</span>
                        )}
                    </div>

                    <div className="admin__edit-category_inputs">
                        <div>
                            <input
                                value={categorySubTitle}
                                placeholder="Описание"
                                required
                                onChange={(e) => setCategorySubTitle(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="admin__edit-category_labels-wrapper">
                        <label>
                            <p>Цена первая</p>
                            <input value={price1} onChange={(e) => setPrice1(e.target.value)}></input>
                        </label>
                        <label>
                            <p>Цена вторая</p>
                            <input value={price2} onChange={(e) => setPrice2(e.target.value)}></input>
                        </label>
                    </div>
                    <div className="admin__edit-category_labels-wrapper">
                        <label>
                            <p>Сумма отклика</p>
                            <input value={responsePrice} onChange={(e) => setResponsePrice(e.target.value)}></input>
                        </label>
                        <label>
                            <p>Сумма открытия контактов</p>
                            <input value={contactsPrice} onChange={(e) => setContactsPrice(e.target.value)}></input>
                        </label>
                    </div>
                    <div className="admin__edit-category_labels-wrapper">
                        <label>
                            <p>Seo заголовок</p>
                            <input onChange={(e) => setSeoInfo({...seoInfo, seoTitle: e.target.value})}></input>
                        </label>
                        <label>
                            <p>Seo Описание</p>
                            <input onChange={(e) => setSeoInfo({...seoInfo, seoDescription: e.target.value})}></input>
                        </label>
                    </div>
                    <div>
                        <Editor
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={""}
                            init={{
                                height: 350,
                                menubar: false,
                                plugins:
                                    "advlist autolink lists link image charmap print preview anchor" +
                                    "searchreplace visualblocks code fullscreen" +
                                    "insertdatetime media table paste code help wordcount",
                                toolbar:
                                    "undo redo | styles | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
                                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                        />
                    </div>
                </div>
                {error && <span className="admin__error">Что-то пошло не так, попробуйте позже</span>}
                {success && <span className="admin__success">Изменения успешно сохранены</span>}
            </div>
        </div>
    );
};

export default CreateCategory;
