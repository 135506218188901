import style from '../subscribe.module.sass'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {link} from "../../../store/host-store";
import {token} from "../../../App";
import Loader from "../../../components/loader/Loader";

const SubscribeCreateIn = () => {
    const params = useParams();
    const [categoryName, setCategoryName] = useState();
    const [subCategories, setSubCategories] = useState([]);
    const [idCheckbox, setIdCheckbox] = useState([+params.id]);
    const [loadind, setLoading] = useState(true);
    const location = useLocation();
    const { city } = location.state
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);

        fetch(`${link}/category/${params.id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        }).then(res => res.json()).then(res => {
            setCategoryName(res.data.title)
        })

            fetch(`${link}/category?&filter[parent_id][in][]=${params.id}&pagination=0`, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }).then(res => res.json()).then(res => {
                console.log(res.data);
                setSubCategories(res.data)
                setLoading(false)
            })
    }, [params.id]);


    const check = (id) => {
        const exists = idCheckbox.includes(id)

        if(exists){
            setIdCheckbox(prevState => prevState.filter(item => item !==id));
        }else{
            setIdCheckbox(prevState => [...prevState, id]);
        }
    }

    const checkAll = () => {
        if(idCheckbox.length === 1){
             setIdCheckbox(subCategories.map(item => item.id).concat(+params.id))
        }else{
            setIdCheckbox([+params.id]);
        }
    }
    console.log(idCheckbox)
    const postData = () => {
        console.log(idCheckbox, city.value);

        let data = {
            category_ids: idCheckbox,
            city_id: city?.value !== undefined ? city?.value : city
        }

        fetch(`${link}/subscription/mass-create`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then(res => {
            console.log(res.data)
            navigate('/lc/subscribe')
        })
    }

  return(
      <div className='cabinet__redesign_container'>
          <h3 className='cabinet__redesign_title'>Мои подписки</h3>
          <div
              style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
              }}
              className={style.promotion_block}>
              <div className={style.promotion_create_in}>
                  {loadind ? <Loader/> :
                      <div className={style.promotion_create_in_block}>
                          <div className={style.promotion_create_in_title}>
                              <h4>{categoryName}</h4>
                              <div onClick={checkAll}>Выделить все</div>
                          </div>

                          <div className={style.promotion_create_in_array}>
                              {subCategories.map(item => (
                                      <label className={style.container} key={item.id}>
                                          <span className={style.span_title}>{item?.title}</span>
                                          <input type="checkbox"
                                                 checked={idCheckbox.includes(item.id)}
                                                 onChange={() => check(item.id)}/>
                                          <span className={style.checkmarks}></span>
                                      </label>
                                  )
                              )}
                          </div>
                      </div>
                  }
              </div>

              <div className={style.subscribe_create_inBtn}>
                  <button onClick={postData}
                      type='button'>Сохранить</button>
              </div>
          </div>
      </div>
  )
}

export default SubscribeCreateIn;