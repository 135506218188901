export const ArrowBlackIcon = () => {
    return (
        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.361809 0.345257C0.844222 -0.115004 1.62637 -0.115004 2.10878 0.345257L6.63819 4.66669C7.1206 5.12695 7.1206 5.87318 6.63819 6.33344L2.10878 10.6549C1.62637 11.1151 0.844222 11.1151 0.361809 10.6549C-0.120603 10.1946 -0.120603 9.44837 0.361809 8.98811L4.01774 5.50006L0.361809 2.01201C-0.120603 1.55175 -0.120603 0.805517 0.361809 0.345257Z"
                fill="black"
            />
        </svg>
    );
};
