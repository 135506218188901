import StarActiveSvg from "./svg/StarActiveSvg";
import StarSvg from "./svg/StarSvg";
import avatar from "../../assets/images/stubs/avatar.svg";
import { url } from "../../store/host-store";
import moment from "moment";
import uuid from "react-uuid";

const FeedbackItems = (props) => {
    return (
        <div className="feedback__list">
            {props.feedbackArray.map((item) => (
                <div className="feedback_item" key={item.id}>
                    <div className="feedback__top_flex">
                        <div className="feedback__img">
                            {item.avatar ? (
                                <img src={url + item.avatar} alt="avatar" />
                            ) : (
                                <img src={avatar} alt="avatar" />
                            )}
                        </div>
                        <div className="feedback__info">
                            <div className="feedback_name">{item.name}</div>
                            <div className="feedback_date">{moment(item.date).format("DD.MM.YYYY")}</div>
                        </div>
                    </div>
                    <div className="feedback_stars">
                        {[...Array(+item.mark)].map(() => {
                            return <StarActiveSvg key={uuid()} />;
                        })}
                        {[...Array(5 - item.mark)].map(() => {
                            return <StarSvg key={uuid()}/>;
                        })}
                    </div>
                    <div className="feedback_text">
                        <p>{item.comment}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeedbackItems;
