import moment from "moment";

import RequestRatingStarSvg from "../../../request/svg/RequestRatingStarSvg";
import { WhiteStarIcon } from "../../../../components/icons/WhiteStarIcon";
import RequestCalendarSvg from "../../../request/svg/RequestCalendarSvg";
import RequestAddressSvg from "../../../request/svg/RequestAddressSvg";
import { BucketIcon } from "../../../../components/icons/BucketIcon";
import { ClockIcon } from "../../../../components/icons/ClockIcon";
import RequestRubleSvg from "../../../request/svg/RequestRubleSvg";
import avatar from "../../../../assets/images/stubs/avatar.svg";
import { url } from "../../../../store/host-store";
import style from "../../style.module.sass";

const OfferResponse = ({ item, onDeleteResponse, modal, activeCard, isArchive, isSeek }) => {
    return (
        <div
            className={`${style.response__order_cart} ${isArchive ? style.finish : ""} ${
                activeCard === item.id && style.active
            }`}>
            {isArchive && <p className={style.finish_text}>Заявка завершена</p>}
            <div className={style.response__order_cart_top}>
                <div className={style.response__order_cart_wrap}>
                    {item.user?.avatar === null ? (
                        <img src={avatar} alt="" />
                    ) : (
                        <img src={url + item.user?.avatar} alt="" />
                    )}

                    <div>
                        <p className={style.response__order_cart_name}>{item.user?.name}</p>
                        <div>
                            <div className={style.response__order_cart_id}>ID {item.user?.id}</div>
                            {item.user?.mark === null ? (
                                <div>
                                    <span>Нет рейтинга</span>
                                    <RequestRatingStarSvg />
                                    <span className={style.response__order_cart_rating}>{item?.user?.mark}</span>
                                </div>
                            ) : (
                                <div className={style.rating}>
                                    <span>Рейтинг</span>
                                    <RequestRatingStarSvg />
                                    <span className={style.response__order_cart_rating}>{item?.user?.mark}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={style.response__order_cart_data}>
                    <div>№ {item.id}</div>
                    <div>{moment(item.created).format("DD.MM.YYYY")}</div>
                    <div>{moment(item.created).format("HH:mm")}</div>
                </div>
            </div>

            <div className={style.response__order_cart_bottom}>
                {!isSeek && (
                    <div className={style.response__order_data}>
                        <div className={style.response__order_data_date}>
                            <span>
                                <RequestCalendarSvg />
                                Дата работы
                            </span>

                            {isArchive ? (
                                <p>
                                    {moment(item.date_from).format("DD.MM.YYYY")} -
                                    {moment(item.date_from).add(item.duration, "days").format("DD.MM.YYYY")}
                                </p>
                            ) : (
                                item?.bookings.map((item) => {
                                    return (
                                        <p key={item.date_from} className="request__info">
                                            {moment(item.date_from).format("DD.MM.YYYY")} -
                                            {moment(item.date_from).add(item.duration, "days").format("DD.MM.YYYY")}
                                        </p>
                                    );
                                })
                            )}
                        </div>

                        <div className={style.response__order_data_time}>
                            <span>
                                <ClockIcon />
                                Время
                            </span>
                            <p>
                                {item.time_from} - {item.time_to}
                            </p>
                        </div>

                        <div className={style.response__order_data_paid}>
                            <span>
                                <RequestRubleSvg />
                                Оплата
                            </span>
                            <p>
                                {item.payment_from} - {item.payment_to} ₽
                            </p>
                        </div>
                    </div>
                )}

                {item.about.length > 0 && <p className={style.p_comment}>{item.about}</p>}

                {item.address && (
                    <div className={style.address_response}>
                        <RequestAddressSvg />
                        {item.address}
                    </div>
                )}

                {isArchive ? (
                    <div className={`${style.response__order_call} ${style.disabled}`}>Позвонить</div>
                ) : (
                    <a style={{cursor: 'pointer'}} onClick={() => modal.modalTel(item.user?.phone)} className={style.response__order_call}>
                        Позвонить
                    </a>
                )}

                <div className={style.contact__messangers}>
                    <div className={style.contact__messangers_btns}>
                        {!item?.markExist &&
                            (item.object?.on_moderation !== 1 && item.object?.moderator_id !== null && (
                                <div
                                    className={style.contact__messangers_feedback}
                                    onClick={() => {
                                         modal.feedbackModal(item.user_id, item.object.id);
                                    }}>
                                    <WhiteStarIcon />
                                </div>
                            ))}
                        {!isArchive && (
                            <div className={style.contact__messangers_del} onClick={() => onDeleteResponse(item.id)}>
                                <BucketIcon />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OfferResponse;
