import {PhoneIconForms} from "../svg/PhoneIconForms";
import InputMask from "react-input-mask";
import {ProfileIconForm} from "../svg/ProfileIconForm";
import InputPhoto from "../InputPhoto";
import React from "react";

export const RegistratorBlock = ({setRegistratorData, registratorData, setImagesAvatar, imagesAvatar, id, avatar, error}) => {
    return(
        <div className="create_ads_border">
            <div className="create__ads_block">
                <div className="input_wrap input_wrap_210">
                    <div className="advers___title">Телефон контактный:</div>
                    <div className="relative_input">
                        <PhoneIconForms />
                        <InputMask
                            mask="+7 (999) 999 99 99"
                            required
                            className='input'
                            name="inputMask"
                            inputMode="decimal"
                            maskChar={null}
                            placeholder="Телефон"
                            value={registratorData.tel}
                            onChange={(e) => setRegistratorData({ ...registratorData, tel: e.target.value })}
                        />
                    </div>
                    <p className="error">{error?.contactPhone?.message}</p>
                </div>
                <div className="input_wrap input_wrap_300">
                    <div className="advers___title">Ваш никнейм:</div>
                    <div className="relative_input">
                        <ProfileIconForm />
                        <input placeholder='Введите ник' className='input' value={registratorData.name} type="text" onChange={(e) =>{
                            setRegistratorData({...registratorData, name: e.target.value})
                        }}/>
                    </div>
                </div>
                <div className="input_image_custom">
                    <div className="advers___title">Фото на аватар:</div>
                    <InputPhoto
                        images={imagesAvatar}
                        onLoad={setImagesAvatar}
                        onDelete={setImagesAvatar}
                        id={id}
                        avatar={avatar}
                        custom
                    />
                </div>
            </div>
        </div>
    )
}