export const AsideFeedback = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C2.69148 0 0 2.69148 0 6C0 9.30852 2.69148 12 6 12C9.30852 12 12 9.30852 12 6C12 2.69148 9.30852 0 6 0ZM9.50488 5.28936L8.05632 6.86505L8.30205 8.99122C8.3072 9.03568 8.30043 9.08071 8.28243 9.12169C8.26444 9.16267 8.23588 9.19813 8.19967 9.22443C8.16346 9.25074 8.1209 9.26694 8.07637 9.27139C8.03183 9.27583 7.98691 9.26835 7.94622 9.24971L6 8.3591L4.05378 9.24971C4.01308 9.26836 3.96815 9.27584 3.9236 9.2714C3.87906 9.26696 3.83649 9.25075 3.80028 9.22443C3.76406 9.19812 3.73549 9.16264 3.71751 9.12165C3.69952 9.08065 3.69276 9.03561 3.69792 8.99114L3.94366 6.86497L2.49512 5.28936C2.46482 5.2564 2.44383 5.21597 2.4343 5.17223C2.42476 5.12849 2.42703 5.08299 2.44087 5.04041C2.45471 4.99784 2.47962 4.9597 2.51305 4.92992C2.54648 4.90014 2.58723 4.87978 2.63112 4.87094L4.72911 4.44763L5.77996 2.5831C5.80194 2.54409 5.83389 2.51163 5.87254 2.48904C5.9112 2.46645 5.95516 2.45455 5.99994 2.45455C6.04471 2.45455 6.08867 2.46645 6.12733 2.48904C6.16598 2.51163 6.19794 2.54409 6.21991 2.5831L7.27076 4.44763L9.36876 4.87094C9.41265 4.87978 9.45339 4.90014 9.48682 4.92992C9.52025 4.9597 9.54516 4.99784 9.559 5.04041C9.57284 5.08299 9.57511 5.12849 9.56558 5.17223C9.55604 5.21597 9.53505 5.2564 9.50475 5.28936H9.50488Z"
                fill="white"
            />
        </svg>
    );
};
