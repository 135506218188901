import {useDispatch, useSelector} from "react-redux";
import {checkboxAction} from "../../store/redux";
import {useContext, useEffect} from "react";
import AuthContext from "../../store/auth-context";

const CheckboxAll = ({arr, extendHandler, deleteHandler}) => {
    const ctx = useContext(AuthContext);
    const getCheckboxesState = useSelector((state) => state.checkboxes);
    const status = useSelector(state => state.status.status)
    const dispatch = useDispatch();

    let idsArr = arr?.map((item) => item?.id);
    const isRegistrarRole = ctx.userData?.account?.role === 'registrar'


    useEffect(() => {
        dispatch(checkboxAction.resetStates(arr.length))
    }, [])

    const handleMainCheckboxChange = (ids) => {
        dispatch(checkboxAction.toggleMainCheckbox(ids));
    };


  return(
      <div className="flex_advers__settings">
            <label className="container_checkbox">
                <input
                    type="checkbox"
                    className="advers__settings advers__settings_checkbox"
                    checked={getCheckboxesState.isCheckAll}
                    onChange={() => {
                        handleMainCheckboxChange(idsArr);
                    }}
                />
                <span className="checkmark"></span>
            </label>
          {+status !== 2 && (
              <div className="advers__settings advers__settings_stop">
                  <div onClick={extendHandler}>Продлить рекламу</div>
              </div>
          )}
          {!isRegistrarRole &&
          <div className="advers__settings advers__settings_delete">
              <div onClick={deleteHandler}>Удалить</div>
          </div>
          }
      </div>
  )
}

export default CheckboxAll;