import {Route, Routes} from "react-router-dom";
import SubscribePage from "./SubscribePage";
import SubscribeCreate from "../subscribeCreate/SubscribeCreate";
import SubscribeCreateIn from "../subscribeCreate/SubscribeCreateIn";
import SubscribeEditIn from "../SubscribeEditIn";

const SubscribeRoutes = () => {

  return(
      <>
          <Routes>
            <Route path="/" element={<SubscribePage />}></Route>
            <Route path="/create" element={<SubscribeCreate />}></Route>
            <Route path="/create/:id" element={<SubscribeCreateIn />}></Route>
            {/*<Route path="/edit/:id" element={<SubscribeEdit/>}></Route>*/}
            <Route path="/edit/:id" element={<SubscribeEditIn/>}></Route>
              )}
          </Routes>
      </>
  )
}

export default SubscribeRoutes;