import ObjCounter from "../../moderator/main/object/components/ObjCounter";
import AdObject from "../object/AdObject";
import OnPublic from "../../moderator/buttons/OnPublic";
import {useContext} from "react";
import AdModeratorContext from "../../store/ad-moderator-context";

const AdsPageModeration = () => {
    const ctx = useContext(AdModeratorContext)

  return(
      <>
          <ObjCounter />
          <AdObject />
          <OnPublic isCorrect={ctx.isCorrectAdv.img && ctx.isCorrectAdv.descr && ctx.isCorrectAdv.link} />
      </>
  )
}

export default AdsPageModeration;