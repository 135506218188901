import { useContext, useEffect } from "react";
import { useState } from "react";

import CustomScrollbar from "../../../UI/Scrollbar/CustomScrollbar";
import PaginationPattern from "../../../UI/PaginationPattern";
import DeviceContext from "../../../store/device-context";
import { link } from "../../../store/host-store";
import { useSelector } from "react-redux";
import OfferObject from "./objectsTypes/OfferObject";
import { token } from "../../../App";
import SeekObject from "./objectsTypes/SeekObject";

const ObjectsList = ({ setObjectId, setMobileVisibleCart, objectId, type, page, setPage }) => {
    const device = useContext(DeviceContext);

    const [objects, setObjects] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const PAGINATION = `&pagination[pageSize]=10&page=${page}`;

    const seekType = useSelector((state) => state.adsSearchLogic.statusAds);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetch(
            `${link}/object/user-objects?&filter[type]=${type}${PAGINATION}&filter[seek]=${seekType}&expand=city&confirmed=1`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                if (result.data.success) {
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    setObjects(result.data.data);
                } else {
                    if (result.data.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    }, [type, page, seekType]);

    return objects.length > 0 ? (
        <>
            <CustomScrollbar style={{ height: 410 }}>
                <div>
                    {objects.map((item) => {
                        return seekType ? (
                            <SeekObject
                                key={item.id}
                                item={item}
                                setMobileVisibleCart={setMobileVisibleCart}
                                device={device}
                                objectId={objectId}
                                setObjectId={setObjectId}
                            />
                        ) : (
                            <OfferObject
                                key={item.id}
                                item={item}
                                setMobileVisibleCart={setMobileVisibleCart}
                                device={device}
                                objectId={objectId}
                                setObjectId={setObjectId}
                            />
                        );
                    })}
                </div>
            </CustomScrollbar>

            <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page} />
        </>
    ) : (
        "Нет объявлений"
    );
};
export default ObjectsList;
