import { ru } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";

function DateRangePickerExample({ startDate, setStartDate, endDate, setEndDate }) {
    return (
        <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            minimumLength={1}
            format="dd MMM yyyy"
            locale={ru}>
            {({ startDateInputProps, endDateInputProps, focus }) => (
                <div className="date-range">
                    <input
                        className={"input" + (focus === START_DATE ? " -focused" : "")}
                        {...startDateInputProps}
                        placeholder="От"
                    />
                    <span className="date-range_arrow" />
                    <input
                        className={"input" + (focus === END_DATE ? " -focused" : "")}
                        {...endDateInputProps}
                        placeholder="До"
                    />
                </div>
            )}
        </DateRangePicker>
    );
}

export default DateRangePickerExample;
