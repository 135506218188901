import moment from "moment";
import avatar from "../../assets/images/stubs/avatar.svg";
import { url } from "../../store/host-store";
import style from "./style.module.sass";
import {useSelector} from "react-redux";

const DesktopReferral = ({ users }) => {
    console.log(users)
    const partnerProgram = useSelector(state => state.referralSlice.referralToggle)
    return (
        <div className={`${!partnerProgram ? style.bottom__table : `${style.bottom_p__table + " " + style.bottom__table}`}`}>
            <div className={`${!partnerProgram ? style.bottom__titles : `${style.bottom_p__titles + " " + style.bottom__titles}`}`}>
                <p>№</p>
                <p>Дата</p>
                <p>ID-пользователя</p>
                <p>Аватар</p>
                <p>Имя пользователя</p>
                {!partnerProgram && <p>Статус</p>}
                {partnerProgram && <p>Баллы</p>}
                {partnerProgram && <p>Деньги</p>}
            </div>
            {users.length > 0 ? (
                users.map((el, i) => {
                    return (
                        <div className={style.bottom__card} key={el.id}>
                            <p>{i + 1}</p>
                            <p>{moment(el.referal_date).format("DD.MM.YYYY")}</p>
                            <p>{el.id}</p>
                            {el.avatar === null ? (
                                <img alt="avatar" src={avatar} />
                            ) : (
                                <img alt="avatar" src={url + el.avatar} />
                            )}
                            <p>{el.name}</p>

                            {!partnerProgram && <>
                            {Number(el.referal_status) === 1 ? (
                                <p className={style.bottom__card_null}>0 баллов</p>
                            ) : Number(el.referal_status) === 2 ? (
                                <p className={style.bottom__card_points}>+1000 баллов</p>
                            ) : (
                                <p>ошибка</p>
                            )}
                            </>}
                            {partnerProgram && <p className="hard_green">+{el?.referral_points} баллов</p>}
                            {partnerProgram && <p className="hard_green">+{el?.affiliate_money} ₽</p>}
                        </div>
                    );
                })
            ) : (
                <div className={style.bottom__card_empty}>Пока никто не воспользовался Вашей реферальной ссылкой</div>
            )}
        </div>
    );
};

export default DesktopReferral;
