import InputPhoto from "../../InputPhoto";
import {useEffect, useState} from "react";
import getHost, {url} from "../../../../store/host-store";
import {DeleteIcon} from "../../../../components/icons/DeleteIcon";
import {token} from "../../../../App";



const EditSearchAbout = ({register, setDownloadImages, downloadImages, errors, ad, reset}) => {
    const [currentImages, setCurrentImages] = useState(null);


    useEffect(() => {
        reset(ad)
        setCurrentImages(ad?.images);
        console.log(downloadImages)
    }, [ad])


    const deleteCurrentImage = (e, imgID) => {
        if (currentImages === undefined) {
            return;
        }
        e.preventDefault();

        setCurrentImages((prev) => prev.filter((id) => id.id !== imgID));
        console.log(currentImages);
        // setCurrentImages(null);

        fetch(
            getHost({
                controller: "image",
                action: imgID,
            }),
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json,",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((resp) => resp.json())
            .then((json) => console.log(json));
    };

    return(
        <div className="create_ads_m_block create_ads_border">
            <div className="advers___title">Описание:</div>

            <div className="input_wrap input_wrap_100">
                <input defaultValue={ad.name} className="input" type="text" placeholder='Введите заголовок' {...register('name')}/>
                <p className="error">{errors.name?.message}</p>
            </div>

            <div className="input_wrap input_wrap_100">
                <textarea defaultValue={ad.about?.replaceAll('<br />', '')} placeholder='Описание требуемой услуги' className='textarea' {...register('about')}></textarea>
                <p className="error">{errors.about?.message}</p>
            </div>

            {currentImages !== undefined && currentImages !== null && (
                <div className="imagesMultiple">
                    {currentImages?.map((img) => {
                        return (
                            <div key={img.id} className="input_foto_wrap">
                                <img src={url + img.filename} alt="" />
                                <button
                                    title="удалить"
                                    type="button"
                                    data_id={img.id}
                                    onClick={(e) => deleteCurrentImage(e, img.id)}>
                                    <DeleteIcon />
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}

                <InputPhoto
                    images={downloadImages}
                    onLoad={setDownloadImages}
                    onDelete={setDownloadImages}
                    maxCount={5}
                    limitText={"До 5-ти фото."}
                />

        </div>
    )
}

export default EditSearchAbout;