import { NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";

import ModalsContext from "../../store/modals-context";
import google from "./../../assets/images/app/google.webp";
import huawei from "./../../assets/images/app/huawei.webp";
import vk from "./../../assets/images/icons/vk.svg";
import tg from "./../../assets/images/icons/telegram.svg";
import yt from "./../../assets/images/icons/youtube.svg";
import apple from "./../../assets/images/app/apple.webp";
import AuthContext from "../../store/auth-context";
import logo from "./../../assets/images/icons/logo.svg";
import age from "./../../assets/images/icons/age.svg";
import { isAppstore } from "../../App";
import s from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {adsSearchAction, blockerAction} from "../../store/redux";

const Footer = () => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);

    const dispatch = useDispatch();
    const selector = useSelector((state) => state.blockerPage.blockPage);

    const navigate = useNavigate();

    return (
        <footer className={s.footer}>
            <div className="container">
                <div className={s.footer__top}>
                    <div className={s.socials}>
                        <NavLink
                            to="/"
                            className={s.logo_link}
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/"));
                            }}>
                            <img width={134} height={50} alt="logo" src={logo}></img>
                        </NavLink>
                        <div>
                            <div className={s.links_wrapper}>
                                <img width={35} height={35} alt="age" src={age}></img>
                            </div>
                        </div>
                    </div>
                    <div className={s.nav_left}>
                        <NavLink
                            className="item"
                            to="/about"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/about");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/about"));
                            }}>
                            О проекте
                        </NavLink>
                        <NavLink
                            className="item"
                            to="/blog"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/blog");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/blog"));
                            }}>
                            Блог
                        </NavLink>
                        <NavLink
                            to="/administrants-info"
                            className="item"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/administrants-info");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/administrants-info"));
                            }}>
                            Информация для исполнителей
                        </NavLink>
                        <NavLink
                            to="/customers-info"
                            className="item"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/customers-info");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/customers-info"));
                            }}>
                            Информация для заказчиков
                        </NavLink>
                        {!isAppstore && (
                            <NavLink
                                to="/application"
                                className="item"
                                onClick={(e) => {
                                    selector ? e.preventDefault() : navigate("/application");
                                    selector ? modal.blockModal() : modal.closeModal();
                                    dispatch(blockerAction.getUrl("/application"));
                                }}>
                                Мобильное приложение
                            </NavLink>
                        )}
                        <NavLink
                            to="/partners"
                            className="item"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/partners");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/partners"));
                            }}>
                            Наши партнеры
                        </NavLink>

                        {ctx.userData?.id ? (
                            <NavLink
                                className="item"
                                to="/marketing"
                                onClick={(e) => {
                                    selector ? e.preventDefault() : navigate("/advertiser");
                                    selector ? modal.blockModal() : modal.closeModal();
                                    dispatch(blockerAction.getUrl("/advertiser"));
                                }}>
                                Реклама
                            </NavLink>
                        ) : (
                            <NavLink to='/marketing' className="item" onClick={() => {
                                dispatch(adsSearchAction.takeFooterLink(1))
                            }}>
                                Реклама
                            </NavLink>
                        )}
                    </div>
                    <div className={s.nav_right}>
                        <NavLink
                            className="item"
                            to="/referral"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/referral");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/referral"));
                            }}>
                            Бонусная программа
                        </NavLink>
                        <NavLink
                            className="item"
                            to="/partner_program"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/partner_program");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/partner_program"));
                            }}>
                            Партнерская программа
                        </NavLink>
                        {ctx.userData?.id ? (
                            <NavLink
                                className="item"
                                to="/lc/support"
                                onClick={(e) => {
                                    selector ? e.preventDefault() : navigate("/lc/support");
                                    selector ? modal.blockModal() : modal.closeModal();
                                    dispatch(blockerAction.getUrl("/lc/support"));
                                }}>
                                Служба поддержки
                            </NavLink>
                        ) : (
                            <NavLink className="item" onClick={() => {
                                modal.unauthModal();
                                dispatch(adsSearchAction.takeFooterLink(2))
                            }}>
                                Служба поддержки
                            </NavLink>
                        )}

                        <NavLink
                            className="item"
                            to="/faq"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/faq");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/faq"));
                            }}>
                            Часто задаваемые вопросы
                        </NavLink>
                        <NavLink
                            to="/docs"
                            className="item"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/docs");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/docs"));
                            }}>
                            Правовые документы
                        </NavLink>
                        <NavLink
                            to="/charity"
                            className="item"
                            onClick={(e) => {
                                selector ? e.preventDefault() : navigate("/charity");
                                selector ? modal.blockModal() : modal.closeModal();
                                dispatch(blockerAction.getUrl("/charity"));
                            }}>
                            Благотворительность
                        </NavLink>
                        <NavLink to="/contacts" className="item">
                            Контакты
                        </NavLink>
                    </div>
                    <div className={s.apps}>
                        <div className={s.contacts}>
                            <span>E-mail для связи:</span>
                            <a href="mailto:info@citywork.ru">info@citywork.ru</a>
                        </div>
                        <div className={s.links}>
                            <a
                                target="_blank"
                                aria-label="apple application"
                                rel="nofollow noreferrer"
                                href="https://apps.apple.com/ru/app/citywork/id6445844643">
                                <img width={133} height={41} src={apple} alt="apple" />
                            </a>
                            <a
                                aria-label="google application"
                                target="_blank"
                                rel="nofollow noreferrer"
                                href="https://play.google.com/store/apps/details?id=ru.foreman.go">
                                <img width={133} height={41} src={google} alt="google" />
                            </a>
                            <a
                                target="_blank"
                                rel="nofollow noreferrer"
                                aria-label="huawei application"
                                href="https://appgallery.huawei.com/app/C107725959">
                                <img width={133} height={41} src={huawei} alt="huawei" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.footer__bottom}>
                <div className={`container ${s.container}`}>
                    <div className={s.docs}>
                        <p>(с) 2022-{new Date().getFullYear()} ООО «Миговеб»</p>
                        <NavLink to="/rules" className="item">
                            Правила сервиса
                        </NavLink>
                        <NavLink to="/userTerm" className="item">
                            Пользовательское соглашение
                        </NavLink>
                        <NavLink to="/privacy" className="item">
                            Политика конфиденциальности
                        </NavLink>
                        <NavLink to="/agreement" className="item">
                            Согласие на обработку персональных данных
                        </NavLink>
                        <NavLink to="/personalData" className="item">
                            Правила политики обработки персональных данных
                        </NavLink>
                    </div>
                    <div className={s.icons}>
                        <a href="https://vk.com/public218647080" rel="nofollow noreferrer" target="_blank">
                            <img width={22} height={22} alt="icon" src={vk}></img>
                        </a>
                        <a href="https://www.youtube.com/@migoweb2014" rel="nofollow noreferrer" target="_blank">
                            <img width={20} height={20} alt="icon" src={yt}></img>
                        </a>
                        <a href="https://t.me/servisProrab" rel="nofollow noreferrer" target="_blank">
                            <img width={18} height={18} alt="icon" src={tg}></img>
                        </a>
                    </div>
                </div>
                <p className={s.register_num}>
                    Регистрационный номер 22-23-008374 ООО "Миговеб" в реестре операторов персональных данных
                </p>
            </div>
        </footer>
    );
};

export default Footer;
