import "../cabinet.sass";
import CabinetAside from "./CabinetAside";
import CabinetInfo from "../cabinetInfo/CabinetInfo";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { token } from "../../App";
import Notifications from "../notifications/Notifications";
import FavoritesAds from "../favorites/Favorites";
import Support from "../support/Support";
import TermsMain from "../terms/TermsMain";
import Request from "../request/Request";
import ResponseMain from "../responses/ResponseMain";
import DeviceContext from "../../store/device-context";
import getHost from "../../store/host-store";
import Feedback from "../feedback/Feedback";
import Referral from "../referral/Referral";
import BankChecks from "../BankChecks/BankChecks";
import NotFound from "../../components/404/NotFound";
import AdvertisementMain from "../advertisement/AdvertisementMain";
import { useDispatch, useSelector } from "react-redux";
import { adsSearchAction } from "../../store/redux";
import RoleTabs from "../../UI/RoleTabs/RoleTabs";
import PromoPage from "../promotion/Promo";
import SubscribeRoutes from "../subscribe/subscribeMain/SubscribeRoutes";
import ChoosePage from "../promotion/choose/ChoosePage";
import RatePage from "../promotion/rates/RatePage";
import ModalsContext from "../../store/modals-context";

const CabinetMainPage = () => {
    const pageWithRoleTabs = window.location.href.indexOf('advertisement') > -1 || window.location.href.indexOf('request') > -1 ||
        window.location.href.indexOf('responses') > -1 || window.location.href.indexOf('promotion') > -1 || window.location.href.indexOf('subscribe') > -1
    const pageCabinet = window.location.href.indexOf('/lc') > -1;
    const modal = useContext(ModalsContext);
    const [dataUser, setDataUser] = useState({});
    const [render, setRender] = useState(false);
    const device = useContext(DeviceContext);
    const selectorStatus = useSelector((state) => state.adsSearchLogic.isPageExist);
    const dispatch = useDispatch();

    if (window.location.href.includes("/lc/advertisement/edit") && selectorStatus) {
        // dispatch(adsSearchAction.isPageExistence(''))
    }

    useEffect(() => {
        dispatch(adsSearchAction.isPageExistence(""));
    }, [window.location.href]);

    const getRerender = () => {
        setRender((rerun) => !rerun);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetch(
            getHost({
                controller: "user",
                action: "identity",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setDataUser(result.data);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [render]);

    return (
        <>
            {selectorStatus !== 403 ? (
                <section className="container">
                    <div className="cabinet__box">
                        {device.openAside && <CabinetAside data={dataUser} />}
                        <div className="cabinet__content">
                            {window.innerWidth <= 1025 && (
                                <button type="button" className="open-admin-menu" onClick={pageCabinet ? modal.toggleMenu : device.toggleAside}>
                                    <div className="line">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    Меню
                                </button>
                            )}
                            {device.isMobile && pageWithRoleTabs && <RoleTabs isSquare firstTitle="Я ищу" secondTitle="Я предлагаю" />}

                            <Routes>
                                <Route
                                    path="/"
                                    element={<CabinetInfo getRerender={getRerender} data={dataUser} />}></Route>
                                <Route path="/advertisement/*" element={<AdvertisementMain />}></Route>
                                <Route path="/request" element={<Request />}></Route>
                                <Route path="/responses" element={<ResponseMain />}></Route>
                                <Route path="/notifications" element={<Notifications />}></Route>
                                <Route path="/support" element={<Support />}></Route>
                                <Route path="/favorites" element={<FavoritesAds />}></Route>
                                <Route path="/promotion" element={<PromoPage />}></Route>
                                <Route path="/promotion/choose" element={<ChoosePage />}></Route>
                                <Route path="/promotion/rate" element={<RatePage />}></Route>
                                <Route path="/subscribe/*" element={<SubscribeRoutes />}></Route>
                                <Route path="/feedback" element={<Feedback />}></Route>
                                <Route path="/term" element={<TermsMain />}></Route>
                                <Route path="/referral" element={<Referral />}></Route>
                                <Route path="/Checks" element={<BankChecks />}></Route>
                                <Route path="*" element={<NotFound />}></Route>
                            </Routes>
                        </div>
                    </div>
                </section>
            ) : (
                <NotFound />
            )}
        </>
    );
};

export default CabinetMainPage;
