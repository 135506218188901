const AdvertiserText = ({organization}) => {
    return (
        <>
        {organization !== null &&
            (
                (organization?.on_moderation || organization?.status === 0) &&
                <div className="toast_advertiser toast_on_moderation">Ваш рекламный аккаунт на проверке. Ожидайте...</div>
                ||
                (organization?.status === 1 && organization?.confirmed === 0) && <div className="toast_advertiser toast_advertiser_success">
                    <h4>Рекламный аккаунт прошел предварительную проверку!</h4>
                    <p>Редактировать данные невозможно. Нам необходимо убедится что Вы указали реквизиты правильно,
                        для этого Вам потребуется оплатить любую сумму с расчётного счёта на кошелёк CityWork, вся сумма зачислится Вам в кошелёк.
                    </p>
                </div>
                ||
                (organization?.status === 2 && !organization?.on_moderation) && <div className="toast_advertiser toast_moderation_failed">
                    <h4>Рекламный аккаунт не прошел проверку!</h4>
                    <p>Пожалуйста, проверьте правильность введенных данных и повторно отправьте профиль на проверку!</p>
                </div>
                ||
                (organization?.status === 1 && organization?.confirmed === 1) &&
                <div className="toast_advertiser toast_advertiser_success">
                    <h4>Рекламный аккаунт прошел проверку!</h4>
                    <p>Редактировать данные невозможно. Теперь Вы можете размещать свою рекламу на сервисе CityWork!</p>
                </div>
                ||
                (organization?.status === 1 && organization?.confirmed === 2) &&
                <div className="toast_advertiser toast_advertiser_success">
                    <p>Ожидаем поступления оплаты для подтверждения юридической информации. Процесс занимает в течение 48 часов.</p>
                </div>
            )
        }
        </>
    )
}

export default AdvertiserText;