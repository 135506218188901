import {useContext, useEffect, useState} from "react";
import axios from "axios";

import { NonViewedIcon } from "../../../components/icons/NotViewedIcon";
import { ViewedIcon } from "../../../components/icons/ViewedIcon";
import MessageField from "../../../UI/MessageField/MessageField";
import noAvatar from "../../../assets/images/stubs/avatar.svg";
import getHost, { url } from "../../../store/host-store";
import { token } from "../../../App";
import s from "./style.module.scss";
import AuthContext from "../../../store/auth-context";
import {usePutMessageTextMutation} from "../../../store/redux/queries/support-message";
import style from "../../../cabinet/support/style.module.sass";
import {EditIcon} from "../../../components/icons/EditIcon";
import {CloseIcon} from "../../../components/icons/CloseIcon";

const Dialog = ({ id, userData, setRerender }) => {
    const [messages, setMessages] = useState([]);

    const [attachedFiles, setAttachedFiles] = useState([]);
    const [textInput, setTextInput] = useState("");
    const disabled = textInput === "" && attachedFiles.length === 0;

    const ctx = useContext(AuthContext)
    const [messageId, setMessageId] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const ifAdmin = ctx.userData.account.role === 'admin' || ctx.userData.account.role === 'tech'
    const [putMessage] = usePutMessageTextMutation();

    const clickEditMessage = async (id, text) => {
        setInputValue(text)
        setMessageId(id)

        if(messageId !== 0){
            await putMessage({id: messageId, body: {text: inputValue}})
            setMessageId(0)
            ctx.setRerender(prev => !prev)
        }
    }

    const closeMessageInput = () => {
        setMessageId(0)
    }

    const fetchChatMessages = () => {
        // Получаем сообщения после первой загрузки стр
        fetch(
            getHost({
                controller: "chat-message",
                action: "user-messages-by-id",
                id,
                expand: "fromUser, files",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setMessages(result.data);
                }
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        if (id !== null) {
            fetchChatMessages();
        }
    }, [id, ctx.rerender]);

    useEffect(() => {
        if (id !== null) {
            setMessages([]);
            //очистка формы
            setTextInput("");
            // очищаем выбранные изображения
            setAttachedFiles([]);
            //повторный запрос для отображения нового сообщения
            fetchChatMessages();
        }
    }, [id]);

    // Отправка сообщения в поддержку
    const sendMessage = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("text", textInput);
        formData.append("to_user_id", id);

        // Отправка если выбрано изображение
        if (attachedFiles.length > 0) {
            attachedFiles.map((file) => formData.append("file[]", file));
        }

        axios
            .post(
                getHost({
                    controller: "chat-message",
                }),
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
            .then((res) => {
                //очистка формы
                setTextInput("");
                //повторный запрос для отображения нового сообщения
                fetchChatMessages();
                // очищаем выбранные изображения
                setAttachedFiles([]);
                //ререндерим список сообщений, чтобы обновилось последнее в диалогах
                setRerender((prev) => !prev);
            })
            .catch((err) => console.log(err));
    };

    console.log(id)

    if (messages.length > 0) {
        return (
            <div className={s.dialog}>
                <div>
                    {Object.keys(userData).length > 0 && (
                        <div className={s.top}>
                            <div className={s.user_info}>
                                {userData.img === null ? (
                                    <img alt="avatar" src={noAvatar}></img>
                                ) : userData.webpFilename ? (
                                    <img alt="avatar" src={url + userData.webpFilename}></img>
                                ) : (
                                    <img alt="avatar" src={url + userData.img}></img>
                                )}
                                <p>{userData.name}</p>
                                <p style={{textDecoration: 'underline'}}>ID {id}</p>
                            </div>
                        </div>
                    )}
                    <div className={s.dialog__wrapper}>
                        {messages.map((message) => {
                            return isUserMessage(message) ? (
                                <div className={`${s.from_user} ${s.message_wrapper}`} key={message.id}>
                                    {message.fromUser?.avatar === null ? (
                                        <img className={s.avatar} alt="avatar" src={noAvatar}></img>
                                    ) : message.fromUser?.webpFilename ? (
                                        <img
                                            className={s.avatar}
                                            alt="avatar"
                                            src={url + message.fromUser?.webpFilename}></img>
                                    ) : (
                                        <img
                                            className={s.avatar}
                                            alt="avatar"
                                            src={url + message.fromUser?.avatar}></img>
                                    )}
                                    <div className={s.message}>
                                        <span className={s.date}>
                                            {message.fromUser?.name} {message.created}
                                        </span>
                                        <div className={`${s.text} ${s.user_message}`}>
                                            {getImages(message).map((img) => (
                                                <img key={img.file} alt={img.id} src={url + img.file}></img>
                                            ))}
                                            {messageId !== message.id && <p key={message.id} className={style.message__text}>{message.text}</p>}
                                            {messageId === message.id && <input
                                                type="text"
                                                onChange={(event) => setInputValue(event.target.value)}
                                                defaultValue={message.text}/>}
                                            <div className='flex'>
                                                {!ifAdmin && <div className={style.edit} onClick={() => clickEditMessage(message.id, message.text)}>
                                                    <EditIcon/>
                                                </div>}
                                                {!ifAdmin && messageId === message.id && <div className={style.edit} onClick={closeMessageInput}>
                                                    <CloseIcon/>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${s.from_support} ${s.message_wrapper}`} key={message.id}>
                                    {message.fromUser?.avatar === null ? (
                                        <img className={s.avatar} src={noAvatar} alt="avatar" />
                                    ) : (
                                        <img className={s.avatar} src={url + message.fromUser?.avatar} alt="avatar" />
                                    )}
                                    <div className={s.message}>
                                        <span className={s.date}>
                                            {message.fromUser?.name} {message.created}
                                        </span>
                                        <div className={`${s.text} ${s.support_message}`}>
                                            {getImages(message).map((img, i) => (
                                                <img key={i} alt={img.id} src={url + img.file}></img>
                                            ))}
                                            {messageId !== message.id && <p key={message.id} className={style.message__text}>{message.text}</p>}
                                            {messageId === message.id && <input
                                                type="text"
                                                onChange={(event) => setInputValue(event.target.value)}
                                                defaultValue={message.text}/>}
                                            <div className='flex'>
                                                {ifAdmin && <div className={style.edit} onClick={() => clickEditMessage(message.id, message.text)}>
                                                    <EditIcon/>
                                                </div>}
                                                {ifAdmin && messageId === message.id && <div className={style.edit} onClick={closeMessageInput}>
                                                    <CloseIcon/>
                                                </div>}
                                            </div>
                                        </div>
                                        {message.viewed ? (
                                            <div className={s.message__view_time}>
                                                <span>{message.view_time}</span>
                                                <ViewedIcon />
                                            </div>
                                        ) : (
                                            <div className={s.message__view_time}>
                                                <NonViewedIcon />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={s.message_field}>
                    <MessageField
                        textInput={textInput}
                        onTextChange={setTextInput}
                        onSendMessage={sendMessage}
                        onFilesChange={setAttachedFiles}
                        attachedFiles={attachedFiles}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    }
};

function isUserMessage(message) {
    return message.to_user_id === null;
}
function getImages(message) {
    return message?.files || [];
}
export default Dialog;
