import { useEffect } from "react";
import "./Docs.sass";

const UserTerm = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
    <div
        className="userTerm"
        style={{
            padding: "40px 15px 40px 15px",
        }}>
        <h5 className="text-align">Оферта о заключении договора оказания услуг</h5>
        <p>
            Настоящий документ является предложением ООО «Миговеб» (далее – «Компания») в адрес зарегистрированных
            пользователей Интернет-ресурса citywork.ru (далее – «пользователи», «вы») заключить рамочный договор
            оказания услуг (далее – «Договор»,) на указанных ниже условиях (далее – «оферта»).
        </p>
        <p className="p">
            В оферте могут встречаться термины, как они определены в Соглашение об использовании ресурса «CityWork» на
            сайте citywork.ru.
        </p>
        <p className="text-align">1. Предмет Договора</p>
        <p>
            1.1. Договор определяет общие условия обязательственных отношений, возникающих между Компанией и
            пользователем при оказании Компанией услуг информационно-технического или рекламного характера (далее –
            «услуги»). Данные условия могут быть дополнены и уточнены в дальнейшем путем заключения сторонами на
            основании Договора отдельных соглашений (сделок) об оказании услуг на сайте citywork.ru.
        </p>
        <p>
            1.2. Заключаемый Договор представляет собой договор с открытыми условиями. Существенные условия каждой
            совершаемой на сайте citywork.ru сделки по оказанию услуг формируются онлайн индивидуально для
            пользователя с помощью интерфейса citywork.ru, посредством которого пользователь выбирает услугу и ее
            параметры, сторонами согласовываются условия сделки.
        </p>
        <p>
            1.3. Настоящая оферта не распространяется на взаимоотношения, складывающиеся между Пользователями
            (Заказчиком и Исполнителем), все обязательства, возникающие между ними, регулируются непосредственно
            между такими Сторонами. Компания выступает лишь как лицо, оказывающее техническую поддержку по
            размещению соответствующих объявлений об оказываемых услугах и/или заданиях (потребностях) на выполнение
            таких услуг.
        </p>
        <p className="text-align">2. Заключение Договора. Заказ услуг</p>
        <p>
            2.1. Оферта считается акцептованной пользователем, а Договор между Компанией и пользователем заключенным
            с момента регистрации Пользователя в личном кабинете на сайте citywork.ru. Условия заключенного
            Договора применяются ко всем последующим сделкам между пользователем и Компанией. Договор может быть
            заключен только с пользователем, являющимся дееспособным физическим лицом либо юридическим лицом или
            индивидуальным предпринимателем.
        </p>
        <p>
            2.2. Каждый заказ конкретных услуг в рамках Договора является самостоятельной сделкой по оказанию услуг
            информационно-технического характера или рекламного характера. Сделка во исполнение настоящего Договора
            может быть заключена в отношении услуг, представленных на citywork.ru и доступных для заказа и оплаты
            в момент обращения пользователя к тому или иному сервису citywork.ru.
        </p>
        <p>
            2.3. Оплата заказа признается акцептом пользователя предложения о заключении сделки на согласованных
            сторонами условиях и правилах сервиса.
        </p>
        <p>
            2.4. Пользователь оформляет каждый заказ услуг в соответствии с условиями действующей редакции оферты и
            условиями соответствующего сервиса. Условия всех сервисов citywork.ru, которые размещены на
            citywork.ru в момент заказа услуг, являются неотъемлемой частью оферты. Заказ оформляется в режиме
            онлайн с Исполнителем услуги.
        </p>
        <p>
            2.5. Совершая акцепт оферты, вы выражаете согласие с тем, что факт выполнения определенных действий на
            citywork.ru, в том числе с использованием профессиональных технических инструментов, выполнение команд
            через интерфейс citywork.ru (нажатие кнопок, клики), совершение платежа и иные подобные действия
            означают ваше волеизъявление в отношении заказа и/или активации услуг в соответствии с указанными на
            citywork.ru параметрами услуг и их ценой.
        </p>
        <p className="text-align">3. Общие условия оказания услуг</p>
        <p>
            3.1. Услуги оказываются на территории Российской Федерации. Настоящие условия Договора в соответствии со
            ст. 435, 437 Гражданского Кодекса Российской Федерации являются публичной офертой (предложением)
            Компании в адрес физических и юридических лиц, содержащей существенные условия Договора на оказание
            Услуг и использование Сервиса
        </p>
        <p>
            3.2. Условиями получения пользователем услуг Компании является оплата услуг и соблюдение пользователем
            правил citywork.ru, установленных в документах, перечисленных в данном пункте, а также в инструкциях,
            представленных в интерфейсе citywork.ru (далее – «правила citywork.ru»):
        </p>
        <ul>
            <li>Правила сервиса;</li>
            <li>Правила обработки персональных данных;</li>
            <li>Политика конфиденциальности;</li>
            <li>Согласия на обработку персональных данных;</li>
            <li>Иные документы.</li>
        </ul>
        <p>
            3.3. Обязательства Компании по предоставлению согласно заказам пользователя, являются встречными по
            отношению к обязательствам пользователя по соблюдению правил citywork.ru и оплате услуг. При
            невыполнении пользователем данных обязательств Компания может приостановить или отказаться от оказания
            услуг в соответствующей части на основании правомочий, предусмотренных гражданским законодательством
            Российской Федерации.
        </p>
        <p>
            3.4. Компания оказывает услуги при наличии возможности их предоставления, которая определяется, в том
            числе, соблюдением пользователями правил citywork.ru. Несоблюдение правил citywork.ru может привести
            к невозможности исполнения обязательств Компании по оказанию соответствующих услуг.
        </p>
        <p>
            3.5. В силу характера предлагаемых Компанией услуг, направленных на удовлетворение индивидуальных
            потребностей пользователей по продвижению товаров, работ, услуг пользователей и иных предложений, в
            рамках Договора Компания не принимает на себя обязанности по оказанию услуг каждому лицу, которое к ней
            обратится и может отказать в оказании услуг пользователю. Например, в случаях, когда предоставление
            услуг не соответствует правилам citywork.ru, его тематике, категориям и пр.
        </p>
        <p>
            3.6. Некоторые услуги на citywork.ru могут быть доступны только для отдельных категорий пользователей,
            например, использующих citywork.ru для осуществления своей предпринимательской деятельности.
        </p>
        <p>
            3.7. Условия предоставления конкретных услуг в рамках Договора, в том числе их содержание, объем и сроки
            определяются в соответствующих Условиях сервисов и других правилах citywork.ru.
        </p>
        <p>
            3.8. Предоставляемые на citywork.ru услуги могут изменяться, дополняться, обновляться, в связи с чем
            их использование предлагается в режиме «как есть», то есть в том виде и объеме, в каком они
            предоставляются Компанией в момент обращения пользователей к услугам и сервисам citywork.ru.
        </p>
        <p>
            3.9. Компания вправе направлять вам по имеющимся в вашем профиле контактным данным уведомления,
            связанные с исполнением настоящего Договора или любой из заключенных в рамках Договора сделок, включая
            оповещения о статусе оказания услуги, способах продления, изменениях условий оказания услуг и иные
            информационные сообщения, посредством смс- и push-сообщений, электронной почты и телефонных звонков.
        </p>
        <p>
            3.10. Вы обязуетесь соблюдать при пользовании услугами требования действующего законодательства
            Российской Федерации, положения Договора, соответствующие условия выбранной услуги или сервиса и все
            иные правила citywork.ru.
        </p>
        <p>
            3.11. По требованию Компании вы обязаны предоставить информацию и документы, необходимые для
            идентификации пользователя в качестве стороны Договора, в частности при направлении в адрес Компании
            заявлений, запросов и прочих обращений, связанных с оказанием услуг на citywork.ru. В целях
            идентификации частных пользователей Компанией могут быть запрошены ФИО, серия и номер паспорта,
            скан-копия второй и третьей страницы паспорта (второй разворот), адрес электронной почты и номер
            телефона пользователя, используемые для доступа к профилю. Кроме того, Компания вправе самостоятельно
            проводить верификацию пользователя в качестве стороны по Договору путем проведения сверки указанных вами
            в профиле реквизитов с реквизитами плательщика по Договору, а также иными способами.
        </p>
        <p>
            3.12. Стороны признают действия, совершенные с использованием логина и пароля пользователя, действиями,
            совершенными пользователем и имеющими силу простой электронной подписи.
        </p>
        <p className="text-align">4. Стоимость услуг и порядок расчетов</p>
        <p>
            4.1. Стоимость выбранной пользователем услуги при заключении сделки в рамках Договора определяется
            исходя из характеристик услуги (вид услуги, состав пакета услуг и иные параметры). Стоимость выбранной
            услуги отображается в рублях в интерфейсе citywork.ru при оформлении заказа, если иное не
            предусмотрено условиями соответствующего сервиса или услуги. Стоимость не включает в себя НДС. Оплата
            услуг осуществляется по ценам, действующим на момент совершения платежа.
        </p>
        <p>
            4.2. Вы можете совершить оплату услуг одним из способов, представленных в интерфейсе citywork.ru в
            момент оформления заказа услуг, в том числе за счет ранее перечисленного аванса.
        </p>
        <p>
            4.3. Пользователь с профилем юридического лица или индивидуального предпринимателя должен осуществлять
            оплату услуг по Договору безналичным переводом денежных средств на расчетный счет Компании на основании
            выставленного через интерфейс citywork.ru счета. Допускается также оплата с использованием
            корпоративной банковской карты, оформленной на соответствующее юридическое лицо или индивидуального
            предпринимателя.
        </p>
        <p>
            4.4. В случае отмены услуги до начала ее предоставления, прекращения услуги по любому иному основанию,
            Компания вправе зачислить неиспользованную сумму (при её наличии), на Кошелек пользователя (если он
            создан), независимо от того, каким способом была совершена ранее оплата услуг.
        </p>
        <p>
            4.5. Факт предоставления пользователю услуг на citywork.ru и их объем определяются на основании
            статистических данных учетной системы Компании.
        </p>
        <p>
            4.6. В отношении услуг, которые Компания оказывает пользователям с профилями юридического лица или
            индивидуального предпринимателя в целях исполнения требований законодательства Российской Федерации о
            бухгалтерском учете, Компания ежемесячно направляет по адресу электронной почты пользователя или
            предоставляет возможность выгрузки в профиле копии универсального передаточного документа, включающего в
            себя счет-фактуру и акт (далее – «УПД») за отчетный месяц. Оригинал такого документа может быть
            направлен почтой по адресу, указанному в профиле, по запросу стороны.
        </p>
        <p>
            Стороны могут договориться об обмене документами в электронном виде с помощью систем электронного
            документооборота.
        </p>
        <p>
            4.7. Если Компания не получает письменные мотивированные возражения пользователя относительно оказанных
            услуг в течение 5 дней с даты завершения отчетного месяца (в том числе по причине отказа представителя
            пользователя от получения электронной копии УПД или других документов или указания пользователем
            ненадлежащего адреса для получения документов) услуги за отчетный период, указанные в УПД, считаются
            оказанными Компанией надлежащим образом и принятыми пользователем в полном объеме.
        </p>
        <p>
            4.8. Ответственность за получение любых документов (в том числе УПД, сообщений, уведомлений)
            вышеуказанными способами лежит на пользователе. Компания не несет ответственности за задержку или
            неполучение пользователем документов, если это явилось результатом неисправности систем связи,
            действия/бездействия провайдеров, операторов связи, утраты пользователем доступа к профилю или иных
            обстоятельств, находящихся вне зоны контроля Компании.
        </p>
        <p>
            4.9. Наименование, юридический адрес, почтовый адрес, ИНН, КПП, контактная и прочая информация в
            платежных документах, УПД и других документах, формируемых Компанией, указываются в соответствии с
            реквизитами пользователя, указанными в его профиле.
        </p>
        <p>
            4.10. Пользователь самостоятельно несет ответственность за правильность данных, указанных в профиле
            пользователя на citywork.ru. При изменении реквизитов Пользователь обязан незамедлительно внести
            соответствующие изменения в своем профиле. Если вы не можете самостоятельно изменить данные в профиле,
            вы обязаны уведомить Компанию о таких изменениях по установленной Компанией форме с приложением, по
            запросу Компании, подтверждающих изменения документов.
        </p>
        <p>
            4.11. Компания вправе проверять правильность указанных в профиле реквизитов пользователя путем сверки с
            данными, содержащимися в едином государственном реестре юридических лиц (индивидуальных
            предпринимателей) и, при наличии расхождений, корректировать соответствующие данные, используя в
            качестве идентификатора пользователя указанный им ИНН.
        </p>
        <p className="text-align">5. Ответственность</p>
        <p>
            5.1. Компания обязуется предпринимать все разумные усилия для надлежащего оказания услуг, однако не
            отвечает и не компенсирует убытки пользователя в случае, если пользователь не может воспользоваться
            услугами по следующим причинам:
        </p>
        <ul>
            <li>
                технологические неисправности каналов связи общего пользования, посредством которых осуществляется
                доступ к услугам, утраты пользователем доступа в интернет по любой причине, ошибки, пропуски,
                перерывы в работе или передаче данных, дефекты линий связи и иные технические сбои;
            </li>
            <li>
                несанкционированное вмешательство в работу citywork.ru третьих лиц, включая хакерские, DDoS-атаки,
                действия вирусных программ и иные нарушения работы citywork.ru.
            </li>
            <li>
                утрата пользователем доступа к профилю, в том числе при наличии признаков несанкционированного его
                использования;
            </li>
            <li>при наступлении обстоятельств непреодолимой силы, и иных объективных обстоятельствах.</li>
        </ul>
        <p>
            5.2. В случае причинения убытков пользователю по вине Компании ответственность определяется в порядке,
            предусмотренном законодательством Российской Федерации. При этом ответственность Компании перед
            пользователями, использующими citywork.ru для предпринимательской и иной профессиональной
            деятельности, определяется в размере, не превышающем стоимость заказанной и оплаченной пользователем, но
            не полученной по вине Компании услуги или, если применимо, в сумме, не превышающей баланс Кошелька
            пользователя на момент причинения убытков и исключительно в случае потери пользователем части или всей
            суммы денежных средств на балансе Кошелька по вине Компании.
        </p>
        <p>
            5.3. Компания не отвечает перед пользователем за убытки, возникшие у пользователя не по вине Компании, в
            том числе в связи с нарушением пользователем правил citywork.ru.
        </p>
        <p>
            5.4. Компания не несет ответственности за перевод или поступление денежных средств пользователя в оплату
            услуг (оказываемых Исполнителем Заказчику). Безопасность, конфиденциальность, а также иные условия
            использования выбранных вами способов оплаты определяются соглашениями между пользователем и
            соответствующими организациями по приему платежей.
        </p>
        <p>
            5.5. Вы отвечаете за любые действия, совершенные на citywork.ru с использованием ваших данных для
            входа на citywork.ru (в том числе за действия работников и третьих лиц) и за убытки, которые могут
            возникнуть по причине несанкционированного использования вашего профиля и простой электронной подписи.
        </p>
        <p>
            5.6. В случае кражи, утери данных для входа вы самостоятельно предпринимаете необходимые меры для смены
            пароля для доступа к профилю. Компания не несет ответственность за действия третьих лиц, повлекшие
            кражу, утерю ваших данных для входа, а также любые расходы, ущерб или упущенную выгоду, возникшие у
            пользователя в связи с несанкционированным доступом третьих лиц к профилю пользователя.
        </p>
        <p>
            5.7. Компания не гарантирует соответствие сервисов citywork.ru ожиданиям пользователя и получение
            пользователем отклика посетителей на размещенные им объявления в связи с получением услуг на
            citywork.ru.
        </p>
        <p>
            5.8. Компания не несет ответственности за качество услуг, оказываемых Исполнителем Заказчику, все
            взаимоотношения по услугам, товарам, работам, выстраиваются между таким сторонами, в том числе
            претензионные.
        </p>
        <div className="text-align">6. Рассмотрение претензий</div>
        <p>
            6.1. Споры, возникшие в рамках настоящего Договора, должны быть переданы на рассмотрение в Арбитражный
            суд по месту нахождения Компании, а если спор не подведомственен арбитражному суду — в иные суды в
            соответствии с законодательством Российской Федерации.
        </p>
        <p>
            6.2. До обращения в суд пользователь с профилем юридического лица или индивидуального предпринимателя,
            должен соблюсти досудебный порядок разрешения споров, направив в адрес Компании письменную претензию, с
            приложением документов, подтверждающих обстоятельства и требования, на которые ссылается пользователь.
            Срок рассмотрения таких претензий составляет не более 30 дней с момента получения претензии Компанией.
        </p>
        <p className="text-align">7. Прочие условия</p>
        <p>
            7.1. Договор вступает в силу с момента его заключения в порядке, указанном в разделе настоящей оферты, и
            действует в течение неопределенного срока до момента его прекращения в соответствии с Договором и
            законодательством РФ.
        </p>
        <p>
            7.2. Компания вправе изменять и/или дополнять оферту в той мере, в какой это допускается
            законодательством, а также отозвать оферту в любое время. Датой изменения оферты является дата
            опубликования на citywork.ru новой редакции оферты. Вы должны ознакомиться с действующей редакцией
            оферты, размещенной на citywork.ru, и с условиями каждой сделки до заключения Договора и совершения
            сделки.
        </p>
        <p>
            7.3. Заказ услуг и совершение пользователем сделки в рамках Договора после вступления в силу изменений в
            оферте означает, что стороны пришли к согласию о внесении соответствующих изменений и/или дополнений в
            Договор. Такие изменения в Договоре не применяются к услугам, заказанным и оплаченным пользователем до
            вступления в силу изменений в оферте.
        </p>
        <p>
            7.4. Компания вправе расторгнуть Договор в порядке, предусмотренном законодательством, в том числе в
            случае однократного нарушения пользователем законодательства, условий Договора или правил citywork.ru,
            с уведомлением об этом пользователя через интерфейс citywork.ru, по адресу электронной почты или иным
            способом.
        </p>
        <p>
            7.5. Договор может быть прекращен досрочно по инициативе пользователя, направив Компании соответствующее
            уведомление на электронную почту, указанную в разделе 8. настоящего Соглашения). Если пользователь не
            воспользовался профилем на citywork.ru в течение 3 лет, Договор считается прекращенным по инициативе
            пользователя по истечении указанного срока. При прекращении действия Договора вы вправе обратиться к
            Компании за возвратом неиспользованного остатка денежных средств в размере баланса Кошелька (если
            таковой имеется). Возврат неиспользованного остатка денежных средств осуществляется на основании
            письменного заявления пользователя по установленной Компанией форме на средство платежа, с помощью
            которого был осуществлен платеж за услуги, а в случае невозможности использования данного средства
            платежа для возврата денежных средств – иным определенным Компанией способом.
        </p>
        <p>
            7.6. При обращении за возвратом неиспользованного остатка денежных средств пользователь по требованию
            Компании обязан идентифицировать себя в качестве стороны по Договору, совершив указанные в инструкциях
            Компании действия (направить заявление с адреса электронной почты, указанного в профиле, использовать
            при обращении номер телефона, указанный в профиле и подтвержденный в порядке, предусмотренном
            citywork.ru, и др.), а также предоставить необходимые документы и информацию (в том числе документы,
            требуемые для идентификации пользователя, документы, подтверждающие факт внесения пользователем платежей
            и др.).
        </p>
        <p>
            7.7. Компания вправе указывать наименование, товарный знак и общее описание услуг пользователя с
            профилем юридического лица или индивидуального предпринимателя в своих маркетинговых материалах.
        </p>
        <p>
            7.8. Договор представляет все условия, согласованные между сторонами в отношении его предмета, и
            заменяет собой все прежние договоренности, заверения и любого рода соглашения между сторонами в
            отношении его предмета, если иное прямо не согласовано сторонами.
        </p>
        <div className="text-align">8. Реквизиты Компании:</div>
        <p className="strong">Общество с ограниченной ответственностью «Миговеб» (ООО «Миговеб»)</p>
        <p className="strong">
            119296, г. Москва, муниципальный округ Гагаринский, ул. Молодёжная, д. 3, пом. XVII, офис 6.
        </p>
        <p className="strong">ИНН 0411167510 ОГРН 1140411000124</p>
        <p className="strong">
            E-mail: <a href="mailto: migowebmail@yandex.ru">info@migoweb.ru</a>
        </p>
    </div>
    );
};

export default UserTerm;

// <div className="userTerm">
//     <h5 className="text-align">Оферта о заключении договора оказания услуг</h5>
//     <p>
//         Настоящий документ является предложением ООО «Миговеб» (далее – «Компания») в адрес зарегистрированных
//         пользователей Интернет-ресурса citywork.ru (далее – «пользователи», «вы») заключить рамочный договор
//         оказания услуг (далее – «Договор»,) на указанных ниже условиях (далее – «оферта»).
//     </p>
//     <p className="p">
//         В оферте могут встречаться термины, как они определены в Пользовательском соглашении citywork.ru.
//     </p>
//     <p className="text-align">1. Предмет Договора</p>
//     <p>
//         1.1. Договор определяет общие условия обязательственных отношений, возникающих между Компанией и
//         пользователем при оказании Компанией услуг информационно-технического или рекламного характера (далее –
//         «услуги»). Данные условия могут быть дополнены и уточнены в дальнейшем путем заключения сторонами на
//         основании Договора отдельных соглашений (сделок) об оказании услуг на citywork.ru.
//     </p>
//     <p>
//         1.2. Заключаемый Договор представляет собой договор с открытыми условиями. Существенные условия каждой
//         совершаемой на citywork.ru сделки по оказанию услуг формируются онлайн индивидуально для пользователя с
//         помощью интерфейса citywork.ru, посредством которого пользователь выбирает услугу и ее параметры,
//         сторонами согласовываются условия сделки.
//     </p>
//     <p>
//         1.3. Настоящая оферта не распространяется на взаимоотношения, складывающиеся между Пользователями
//         (Заказчиком и Исполнителем), все обязательства, возникающие между ними, регулируются непосредственно
//         между такими Сторонами. Компания выступает лишь как лицо, оказывающее техническую поддержку по
//         размещению соответствующих объявлений об оказываемых услугах и/или заданиях (потребностях) на выполнение
//         таких услуг.{" "}
//     </p>
//     <p className="text-align">2. Заключение Договора. Заказ услуг</p>
//     <p>
//         2.1. Оферта считается акцептованной пользователем, а Договор между Компанией и пользователем заключенным
//         с момента оказания пользователем услуг по соответствующей сделке (в этом случае она совершается
//         одновременно с заключением Договора) или с момента внесения аванса в счет оказания заказываемых в
//         будущем услуг. Условия заключенного Договора применяются ко всем последующим сделкам между пользователем
//         и Компанией. Договор может быть заключен только с пользователем, являющимся дееспособным физическим
//         лицом либо юридическим лицом или индивидуальным предпринимателем.
//     </p>
//     <p>
//         2.2. Каждый заказ конкретных услуг в рамках Договора является самостоятельной сделкой по оказанию услуг
//         информационно-технического характера или рекламного характера. Сделка во исполнение настоящего Договора
//         может быть заключена в отношении услуг, представленных на citywork.ru и доступных для заказа и оказания
//         в момент обращения пользователя к тому или иному сервису citywork.ru.
//     </p>
//     <p>
//         2.3. оказание заказа признается акцептом пользователя предложения о заключении сделки на согласованных
//         сторонами условиях и правилах сервиса.
//     </p>
//     <p>
//         2.4. Пользователь оформляет каждый заказ услуг в соответствии с условиями действующей редакции оферты и
//         условиями соответствующего сервиса. Условия всех сервисов citywork.ru, которые размещены на
//         citywork.ru в момент заказа услуг, являются неотъемлемой частью оферты. Заказ оформляется в режиме
//         онлайн с Исполнителем услуги.
//     </p>
//     <p>
//         2.5. Совершая акцепт оферты, вы выражаете согласие с тем, что факт выполнения определенных действий на
//         citywork.ru, в том числе с использованием профессиональных технических инструментов, выполнение команд
//         через интерфейс citywork.ru (нажатие кнопок, клики), совершение платежа и иные подобные действия
//         означают ваше волеизъявление в отношении заказа и/или активации услуг в соответствии с указанными на
//         citywork.ru параметрами услуг и их ценой.
//     </p>
//     <p className="text-align">3. Общие условия оказания услуг</p>
//     <p>3.1. Услуги оказываются на территории Российской Федерации</p>
//     <p>
//         3.2. Условиями получения пользователем услуг Компании является оказание услуг и соблюдение пользователем
//         правил citywork.ru, установленных в документах, перечисленных в данном пункте, а также в инструкциях,
//         представленных в интерфейсе citywork.ru (далее – «правила citywork.ru»):
//     </p>
//     <ul>
//         <li>Правила сервисе;</li>
//         <li>Правила обработки персональных данных;</li>
//         <li>Политика конфиденциальности;</li>
//         <li>Согласия на обработку персональных данных;</li>
//         <li>Иные документы.</li>
//     </ul>
//     <p>
//         3.3. Обязательства Компании по предоставлению согласно заказам пользователя, являются встречными по
//         отношению к обязательствам пользователя по соблюдению правил citywork.ru. При невыполнении
//         пользователем данных обязательств Компания может приостановить или отказаться от оказания услуг в
//         соответствующей части на основании правомочий, предусмотренных гражданским законодательством Российской
//         Федерации.
//     </p>
//     <p>
//         3.4. Компания оказывает услуги при наличии возможности их предоставления, которая определяется, в том
//         числе, соблюдением пользователями правил citywork.ru. Несоблюдение правил citywork.ru может привести к
//         невозможности исполнения обязательств Компании по оказанию соответствующих услуг.
//     </p>
//     <p>
//         3.5. В силу характера предлагаемых Компанией услуг, направленных на удовлетворение индивидуальных
//         потребностей пользователей по продвижению товаров, работ, услуг пользователей и иных предложений, в
//         рамках Договора Компания не принимает на себя обязанности по оказанию услуг каждому лицу, которое к ней
//         обратится и может отказать в оказании услуг пользователю. Например, в случаях, когда предоставление
//         услуг не соответствует правилам citywork.ru, его тематике, категориям и пр.
//     </p>
//     <p>
//         3.6. Некоторые услуги на citywork.ru могут быть доступны только для отдельных категорий пользователей,
//         например, использующих citywork.ru для осуществления своей предпринимательской деятельности.
//     </p>
//     <p>
//         3.7. Условия предоставления конкретных услуг в рамках Договора, в том числе их содержание, объем и сроки
//         определяются в соответствующих Условиях сервисов и других правилах citywork.ru.{" "}
//     </p>
//     <p>
//         3.8. Предоставляемые на citywork.ru услуги могут изменяться, дополняться, обновляться, в связи с чем их
//         использование предлагается в режиме «как есть», то есть в том виде и объеме, в каком они предоставляются
//         Компанией в момент обращения пользователей к услугам и сервисам citywork.ru.
//     </p>
//     <p>
//         3.9. Компания вправе направлять вам по имеющимся в вашем профиле контактным данным уведомления,
//         связанные с исполнением настоящего Договора или любой из заключенных в рамках Договора сделок, включая
//         оповещения о статусе оказания услуги, способах продления, изменениях условий оказания услуг и иные
//         информационные сообщения, посредством смс- и push-сообщений, электронной почты и телефонных звонков.
//     </p>
//     <p>
//         3.10. Вы обязуетесь соблюдать при пользовании услугами требования действующего законодательства
//         Российской Федерации, положения Договора, соответствующие условия выбранной услуги или сервиса и все
//         иные правила citywork.ru.
//     </p>
//     <p>
//         3.11. По требованию Компании вы обязаны предоставить информацию и документы, необходимые для
//         идентификации пользователя в качестве стороны Договора, в частности при направлении в адрес Компании
//         заявлений, запросов и прочих обращений, связанных с оказанием услуг на citywork.ru. В целях
//         идентификации частных пользователей Компанией могут быть запрошены ФИО, серия и номер паспорта,
//         скан-копия второй и третьей страницы паспорта (второй разворот), адрес электронной почты и номер
//         телефона пользователя, используемые для доступа к профилю. Кроме того, Компания вправе самостоятельно
//         проводить верификацию пользователя в качестве стороны по Договору путем проведения сверки указанных вами
//         в профиле реквизитов с реквизитами плательщика по Договору, а также иными способами.
//     </p>
//     <p>
//         3.12. Стороны признают действия, совершенные с использованием логина и пароля пользователя, действиями,
//         совершенными пользователем и имеющими силу простой электронной подписи.
//     </p>
//     <p className="text-align">4. Стоимость услуг и порядок расчетов</p>
//     <p>
//         4.1. Стоимость выбранной пользователем услуги при заключении сделки в рамках Договора определяется
//         исходя из характеристик услуги (вид услуги, состав пакета услуг и иные параметры). Стоимость выбранной
//         услуги отображается в рублях в интерфейсе citywork.ru при оформлении заказа, если иное не предусмотрено
//         условиями соответствующего сервиса или услуги. Стоимость не включает в себя НДС. оказание услуг
//         осуществляется по ценам, действующим на момент совершения платежа.
//     </p>
//     <p>
//         4.2. Вы можете совершить оказание услуг одним из способов, представленных в интерфейсе citywork.ru в
//         момент оформления заказа услуг, в том числе за счет ранее перечисленного аванса.
//     </p>
//     <p>
//         4.4. В случае отмены услуги до начала ее предоставления, прекращения услуги по любому иному основанию,
//         Компания вправе зачислить неиспользованную сумму (при её наличии), на Кошелек пользователя (если он
//         создан), независимо от того, каким способом была совершена ранее оказание услуг.
//     </p>
//     <p>
//         4.5. Факт предоставления пользователю услуг на citywork.ru и их объем определяются на основании
//         статистических данных учетной системы Компании
//     </p>
//     <p>
//         4.6. В отношении услуг, которые Компания оказывает пользователям с профилями юридического лица или
//         индивидуального предпринимателя в целях исполнения требований законодательства Российской Федерации о
//         бухгалтерском учете, Компания ежемесячно направляет по адресу электронной почты пользователя или
//         предоставляет возможность выгрузки в профиле копии универсального передаточного документа, включающего в
//         себя счет-фактуру и акт (далее – «УПД») за отчетный месяц. Оригинал такого документа может быть
//         направлен почтой по адресу, указанному в профиле, по запросу стороны.
//     </p>
//     <p>
//         Стороны могут договориться об обмене документами в электронном виде с помощью систем электронного
//         документооборота (далее – «ЭДО»).
//     </p>
//     <p>
//         4.7. Если Компания не получает письменные мотивированные возражения пользователя относительно оказанных
//         услуг в течение 5 дней с даты завершения отчетного месяца (в том числе по причине отказа представителя
//         пользователя от получения электронной копии УПД или других документов или указания пользователем
//         ненадлежащего адреса для получения документов) услуги за отчетный период, указанные в УПД, считаются
//         оказанными Компанией надлежащим образом и принятыми пользователем в полном объеме.
//     </p>
//     <p>
//         4.8. Ответственность за получение любых документов (в том числе УПД, сообщений, уведомлений)
//         вышеуказанными способами лежит на пользователе. Компания не несет ответственности за задержку или
//         неполучение пользователем документов, если это явилось результатом неисправности систем связи,
//         действия/бездействия провайдеров, операторов связи, утраты пользователем доступа к профилю или иных
//         обстоятельств, находящихся вне зоны контроля Компании.
//     </p>
//     <p>
//         4.9. Наименование, юридический адрес, почтовый адрес, ИНН, КПП, контактная и прочая информация в
//         платежных документах, УПД и других документах, формируемых Компанией, указываются в соответствии с
//         реквизитами пользователя, указанными в его профиле.
//     </p>
//     <p>
//         4.10. Пользователь самостоятельно несет ответственность за правильность данных, указанных в профиле
//         пользователя на citywork.ru. При изменении реквизитов, вы обязаны незамедлительно внести
//         соответствующие изменения в вашем профиле. Если вы не можете самостоятельно изменить данные в профиле,
//         вы обязаны уведомить Компанию о таких изменениях по установленной Компанией форме с приложением, по
//         запросу Компании, подтверждающих изменения документов
//     </p>
//     <p>
//         4.11. Компания вправе проверять правильность указанных в профиле реквизитов пользователя путем сверки с
//         данными, содержащимися в едином государственном реестре юридических лиц (индивидуальных
//         предпринимателей) и, при наличии расхождений, корректировать соответствующие данные, используя в
//         качестве идентификатора пользователя указанный им ИНН.
//     </p>
//     <p className="text-align">5. Ответственность</p>
//     <p>
//         5.1. Компания обязуется предпринимать все разумные усилия для надлежащего оказания услуг, однако не
//         отвечает и не компенсирует убытки пользователя в случае, если пользователь не может воспользоваться
//         услугами по следующим причинам:
//     </p>
//     <ul>
//         <li>
//             технологические неисправности каналов связи общего пользования, посредством которых осуществляется
//             доступ к услугам, утраты пользователем доступа в интернет по любой причине, ошибки, пропуски,
//             перерывы в работе или передаче данных, дефекты линий связи и иные технические сбои;
//         </li>
//         <li>
//             несанкционированное вмешательство в работу citywork.ru третьих лиц, включая хакерские, DDoS-атаки,
//             действия вирусных программ и иные нарушения работы citywork.ru.
//         </li>
//         <li>
//             утрата пользователем доступа к профилю, в том числе при наличии признаков несанкционированного его
//             использования;
//         </li>
//         <li>при наступлении обстоятельств непреодолимой силы, и иных объективных обстоятельствах.</li>
//     </ul>
//     <p>
//         5.3. Компания не отвечает перед пользователем за убытки, возникшие у пользователя не по вине Компании, в
//         том числе в связи с нарушением пользователем правил citywork.ru.
//     </p>
//     <p>
//         5.5. Вы отвечаете за любые действия, совершенные на citywork.ru с использованием ваших данных для входа
//         на citywork.ru (в том числе за действия работников и третьих лиц) и за убытки, которые могут возникнуть
//         по причине несанкционированного использования вашего профиля и простой электронной подписи.
//     </p>
//     <p>
//         5.6. В случае кражи, утери данных для входа вы самостоятельно предпринимаете необходимые меры для смены
//         пароля для доступа к профилю. Компания не несет ответственность за действия третьих лиц, повлекшие
//         кражу, утерю ваших данных для входа, а также любые расходы, ущерб или упущенную выгоду, возникшие у
//         пользователя в связи с несанкционированным доступом третьих лиц к профилю пользователя.
//     </p>
//     <p>
//         5.7. Компания не гарантирует соответствие сервисов citywork.ru ожиданиям пользователя и получение
//         пользователем отклика посетителей на размещенные им объявления в связи с получением услуг на
//         citywork.ru.
//     </p>
//     <p>
//         5.8. Компания не несет ответственности за качество услуг оказываемых Исполнителем Заказчику, все
//         взаимоотношения по услугам, товарам, работам, выстраиваются между таким сторонами, в том числе
//         претензионные.
//     </p>
//     <div className="text-align">6. Рассмотрение претензий</div>
//     <p>
//         6.1. Споры, возникшие в рамках настоящего Договора, должны быть переданы на рассмотрение в Арбитражный
//         суд по месту нахождения Компании, а если спор не подведомственен арбитражному суду — в иные суды в
//         соответствии с законодательством Российской Федерации.
//     </p>
//     <p>
//         6.2. До обращения в суд пользователь с профилем юридического лица или индивидуального предпринимателя,
//         должен соблюсти внесудебный порядок разрешения споров, направив в адрес Компании письменную претензию, с
//         приложением документов, подтверждающих обстоятельства и требования, на которые ссылается пользователь.
//         Срок рассмотрения таких претензий составляет не более 30 дней с момента получения претензии Компанией.
//     </p>
//     <p className="text-align">7. Прочие условия</p>
//     <p>
//         7.1. Договор вступает в силу с момента его заключения в порядке, указанном в разделе настоящей оферты, и
//         действует в течение неопределенного срока до момента его прекращения в соответствии с Договором и
//         законодательством.
//     </p>
//     <p>
//         7.2. Компания вправе изменять и/или дополнять оферту в той мере, в какой это допускается
//         законодательством, а также отозвать оферту в любое время. Датой изменения оферты является дата
//         опубликования на citywork.ru новой редакции оферты. Вы должны ознакомиться с действующей редакцией
//         оферты, размещенной на citywork.ru, и с условиями каждой сделки до заключения Договора и совершения
//         сделки.
//     </p>
//     <p>
//         7.3. Заказ услуг и совершение пользователем сделки в рамках Договора после вступления в силу изменений в
//         оферте означает, что стороны пришли к согласию о внесении соответствующих изменений и/или дополнений в
//         Договор. Такие изменения в Договоре не применяются к услугам, заказанным пользователем до вступления в
//         силу изменений в оферте.
//     </p>
//     <p>
//         7.4. Компания вправе расторгнуть Договор в порядке, предусмотренном законодательством, в том числе в
//         случае однократного нарушения пользователем законодательства, условий Договора или правил citywork.ru,
//         с уведомлением об этом пользователя через интерфейс citywork.ru, по адресу электронной почты или иным
//         способом.
//     </p>
//     <p>
//         7.7. Компания вправе указывать наименование, товарный знак и общее описание услуг пользователя с
//         профилем юридического лица или индивидуального предпринимателя в своих маркетинговых материалах.
//     </p>
//     <p>
//         7.8. Договор представляет все условия, согласованные между сторонами в отношении его предмета, и
//         заменяет собой все прежние договоренности, заверения и любого рода соглашения между сторонами в
//         отношении его предмета, если иное прямо не согласовано сторонами.
//     </p>
//     <div className="text-align">8. Реквизиты Компании</div>
//     <p className="strong">Общество с ограниченной ответственностью «Миговеб» (ООО «Миговеб»)</p>
//     <p className="strong">Юридический адрес:</p>
//     <p className="strong">ИНН 0411167510 ОГРН 1140411000124 </p>
//     <p className="strong">
//         E-mail: <a href="mailto: migowebmail@yandex.ru">migowebmail@yandex.ru</a>
//     </p>
// </div>