import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {link} from "../../../host-store";
import {token} from "../../../../App";

export const partnerProgramPrices = createApi({
    reducerPath: "partnerProgramPrices",
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["PartnerProgramPrice"],
    endpoints:  (builder) => ({
        putPricesPartner: builder.mutation({
            query:({id, text}) => ({
                url: `/settings/${id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token
                },
                body: JSON.stringify(text)
            }),
            invalidatesTags: ["PartnerProgramPrice"],
        }),
        getPricesPartner: builder.query({
            query:({id}) => ({
                url: `/settings/${id}`, // есть ли неподтвержденный запрос
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["PartnerProgramPrice"],
        }),
    })
})

export const {usePutPricesPartnerMutation, useGetPricesPartnerQuery} = partnerProgramPrices