import BankPlusSvg from "./svg/BankPlusSvg";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import ModalsContext from "../../store/modals-context";

const BankCheckWallet = () => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);

    return (
        <div className="bank__checks__top">
            <div className="bankChecks__wallet__data">
                <div className="wallet__data">
                    <div className="title__data_wallets">Кошелек:</div>
                    <div className="wallet_data__data">{ctx.userData.wallet?.money} ₽</div>
                </div>

                <div className="wallet__data data__points">
                    <div className="title__data_wallets">Баллов:</div>
                    <div className="wallet_data__data">{ctx.userData.wallet?.points}</div>
                </div>
            </div>

            <div className="bankChecks_wallet">
                <button type="button" className="bank_checks_wallet_btn" onClick={modal.paymentModal}>
                    <BankPlusSvg />
                    Пополнить кошелек
                </button>
            </div>
        </div>
    );
};

export default BankCheckWallet;
