export const LogoutIcon = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.85548 16.8146V17.8113C8.85548 18.6106 8.45432 19.3007 7.75725 19.7003C7.42186 19.8925 7.04754 19.9998 6.65945 20C6.271 20.0002 5.89679 19.8927 5.56118 19.7003L1.09823 17.1415C0.401073 16.7418 0 16.052 0 15.2526V2.18117C0 0.978397 0.985297 0 2.19646 0H12.0255C13.2367 0 14.2221 0.978319 14.2221 2.18117V4.93659C14.2221 5.33186 13.8989 5.6528 13.5009 5.6528C13.1027 5.6528 12.7798 5.3319 12.7798 4.93659V2.18117C12.7798 1.76816 12.4414 1.43211 12.0255 1.43211H3.4127L7.75725 3.92339C8.45409 4.323 8.85548 5.01284 8.85548 5.81194V15.3824H12.0255C12.4413 15.3824 12.7798 15.0465 12.7798 14.6334V12.218C12.7798 11.8224 13.1025 11.5018 13.5009 11.5018C13.8991 11.5018 14.2221 11.8225 14.2221 12.218V14.6334C14.2221 15.8363 13.2367 16.8146 12.0255 16.8146H8.85548ZM15.5381 9.12334L14.7438 9.91205C14.4622 10.1917 14.4623 10.6451 14.7438 10.9247C14.8794 11.0594 15.062 11.1343 15.2537 11.1343C15.4456 11.1343 15.6281 11.0596 15.7638 10.9247L17.7888 8.9135C18.0704 8.63389 18.0704 8.18077 17.7888 7.90116L15.7638 5.89022C15.4822 5.61054 15.0256 5.61065 14.7439 5.89018C14.4623 6.16956 14.4624 6.62311 14.7439 6.90256L15.5381 7.69108H10.2174C9.81898 7.69108 9.49634 8.0117 9.49634 8.40733C9.49634 8.80295 9.81902 9.12338 10.2174 9.12338H15.5381V9.12334Z"
                fill="white"
            />
        </svg>
    );
};
