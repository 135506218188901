import style from "../../subscribe/subscribe.module.sass";
import StubAdsTop from "./StubAdsTop";
import StubAdsBottom from "./StubAdsBottom";

const StubAds = () => {
  return(
      <div className='cabinet__redesign_container container container_marketing'>
          <h3 className='cabinet__redesign_title'>3 простых шага, начать рекламу</h3>
          <div className={style.promotion_block}>
              <StubAdsTop />
              <StubAdsBottom />
          </div>
      </div>
  )
}

export default StubAds;