import {useContext, useEffect, useState} from "react";

import avatar from "../../../assets/images/stubs/avatar.svg";
import AuthContext from "../../../store/auth-context";
import {link, url} from "../../../store/host-store";
import s from "./style.module.sass";
import {token} from "../../../App";

const ModeratorCard = () => {
    const [data, setData] = useState([]);
    const ctx = useContext(AuthContext);

    useEffect(() => {
        fetch(`${link}/settings?filter[type_id]=1`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        })
            .then(res => res.json())
            .then(res => {
                setData(res.data)
            })
    }, [])

    return (
        <div className={s.card}>
            <div className={s.card__info}>
                {ctx.userData.avatar === null ? (
                    <img src={avatar} alt="avatar"></img>
                ) : (
                    <img src={url + ctx.userData.avatar} alt="avatar"></img>
                )}
                <div className={s.card__info_user}>
                    <p>{ctx.userData.name}</p>
                    <span className={s.role}>
                       {ctx.userData?.account?.role === 'moderator' ? 'Модератор объявлений': 'Модератор рекламы'}
                        </span>
                </div>
            </div>
            <div className={s.card__balance}>
                <div className={s.card__balance_top}>
                    <p className={s.card__balance_title}>Баланс</p>
                    <p className={s.card__balance_summ}>{ctx.userData.balance} ₽</p>
                </div>
                <div className={s.card__balance_bottom}>
                    <p>
                        {data[0]?.title}: <br></br>
                        <span>{data[0]?.text}.</span>
                    </p>
                    <p>
                        {data[1]?.title}: <br></br>
                        <span>{data[1]?.text}.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ModeratorCard;
