import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import documents from "./svg/documents.svg"

const ModalAccountComplete = () => {
    const ctx = useContext(ModalsContext);

    return(
        <div className="modal modal__card3">
            <div className="closeModal" onClick={ctx.closeModal}>
                <ModalCloseSvg/>
            </div>
            <div className="modal_block">
                <h2 className="modal_title_ads">Ваш запрос
                    отправлен!</h2>
            </div>
            <img src={documents} alt="onCheck" style={{marginTop: "4px", marginBottom: "6px"}}/>
            <div className="modal_advers_text">
                <p><span>В течении 3-ех рабочий дней</span> будет обработан запрос.</p>
                <p>Результат Вы получите на свой <span>e-mail</span>, указанный при регистрации в личном кабинете сервиса CityWORK.</p>
            </div>
            <button onClick={ctx.closeModal}
                    type="button" className="modal__btn">Закрыть</button>
        </div>
    )
}

export default ModalAccountComplete;