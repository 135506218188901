import dots1 from "../images/payment/dots1.svg";
import dots2 from "../images/payment/dots2.svg";
import dots3 from "../images/payment/dots3.svg";
import img1 from "../images/payment/1.svg";
import img2 from "../images/payment/2.svg";
import img3 from "../images/payment/3.svg";

export const mockData = [
    {
        id: '01',
        dots: dots1,
        img: img1,
        title: '01. Скопируйте ссылку или реферальный код',
        description: 'Скопировать ссылку или реферальный код. Разместить в социальной сети, на сайте и в блоге или прислать личным сообщением своему другу.'
    },
    {
        id: '02',
        dots: dots2,
        img: img2,
        title: '02. Регистрируются привлеченные пользователи',
        description: 'Подождать, пока пользователь присоединится к нашей команде. Он должен пройти регистрацию по ссылке или используя реферальный код.'
    },
    {
        id: '03',
        dots: dots3,
        img: img3,
        title: '03. Зарабатывайте на своей базе пользователей',
        description: 'Получать пассивный доход. С ростом приглашенных людей вы будете регулярно получать пассивный доход в виде вознаграждения 10% на счет.'
    },
]