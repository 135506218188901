import FilterStatus from "../../cabinet/advertisement/FilterStatus";
import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import CheckboxAll from "./CheckboxAll";
import AdversItems from "./AdversItems";
import {link} from "../../store/host-store";
import {token} from "../../App";
import {checkboxAction} from "../../store/redux";
import Loader from "../../components/loader/Loader";
import AuthContext from "../../store/auth-context";
import ModalsContext from "../../store/modals-context";
import PaginationPattern from "../../UI/PaginationPattern";

const AdsMainPage = () => {
    const [arr, setArr] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const getCheckboxesState = useSelector((state) => state.checkboxes);
    const status = useSelector(state => state.status.status);
    const navigate = useNavigate();
    const modals = useContext(ModalsContext);
    const auth = useContext(AuthContext);

    const extendHandler = () => {
        localStorage.setItem('ads', "item=" + getCheckboxesState.checkedArr.map(item => item));
        navigate(`/advertiser/launch/ads_items?item=`)
    }

    const deleteHandler = () => {
        console.log(getCheckboxesState.checkedArr)
        if(getCheckboxesState.checkedArr.length === 0){
            return alert('Выберите рекламу для удаления')
        }
        getCheckboxesState.checkedArr.map(item => {
            fetch(`${link}/advertisement/${item}`, {
                method: 'DELETE',
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                }
            }).then(res => res.json())
                .then(res => {
                    console.log(res.data);
                    setArr(prev => prev?.filter(el => el?.id !== item))
                })
        })
    }

    //Модерация = 0,2
    //Активные = 1
    //Остановленные = 3
    let statusModeration = "?filter[status]=1";

    if (+status === 1) {
        statusModeration = "?filter[status]=1";
    }
    if (+status === 2) {
        statusModeration = "?filter[status][in][]=0&filter[status][in][]=2";
    }
    if (+status === 3) {
        statusModeration = "?filter[status]=3";
    }


    useEffect(() => {
        setLoading(true);
        fetch(`${link}/advertisement/user-advertisements${statusModeration}&pagination[pageSize]=10&page=${page}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        }).then(res => res.json().then((data) => ({ data, headers: res.headers })))
            .then(result => {
                console.log(result.data.data)
                setArr(result.data.data)
                setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                dispatch(checkboxAction.resetStates(result.data.data.length))
                setLoading(false)
            })
    }, [statusModeration, page])

    const extendItem = (id) => {
        localStorage.setItem('ads', "item=" + id);
       navigate(`/advertiser/launch/ads_items?item=`)
    }

    const editItem = (id) => navigate(`/advertiser/ad/edit/${id}`)

    const deleteItem = (id) => {
        fetch(`${link}/advertisement/${id}?pagination=0`, {
            method: 'DELETE',
            headers: {
                Accept: "application/json",
                Authorization: token,
            }
        }).then(res => res.json())
            .then(res => {
                console.log(res.data);
                setArr(prev => prev.filter(el => el.id !== id))
            })
    }



  return(
      <>
          <h2 className="h2_cabinet_main">Реклама</h2>

          {auth.userData?.organization?.status === 1 && window.innerWidth < 769 &&
              <div className="cabinet_for_mobile_btn">
                  {(auth.userData.organization?.confirmed === 0 && auth.userData?.organization.status === 1)
                      ? <div className="btn_save btn_save_custom" onClick={modals.advertiserModerationConfirmed}>
                          Разместить рекламу
                      </div>
                      : (auth.userData.organization?.confirmed === 2 && auth.userData?.organization.status === 1)
                          ? <div className="btn_save btn_save_custom" onClick={modals.advertiserOnCheckingPayment}>
                              Разместить рекламу
                          </div>
                          :
                          <NavLink end to='/advertiser/ad/create' className="btn_save btn_save_custom">
                              Разместить рекламу
                          </NavLink>
                  }
              </div>
          }

          <div className="cabinet_ads__left">
              <div className="wrap_adv_top">
                  <div className="advers___title">Статус рекламных компаний:</div>
                  <div className="advers__flex_top">
                    <FilterStatus className='advers_top_flex_left_custom'/>
                      {auth.userData?.organization?.status === 1 && window.innerWidth > 769 &&
                          <div className="advers_top_flex_right">
                              {(auth.userData.organization?.confirmed === 0 && auth.userData?.organization.status === 1)
                                  ? <div className="btn_save btn_save_custom" onClick={modals.advertiserModerationConfirmed}>
                                      Разместить рекламу
                                  </div>
                                  : (auth.userData.organization?.confirmed === 2 && auth.userData?.organization.status === 1)
                                  ? <div className="btn_save btn_save_custom" onClick={modals.advertiserOnCheckingPayment}>
                                          Разместить рекламу
                                      </div>
                                      :
                                  <NavLink end to='/advertiser/ad/create' className="btn_save btn_save_custom">
                                    Разместить рекламу
                                  </NavLink>
                              }
                          </div>
                      }
                  </div>

                  <div className="advers__flex__bottom">
                      {+status !== 2 && <CheckboxAll arr={arr} extendHandler={extendHandler} deleteHandler={deleteHandler}/>}
                      {/* <div className="flex__advers__sorter">
                                Сортировка
                                <ArrowSvg />
                       </div> */}
                  </div>
              </div>

              {loading ? <Loader/> :
                  <AdversItems
                      arr={arr}
                      extendItem={extendItem}
                      editItem={editItem}
                      deleteItem={deleteItem}
                  />
              }

              <div style={{marginTop: '-40px', marginRight: '10px', marginBottom: '20px'}}>
                  <PaginationPattern
                      setChange={(e, value) => setPage(value)}
                      pageCount={pageCount}
                      page={page}
                  />
              </div>
          </div>
      </>
  )
}

export default AdsMainPage;