const PromoteMobileSvg = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
            <mask id="a" width="14" height="14" x="0" y="0" maskUnits="userSpaceOnUse" style={{maskType:'luminance'}}>
                <path fill="#fff" d="M.5.5H14V14H.5V.5Z"/></mask><g stroke="#546EDB" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" mask="url(#a)">
            <path d="M6.866 10.617 3.883 7.634a1.055 1.055 0 0 1 0-1.492l3.608-3.608a4.627 4.627 0 0 1 5.341-.866 4.627 4.627 0 0 1-.866 5.341l-3.608 3.608a1.055 1.055 0 0 1-1.492 0ZM5.374 12.109c-1.657 1.658-4.32 1.338-4.32 1.338S.73 10.787 2.39 9.126M13.473 1.027l-.64.64"/><path d="M9.85 6.142a1.055 1.055 0 1 1-1.492-1.491 1.055 1.055 0 0 1 1.491 1.491ZM1.027 6.01l1.364-1.36a2.11 2.11 0 0 1 2.984 0M8.49 13.473l1.36-1.365a2.11 2.11 0 0 0 0-2.983"/></g>
        </svg>
    )
}

export default PromoteMobileSvg;