import { useEffect, useState } from "react";
import style from "./style.module.sass";
import moment from "moment";
import getHost from "../../store/host-store";
import {cityAlias, token} from "../../App";
import { NavLink } from "react-router-dom";

export const SubscribeCard = ({ el, onClick }) => {
    const [name, setname] = useState("");
    const [alias, setAlias] = useState("");

    useEffect(() => {
        fetch(
            getHost({
                controller: "object",
                action: el.model_id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setAlias(result.data.alias)
                    setname(result.data.name);
                }
            })
            .catch((error) => console.log(error));
    }, [el]);

    return (
        <NavLink
            to={`/${cityAlias}/${alias}`}
            key={el.id}
            className={style.item}
            style={{ cursor: "pointer", color: "inherit" }}
                onClick={onClick}
            >
            <div className={style.title}>
                <h5>Подписка</h5>
                <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
            </div>
            <p className={style.text}>
                По Вашей подписке появилось новое объявление - <span style={{ fontWeight: 600 }}>{name}</span>
            </p>
        </NavLink>
    );
};
