import { useContext } from "react";

import CustomScrollbar from "../../UI/Scrollbar/CustomScrollbar";
import AuthContext from "../../store/auth-context";
import NewResponseCart from "./NewResponseCart";
import { link } from "../../store/host-store";
import RequestTabs from "./RequestTabs";
import { token } from "../../App";
import ModalsContext from "../../store/modals-context";
import OfferConfirmRequest from "./ConfirmedTypes/OfferConfirmRequest";
import SeekConfirmRequest from "./ConfirmedTypes/SeekConfirmRequest";
import OfferRequest from "./RequestsTypes/OfferRequest";
import SeekRequest from "./RequestsTypes/SeekRequest";
import { useSelector } from "react-redux";

const RequestsList = (props) => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);

    let heightScroll = props.mobileVisibleCart ? 570 : 510;

    const newRequests = props.requestCart.filter((item) => item.payment === false);
    const confirmRequests = props.requestCart.filter((item) => item.payment !== false);
    const isSeek = useSelector((state) => state.adsSearchLogic.statusAds);

    const dropRequest = (id) => {
        fetch(`${link}/order/reject-by-user?id=${id}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    ctx.setRerender((prev) => !prev);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => error);
    };

    return (
        <div className="wrap">
            <RequestTabs
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
                counterNewResponse={modal.counterNewResponse}
                setCounterNewResponse={modal.setCounterNewResponse}
                responseCount={confirmRequests.length}
                requestCount={newRequests.length}
            />
            <div className="request_cart__scroll">
                <CustomScrollbar style={{ height: heightScroll }}>
                    {props.activeTab === "newRequests"
                        ? //новые заявки
                          newRequests.map((item) =>
                              //вид карточки при оплате заявки
                              modal.newResponses.find((el) => el === item.id) ? (
                                  <NewResponseCart key={item.id} item={item} />
                              ) : //проверка на тип я ищу-я предлагаю
                              !isSeek ? (
                                  //я ищу
                                  <SeekRequest key={item.id} dropRequest={dropRequest} item={item} />
                              ) : (
                                  // я предлагаю
                                  <OfferRequest key={item.id} dropRequest={dropRequest} item={item} />
                              )
                          )
                        : //вы откликнулись
                          confirmRequests.map((item) => {
                              // также проверка я ищу или предлагаю
                              return !isSeek ? (
                                  // я ищу
                                  <SeekConfirmRequest key={item.id} item={item} />
                              ) : (
                                  //  я предлагаю
                                  <OfferConfirmRequest key={item.id} item={item} />
                              );
                          })}
                </CustomScrollbar>
            </div>
        </div>
    );
};

export default RequestsList;
