import "./Docs.sass";

const PersonalData = () => {
    return (
    <div className="rules">
        <h5>Правила политики обработки персональных данных</h5>
        <p>
            Правила обработки персональных данных (далее по тексту - ПДн) Общества с ограниченной ответственностью
            «Миговеб» (сокращенное наименование - ООО «Миговеб» (ИНН 0411167510, ОГРН 1140411000124) в отношении
            обработки ПДн разработаны во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона «О
            персональных данных» от 27.07.2006г. № 152-ФЗ (далее – Закон о ПДн) в целях обеспечения защиты прав и
            свобод человека и гражданина при обработке его персональных данных в соответствии с положениями
            законодательства Российской Федерации и Правилами использования Сервиса «CityWork», размещенными на сайте
            по электронному адресу: citywork.ru.
        </p>
        <p>
            Правила ПДн действуют в отношении всех персональных данных, которые обрабатывает ООО «Миговеб» (далее –
            Оператор, Компания, ООО «Миговеб»).
        </p>
        <p>
            Правила ПДН распространяется на отношения в области обработки ПДн, возникших у Оператора как до, так и
            после утверждения настоящих Правил ПДн.
        </p>
        <p>
            Во исполнения требований ч. 2 ст. 18.1 Закона ПДн настоящие Правила публикуются в свободном доступе в
            информационно-телекоммуникационной сети Интернет на сайте Оператора.
        </p>
        <p>
            Правила ПДн являются приложением к Правилам использования Сервиса и их неотъемлемой частью. Принимая
            условия Правил использования Сервиса, Пользователь автоматически принимает условия настоящих Правил ПДн.
        </p>
        <p>Термины и определения.</p>
        <p>***</p>
        <p>
            <b>Стороны</b> – Администрация, Оператор, Компания, ООО «Миговеб» и любой зарегистрированный
            Пользователь, а также посетитель Сайта.
        </p>
        <p>
            <b>Персональные данные (ПДн) </b>— любая информация, относящаяся прямо или косвенно к определенному или
            определяемому физическому лицу (субъекту персональных данных) и/или данные индивидуального
            предпринимателя и/или юридического лица.
        </p>
        <p>
            <b>Обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без использования таких средств с персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ), удаление, уничтожение
            персональных данных.
        </p>
        <p>
            <b>Автоматизированная обработка персональных данных</b> – обработка персональных данных с помощью
            средств вычислительной техники.
        </p>
        <p>
            <b>Распространение персональных данных</b> — действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц.
        </p>
        <p>
            <b>Предоставление персональных данных</b> — действия, направленные на раскрытие персональных данных
            определенному лицу или определенному кругу лиц.
        </p>
        <p>
            <b>Блокирование персональных данных</b> — временное прекращение обработки персональных данных (за
            исключением случаев, если обработка необходима для уточнения персональных данных);
        </p>
        <p>
            <b>Уничтожение персональных данных</b> — действия, в результате которых становится невозможным
            восстановить содержание персональных данных в информационной системе персональных данных и (или) в
            результате которых уничтожаются материальные носители персональных данных;
        </p>
        <p>
            <b>Обезличивание персональных данных</b> — действия, в результате которых становится невозможным без
            использования дополнительной информации определить принадлежность персональных данных конкретному
            субъекту персональных данных.
        </p>
        <p>
            <b>Информационная система персональных данных</b> — совокупность содержащихся в базах данных
            персональных данных и обеспечивающих их обработку информационных технологий и технических средств.
        </p>
        <p>
            <b>Информация</b> — сведения (сообщения, данные) независимо от формы их представления.
        </p>
        <p>
            <b>Документированная информация</b> — зафиксированная на материальном носителе путем документирования
            информация с реквизитами, позволяющими определить такую информацию или ее материальный носитель.
        </p>
        <p>
            <b>Конфиденциальность персональных данных</b> - обязательное для соблюдения Администрацией или иным
            получившим доступ к персональным данным лицом требование не допускать их распространения без согласия
            субъекта персональных данных или наличия иного законного основания.
        </p>
        <h5>1. Общие положения.</h5>
        <p>
            1.1. Правила ПДн устанавливают обязательства Администрации по обеспечению режима защиты
            конфиденциальности персональных данных, которые Пользователь предоставляет.
        </p>
        <p>
            1.2. Использование Сервиса Пользователем означает согласие с Правилами ПДн и условиями обработки
            Администрацией персональных данных Пользователя.
        </p>
        <p>В случае несогласия с условиями Правил ПДн Пользователь обязуется прекратить использование Сервиса.</p>
        <p>
            1.3. Персональные данные, разрешенные к обработке в рамках настоящих Правил ПДн, предоставляются
            Пользователем (Стороной) добровольно путем заполнения различных форм при регистрации в Сервисе «CityWork»
            и использовании Сервиса и включают в себя следующую информацию: фамилия, имя, отчество, дату, месяц и
            год рождения, данные документа, удостоверяющего личность (паспорта), номер ИНН, фото и видео своего
            личного изображения, номер телефона, адрес регистрации, другую аналогичную информацию, электронную
            почту, сообщённую о себе Пользователем, и на основании которой возможна идентификация субъекта
            персональных данных (в том числе характеристики оборудования, транспорта и т.д.).
        </p>
        <p>
            1.4. Администрация вправе осуществлять с полученными персональными данными Пользователей все законные
            необходимые действия, связанные исключительно с достижением целей, обозначенных в разделе 2 Правил.
        </p>
        <p>
            1.5. Любая иная персональная и конфиденциальная информация, неоговоренная выше подлежит надежному
            хранению и нераспространению Администрацией и Пользователем.
        </p>
        <h5>2. Основные принципы и цели сбора персональной информации.</h5>
        <p>
            2.1. Администрация обрабатывает персональные данные, которые необходимы для предоставления Сервиса
            Пользователю.
        </p>
        <p>2.2. Настоящим, Пользователь поручает Администрации и соглашается с тем, что Администрация:</p>
        <ul>
            <li>
                - обрабатывает персональные данные Пользователя в целях предоставления доступа к Сервису и его
                функционалу, проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать
                действующий функционал Сервиса, разрабатывать новый функционал, а также в иных, предусмотренных
                настоящими Правилами ПДн, целях;
            </li>
            <li>
                - принимает все необходимые меры для защиты персональных данных Пользователя от неправомерного
                доступа, изменения, раскрытия или уничтожения;
            </li>
            <li>
                - предоставляет доступ к персональным данным Пользователя только тем работникам, подрядчикам и
                агентам Администрации, которым эта информация необходима для обеспечения функционирования Сервиса и
                предоставления Пользователям доступа (услуг) к его использованию;
            </li>
            <li>
                - будет и вправе использовать предоставленную Пользователем информацию, в том числе персональные
                данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации
                (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий
                Пользователей).
            </li>
        </ul>
        <p>2.3. Принципы обработки персональных данных Пользователей:</p>
        <ul>
            <li>
                - обработка персональных данных должна осуществляться исключительно на законных основаниях и в
                интересах Пользователей;
            </li>
            <li>- обработка персональных данных должна ограничиваться достижением конкретных законных целей;</li>
            <li>
                - при обработке персональных данных обеспечивается точность, достаточность, а в необходимых случаях
                актуальность персональных данных;
            </li>
            <li>
                - хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта
                персональных данных, не дольше, чем этого требуют цели обработки персональных данных.
            </li>
        </ul>
        <p>2.4. Персональные данные Пользователя Администрация обрабатывает в целях:</p>
        <ul>
            <li>
                - исполнения соглашений с Пользователем по предоставлению доступа к функционалу Сервиса, для
                администрирования Сервиса;
            </li>
            <li>
                - идентификации Пользователя при регистрации в Сервисе и аутентификации Пользователя при
                использовании Сервиса;
            </li>
            <li>- оказания услуг, обработки запросов и заявок от Пользователя;</li>
            <li>- установления с Пользователем обратной связи, включая направление уведомлений и запросов;</li>
            <li>- подтверждения полноты персональных данных, предоставленных Пользователем;</li>
            <li>- заключения с Пользователем договоров, осуществления взаиморасчетов;</li>
            <li>- сбора статистики;</li>
            <li>- улучшения качества Сервиса, удобства его использования и разработки новых сервисов и услуг;</li>
            <li>
                - проведения маркетинговых (рекламных) мероприятий, направления предложений, продвижения на рынке
                услуг.
            </li>
            <li>- предоставление услуг по размещению информации и связанная с этим деятельность;</li>
            <li>- ведение кадрового и бухгалтерского учета;</li>
        </ul>
        <p>
            2.5. Пользователь осведомлен и согласен, что для целей, предусмотренных в Правилах ПДн, Администрация
            вправе:
        </p>
        <ul>
            <li>
                - собирать и использовать дополнительную информацию, связанную с Пользователем, получаемую в
                процессе доступа Пользователя к Сервису или от третьих лиц, и включающую в себя данные о технических
                средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сервисом
                (в т. ч. IP-адрес хоста, вид операционной системы Пользователя, тип браузера, географическое
                положение, данные о провайдере и иное), об активности Пользователя на Сервисе, Cookie, об информации
                об ошибках, выдаваемых Пользователям, о скачанных файлах, видео, инструментах, а также иные данные,
                получаемые установленными Правилами ПДн способами;
            </li>
            <li>
                - распоряжаться статистической информацией, связанной с функционированием Сервиса, а также
                информацией Пользователей для целей организации функционирования и технической поддержки Сервиса и
                исполнения условий настоящих Правил.
            </li>
        </ul>
        <h5>3. Условия обработки персональной информации.</h5>
        <p>
            3.1. Обработка персональных данных Пользователя осуществляется в сроки, определенные п.3.5 Правил ПДн,
            любым законным способом, в том числе в информационных системах персональных данных, с использованием
            средств автоматизации (в виде электронных образов документов), за исключением случаев, когда
            неавтоматизированная обработка персональных данных необходима в связи с исполнением требований
            законодательства. Обработка персональных данных Пользователей осуществляется в соответствии с
            Конституцией Российской Федерацией, Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»,
            настоящими Правилами ПДн.
        </p>
        <p>
            3.2. Источником информации обо всех персональных данных Пользователя является непосредственно сам
            Пользователь Сервиса. Заполняя любую форму и/или прикрепляя файл при регистрации в Приложении и при
            использовании Сервиса, Пользователь тем самым дает согласие на обработку его персональных данных для
            целей, указанных в разделе 2 Правил ПДн. Пользователь подтверждает права и обязательства в отношении
            созданной таким образом своей учетной записи.
        </p>
        <p>
            3.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
            добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу
            лиц. При использовании Сервиса Пользователь соглашается с тем, что определенная часть его персональной
            информации в результате действий Пользователя становится общедоступной для других Пользователей Сервиса
            и пользователей сети Интернет, может быть скопирована и/или распространена такими Пользователями, с
            учетом доступных настроек конфиденциальности.
        </p>
        <p>
            Общедоступной, является, в частности, следующая персональная информация Пользователей: фамилия, имя,
            отчество, номер телефона, адрес электронной почты.
        </p>
        <p>
            3.4. Администрация принимает необходимые организационные и технические меры для защиты персональной
            информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
            копирования, распространения, а также от иных неправомерных действий третьих лиц.
        </p>
        <p>
            3.5. Администрация хранит информацию о Пользователе в течение периода предоставления последнему доступа
            к Сервису, а также 3 (трех) месяцев с момента прекращения такого доступа по любым основаниям. В случае
            получения Администрацией заявления от Пользователя об отзыве согласия на обработку ПДн, Администрация
            прекращает обработку ПДн Пользователя с даты, указанной в заявлении, но не ранее даты, следующей за
            датой фактического получения Администрацией отзыва.
        </p>
        <p>
            3.6. Администрация осуществляет хранение персональных данных Пользователя и его сотрудников. При этом,
            Пользователь гарантирует, что получил согласие каждого своего сотрудника на передачу его персональных
            данных Администрации.
        </p>
        <p>
            Администрация, являясь Оператором обработки персональных данных по поручению Пользователя, не обязана
            получать согласие сотрудников Пользователя на обработку их персональных данных. Безоговорочно принимая
            условия настоящих Правил, Пользователь подтверждает, что он заблаговременно получил согласие своих
            сотрудников на передачу Администрации их персональных данных.
        </p>
        <p>
            Ответственность перед сотрудниками Пользователя, чьи персональные данные обрабатываются Администрацией
            по поручению Пользователя, Пользователь несет самостоятельно.
        </p>
        <p>
            Администрация обрабатывает персональные данные сотрудников Пользователя полностью в соответствии с
            положениями, предусмотренными настоящими Правилами.
        </p>
        <p>
            3.7. Администрация осуществляет обработку персональных данных следующими способами: сбор, запись,
            систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
            передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
            персональных данных Пользователя.
        </p>
        <p>
            Хранение Персональных данных Пользователей осуществляется на электронных носителях и обрабатываются с
            использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка
            персональных данных необходима в связи с исполнением требований законодательства.
        </p>
        <p>
            3.8. Если Пользователь не согласен с обработкой Администрацией его персональных, Пользователь не должен
            публиковать эту информацию или сообщать эти данные при регистрации в Сервисе и использование этого
            Сервиса. Как только Пользователь предоставит свои персональные данные при регистрации в Сервисе и
            использование Сервиса, эти данные будут доступны Администрации, другим пользователям Сервиса.
        </p>
        <h5>4. Обязательства сторон.</h5>
        <p>4.1. Пользователь обязан:</p>
        <ul>
            <li>
                - Предоставить корректную информацию о персональных данных, необходимую для целей, указанных в
                разделе 2 Правил ПДн.
            </li>
            <li>
                - Обновилять, дополниять предоставленную информацию о персональных данных в случае изменения данной
                информации.
            </li>
        </ul>
        <p>4.2. Администрация обязана:</p>
        <ul>
            <li>- организовывать обработку персональных данных в соответствии с требованиями Закона о ПДн;</li>
            <li>
                - отвечать на обращения и запросы субъектов персональных данных и их законных представителей в
                соответствии с требованиями Закона о ПДн;
            </li>
            <li>
                {" "}
                - сообщать в уполномоченный орган по защите прав субъектов персональных данных (Федеральную службу
                по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)) по
                запросу этого органа необходимую информацию в течение 10 рабочих дней с даты получения такого
                запроса. Данный срок может быть продлен, но не более чем на пять рабочих дней. Для этого Оператору
                необходимо направить в Роскомнадзор мотивированное уведомление с указанием причин продления срока
                предоставления запрашиваемой информации;
            </li>
            <li>
                {" "}
                - в порядке, определенном федеральным органом исполнительной власти, уполномоченным в области
                обеспечения безопасности, обеспечивать взаимодействие с государственной системой обнаружения,
                предупреждения и ликвидации последствий компьютерных атак на информационные ресурсы РФ, включая
                информирование его о компьютерных инцидентах, которые повлекли неправомерную передачу
                (предоставление, распространение, доступ) персональных данных;
            </li>
            <li>-использовать полученную информацию исключительно для целей, указанных в разделе 2 Правил ПДн.</li>
            <li>-обеспечить хранение конфиденциальной информации в тайне.</li>
            <li>
                -принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
                согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом
                обороте.
            </li>
            <li>
                -осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с
                момента обращения или запроса Пользователя, или его законного представителя, либо уполномоченного
                органа по защите прав субъектов персональных данных на период проверки, в случае выявления
                недостоверных персональных данных или неправомерных действий.
            </li>
        </ul>
        <p>
            4.3. Администрация при обработке персональных данных обязана принимать необходимые правовые,
            организационные и технические меры для защиты персональных данных от несанкционированного,
            неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
            предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении
            персональных данных, путем:
        </p>
        <ul>
            <li>- разработки и внедрения в организации документов, регламентирующих работу с ПДн;</li>
            <li>- ограничения и регламентации состава работников, имеющих доступ к персональным данным; </li>
            <li>
                - реализации разрешительной системы доступа Пользователей к информационным ресурсам,
                программно-аппаратным средствам обработки и защиты информации;
            </li>
            <li>
                - осуществления антивирусного контроля, предотвращения внедрения в корпоративную сеть вредоносных
                программ (программ-вирусов) и программных закладок;
            </li>
            <li>- при необходимости, резервного копирование информации.</li>
        </ul>
        <p>
            4.4. При определении объема и содержания обрабатываемых персональных данных Администрация
            руководствуется Конституцией Российской Федерации, Федеральным закон от 27.07.2006 № 152-ФЗ «О
            персональных данных».
        </p>
        <p>
            Федеральный закон от 08.02.1998 № 14-ФЗ «Об обществах с ограниченной ответственностью», Федеральный
            закон от 06.12.2011 № 402-ФЗ «О бухгалтерском учете»; Федеральный закон от 15.12.2001№ 167-ФЗ «Об
            обязательном пенсионном страховании в Российской Федерации», Гражданским кодексом РФ, Налоговым кодексом
            РФ, Трудовым кодексом РФ, настоящими Правилами ПДн и иные нормативные правовые акты, регулирующие
            отношения, связанные с деятельностью Оператора.
        </p>
        <p>4.4.1. Правовым основанием обработки персональных данных также являются:</p>
        <ul>
            <li>- устав ООО «Миговеб»;</li>
            <li>- договоры, заключаемые между Оператором и субъектами персональных данных;</li>
            <li>- согласие субъектов персональных данных на обработку их персональных данных.</li>
        </ul>
        <p>
            4.5. Администрация обязуется обеспечить недопущение несанкционированного и нецелевого доступа к
            персональным данным Пользователей Сервиса.
        </p>
        <p>
            При этом санкционированным и целевым доступом к персональным данным Пользователей Сервиса будет
            считаться доступ уполномоченных Администрацией лиц в рамках целей деятельности и тематики Сервиса, а
            также самих пользователей, зарегистрированных в установленном порядке.
        </p>
        <h5>5. Ответственность сторон и разрешение споров.</h5>
        <p>
            5.1. Администрация, не исполнившая свои обязательства, несёт ответственность перед Пользователем за
            прямой фактический ущерб в связи с неправомерным использованием персональных данных в соответствии с
            законодательством Российской Федерации.
        </p>
        <p>
            5.2. В случае утраты или разглашения персональных данных Администрация не несёт ответственность, если
            данная информация:
        </p>
        <ul>
            <li>- стала публичным достоянием до её утраты или разглашения;</li>
            <li>- была получена от третьей стороны до момента её получения Администрацией;</li>
            <li>- была разглашена с согласия Пользователя.</li>
        </ul>
        <p>
            5.3. К Правилам ПДн и отношениям между Пользователем и Администрацией применяется действующее
            законодательство Российской Федерации.
        </p>
        <p>
            В случае возникновения любых споров или разногласий, связанных с исполнением Правил ПДн, Пользователь и
            Администрация приложат все усилия для их разрешения путем проведения переговоров между ними. В случае,
            если споры не будут разрешены путем переговоров, споры подлежат разрешению в порядке, установленном
            действующим законодательством Российской Федерации, по месту нахождения Администрации Сайта.
        </p>
        <p>
            5.4. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и
            Администрацией, обязательным является предъявление претензии.
        </p>
        <p>
            Получатель претензии в течение 10 (десяти) дней со дня получения претензии, письменно уведомляет
            заявителя претензии о результатах ее рассмотрения.
        </p>
        <p>
            При недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
            действующим законодательством Российской Федерации.
        </p>
        <h5>6. Заключительные положения.</h5>
        <p>
            6.1. Настоящие Правила ПДн действуют в течение неопределенного срока, а в части согласия Пользователя на
            обработку ПДн - до момента его отзыва Пользователем путем направления соответствующего уведомления на
            электронный адрес Администрации, а также путем письменного обращения по юридическому адресу
            Администрации. Выбранный способ обращения должен гарантировать возможность Администрации достоверно
            идентифицировать обратившееся лицо.
        </p>
        <p>6.2. Правила ПДн являются открытым и общедоступным документом, располагаются на сайте citywork.ru</p>
        <p>
            6.3. Пользователь может обратиться к Администрации с требованием об уточнении, изменении, блокировании,
            отзыве и т.д. своих персональных данных по адресу электронной почты
            <a href="mailto: info@migoweb.ru">info@migoweb.ru</a>.
        </p>
        <p>Общество с ограниченной ответственностью «Миговеб» (ООО «Миговеб»)</p>
        <p>119296, г. Москва, муниципальный округ Гагаринский, ул. Молодёжная, д. 3, пом. XVII, офис 6.</p>
        <p>ИНН 0411167510 ОГРН 1140411000124</p>
        <p>Тел.: + 74951287300</p>
        <p>
            E-mail:<a href="mailto: info@migoweb.ru">info@migoweb.ru</a>
        </p>
    </div>
);
};

export default PersonalData;

// <div className="rules">
//     <h5>Правила обработки персональных данных</h5>
//     <p>
//         Правила обработки персональных данных (далее по тексту - ПДн) Общества с ограниченной ответственностью
//         «Миговеб» (сокращенное наименование - ООО «Миговеб» (ИНН 0411167510, ОГРН 1140411000124) разработаны в
//         соответствии с положениями законодательства Российской Федерации и Правилами использования Сервиса
//         «CityWork», размещенными в Приложении по электронному адресу: citywork.ru.
//     </p>
//     <p>
//         Правила ПДн являются приложением к Правилам использования Сервиса и их неотъемлемой частью. Принимая
//         условия Правил использования Сервиса, Пользователь автоматически принимает условия настоящих Правил ПДн.
//     </p>
//     <p>Термины и определения.</p>
//     <p>
//         <b>Стороны</b> – Администрация и любой зарегистрированный Пользователь, а также посетитель Приложения.
//     </p>
//     <p>
//         <b>Персональные данные (ПДн) </b>— любая информация, относящаяся прямо или косвенно к определенному или
//         определяемому физическому лицу (субъекту персональных данных) и/или данные индивидуального
//         предпринимателя и/или юридического лица.
//     </p>
//     <p>
//         <b>Обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций),
//         совершаемых с использованием средств автоматизации или без использования таких средств с персональными
//         данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
//         извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
//         блокирование, удаление, уничтожение персональных данных.
//     </p>
//     <p>
//         <b>Распространение персональных данных</b> — действия, направленные на раскрытие персональных данных
//         неопределенному кругу лиц.
//     </p>
//     <p>
//         <b>Предоставление персональных данных</b> — действия, направленные на раскрытие персональных данных
//         определенному лицу или определенному кругу лиц.
//     </p>
//     <p>
//         <b>Блокирование персональных данных</b> — временное прекращение обработки персональных данных (за
//         исключением случаев, если обработка необходима для уточнения персональных данных);
//     </p>
//     <p>
//         <b>Уничтожение персональных данных</b> — действия, в результате которых становится невозможным
//         восстановить содержание персональных данных в информационной системе персональных данных и (или) в
//         результате которых уничтожаются материальные носители персональных данных;
//     </p>
//     <p>
//         <b>Обезличивание персональных данных</b> — действия, в результате которых становится невозможным без
//         использования дополнительной информации определить принадлежность персональных данных конкретному
//         субъекту персональных данных.
//     </p>
//     <p>
//         <b>Информация</b> — сведения (сообщения, данные) независимо от формы их представления.
//     </p>
//     <p>
//         <b>Документированная информация</b> — зафиксированная на материальном носителе путем документирования
//         информация с реквизитами, позволяющими определить такую информацию или ее материальный носитель.
//     </p>
//     <p>
//         <b>Конфиденциальность персональных данных</b> - обязательное для соблюдения Администрацией или иным
//         получившим доступ к персональным данным лицом требование не допускать их распространения без согласия
//         субъекта персональных данных или наличия иного законного основания.
//     </p>
//     <h5>1. Общие положения.</h5>
//     <p>
//         1.1. Правила ПДн устанавливают обязательства Администрации по обеспечению режима защиты
//         конфиденциальности персональных данных, которые Пользователь предоставляет.
//     </p>
//     <p>
//         1.2. Использование Сервиса Пользователем означает согласие с Правилами ПДн и условиями обработки
//         Администрацией персональных данных Пользователя.
//     </p>
//     <p>В случае несогласия с условиями Правил ПДн Пользователь обязуется прекратить использование Сервиса.</p>
//     <p>
//         1.3. Персональные данные, разрешенные к обработке в рамках настоящих Правил ПДн, предоставляются
//         Пользователем (Стороной) добровольно путем заполнения различных форм при регистрации в Сервисе
//         «CityWork» и использовании Сервиса и включают в себя следующую информацию: фамилия, имя, отчество, дату,
//         месяц и год рождения, данные документа, удостоверяющего личность (паспорта), номер ИНН, фото и видео
//         своего личного изображения, номер телефона, адрес регистрации, другую аналогичную информацию,
//         электронную почту, сообщённую о себе Пользователем, и на основании которой возможна идентификация
//         субъекта персональных данных (в том числе характеристики оборудования, транспорта и т.д.).
//     </p>
//     <p>
//         1.4. Администрация вправе осуществлять с полученными персональными данными Пользователей все законные
//         необходимые действия, связанные исключительно с достижением целей, обозначенных в разделе 2 Правил.
//     </p>
//     <p>
//         1.5. Любая иная персональная и конфиденциальная информация, неоговоренная выше подлежит надежному
//         хранению и нераспространению Администрацией и Пользователем.
//     </p>
//     <h5>2. Основные принципы и цели сбора персональной информации.</h5>
//     <p>
//         2.1. Администрация обрабатывает персональные данные, которые необходимы для предоставления Сервиса
//         Пользователю.
//     </p>
//     <p>2.2. Настоящим, Пользователь поручает Администрации и соглашается с тем, что Администрация:</p>
//     <ul>
//         <li>
//             - обрабатывает персональные данные Пользователя в целях предоставления доступа к Сервису и его
//             функционалу, проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать
//             действующий функционал Сервиса, разрабатывать новый функционал, а также в иных, предусмотренных
//             настоящими Правилами ПДн, целях;
//         </li>
//         <li>
//             - принимает все необходимые меры для защиты персональных данных Пользователя от неправомерного
//             доступа, изменения, раскрытия или уничтожения;
//         </li>
//         <li>
//             - предоставляет доступ к персональным данным Пользователя только тем работникам, подрядчикам и
//             агентам Администрации, которым эта информация необходима для обеспечения функционирования Сервиса и
//             предоставления Пользователям доступа (услуг) к его использованию;
//         </li>
//         <li>
//             - будет и вправе использовать предоставленную Пользователем информацию, в том числе персональные
//             данные, в целях обеспечения соблюдения требований действующего законодательства Российской Федерации
//             (в том числе в целях предупреждения и/или пресечения незаконных и/или противоправных действий
//             Пользователей).
//         </li>
//     </ul>
//     <p>2.3. Принципы обработки персональных данных Пользователей:</p>
//     <ul>
//         <li>
//             - обработка персональных данных должна осуществляться исключительно на законных основаниях и в
//             интересах Пользователей;
//         </li>
//         <li>- обработка персональных данных должна ограничиваться достижением конкретных законных целей;</li>
//         <li>
//             - при обработке персональных данных обеспечивается точность, достаточность, а в необходимых случаях
//             актуальность персональных данных;
//         </li>
//         <li>
//             - хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта
//             персональных данных, не дольше, чем этого требуют цели обработки персональных данных.
//         </li>
//     </ul>
//     <p>2.4. Персональные данные Пользователя Администрация обрабатывает в целях: </p>
//     <ul>
//         <li>
//             - исполнения соглашений с Пользователем по предоставлению доступа к функционалу Сервиса, для
//             администрирования Сервиса;
//         </li>
//         <li>
//             - идентификации Пользователя при регистрации в Сервисе и аутентификации Пользователя при
//             использовании Сервиса;
//         </li>
//         <li>- оказания услуг, обработки запросов и заявок от Пользователя;</li>
//         <li>- установления с Пользователем обратной связи, включая направление уведомлений и запросов; </li>
//         <li>- подтверждения полноты персональных данных, предоставленных Пользователем;</li>
//         <li>- заключения с Пользователем договоров, осуществления взаиморасчетов;</li>
//         <li>- сбора статистики;</li>
//         <li>- улучшения качества Сервиса, удобства его использования и разработки новых сервисов и услуг;</li>
//         <li>
//             - проведения маркетинговых (рекламных) мероприятий, направления предложений, продвижения на рынке
//             услуг.
//         </li>
//     </ul>
//     <p>
//         2.5. Пользователь осведомлен и согласен, что для целей, предусмотренных в Правилах ПДн, Администрация
//         вправе:
//     </p>
//     <ul>
//         <li>
//             - собирать и использовать дополнительную информацию, связанную с Пользователем, получаемую в
//             процессе доступа Пользователя к Сервису или от третьих лиц, и включающую в себя данные о технических
//             средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сервисом
//             (в т. ч. IP-адрес хоста, вид операционной системы Пользователя, тип браузера, географическое
//             положение, данные о провайдере и иное), об активности Пользователя на Сервисе, Cookie, об информации
//             об ошибках, выдаваемых Пользователям, о скачанных файлах, видео, инструментах, а также иные данные,
//             получаемые установленными Правилами ПДн способами;
//         </li>
//         <li>
//             - распоряжаться статистической информацией, связанной с функционированием Сервиса, а также
//             информацией Пользователей для целей организации функционирования и технической поддержки Сервиса и
//             исполнения условий настоящих Правил.
//         </li>
//     </ul>
//     <h5>3. Условия обработки персональной информации.</h5>
//     <p>
//         3.1. Обработка персональных данных Пользователя осуществляется в сроки, определенные п.3.5 Правил ПДн,
//         любым законным способом, в том числе в информационных системах персональных данных, с использованием
//         средств автоматизации (в виде электронных образов документов), за исключением случаев, когда
//         неавтоматизированная обработка персональных данных необходима в связи с исполнением требований
//         законодательства. Обработка персональных данных Пользователей осуществляется в соответствии с
//         Конституцией Российской Федерацией, Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»,
//         настоящими Правилами ПДн.
//     </p>
//     <p>
//         3.2. Источником информации обо всех персональных данных Пользователя является непосредственно сам
//         Пользователь Сервиса. Заполняя любую форму и/или прикрепляя файл при регистрации в Приложении и при
//         использовании Сервиса, Пользователь тем самым дает согласие на обработку его персональных данных для
//         целей, указанных в разделе 2 Правил ПДн. Пользователь подтверждает права и обязательства в отношении
//         созданной таким образом своей учетной записи.
//     </p>
//     <p>
//         3.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
//         добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу
//         лиц. При использовании Сервиса Пользователь соглашается с тем, что определенная часть его персональной
//         информации в результате действий Пользователя становится общедоступной для других Пользователей Сервиса
//         и пользователей сети Интернет, может быть скопирована и/или распространена такими Пользователями, с
//         учетом доступных настроек конфиденциальности.
//     </p>
//     <p>
//         Общедоступной, является, в частности, следующая персональная информация Пользователей: фамилия, имя,
//         отчество, номер телефона, адрес электронной почты.
//     </p>
//     <p>
//         3.4. Администрация принимает необходимые организационные и технические меры для защиты персональной
//         информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
//         копирования, распространения, а также от иных неправомерных действий третьих лиц.
//     </p>
//     <p>
//         3.5. Администрация хранит информацию о Пользователе в течение периода предоставления последнему доступа
//         к Сервису, а также 3 (трех) месяцев с момента прекращения такого доступа по любым основаниям. В случае
//         получения Администрацией заявления от Пользователя об отзыве согласия на обработку ПДн, Администрация
//         прекращает обработку ПДн Пользователя с даты, указанной в заявлении, но не ранее даты, следующей за
//         датой фактического получения Администрацией отзыва.
//     </p>
//     <p>
//         3.6. Администрация осуществляет хранение персональных данных Пользователя и его сотрудников. При этом,
//         Пользователь гарантирует, что получил согласие каждого своего сотрудника на передачу его персональных
//         данных Администрации.
//     </p>
//     <p>
//         Администрация, являясь обработчиком персональных данных по поручению Пользователя, не обязана получать
//         согласие сотрудников Пользователя на обработку их персональных данных. Безоговорочно принимая условия
//         настоящих Правил, Пользователь подтверждает, что он заблаговременно получил согласие своих сотрудников
//         на передачу Администрации их персональных данных.
//     </p>
//     <p>
//         Ответственность перед сотрудниками Пользователя, чьи персональные данные обрабатываются Администрацией
//         по поручению Пользователя, Пользователь несет самостоятельно.
//     </p>
//     <p>
//         Администрация обрабатывает персональные данные сотрудников Пользователя полностью в соответствии с
//         положениями, предусмотренными настоящими Правилами.
//     </p>
//     <p>
//         3.7. Администрация осуществляет обработку персональных данных следующими способами: сбор, запись,
//         систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
//         передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
//         персональных данных Пользователя.
//     </p>
//     <p>
//         Хранение Персональных данных Пользователей осуществляется исключительно на электронных носителях и
//         обрабатываются с использованием автоматизированных систем, за исключением случаев, когда
//         неавтоматизированная обработка персональных данных необходима в связи с исполнением требований
//         законодательства.
//     </p>
//     <p>
//         3.8. Администрация может осуществлять передачу персональных данных Пользователей, включая трансграничную
//         передачу на территорию иностранных государств, при условии получения согласия Пользователя и обеспечения
//         необходимой защиты прав субъектов персональных данных, контрагентам Администрации, которые привлекаются
//         Администрацией для оказания услуг по поддержанию надлежащего технического состояния, работоспособности,
//         модификации Сервиса. При этом хранение персональных данных граждан Российской Федерации за рубежом не
//         осуществляется.
//     </p>
//     <p>
//         3.9. Если Пользователь не согласен с обработкой Администрацией его персональных, в том числе
//         биометрических, данных, Пользователь не должен публиковать эту информацию или сообщать эти данные при
//         регистрации в Приложении и использовании Сервиса. Как только Пользователь предоставит свои персональные,
//         в том числе биометрические, данные при регистрации в Приложении и использовании Сервиса Сервису, они
//         будут доступны Администрации, другим пользователям Сервиса.
//     </p>
//     <h5>4. Обязательства сторон.</h5>
//     <p>4.1. Пользователь обязан:</p>
//     <ul>
//         <li>
//             - Предоставить корректную информацию о персональных данных, необходимую для целей, указанных в
//             разделе 2 Правил ПДн.
//         </li>
//         <li>
//             - Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной
//             информации.
//         </li>
//     </ul>
//     <p>4.2. Администрация обязана:</p>
//     <ul>
//         <li>- Использовать полученную информацию исключительно для целей, указанных в разделе 2 Правил ПДн.</li>
//         <li>- Обеспечить хранение конфиденциальной информации в тайне.</li>
//         <li>
//             - Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
//             согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом
//             обороте.
//         </li>
//         <li>
//             - Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с
//             момента обращения или запроса Пользователя, или его законного представителя, либо уполномоченного
//             органа по защите прав субъектов персональных данных на период проверки, в случае выявления
//             недостоверных персональных данных или неправомерных действий.
//         </li>
//     </ul>
//     <p>
//         4.3. Администрация при обработке персональных данных обязана принимать необходимые правовые,
//         организационные и технические меры для защиты персональных данных от несанкционированного,
//         неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования,
//         предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении
//         персональных данных, путем:
//     </p>
//     <ul>
//         <li>- разработки и внедрения в организации документов, регламентирующих работу с ПДн;</li>
//         <li>- ограничения и регламентации состава работников, имеющих доступ к персональным данным; </li>
//         <li>
//             - реализации разрешительной системы доступа Пользователей к информационным ресурсам,
//             программно-аппаратным средствам обработки и защиты информации;
//         </li>
//         <li>
//             - осуществления антивирусного контроля, предотвращения внедрения в корпоративную сеть вредоносных
//             программ (программ-вирусов) и программных закладок;
//         </li>
//         <li>- при необходимости, резервного копирование информации.</li>
//     </ul>
//     <p>
//         4.4. При определении объема и содержания обрабатываемых персональных данных Администрация
//         руководствуется Конституцией Российской Федерации, Федеральным закон от 27.07.2006 № 152-ФЗ «О
//         персональных данных», настоящими Правилами ПДн.
//     </p>
//     <p>
//         4.5. Администрация обязуется обеспечить недопущение несанкционированного и нецелевого доступа к
//         персональным данным Пользователей Сервиса.
//     </p>
//     <p>
//         При этом санкционированным и целевым доступом к персональным данным Пользователей Сервиса будет
//         считаться доступ уполномоченных Администрацией лиц в рамках целей деятельности и тематике Сервиса, а
//         также самих пользователей, зарегистрированных в установленном порядке.
//     </p>
//     <h5>5. Ответственность сторон и разрешение споров.</h5>
//     <p>
//         5.1. Администрация, не исполнившая свои обязательства, несёт ответственность перед Пользователем за
//         прямой фактический ущерб в связи с неправомерным использованием персональных данных в соответствии с
//         законодательством Российской Федерации.
//     </p>
//     <p>
//         5.2. В случае утраты или разглашения персональных данных Администрация не несёт ответственность, если
//         данная информация:
//     </p>
//     <ul>
//         <li>- стала публичным достоянием до её утраты или разглашения;</li>
//         <li>- была получена от третьей стороны до момента её получения Администрацией;</li>
//         <li>- была разглашена с согласия Пользователя.</li>
//     </ul>
//     <p>
//         5.3. К Правилам ПДн и отношениям между Пользователем и Администрацией применяется действующее
//         законодательство Российской Федерации.
//     </p>
//     <p>
//         В случае возникновения любых споров или разногласий, связанных с исполнением Правил ПДн, Пользователь и
//         Администрация приложат все усилия для их разрешения путем проведения переговоров между ними. В случае,
//         если споры не будут разрешены путем переговоров, споры подлежат разрешению в порядке, установленном
//         действующим законодательством Российской Федерации, по месту нахождения Администрации Приложения.
//     </p>
//     <p>
//         5.4. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и
//         Администрацией, обязательным является предъявление претензии.
//     </p>
//     <p>
//         Получатель претензии в течение 10 (десяти) дней со дня получения претензии, письменно уведомляет
//         заявителя претензии о результатах ее рассмотрения.
//     </p>
//     <p>
//         При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
//         действующим законодательством Российской Федерации.
//     </p>
//     <h5>6. Заключительные положения.</h5>
//     <p>
//         6.1. Настоящие Правила ПДн действуют в течение неопределенного срока, а в части согласия Пользователя на
//         обработку ПДн - до момента его отзыва Пользователем путем направления соответствующего уведомления на
//         электронный адрес Администрации, а также путем письменного обращения по юридическому адресу
//         Администрации. Выбранный способ обращения должен гарантировать возможность Администрации достоверно
//         идентифицировать обратившееся лицо.
//     </p>
//     <p>6.2. Правила ПДн являются открытым и общедоступным документом, располагаются в Приложении citywork.ru</p>
//     <p>
//         6.3. Пользователь может обратиться к Администрации с требованием об уточнении, изменении, блокировании,
//         отзыве и т.д. своих персональных данных по адресу электронной почты
//         <a href="mailto:info@citywork.ru">info@citywork.ru</a>.
//     </p>
//     <p>
//         Общество с ограниченной ответственностью «Миговеб» (ООО «Миговеб») Юридический адрес: ИНН 0411167510
//         ОГРН 1140411000124
//     </p>
//     <p>129301, г. Москва, ул. Касаткина, д.3, стр.2, этаж 2, оф. 27</p>
//     <p>Тел.: + 74951287300</p>
//     <p>
//         E-mail: <a href="mailto:info@citywork.ru">info@citywork.ru</a>
//     </p>
// </div>