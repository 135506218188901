import style from "../subscribe.module.sass";
import SubscribeCity from "./SubscribeCity";
import {useEffect, useState} from "react";
import SubscribeCategory from "./SubscribeCategory";
import SubscribeSubCategory from "./SubscribeSubCategory";
import {link} from "../../../store/host-store";
import {token} from "../../../App";
import {NavLink, useNavigate} from "react-router-dom";

const SubscribeCreate = () => {
    const [cityId, setCityId] = useState();
    const [category, setCategory] = useState([]);
    const [type, setType] = useState(0);
    const [subCategoryId, setSubcategoryId] = useState(null);
    const [equipment, setEquipment] = useState(3);
    const [error, setError] = useState();

    useEffect(() => {
        setEquipment(3)
        setSubcategoryId(null)
    }, [type])

    const navigate = useNavigate();

    const toggleCategory = (type) => {
        setType(type);
    };

    const getCity = (id) => {
        setCityId(id)
    }

    const fetchData = () => {
        console.log(cityId?.value, type, subCategoryId)
        //
        let data = {
            category_id: subCategoryId,
            city_id: cityId?.value
        }
        console.log(data)

        fetch(`${link}/subscription`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then(res => {
            setError(res.data[0])
            console.log(res.data)
            if(res.success){
                navigate('/lc/subscribe')
            }
        })


    }

console.log(equipment, type, subCategoryId, cityId)

  return(
      <div className='cabinet__redesign_container'>
          <h3 className='cabinet__redesign_title'>Мои подписки</h3>
          <div className={style.promotion_block}>
              <SubscribeCity getCity={getCity}/>
              <SubscribeCategory setCategory={setCategory} category={category} type={type} toggleCategory={toggleCategory}/>
              <SubscribeSubCategory type={type} setSubcategoryId={setSubcategoryId} setEquipment={setEquipment}/>

              <div className={style.btn_wrapper}>
                  {subCategoryId !== null && type !== 2 || type === 2 && equipment === 1 ?
                      <NavLink className={style.button} state={{city: cityId}}
                               type='button' to={`/lc/subscribe/create/${subCategoryId}`}>Далее</NavLink>
                      : type === 2 && equipment === 0 ?
                          <button type='button' className={style.button} onClick={fetchData}>Сохранить</button>
                          : equipment === 3 ?
                       <button type='button' className={style.button} onClick={() => alert("Выберите категорию")}>Далее</button>
                             : ''
                  }
              </div>
              {error && <p className='error' style={{textAlign: 'center'}}>{error?.message}</p>}
          </div>
      </div>
  )
}

export default SubscribeCreate;