import style from "../style.module.sass";

const ResponseStatuses = ({ status, setStatus, archiveCount, activeCount, setPage }) => {
    return (
        <div className={style.response__statuses}>
            <h6 className="cabinet__redesign_subtitle">Статус откликов:</h6>
            <div className={style.response__statuses_wrapper}>
                <p
                    className={`${style.item} ${status === 0 ? style.active : ""}`}
                    onClick={() => {
                        setStatus(0);
                        setPage(1);
                    }}>
                    Активные ({activeCount})
                </p>
                <p
                    className={`${style.item} ${status === 1 ? style.active : ""}`}
                    onClick={() => {
                        setStatus(1);
                        setPage(1);
                    }}>
                    Архив ({archiveCount})
                </p>
            </div>
        </div>
    );
};

export default ResponseStatuses;
