import {NavLink, useNavigate} from "react-router-dom";
import ArrowBack from "../../../request/svg/ArrowBack";
import EditSearchForm from "./EditSearchForm";

const EditSearch = ({ type }) => {
    const navigate = useNavigate();

    return(
        <>
            <div className="flex">
                <NavLink to="#" className="margR6" onClick={() => navigate(-1)}>
                    <ArrowBack />
                </NavLink>
                <h2 className="h2_cabinet_main">Разместить объявление</h2>
            </div>
            <div className="create_ads cabinet_ads__left">
                <EditSearchForm type={type}/>
            </div>
        </>
    )
}

export default EditSearch;