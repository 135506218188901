import noAvatar from "../../../assets/images/stubs/avatar.svg";
import {url} from "../../../store/host-store";
import StarMiniIcon from "../../../components/icons/adIcons/StarMiniIcon";
import OneUserIsPersonConfirmed from "./OneUserIsPersonСonfirmed";
import OneUserIsDocsConfirmed from "./OneUserIsDocsConfirmed";
import {useContext} from "react";
import AuthContext from "../../../store/auth-context";

const OneUserDataAndWallet = ({currentUser, addMoney, addPoints, money, setPoints, setMoney, points}) => {
    const ctx = useContext(AuthContext);

    return(
        <div className="admin_one-user_top">
            <div className="admin_one_user_left">
                <div className="admin_one_user_img_infoFlex">
                    {currentUser.avatar === null ? (
                        <img src={noAvatar} alt="user" />
                    ) : currentUser.webpFilename ? (
                        <img src={url + currentUser.webpFilename} alt="user" />
                    ) : (
                        <img src={url + currentUser.avatar} alt="user" />
                    )}
                    <div className="admin_one_user_information">
                        <h2 className="admin_one_user_info_name">{currentUser?.name}</h2>
                        <div className="admin_one_user_info_id">ID {currentUser?.id}</div>
                        <div className="admin_one_user_info_rating">
                            <div className="flex_admin_center">
                                {currentUser.mark === null || currentUser.mark === 0 ? (
                                    <>
                                        <div className="admin__rating  no-rating">Нет рейтинга</div>
                                        <div className="star_admin">
                                            <StarMiniIcon />
                                            {currentUser.mark}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="admin__rating  no-rating">Рейтинг</div>
                                        <div className="star_admin">
                                            <StarMiniIcon />
                                            {currentUser.mark}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <OneUserIsPersonConfirmed confirmed={currentUser?.confirmed}/>
                <OneUserIsDocsConfirmed
                    confirmed={currentUser?.organization?.confirmed}
                    idOrganization={currentUser?.organization?.id}
                    status={currentUser?.organization?.status}
                />
            </div>

            {currentUser.wallet !== null && (
                <div className="admin_one-user_top_wallet">
                    <div className="admin_one-user_top_wallet_wrapper">
                        <h4>Денежных средств</h4>
                        <p>{currentUser.wallet.money} ₽</p>
                        <div className="input__wrapper">
                            <input
                                className="input_blue"
                                type="number" value={money} onChange={(e) => setMoney(e.target.value)} />
                            {ctx.userData?.account?.role === 'fin_admin' ? '' :
                                <button className="button_admins_add_money" type="button" onClick={() => addMoney(currentUser.id)}>
                                    Пополнить
                                </button>
                            }
                        </div>
                    </div>
                    <div className="admin_one-user_top_wallet_wrapper green_wallet">
                        <h4>Бонусы</h4>
                        <p>{currentUser.wallet.points}</p>
                        <div className="input__wrapper">
                            <input
                                className="input_green"
                                type="number"
                                value={points}
                                onChange={(e) => setPoints(e.target.value)}
                            />
                            <button className="button_admins_add_money button_admins_add_point" type="button" onClick={() => addPoints(currentUser.id)}>
                                <span></span>
                                Пополнить
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OneUserDataAndWallet;