import {useContext} from "react";
import AdsPageModeration from "./AdsPageModeration";
import AdModeratorContext from "../../store/ad-moderator-context";
import CompanyModeration from "./CompanyModeration";

const MobilePage = () => {
  const ctx = useContext(AdModeratorContext);

  return (
      <>
          {ctx.randomFetch === 'advertisement' && <AdsPageModeration />}
          {ctx.randomFetch === 'organization' && <CompanyModeration />}
      </>
  )
}
export default MobilePage;