import { NavLink } from "react-router-dom";

import s from "./style.module.sass";

const RateCard = ({ descr, src, fix, title, to }) => {
    return (
        <div className={`${s.card} ${fix ? s.fix : s.eye}`}>
            <img src={src} alt="about rate" />
            <div className={s.content}>
                {/* тут проверка на поле какой тариф, в зависимотси от этого устанавливается класс */}
                <h3 className={fix ? s.fix : s.eye}>{title}</h3>
                <p className={s.descr}>{descr}</p>
            </div>
            <NavLink to={to}>Редактировать</NavLink>
        </div>
    );
};

export default RateCard;
