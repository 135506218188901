import "../cabinet.sass";
import { useContext, useEffect, useRef, useState } from "react";
import Input from "../../UI/Input";
import { token} from "../../App";
import FileInput from "../../UI/FileInput/FileInput";
import axios from "axios";
import avatar from "../../assets/images/stubs/avatar.svg";
import getHost, { url } from "../../store/host-store";
import DeviceContext from "../../store/device-context";
import CabinetInfoRight from "./CabinetInfoRight";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionSvg from "../Svg/AccordionSvg";
import Typography from "@mui/material/Typography";
import CabinetSocToggle from "./CabinetSocToggle";
import tel from "../../assets/images/cabinet/tel.svg";
import email from "../../assets/images/cabinet/email.svg";
import CabinetInfoWalletPoints from "./CabinetInfoWalletPoints";
import tg from "../../assets/images/icons/telegram-blue.svg";
import AuthContext from "../../store/auth-context";
import Timezone from "./Timezone";
import { useDispatch } from "react-redux";
import { blockerAction } from "../../store/redux";
import modalsContext from "../../store/modals-context";

const CabinetInfo = (props) => {
    const device = useContext(DeviceContext);
    const ctx = useContext(AuthContext);
    const [error, setError] = useState("");
    const [accept, setAccept] = useState(0);
    const [notification, setNotification] = useState(false);
    const [workWeekend, setWorkWeekend] = useState(0);
    const [timeFrom, setTimeFrom] = useState("00:00");
    const [timeTo, setTimeTo] = useState("00:00");
    const [telegramActive, setTelegramActive] = useState(0);
    const [stateChanged, setStateChanged] = useState(false);
    const [timezone, setTimezone] = useState(0);
    const inputEmail = useRef();
    const inputName = useRef();
    

    const dispatch = useDispatch();

    useEffect(() => {
        if (stateChanged) {
            dispatch(blockerAction.blockTrue());
        } else {
            dispatch(blockerAction.blockFalse());
        }
    }, [stateChanged]);

    // const inputWa = useRef();
    const inputTelegram = useRef();
    // const inputViber = useRef();

    const toggle = () => {
        setTelegramActive((prevState) => (prevState === 0 ? 1 : 0));
        const activate = telegramActive === 0 || telegramActive == null;
        const data = { messengers_active: activate ? 1 : 0 };
        axios
            .put(
                getHost({
                    controller: "user",
                    action: "identity-update",
                }),
                data,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
            .then((res) => console.log(res.data.data.messengers_active))
            .then(() => ctx.setRefreshData((prev) => !prev));

        if (telegramActive === 0) {
            window.open(`https://t.me/ProrabNotificationBot`);
        }
    };

    useEffect(() => {
        // Загрузка данных лк
        setAccept(props.data.busy || "");
        setWorkWeekend(props.data.work_on_weekend || "");
        setTimeTo(props.data.working_hours_end || "");
        setTimeFrom(props.data.working_hours_start || "");
        setTelegramActive(props.data.messengers_active);
        setNotification(props.data.send_messages);
        setTimezone(props.data.time_zone);
    }, [props.data]);

    const getImg = (selectedImg) => {
        const formData = new FormData();
        formData.append("avatar", selectedImg);

        axios
            .put(
                getHost({
                    controller: "user",
                    action: "identity-update",
                }),
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
            .then(() => {
                props.getRerender((prev) => !prev);
            })
            .catch((err) => console.log(err));
    };

    const submitHandler = (event) => {
        event.preventDefault();
        let data = {
            name: inputName.current.value,
            email: inputEmail.current.value,
            busy: accept ? 1 : 0,
            work_on_weekend: workWeekend ? 1 : 0,
            working_hours_start: timeFrom,
            working_hours_end: timeTo === "00:00" ? "24:00" : timeTo,
            // whatsapp: inputWa.current.value,
            send_messages: notification ? 1 : 0,
            telegram: inputTelegram.current.value,
            time_zone: timezone,
            // viber: inputViber.current.value,
        };
        console.log(data);
        props.getRerender((prev) => !prev);
        fetch(
            getHost({
                controller: "user",
                action: "identity-update",
            }),
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                props.getRerender((prev) => !prev);
                setError(result.data[0]);
                console.log(result);
                window.location.reload();
            });
        console.log(data);
        setStateChanged(false);
    };

    // const whatsAppHolder = props.data.whatsapp === null || props.data.whatsapp === "" ? "Нет данных" : "";
    return (
        <>
            <h2 className="h2_cabinet_main">Главная личный кабинет</h2>
            <form
                className="person_data"
                encType="multipart/form-data"
                onSubmit={submitHandler}
                onChange={(e) => {
                    if(e.target.name === 'exl'){
                        e.preventDefault();
                    }else{
                        setStateChanged(true)
                    }
                }}>
                <div className="admin__main_cabinet__flex">
                    <div className="left__cabinet__main_flex">
                        <div className="admin__info_top-wrapper">
                            <div className="cabinet_info__avatar_and_info_form">
                                {props.data.avatar === null ? (
                                    <div className="cabinet__info_avatar">
                                        <img className="cabinet__info_avatar_img" src={avatar} alt="avatar"></img>
                                        <FileInput getImg={getImg} />
                                    </div>
                                ) : (
                                    <div className="cabinet__info_avatar">
                                        <img
                                            className="cabinet__info_avatar_img"
                                            src={url + props.data.avatar}
                                            alt="avatar"
                                        />
                                        <FileInput getImg={getImg} />
                                    </div>
                                )}

                                {device.isMobile && (
                                    <div className="info_formation">
                                        <div className="info_adv">{props.data?.amountOfObjects} объявлений</div>
                                        <div className="info_response">
                                            {props.data?.amountOfConfirmedOrders} откликов
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="info_top_second">
                                <div className="info_top_second__text">
                                    Ваше имя <span>(видно всем пользователям)</span>
                                </div>
                                <div className="info_top_second_flex">
                                    <Input
                                        classNameParent="input input_name inputMainName"
                                        type="text"
                                        placeholder=""
                                        value={props.data.name}
                                        defaultValue={props.data.name}
                                        onChange={() => setStateChanged(true)}
                                        ref={inputName}
                                    />
                                    {error && <p className="name__error">{error.message}</p>}

                                    {!device.isMobile && (
                                        <div className="info_formation">
                                            <div className="info_adv">{props.data?.amountOfObjects} объявлений</div>
                                            <div className="info_response">
                                                {props.data?.amountOfConfirmedOrders} откликов
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            {/*{isAppstore && !device.isMobile && (*/}
                            {/*    <div className="admin__info_btns-wrapper">*/}
                            {/*        <button className="admin__btn-transparent" onClick={modal.deleteProfileModal}>*/}
                            {/*            Удалить профиль*/}
                            {/*        </button>*/}
                            {/*        /!*<button className="admin__btn-blue">Сохранить</button>*!/*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {device.isMobile && <CabinetInfoWalletPoints />}
                        </div>

                        <div className="info_contacts">
                            <div className="accordion_cabinet">
                                <Accordion defaultExpanded disableGutters>
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                        expandIcon={<AccordionSvg />}>
                                        <Typography className="typography_title_strong">
                                            Контактная информация
                                        </Typography>
                                    </AccordionSummary>

                                    <div className="info_contacts_top">
                                        <div className="info_contacts_tel">
                                            <div className="info__block_title">Телефон контактный:</div>
                                            {/* <Input
                                    h6="Номер телефона"
                                    classNameParent="input2"
                                    className="input_phone tel"
                                    type="number"
                                    placeholder=""
                                    disabled
                                    defaultValue={props.data.phone}
                                /> */}
                                            <div className="input2">
                                                <p>
                                                    <img width={23} height={23} src={tel} alt="" />
                                                    {props.data.phone}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="info__block_title">Телеграмм:</div>
                                            <Input
                                                placeholder="Введите никнейм"
                                                type="text"
                                                classNameParent="input input_tg"
                                                defaultValue={props.data.telegram}
                                                onChange={() => setStateChanged(true)}
                                                ref={inputTelegram}
                                            />
                                        </div>
                                        <div className="info_contacts__led">
                                            <div className="info_soc_flex">
                                                {/* <CabinetSocToggle src="/img/whatsapp.png" /> */}
                                                <CabinetSocToggle src={tg} toggle={toggle} checked={telegramActive} />
                                                {/* <CabinetSocToggle src="/img/viber.png" /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="info_contacts_bottom">
                                        <div className="contacts_bottom_flex">
                                            <div className="info__block_title">E-mail:</div>
                                            <Input
                                                // placeholder={whatsAppHolder}
                                                type="email"
                                                srcLeft={email}
                                                classNameParent="input input_email"
                                                ref={inputEmail}
                                                onChange={() => setStateChanged(true)}
                                                defaultValue={props.data.email}
                                            />
                                        </div>

                                        <div className="contacts_bottom_flex flex_end">
                                            <CabinetSocToggle
                                                text="Получать уведомления"
                                                checked={notification}
                                                toggle={() => {
                                                    setNotification((prev) => !prev);
                                                }}
                                                className="notification_soc"
                                            />
                                        </div>
                                    </div>
                                </Accordion>
                            </div>
                        </div>

                        <div className="info_whenBlock">
                            <div className="accordion_cabinet">
                                <Accordion disableGutters>
                                    <AccordionSummary
                                        aria-controls="panel2d-content"
                                        id="panel2d-header"
                                        expandIcon={<AccordionSvg />}>
                                        <Typography className="typography_title_strong">Рабочий график</Typography>
                                    </AccordionSummary>

                                    <div className="info_when__flex" style={{marginBottom: '10px'}}>
                                        <div className="info_top">
                                            <div className="info_top_when__left">
                                                <div className="info__block_title">Принимаю заявки:</div>
                                                <CabinetSocToggle
                                                    text={accept ? "Занят" : "Принимаю заявки"}
                                                    checked={accept}
                                                    toggle={() => {
                                                        setAccept((prev) => !prev);
                                                    }}
                                                    reverse={true}
                                                    className={!accept ? "switchApplication" : "switchApplication sw"}
                                                />
                                            </div>

                                            <div className="info_top_when__right">
                                                <div className="info__block_title">Выходные дни:</div>
                                                <CabinetSocToggle
                                                    checked={workWeekend}
                                                    toggle={() => setWorkWeekend((prev) => !prev)}
                                                    text="Работаю"
                                                    className="switchWork"
                                                />
                                            </div>
                                        </div>

                                        <div className="info_bottom">
                                            <div className="info__block_title">График работы:</div>
                                            <div className="info_bottom___flex">
                                                <div className="info_bottom___left">
                                                    <div className="cabinet__time_info">
                                                        <input
                                                            onChange={(event) => setTimeFrom(event.target.value)}
                                                            type="time"
                                                            className="info_block__timeFrom info_block__time"
                                                            value={timeFrom}></input>
                                                        <span>c</span>
                                                    </div>
                                                    <div className="cabinet__time_info">
                                                        <input
                                                            onChange={(event) => setTimeTo(event.target.value)}
                                                            type="time"
                                                            className="info_block__timeTo info_block__time"
                                                            value={timeTo}></input>
                                                        <span>до</span>
                                                    </div>
                                                </div>
                                                <div className="info_bottom___right">
                                                    <span>*</span> Графики влияет на время отображения Ваших объявлений
                                                    в каталоге. Если хотите принимать заявки круглосуточно оставьте
                                                    время по умолчанию.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Timezone setTimezone={setTimezone} setStateChanged={setStateChanged} timezone={timezone} />
                                </Accordion>
                            </div>
                        </div>

                        <div className="bottom_info__cabinet_soc">
                            <div className="admin__info_btns-wrapper">
                                {stateChanged && (
                                    <button type="submit" className="admin__btn-blue">
                                        Сохранить
                                    </button>
                                )}
                            </div>
                        </div>
                            {/*<button style={{margin: "auto"}}*/}
                            {/*    className="admin__btn-transparent" onClick={modal.deleteProfileModal}>*/}
                            {/*    Удалить профиль*/}
                            {/*</button>*/}
                    </div>
                    <CabinetInfoRight />
                </div>
            </form>
        </>
    );
};

// <div className="right">
//     <Input
//         className="input_phone tel"
//         placeholder={whatsAppHolder}
//         type="number"
//         src="/img/whatsapp.png"
//         classNameParent="input"
//         ref={inputWa}
//         defaultValue={whatsAppValue}
//     />
//     <Input
//         className="input_phone tel"
//         placeholder={viberHolder}
//         type="number"
//         defaultValue={viberValue}
//         src="/img/viber.png"
//         classNameParent="input"
//         ref={inputViber}
//     />
// </div>

export default CabinetInfo;
