import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import CreateSearchCategories from "./CreateSearchCategories";
import CreateSearchAbout from "./CreateSearchAbout";
import CreateSearchTime from "./CreateSearchTime";

import moment from "moment";
import DeviceContext from "../../../../store/device-context";
import AuthContext from "../../../../store/auth-context";
import axios from "axios";
import {link} from "../../../../store/host-store";
import {token} from "../../../../App";
import {useNavigate} from "react-router-dom";
import CreateAdsCity from "../../../../adRole/ads/createAds/CreateAdsCity";
import {RegistratorBlock} from "../../registratorBlock/registratorBlock";


const CreateSearchForm = ({type}) => {
    const device = useContext(DeviceContext);
    const ctx = useContext(AuthContext)
    const navigate = useNavigate();
    const [elementId, setElementId] = useState([0]);
    const [arrayCityId, setArrayCityId] = useState([]);
    const [categoryParent, setCategoryParent] = useState(null);
    const [rerender, setRerender] = useState(false);
    const [categoryChild, setCategoryChild] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [haveSubCategories, setHaveSubCategories] = useState(0);
    const [images, setImages] = useState([]);
    const {register, handleSubmit} = useForm();
    const [date, setDate] = useState(new Date(new Date().setDate(new Date().getDate() + 90)));
    const [cityId, setCityIid] = useState("");
    const [errors, setErrors] = useState({});
    const [registratorData, setRegistratorData] = useState({
        tel: '',
        name: '',
    });
    const [imagesAvatar, setImagesAvatar] = useState([]);
    const [activeStatus, setActiveStatus] = useState();

    function showErrors(errorList) {
        setErrors("");
        errorList.map((error) => {
            switch (error.field) {
                case "name":
                    setErrors((prevState) => ({ ...prevState, name: error }));
                    break;
                case "about":
                    setErrors((prevState) => ({ ...prevState, about: error }));
                    break;
                case "category_id":
                    setErrors((prevState) => ({ ...prevState, category: error }));
                    break;
                case "final_date":
                    setErrors((prevState) => ({ ...prevState, finalDate: error }));
                    break;
                case "city_id":
                    setErrors((prevState) => ({ ...prevState, city_id: error }));
                  break
                case "contactPhone":
                    setErrors((prevState) => ({ ...prevState, contactPhone: error }));
                    break;
                default:
            }
        });
    }


    const onSubmit = (data) => {
        setDisabled(true);
        let cities = [...new Set(arrayCityId.filter(Boolean))];
        let category_id = categoryChild === null ? "" : categoryChild.value;
        let selected_images = images.length === 0 ? null : images;
        let dates = moment(date).format('YYYY-MM-DD') + " " +  data.time + ':00'

        if (+type === 2 && haveSubCategories.length === 0) {
            category_id = categoryParent.value;
        }


        const formData = new FormData();
        formData.append("seek", 1);
        formData.append("category_id", category_id);
        formData.append("type", type);
        formData.append("name", data.name);
        formData.append("about", data.about);
        {
            selected_images === null
                ? formData.append("image", null)
                :
                selected_images.forEach((file) => {
                    formData.append("image[]", file);
                });
        }
        formData.append("final_date", dates);
        formData.append("user_id", ctx.isUserId);
        if(cities.length > 0){
            cities.map(item => formData.append('cities[]', item))
        }
        if(ctx.userData?.account?.role === 'registrar'){
            formData.append('contactName', registratorData.name)
            formData.append('contactPhone', registratorData.tel)
            formData.append('contactAvatar', imagesAvatar[0])
        }
        if(activeStatus !== undefined){
            formData.append('type_activity', activeStatus?.value)
        }

        const sendRequest = async () => {
            await axios
                .post(`${link}/object`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                })
                .then(({ data }) => {
                    console.log(data)
                    if (data.success) {
                        navigate('/lc/advertisement')
                    } else {
                        showErrors(data.data);
                        setDisabled(false);
                    }
                })
                .catch((err) => console.log(err));
            await ctx.setRerender((prev) => !prev);
            // bufferArr = [];
        };
        sendRequest();
    }

    const getCity = (city) => {
        setCityIid(city?.value)
        setDisabled(false);
    };

    return(
        <form className="form_cabinet_create" onSubmit={handleSubmit(onSubmit)}>
        <div className="create_ads__center">
            <div className="create_ads__box">
                {ctx.userData?.account?.role === 'registrar' &&
                <RegistratorBlock
                    setRegistratorData={setRegistratorData}
                    registratorData={registratorData}
                    setImagesAvatar={setImagesAvatar}
                    id={'registrar'}
                    imagesAvatar={imagesAvatar}
                    error={errors}
                />
                }
                <div className="create_ads_t_block create_ads_border">
                    <div className="advers___title">Что Вам нужно:</div>
                    <CreateSearchCategories
                        setCategoryParent={setCategoryParent}
                        categoryParent={categoryParent}
                        setCategoryChild={setCategoryChild}
                        categoryChild={categoryChild}
                        type={type}
                        setHaveSubCategories={setHaveSubCategories}
                        haveSubCategories={haveSubCategories}
                        errors={errors}
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                    />
                </div>

                <CreateSearchAbout register={register} setImages={setImages} images={images} errors={errors}/>

                <CreateSearchTime register={register} setDate={setDate} date={date} errors={errors}/>

                <div className="create_ads_border">
                    <div className="advers___title">Место оказания услуги: </div>
                    <div className="create_ads__box">
                        {/*<CitySelects getCity={getCity} />*/}
                        {elementId.map((el, i) =>
                            <CreateAdsCity getCity={getCity} key={el} arrayCityId={arrayCityId}
                                           index={i}
                                           elementId={elementId}
                                           setRerender={setRerender}
                                           setElementId={setElementId}/>
                        )
                        }
                    </div>
                    <p className="error">{errors.city_id?.message}</p>
                </div>

            </div>
            {!device.isMobile && (
                <div className="cabinet_ads__left">
                    <button disabled={disabled} type="submit" className="btn_publish btn_save">
                        Разместить объявление
                    </button>
                </div>
            )}
        </div>
            {device.isMobile && (
                <div className="cabinet_ads__left">
                    <button disabled={disabled} type="submit" className="btn_publish btn_save">
                        Разместить объявление
                    </button>
                </div>
            )}
        </form>
    )
}

export default CreateSearchForm;