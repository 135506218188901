export const ShareIcon = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#E5E5E5" />
            <path
                d="M18.125 6C16.5625 6 15.25 7.3125 15.25 8.875C15.25 9.125 15.3125 9.375 15.375 9.5625L12 11.3125C11.375 10.5625 10.4375 10.125 9.4375 10.125C7.5625 10.125 6 11.6875 6 13.5625C6 15.4375 7.5625 16.9375 9.4375 16.9375C10.375 16.9375 11.25 16.5625 11.875 15.9375L14.0625 17.25C14 17.5625 13.9375 17.8125 13.9375 18.125C13.9375 19.6875 15.25 21 16.8125 21C18.375 21 19.6875 19.6875 19.6875 18.125C19.6875 16.5625 18.375 15.25 16.8125 15.25C16 15.25 15.25 15.625 14.75 16.1875L12.625 14.9375C12.8125 14.5 12.875 14.0625 12.875 13.5625C12.875 13.1875 12.8125 12.8125 12.6875 12.4375L15.9375 10.75C16.4375 11.375 17.25 11.8125 18.125 11.8125C19.6875 11.8125 21 10.5 21 8.9375C21 7.3125 19.6875 6 18.125 6Z"
                fill="url(#paint0_linear_10_763)"
            />
            <defs>
                <linearGradient id="paint0_linear_10_763" x1="6" y1="6" x2="21" y2="21" gradientUnits="userSpaceOnUse">
                    <stop offset="0.114583" stopColor="#546EDB" />
                    <stop offset="1" stopColor="#546EDB" stopOpacity="0.5" />
                </linearGradient>
            </defs>
        </svg>
    );
};
