export const ClockIcon = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.3999 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216642 4.00021 -0.0968502 5.00555 0.0960758 5.97545C0.289002 6.94535 0.765206 7.83627 1.46447 8.53553C2.16373 9.23479 3.05465 9.711 4.02455 9.90392C4.99445 10.0969 5.99979 9.99783 6.91342 9.6194C7.82705 9.24096 8.60794 8.60009 9.15735 7.77785C9.70675 6.9556 10 5.9889 10 5C9.99213 3.67635 9.46282 2.40914 8.52684 1.47316C7.59086 0.537183 6.32366 0.00787129 5 0ZM7.375 5.5H5C4.86739 5.5 4.74022 5.44732 4.64645 5.35355C4.55268 5.25978 4.5 5.13261 4.5 5V2.53C4.5 2.39739 4.55268 2.27021 4.64645 2.17645C4.74022 2.08268 4.86739 2.03 5 2.03C5.13261 2.03 5.25979 2.08268 5.35355 2.17645C5.44732 2.27021 5.5 2.39739 5.5 2.53V4.5H7.375C7.50761 4.5 7.63479 4.55268 7.72855 4.64645C7.82232 4.74021 7.875 4.86739 7.875 5C7.875 5.13261 7.82232 5.25978 7.72855 5.35355C7.63479 5.44732 7.50761 5.5 7.375 5.5Z"
                fill="white"
            />
        </svg>
    );
};
