import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {link} from "../../../../store/host-store";
import {token} from "../../../../App";
import moment from "moment/moment";
import {ArrowLeftIcon} from "../../../../components/icons/ArrowLeftIcon";
import SearchInput from "../../../../UI/SearchInput/SearchInput";
import DateRangePickerExample from "../CalendarFromTo";
import Loader from "../../../../components/loader/Loader";
import Orders from "../models/Orders";
import Marks from "../models/Marks";
import UsersList from "../../users/UsersList";
import PaginationPattern from "../../../../UI/PaginationPattern";
import {ObjectRegistrar} from "./ObjectRegistrar";

export const RegistratorStatisticInner = () => {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]); // инфо по объектам
    const [userData, setUserData] = useState({});
    const [search, setSearch] = useState("");

    const [page, setPage] = useState(1); // текущая страница
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации

    const [model, setModel] = useState("object"); // модель текущих объектов
    const [status, setStatus] = useState(null); // статус null - все, approve - 1, reject - 2
    const [quantityObjects, setQuantityObjects] = useState(0);

    const history = useNavigate(); // для возможности вернуться на предыдущую страницу
    const id = useParams().id;

    let pagination = `&pagination[pageSize]=30&page=${page}`;

    //поиск календарь
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // инфо по пользователю и счетчикам модели
    useEffect(() => {
        fetch(`${link}/moderator-statistics/counters?moderator_id=${id}&model=${model}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setUserData(result.data);
                }
            })
            .catch((e) => console.log(e));
    }, [model, id]);

    // вывод объектов в зависимости от модели, страницы пагинации, статуса, календаря и поиска
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        setData([]);
        let fetchLink = `${link}/object/all?filter[user_id]=${id}${pagination}`;

        // статус одобрено или отклонено
        if (status !== null) {
            fetchLink += `&filter[status]=${status}`;
        }

        // если применяется календарь
        if (startDate !== null) {
            fetchLink += `&filter[created][gte]=${moment(startDate).format("YYYY-MM-DD")}`;
        }
        if (endDate !== null) {
            fetchLink += `&filter[created][lte]=${moment(endDate).format("YYYY-MM-DD")}`;
        }

        //если что-то вбито в поиске
        if (search.length > 0) {
            fetchLink += `&ObjectsSearch[query]=${search}`;
        }

        fetch(fetchLink, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json().then((data) => ({data, headers: res.headers})))
            .then((result) => {
                setLoading(false);
                if (result.data.success) {
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    setQuantityObjects(Number(result.headers.get("X-Pagination-Total-Count")));
                    setData(result.data.data);
                }
            })
            .catch((e) => console.log(e));
    }, [status, model, id, pagination, endDate, startDate, search]);

  return(
      <div className="admin__layout">
          <div className="admin__statistic_back">
              <button title="назад" type="button" className="admin__back-arrow" onClick={() => history(-1)}>
                  <ArrowLeftIcon/>
              </button>
              <h3>{userData.moderator?.name}</h3>
          </div>
          <div className="admin__statistic_navigation">
              <div className="admin__statistic_navigation_left">
                  <SearchInput setSearch={setSearch}/>
                  <div className="admin__statistic_navigation_calendar">
                      <DateRangePickerExample
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                      />
                      <button
                          type="button"
                          className="admin__btn-blue"
                          onClick={() => {
                              setStartDate(null);
                              setEndDate(null);
                          }}>
                          Сброс
                      </button>
                  </div>
              </div>
              <div><b>Размещено объявлений:</b> <span>{quantityObjects}</span></div>
          </div>
          {loading ? (
              <Loader/>
          ) : (
              <>
                  {data.length > 0 ? (
                      model === "object" ? (
                          <ObjectRegistrar data={data}/>
                      ) : model === "order" ? (
                          <Orders data={data}/>
                      ) : model === "mark" ? (
                          <Marks data={data}/>
                      ) : (
                          <UsersList
                              dataUsers={data.map((el) => {
                                  return el?.object;
                              })}
                          />
                      )
                  ) : (
                      <>Нет объявлений</>
                  )}

                  <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page}/>
              </>
          )}
      </div>
  )
}