export const AsideUser = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.93011 5.78043C5.72424 5.78043 6.4119 5.49561 6.97375 4.93367C7.53561 4.37181 7.82043 3.68434 7.82043 2.89013C7.82043 2.09618 7.53561 1.40862 6.97366 0.846585C6.41171 0.284819 5.72415 0 4.93011 0C4.13589 0 3.44843 0.284819 2.88657 0.846676C2.32471 1.40853 2.03979 2.09609 2.03979 2.89013C2.03979 3.68434 2.32471 4.3719 2.88666 4.93376C3.44861 5.49552 4.13617 5.78043 4.93011 5.78043Z"
                fill="#fff"
            />
            <path
                d="M9.9874 9.22738C9.97119 8.99356 9.93842 8.73849 9.89017 8.46914C9.84146 8.19778 9.77875 7.94125 9.70367 7.70679C9.62613 7.46445 9.52066 7.22513 9.39029 6.99579C9.25497 6.75776 9.09604 6.55048 8.91769 6.37992C8.7312 6.20148 8.50287 6.05802 8.23883 5.95338C7.97571 5.84928 7.68411 5.79655 7.37219 5.79655C7.24969 5.79655 7.13123 5.84681 6.90244 5.99576C6.76163 6.08759 6.59692 6.19379 6.41309 6.31125C6.25589 6.41141 6.04294 6.50525 5.77991 6.59021C5.52328 6.67325 5.26273 6.71537 5.00555 6.71537C4.74838 6.71537 4.48792 6.67325 4.23102 6.59021C3.96826 6.50535 3.75531 6.4115 3.5983 6.31135C3.4162 6.19498 3.2514 6.08878 3.10849 5.99567C2.87997 5.84672 2.76141 5.79646 2.63892 5.79646C2.3269 5.79646 2.0354 5.84928 1.77237 5.95347C1.50851 6.05793 1.28009 6.20139 1.09341 6.38001C0.915161 6.55066 0.756134 6.75785 0.621002 6.99579C0.490723 7.22513 0.385254 7.46436 0.307617 7.70688C0.232635 7.94134 0.169922 8.19778 0.121216 8.46914C0.0729675 8.73812 0.0401917 8.99328 0.0239868 9.22765C0.00805664 9.45727 0 9.69558 0 9.93627C0 10.5627 0.199127 11.0698 0.591797 11.4438C0.979614 11.8128 1.49277 12 2.11679 12H7.89487C8.51889 12 9.03186 11.8129 9.41977 11.4438C9.81253 11.0701 10.0117 10.5629 10.0117 9.93618C10.0116 9.69439 10.0034 9.45589 9.9874 9.22738Z"
                fill="#fff"
            />
        </svg>
    );
};
