import sign from "./../assets/images/banckCheck/podpis.png";
import stamp from "./../assets/images/banckCheck/stamp.png";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { token } from "../App";
import { link } from "../store/host-store";
import { useParams } from "react-router-dom";

const BankCheck = () => {
    const [dataPayment, setDataPayment] = useState({});

    // Это перевод в рубли

    let mapNumbers = {
        0: [2, 1, "ноль"],
        1: [0, 2, "один", "одна"],
        2: [1, 2, "два", "две"],
        3: [1, 1, "три"],
        4: [1, 1, "четыре"],
        5: [2, 1, "пять"],
        6: [2, 1, "шесть"],
        7: [2, 1, "семь"],
        8: [2, 1, "восемь"],
        9: [2, 1, "девять"],
        10: [2, 1, "десять"],
        11: [2, 1, "одиннадцать"],
        12: [2, 1, "двенадцать"],
        13: [2, 1, "тринадцать"],
        14: [2, 1, "четырнадцать"],
        15: [2, 1, "пятнадцать"],
        16: [2, 1, "шестнадцать"],
        17: [2, 1, "семнадцать"],
        18: [2, 1, "восемнадцать"],
        19: [2, 1, "девятнадцать"],
        20: [2, 1, "двадцать"],
        30: [2, 1, "тридцать"],
        40: [2, 1, "сорок"],
        50: [2, 1, "пятьдесят"],
        60: [2, 1, "шестьдесят"],
        70: [2, 1, "семьдесят"],
        80: [2, 1, "восемьдесят"],
        90: [2, 1, "девяносто"],
        100: [2, 1, "сто"],
        200: [2, 1, "двести"],
        300: [2, 1, "триста"],
        400: [2, 1, "четыреста"],
        500: [2, 1, "пятьсот"],
        600: [2, 1, "шестьсот"],
        700: [2, 1, "семьсот"],
        800: [2, 1, "восемьсот"],
        900: [2, 1, "девятьсот"],
    };

    let mapOrders = [
        { _Gender: true, _arrStates: ["рубль", "рубля", "рублей"] },
        { _Gender: false, _arrStates: ["тысяча", "тысячи", "тысяч"] },
        { _Gender: true, _arrStates: ["миллион", "миллиона", "миллионов"] },
        { _Gender: true, _arrStates: ["миллиард", "миллиарда", "миллиардов"] },
        { _Gender: true, _arrStates: ["триллион", "триллиона", "триллионов"] },
    ];

    let objKop = { _Gender: false, _arrStates: ["копейка", "копейки", "копеек"] };

    function Value(dVal, bGender) {
        let xVal = mapNumbers[dVal];
        if (xVal[1] === 1) {
            return xVal[2];
        } else {
            return xVal[2 + (bGender ? 0 : 1)];
        }
    }

    function From0To999(fValue, oObjDesc, fnAddNum, fnAddDesc) {
        let nCurrState = 2;
        if (Math.floor(fValue / 100) > 0) {
            let fCurr = Math.floor(fValue / 100) * 100;
            fnAddNum(Value(fCurr, oObjDesc._Gender));
            nCurrState = mapNumbers[fCurr][0];
            fValue -= fCurr;
        }

        if (fValue < 20) {
            if (Math.floor(fValue) > 0) {
                fnAddNum(Value(fValue, oObjDesc._Gender));
                nCurrState = mapNumbers[fValue][0];
            }
        } else {
            let fCurr = Math.floor(fValue / 10) * 10;
            fnAddNum(Value(fCurr, oObjDesc._Gender));
            nCurrState = mapNumbers[fCurr][0];
            fValue -= fCurr;

            if (Math.floor(fValue) > 0) {
                fnAddNum(Value(fValue, oObjDesc._Gender));
                nCurrState = mapNumbers[fValue][0];
            }
        }

        fnAddDesc(oObjDesc._arrStates[nCurrState]);
    }

    function FloatToSamplesInWordsRus(fAmount) {
        let fInt = Math.floor(fAmount + 0.005);
        let fDec = Math.floor((fAmount - fInt) * 100 + 0.5);

        let arrRet = [];
        let arrThousands = [];
        for (; fInt > 0.9999; fInt /= 1000) {
            arrThousands.push(Math.floor(fInt % 1000));
        }
        if (arrThousands.length === 0) {
            arrThousands.push(0);
        }

        function PushToRes(strVal) {
            arrRet.push(strVal);
        }

        for (let iSouth = arrThousands.length - 1; iSouth >= 0; --iSouth) {
            if (arrThousands[iSouth] === 0) {
                continue;
            }
            From0To999(arrThousands[iSouth], mapOrders[iSouth], PushToRes, PushToRes);
        }

        if (arrThousands[0] === 0) {
            //  Handle zero amount
            if (arrThousands.length === 1) {
                PushToRes(Value(0, mapOrders[0]._Gender));
            }

            let nCurrState = 2;
            PushToRes(mapOrders[0]._arrStates[nCurrState]);
        }

        if (arrRet.length > 0) {
            arrRet[0] = arrRet[0].match(/^(.)/)[1].toLocaleUpperCase() + arrRet[0].match(/^.(.*)$/)[1];
        }

        arrRet.push(fDec < 10 ? "0" + fDec : "" + fDec);
        From0To999(fDec, objKop, function () {}, PushToRes);

        return arrRet.join(" ");
    }

    let dataPriceString =
        FloatToSamplesInWordsRus(parseFloat(dataPayment.amount) ? parseFloat(dataPayment.amount) : null) + ".";

    const { id } = useParams();

    useEffect(() => {
        fetch(`${link}/bill/${id}?expand=billUser`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                // const myJSON =  JSON.stringify(res.data);
                setDataPayment(res.data);
                // localStorage.setItem('bankData', myJSON);
            });
    }, [id]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${dataPayment?.appointment} № ${dataPayment?.id} от ${dataPayment?.date}`}</title>
                <link rel="canonical" href="" />
            </Helmet>

            <div style={{ position: "relative", width: "705px", margin: "0 auto", paddingTop: "20px" }}>
                <table width="100%" style={{ marginTop: "30px", border: "2px solid #000" }}>
                    <tbody>
                        <tr>
                            <td width="60%" colSpan="4" style={{ borderBottom: "0px" }}>
                                &nbsp;АО "АЛЬФА-БАНК" г. Москва
                            </td>
                            <td width="10%">&nbsp;БИК</td>
                            <td width="30%" style={{ borderBottom: "0px" }}>
                                &nbsp;044525593
                            </td>
                        </tr>
                        <tr>
                            <td width="60%" colSpan="4" style={{ borderTop: "0px", borderBottom: "0px" }}></td>
                            <td width="10%" style={{ borderBottom: "0px" }}>
                                &nbsp;Сч. №
                            </td>
                            <td width="30%" style={{ borderTop: "0px", borderBottom: "0px" }}>
                                &nbsp;30101810200000000593
                            </td>
                        </tr>
                        <tr>
                            <td width="60%" colSpan="4" style={{ borderTop: "0px", fontSize: "12px" }}>
                                &nbsp;Банк получателя
                            </td>
                            <td width="10%" style={{ borderTop: "0px" }}></td>
                            <td width="30%" style={{ borderTop: "0px" }}></td>
                        </tr>
                        <tr>
                            <td width="10%">&nbsp;ИНН</td>
                            <td width="20%">&nbsp;0411167510</td>
                            <td width="10%">&nbsp;КПП</td>
                            <td width="20%">&nbsp;041101001</td>
                            <td width="10%" style={{ borderBottom: "0px" }}>
                                &nbsp;Сч. №
                            </td>
                            <td width="30%" style={{ borderBottom: "0px" }}>
                                &nbsp;40702810601890003776
                            </td>
                        </tr>
                        <tr>
                            <td width="60%" colSpan="4" style={{ borderBottom: "0px" }}>
                                &nbsp;ООО "Миговеб"
                            </td>
                            <td width="10%" style={{ border: "0px" }}></td>
                            <td width="30%" style={{ borderBottom: "0px", borderTop: "0px" }}></td>
                        </tr>
                        <tr>
                            <td width="60%" colSpan="4" style={{ borderTop: "0px", fontSize: "12px" }}>
                                &nbsp;Получатель
                            </td>
                            <td width="10%" style={{ borderTop: "0px" }}></td>
                            <td width="30%" style={{ borderTop: "0px" }}></td>
                        </tr>
                    </tbody>
                </table>
                <strong style={{ marginTop: "6px", fontSize: "12px", textDecoration: "underline" }}>
                    Условия для расчетов:
                </strong>
                <ol style={{ paddingLeft: "15px", margin: 0, paddingTop: 0, paddingBottom: 0 }}>
                    <li style={{ fontWeight: 500, fontSize: "12px" }}>
                        Счет действителен в течение пяти банковских дней
                    </li>
                    <li style={{ fontWeight: 500, fontSize: "12px" }}>
                        В назначении платежа, пожалуйста, указывайте номер счета
                    </li>
                </ol>
                <h2 style={{ textAlign: "center", marginTop: "30px", fontSize: "26px" }}>
                    {dataPayment?.appointment} № {dataPayment?.id} от {dataPayment?.date}
                </h2>
                <hr size="3" color="#000" />
                <div
                    style={{
                        lineHeight: "normal",
                        marginTop: "30px",
                        marginLeft: "10px",
                        fontSize: "14px",
                        marginBottom: "30px",
                    }}>
                    <p>
                        {" "}
                        ООО "Миговеб", ИНН 0411167510, КПП 041101001, 119296, г. Москва, муниципальный округ
                        Гагаринский, ул. Молодёжная, д. 3, пом. XVII, офис 6, тел.: +7 (495) 128-73-00
                    </p>
                    <p style={{ marginTop: "5px" }}>Покупатель (Заказчик):</p>
                </div>
                <table width="100%" style={{ border: "2px solid #000" }}>
                    <tbody>
                        <tr style={{ textAlign: "center" }}>
                            <td width="5%">№</td>
                            <td width="55%">Товары (работы, услуги)</td>
                            <td width="10%">Сумма</td>
                        </tr>
                        <tr>
                            <td style={{ paddingLeft: "20px" }}>1</td>
                            <td>
                                Пополнение кошелька пользователя id:{dataPayment.user_id} ({dataPayment?.billUser?.name}
                                )
                            </td>
                            <td style={{ textAlign: "right" }}>{Intl.NumberFormat().format(dataPayment?.amount)}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ width: "250px", float: "right", textAlign: "right", marginTop: "20px" }}>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td style={{ border: "0px", textAlign: "right", fontWeight: "600", fontSize: "14px" }}>
                                    Итого к оплате:
                                </td>
                                <td style={{ border: "0px", textAlign: "right", fontWeight: "600", fontSize: "14px" }}>
                                    {Intl.NumberFormat().format(dataPayment?.amount)}
                                </td>
                            </tr>
                            {/*{FloatToSamplesInWordsRus(123)}*/}
                            <tr>
                                <td style={{ border: "0px", textAlign: "right", fontWeight: "600", fontSize: "14px" }}>
                                    В том числе НДС:
                                </td>
                                <td style={{ border: "0px", textAlign: "right", fontWeight: "600", fontSize: "14px" }}>
                                    -
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "0px", textAlign: "right", fontWeight: "600", fontSize: "14px" }}>
                                    Всего к оплате
                                </td>
                                <td style={{ border: "0px", textAlign: "right", fontWeight: "600", fontSize: "14px" }}>
                                    {Intl.NumberFormat().format(dataPayment?.amount)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ clear: "both" }}></div>
                <p style={{ paddingTop: "15px", lineHeight: "normal" }}>К оплате: {dataPriceString}</p>
                <br />
                Настоящий счет («Счет») выставлен в соответствии с Офертой на заключение договора оказания услуг ({" "}
                <a href="https://citywork.ru/userTerm">https://citywork.ru/userTerm</a>) Оплачивая настоящий Счет, Вы
                подтверждаете, что полностью и безоговорочно согласились с условиями Оферты и Договора, заключенного
                между ООО "Миговеб" и Вами на условиях Оферты
                <hr size="3" color="#000" />
                <div>
                    <div style={{ height: "40px" }}> </div>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="15%" style={{ border: "0px", paddingTop: "25px" }}>
                                    Руководитель
                                </td>
                                <td
                                    width="26%"
                                    style={{ borderLeft: 0, borderRight: 0, borderTop: 0, position: "relative" }}>
                                    <img
                                        style={{ position: "absolute", bottom: "-12px", left: "26px" }}
                                        height="50px"
                                        src={sign}
                                        alt="sign"
                                    />
                                    <span style={{ fontSize: "12px", position: "absolute", right: 0, bottom: 0 }}>
                                        Пиряева А. А.
                                    </span>
                                </td>
                                <td width="10%" style={{ border: "0px", textAlign: "center", paddingTop: "25px" }}>
                                    Бухгалтер
                                </td>
                                <td
                                    width="20%"
                                    style={{
                                        position: "relative",
                                        borderLeft: 0,
                                        borderRight: 0,
                                        borderTop: 0,
                                        textAlign: "center",
                                    }}>
                                    <img
                                        style={{ position: "absolute", bottom: "-12px", left: "26px" }}
                                        height="50px"
                                        src={sign}
                                        alt="sign"
                                    />
                                    <span style={{ fontSize: "12px", position: "absolute", right: 0, bottom: 0 }}>
                                        Пиряева А. А.
                                    </span>
                                </td>
                            </tr>
                            <tr style={{ lineHeight: "normal" }}>
                                <td width="15%" style={{ border: "0px" }}></td>
                                <td width="5%" style={{ border: "0px" }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ height: "40px" }}> </div>
                </div>
                <img src={stamp} style={{ width: "200px", position: "absolute", bottom: "-138px" }} alt="stamp" />
            </div>
        </>
    );
};

export default BankCheck;
