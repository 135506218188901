import IncorrectBtn from "../../../buttons/IncorrectBtn";
import CorrectBtn from "../../../buttons/CorrectBtn";
import s from "./style.module.sass";

const DescrAdv = ({ object, incorrectClick, correctClick, isActive }) => {
    return (
        <div className={`${s.card} ${isActive ? s.active : ""}`}>
            <ul>
                <li>
                    <h5>Название</h5>
                    <p className={s.card__about}>{object.name}</p>
                </li>
                {object.type === 0 && (
                    <li>
                        <h5>Модель</h5>
                        <p className={s.card__about}>{object.model}</p>
                    </li>
                )}
                {object.type === 1 && (
                    <li>
                        <h5>Количество людей</h5>
                        <p className={s.card__about}>{object.quantity}</p>
                    </li>
                )}

                <li>
                    <h5>Описание</h5>
                    <p className={s.card__about} dangerouslySetInnerHTML={{__html: object.about}}></p>
                </li>
            </ul>
            <div>
                <CorrectBtn isActive={isActive} correctClick={() => correctClick("descr")} />
                <IncorrectBtn incorrectClick={incorrectClick} />
            </div>
        </div>
    );
};

export default DescrAdv;
