import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import EditSearchCategories from "./EditSearchCategories";
import EditSearchAbout from "./EditSearchAbout";
import EditSearchTime from "./EditSearchTime";
import moment from "moment";
import DeviceContext from "../../../../store/device-context";
import AuthContext from "../../../../store/auth-context";
import axios from "axios";
import {link} from "../../../../store/host-store";
import {token} from "../../../../App";
import {useNavigate} from "react-router-dom";
import {useFetchAd} from "../offer/Edit";
import SelectCity from "../SelectCity";
import {RegistratorBlock} from "../../registratorBlock/registratorBlock";



const EditSearchForm = ({type}) => {
    const ad = useFetchAd();

    const device = useContext(DeviceContext);
    const ctx = useContext(AuthContext)
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [categoryParent, setCategoryParent] = useState("");
    const [categoryChild, setCategoryChild] = useState("");
    const [downloadImages, setDownloadImages] = useState([]);
    const {register, handleSubmit, reset} = useForm();
    const [date, setDate] = useState();
    const [cityId, setCityIid] = useState(null);
    const [getCategoryFetch, setCategoryFetch] = useState([]);
    const [errors, setErrors] = useState({});
    const [registratorData, setRegistratorData] = useState({
        tel: '',
        name: '',
    });
    const [imagesAvatar, setImagesAvatar] = useState(ad.contact?.avatar);
    const [activeStatus, setActiveStatus] = useState();

    function showErrors(errorList) {
        setErrors("");
        errorList.map((error) => {
            switch (error.field) {
                case "name":
                    setErrors((prevState) => ({ ...prevState, name: error }));
                    break;
                case "about":
                    setErrors((prevState) => ({ ...prevState, about: error }));
                    break;
                case "category_id":
                    setErrors((prevState) => ({ ...prevState, category: error }));
                    break;
                case "final_date":
                    setErrors((prevState) => ({ ...prevState, finalDate: error }));
                    break;
                case "contactPhone":
                    setErrors((prevState) => ({ ...prevState, contactPhone: error }));
                    break;
                default:
            }
        });
    }

    //seek = 1
    //final_date

    const onSubmit = (data) => {
        setDisabled(true)
        let category_id = categoryChild === null ? "" : categoryChild.value;
        let selected_images = downloadImages;
        let city = cityId === undefined ? "" : cityId;
        let dates = moment(date).format('YYYY-MM-DD') + " " +  data.time + ':00'

        if ((getCategoryFetch.equipment === 0 && +ad.type === 2 )) {
            category_id = categoryParent.value;
        }


        const formData = new FormData();
        formData.append("seek", 1);
        formData.append("category_id", category_id);
        formData.append("type", ad.type);
        formData.append("name", data.name);
        formData.append("about", data.about);
        selected_images.forEach((file) => {
            formData.append("image[]", file);
        });
        formData.append("final_date", dates);
        formData.append("user_id", ctx.isUserId);
        formData.append("city_id", city);
        if(ctx.userData?.account?.role === 'registrar'){
            formData.append('contactName', registratorData.name)
            formData.append('contactPhone', registratorData.tel)
            if(imagesAvatar !== undefined){
                formData.append('contactAvatar', imagesAvatar[0])
            }
        }
        if(activeStatus !== undefined){
            formData.append('type_activity', activeStatus?.value)
        }
        const sendRequest = async () => {
            await axios
                .put(`${link}/object/${ad.id}`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                })
                .then(({ data }) => {
                    if (data.success) {
                        navigate(-1)
                    } else {
                        showErrors(data.data);
                    }
                })
                .catch((err) => console.log(err));
            await ctx.setRerender((prev) => !prev);
            // bufferArr = [];
        };
        sendRequest();
    }

    const getIdCity = (cityId) => {
        setCityIid(cityId);
    };

    useEffect(() => {
        setRegistratorData({...registratorData, name: ad.contact?.name, tel: ad.contact?.phone})
        if(ad.type_activity === 1){
            setActiveStatus({label: 'Юридические лица', value: 1})
        }else{
            setActiveStatus({label: 'Частники/самозанятые', value: 0})
        }
    }, [ad])

    return(
        <form className="form_cabinet_create" onSubmit={handleSubmit(onSubmit)}>
        <div className="create_ads__center">
            <div className="create_ads__box">
                {ctx.userData?.account?.role === 'registrar' &&
                    <RegistratorBlock
                        setRegistratorData={setRegistratorData}
                        registratorData={registratorData}
                        id={'registrar'}
                        setImagesAvatar={setImagesAvatar}
                        imagesAvatar={imagesAvatar}
                        avatar={ad.contact?.avatar}
                        error={errors}
                    />
                }
                <div className="create_ads_t_block create_ads_border create_ads_border_edit">
                    <div className="advers___title">Что Вам нужно:</div>
                    <EditSearchCategories
                        setCategoryParent={setCategoryParent}
                        categoryParent={categoryParent}
                        setCategoryChild={setCategoryChild}
                        categoryChild={categoryChild}
                        type={type}
                        errors={errors}
                        ad={ad}
                        setCategoryFetch={setCategoryFetch}
                        getCategoryFetch={getCategoryFetch}
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                    />
                </div>

                <EditSearchAbout reset={reset} register={register}
                                 setDownloadImages={setDownloadImages}
                                 downloadImages={downloadImages}
                                 errors={errors} ad={ad}/>

                <EditSearchTime  reset={reset} ad={ad} register={register} setDate={setDate} date={date} errors={errors}/>

                <div className="create_ads_border">
                    <div className="advers___title">Место оказания услуги: </div>
                    <div className="create_ads__box">
                        <SelectCity id={ad.id} getIdCity={getIdCity} />
                    </div>
                </div>

            </div>
            {!device.isMobile && (
                <div className="cabinet_ads__left">
                    <button disabled={disabled} type="submit" className="btn_publish btn_save">
                        Разместить объявление
                    </button>
                </div>
            )}
        </div>
            {device.isMobile && (
                <div className="cabinet_ads__left">
                    <button disabled={disabled} type="submit" className="btn_publish btn_save">
                        Разместить объявление
                    </button>
                </div>
            )}
        </form>
    )
}

export default EditSearchForm;