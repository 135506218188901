import { link } from "./store/host-store";

const applicationServerKey = "BL7cPzURoUYtuas0WYSE-IIcd8NPzwezXr9zFA3vop55pc4uIpq0oHeEaE5phzjd-beLsBQ1vK2E1TY3_IEKQTY";

checkPushes();

function checkPushes() {
    if (!("serviceWorker" in navigator)) {
        console.warn("Service workers are not supported by this browser");
        changePushButtonState("incompatible");
        return;
    }

    if (!("PushManager" in window)) {
        console.warn("Push notifications are not supported by this browser");
        changePushButtonState("incompatible");
        return;
    }

    if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
        console.warn("Notifications are not supported by this browser");
        changePushButtonState("incompatible");
        return;
    }

    if (Notification.permission === "denied") {
        console.warn("Notifications are denied by the user");
        changePushButtonState("incompatible");
        return;
    }

    navigator.serviceWorker.register("/serviceWorker.js").then(
        () => {
            push_updateSubscription();
        },
        (e) => {
            console.error("[SW] Service worker registration failed", e);
            changePushButtonState("incompatible");
        }
    );
}

export function push_unsubscribe() {
    changePushButtonState("computing");

    return navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => serviceWorkerRegistration.pushManager.getSubscription())
        .then((subscription) => {
            if (!subscription) {
                changePushButtonState("disabled");
                return;
            }
            return push_sendSubscriptionToServer(subscription, "DELETE");
        })
        .then((subscription) => subscription.unsubscribe())
        .then(() => changePushButtonState("disabled"))
        .catch((e) => {
            console.error("Error when unsubscribing the user", e);
            changePushButtonState("disabled");
        });
}

export function push_subscribe() {
    changePushButtonState("computing");

    return checkNotificationPermission()
        .then(() => navigator.serviceWorker.ready)
        .then((serviceWorkerRegistration) => {
            return serviceWorkerRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
            });
        })
        .then((subscription) => {
            return push_sendSubscriptionToServer(subscription, "POST");
        })
        .then((subscription) => subscription && changePushButtonState("enabled"))
        .catch((e) => {
            if (Notification.permission === "denied") {
                console.warn("Notifications are denied by the user.");
                changePushButtonState("incompatible");
            } else {
                console.error("Impossible to subscribe to push notifications", e);
                changePushButtonState("disabled");
            }
        });
}

function push_sendSubscriptionToServer(subscription, method) {
    const key = subscription.getKey("p256dh");
    const token = subscription.getKey("auth");
    const contentEncoding = (PushManager.supportedContentEncodings || ["aesgcm"])[0];

    // решение проблемы с преобразованием символов %, которые отдает Edge
    const endpoint = decodeURIComponent(subscription.endpoint);

    if (method == "PUT" || method == "DELETE") {
        return fetch(`${link}/push-subscription/by-endpoint?endpoint=` + subscription.endpoint, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response) => response.json())
            .then((res) => {
                if(res.data.id !== undefined){
                    return fetch(`${link}/push-subscription/` + res.data.id, {
                        method,
                        headers: {
                            Accept: "application/json",
                            "Content-type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                        body: JSON.stringify({
                            endpoint: endpoint,
                            public_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                            auth_token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                            contentEncoding,
                        }),
                    }).then(() => subscription);
                }
            })
    } else {
        return fetch(`${link}/push-subscription`, {
            method,
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                endpoint: endpoint,
                public_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                auth_token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                contentEncoding,
            }),
        }).then(() => subscription);
    }
}

function changePushButtonState(state) {
    switch (state) {
        case "enabled":
            localStorage.setItem("pushes", true);
            break;
        case "disabled":
            localStorage.setItem("pushes", false);
            break;
        case "computing":
            break;
        case "incompatible":
            break;
        default:
            console.error("Unhandled push button state", state);
            break;
    }
}

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function checkNotificationPermission() {
    return new Promise((resolve, reject) => {
        if (Notification.permission === "denied") {
            return reject(new Error("Push messages are blocked."));
        }

        if (Notification.permission === "granted") {
            return resolve();
        }

        if (Notification.permission === "default") {
            return Notification.requestPermission().then((result) => {
                if (result !== "granted") {
                    reject(new Error("Bad permission result"));
                } else {
                    resolve();
                }
            });
        }
        return reject(new Error("Unknown permission"));
    });
}

function push_updateSubscription() {
    navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => serviceWorkerRegistration.pushManager.getSubscription())
        .then((subscription) => {
            changePushButtonState("disabled");

            if (!subscription) {
                return;
            }

            return push_sendSubscriptionToServer(subscription, "PUT");
        })
        .then((subscription) => subscription && changePushButtonState("enabled"))
        .catch((e) => {
            console.error("Error when updating the subscription", e);
        });
}
