import { NavLink, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import InputMask from "react-input-mask";

import QuestionMarkSvg from "../../../components/login/svg/QuestionMarkSvg";
import { ButtonsGroup } from "../../../components/icons/ButtonsGroup";
import { LogoIcon } from "../../../components/icons/LogoIcon";
import { PostIcon } from "../../../components/icons/PostIcon";
import { UserIcon } from "../../../components/icons/UserIcon";
import ModalsContext from "../../../store/modals-context";
import { CSSTransition } from "react-transition-group";
import getHost from "../../../store/host-store";
import s from "./style.module.sass";

const RegistrationModal = () => {
    const modal = useContext(ModalsContext);
    const [phone, setPhone] = useState({ value: "", invalid: false, error: "" });
    const [name, setName] = useState({ value: "", invalid: false, error: "" });
    const [email, setEmail] = useState({ value: "", invalid: false, error: "" });
    const [promoModal, setPromoModal] = useState(false);
    const [promo, setPromo] = useState("");

    const location = useLocation();
    const referral = new URLSearchParams(location.search).get("ref");

    const onLogin = (e) => {
        e.preventDefault();
        window.ym(91815886, "reachGoal", "reg_ok");

        const data = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            referal_code: promo,
        };

        fetch(
            getHost({
                controller: "user",
                action: "sign-up",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    // modal.unauthModal();
                    modal.setLoginPhone(phone.value);
                    modal.codeAuthorization();
                } else {
                    showErrors(result.data);
                }
            });
    };

    function showErrors(errorsList) {
        setName({ ...name, invalid: false });
        setEmail({ ...email, invalid: false });
        setPhone({ ...phone, invalid: false });

        errorsList.forEach((error) => {
            switch (error.field) {
                case "name":
                    setName({ ...name, invalid: true, error: error?.message !== undefined ? error?.message : "" });
                    break;
                case "email":
                    setEmail({ ...email, invalid: true, error: error?.message !== undefined ? error?.message : "" });
                    break;
                case "phone":
                    setPhone({ ...phone, invalid: true, error: error?.message !== undefined ? error?.message : "" });
                    break;
                default:
            }
        });
    }

    return (
        <div className={s.modal}>
            <form onSubmit={onLogin} className={s.modal__top}>
                <div className={s.logo}>
                    <LogoIcon color={"#4088DD"}/>
                </div>
                <h2 className={s.title}>Регистрация</h2>
                <p className={s.descr}>Впервые пользуетесь услугами нашего сервиса? Введите данные ниже:</p>
                <div className={`${s.phone_wrapper} ${s.place_name}`}>
                    <UserIcon />
                    <input
                        type="text"
                        required
                        name="name"
                        placeholder="Имя/ИП/ООО *"
                        value={name.value}
                        onChange={(e) => setName({ ...name, value: e.target.value })}
                    />

                    {name.invalid && <span className={s.error}>{name?.error}</span>}
                </div>
                <div className={`${s.phone_wrapper} ${s.place_tel}`}>
                    <ButtonsGroup />
                    <InputMask
                        mask={"+7 (999) 999 99 99"}
                        placeholder="Телефон *"
                        maskChar={null}
                        name="inputMask"
                        inputMode="numeric"
                        value={phone.value}
                        onChange={(e) => {
                            setPhone({ ...phone, value: e.target.value });
                        }}
                    />
                    {phone.invalid && <span className={s.error}>{phone.error}</span>}
                </div>
                <div className={`${s.phone_wrapper} ${s.place_email}`}>
                    <PostIcon />
                    <input
                        type="email"
                        name="email"
                        required
                        placeholder="Email *"
                        value={email.value}
                        onChange={(e) => setEmail({ ...email, value: e.target.value })}
                    />
                    {email.invalid && <span className={s.error}>{email?.error}</span>}
                </div>

                {!referral && (
                    <div className={s.phone_wrapper}>
                        <div onClick={() => setPromoModal((prevState) => !prevState)}>
                            <div>
                                <QuestionMarkSvg />
                                <CSSTransition in={promoModal} timeout={200} unmountOnExit>
                                    <div className={s.promo_wrapper}>
                                        Введите реф.код друга, который уже работает на сервисе, и после первого
                                        пополнения кошелька Вам вдвоём начислят
                                        <br />
                                        <span> по 1000 бонусов!</span>
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                        <input
                            type="text"
                            name="promo"
                            placeholder="Реферальный код"
                            value={promo}
                            onChange={(e) => setPromo(e.target.value)}
                        />
                    </div>
                )}
                <button type="submit" className={s.btn_blue}>
                    Зарегистрироваться
                </button>
                <p className={s.terms}>
                    При регистрации Вы подтверждаете согласие с условиями
                    <br />
                    <NavLink to="/userTerm" target="_blank">
                        &nbsp;пользовательского соглашения&nbsp;
                    </NavLink>
                    и
                    <NavLink to="/personalData" target="_blank">
                        &nbsp;политикой обработки данных.
                    </NavLink>
                </p>
            </form>
            <div className={s.modal__bottom}>
                <p>У меня уже есть профиль:</p>
                <button type="button" className={s.btn_green} onClick={() => modal.unauthModal()}>
                    Войти
                </button>
            </div>
        </div>
    );
};

export default RegistrationModal;
