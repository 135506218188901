export const EditIcon = () => {
    return (
        <svg
            className="editIcon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3639 1.63604C14.2426 2.51472 14.2426 3.93934 13.3639 4.81802L5.05412 13.1278C4.69368 13.4882 4.24204 13.7439 3.74752 13.8676L1.17014 14.5119C0.758177 14.6149 0.385019 14.2418 0.48801 13.8298L1.13235 11.2524C1.25599 10.7579 1.51169 10.3063 1.87214 9.94581L10.1819 1.63604C11.0606 0.757359 12.4852 0.757359 13.3639 1.63604ZM9.38633 4.0224L2.66764 10.7413C2.45137 10.9576 2.29794 11.2285 2.22376 11.5253L1.8068 13.1931L3.47466 12.7762C3.77138 12.702 4.04236 12.5486 4.25863 12.3323L10.9771 5.61315L9.38633 4.0224ZM10.9774 2.43153L10.1817 3.22703L11.7725 4.81778L12.5684 4.02252C13.0077 3.58318 13.0077 2.87087 12.5684 2.43153C12.1291 1.99219 11.4167 1.99219 10.9774 2.43153Z"
                fill="#212121"
            />
        </svg>
    );
};
