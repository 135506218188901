import {useEffect} from "react";
import LaunchEmptyTop from "./LaunchEmptyTop";
import style from "../../cabinet/subscribe/subscribe.module.sass";
import LaunchBottomEmpty from "./LaunchBottomEmpty";
import {statusAction} from "../../store/redux";
import {useGetAdversByStatusActiveQuery} from "../../store/redux/queries/launch-advertisement";
import Loader from "../../components/loader/Loader";
import AdversItems from "../ads/AdversItems";
import {useDispatch} from "react-redux";

const LaunchAds = () => {
    const {data, isLoading} = useGetAdversByStatusActiveQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(statusAction.toggleStatus(1));
    }, [])

  return(
      <div className='cabinet__redesign_container'>
          <h3 className='cabinet__redesign_title'>Реклама</h3>
          <div className={style.promotion_block}>
              <LaunchEmptyTop />
              {isLoading ? <Loader/> :
                  data?.data.length === 0 ? <LaunchBottomEmpty/> :
                      <div className="loads_advers advers_wrap">
                          <AdversItems arr={data?.data} launchView={true}/>
                      </div>
              }
          </div>
      </div>
  )
}

export default LaunchAds;