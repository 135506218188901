const DeleteDesktopSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="none">
            <circle cx="14" cy="14" r="14" fill="#D32121" />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M11.5 9.167c0-.645.56-1.167 1.25-1.167h2.5c.69 0 1.25.522 1.25 1.167v.499h1.25c.69 0 1.25.522 1.25 1.167v1c0 .276-.24.5-.536.5h-.178v6.5c0 .645-.56 1.167-1.25 1.167h-6.072c-.69 0-1.25-.522-1.25-1.167v-6.5h-.178c-.296 0-.536-.224-.536-.5v-1c0-.645.56-1.167 1.25-1.167h1.25v-.5Zm3.929.499H12.57v-.5c0-.091.08-.166.179-.166h2.5c.099 0 .179.075.179.167v.499Zm-3.424 1H10.25c-.099 0-.179.075-.179.167v.5H17.93v-.5c0-.092-.08-.167-.179-.167h-5.745Zm-1.22 1.667v6.5c0 .092.08.167.18.167h6.07c.1 0 .18-.075.18-.167v-6.5h-6.43Zm1.43 1c.295 0 .535.224.535.5V17.5c0 .276-.24.5-.536.5-.296 0-.535-.224-.535-.5v-3.667c0-.276.24-.5.535-.5Zm1.785 0c.296 0 .536.224.536.5V17.5c0 .276-.24.5-.536.5-.296 0-.536-.224-.536-.5v-3.667c0-.276.24-.5.536-.5Zm1.786 0c.296 0 .535.224.535.5V17.5c0 .276-.24.5-.535.5-.296 0-.536-.224-.536-.5v-3.667c0-.276.24-.5.536-.5Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default DeleteDesktopSvg;