import TimeBigSvg from "../../../cabinet/advertisement/desktopPages/svg/TimeBigSvg";
import moment from "moment/moment";

const AdvStatistic = ({final_date, tg, views, views_new, tg_new, status}) => {
    const currentDate = moment();
    const endDate = moment(final_date);
    const difference = moment.duration(endDate.diff(currentDate));

    const days = Math.floor(difference/(1000*60*60*24));
    const hours = Math.floor((difference % (1000*60*60*24))/
        (1000*60*60))

    function createLabel(number, titles) {
        const cases = [2, 0, 1, 1, 1, 2];
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
    }

    return(
      <div className={`adv_statistic ${status === 2 ? 'adv_statistic_declined' : ''}`}>
          {status !== 2 ? (
              <>
                  <div className="adv_statistic_time">
                      <TimeBigSvg />
                      <span>Осталось:{days !== 0 ? <span>{days} {createLabel(days, ['день', 'дня', 'дней'])}</span> : ''}
                          <span>{hours} {createLabel(hours, ['час', 'часа', 'часов'])}</span>
            </span>
                  </div>
                  {/*<div className="adv_statistic_block">*/}
                  {/*    <div className="adv_statistic_title">Статистика: </div>*/}
                  {/*    <div className="adv_statistic_views">*/}
                  {/*        <AdvViewsSvg />*/}
                  {/*        <span>{views} <span className="green">+ {views_new}</span></span>*/}
                  {/*    </div>*/}
                  {/*    <div className="adv_statistic_telegram">*/}
                  {/*        <AdvTelegramSvg />*/}
                  {/*        <span>{tg} <span className="green">+ {tg_new}</span></span>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
              </>)
              : (
                  <p>Рекламное объявление не прошло модерацию</p>
              )
              }
      </div>
  )
}

export default AdvStatistic;