import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import accConf from "./images/confirmAcc.svg";
import {NavLink} from "react-router-dom";

export const AccountNeedConfirmModal = () => {
    const ctx = useContext(ModalsContext);

  return(
      <div className="modal modalOrdered modal_account_confirmed">
          <div className="closeModal" onClick={ctx.closeModal}>
              <ModalCloseSvg />
          </div>
            <div className="title_account_confirmed">Аккаунт должен быть подтверждён</div>
          <img className="acc_img_modal" src={accConf} alt=""/>
          <p className="acc_modal_conf_text">Для осуществления вывода средств с вашего баланса, необходимо иметь подтверждённый аккаунт.</p>
          <NavLink type="button" className="acc_btn_blue" to='/advertiser' onClick={ctx.closeModal}>Подтвердить</NavLink>
      </div>
  )
}