import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    choosingCheckboxes: [],
    rate: 0,
};

const promotion = createSlice({
    name: "promotion",
    initialState,
    reducers: {
        resetCheckboxes(state) {
            state.choosingCheckboxes = [];
        },
        getRate(state, action) {
            state.rate = action.payload;
        },
        changeCheckboxes(state, action) {
            const id = action.payload;
            state.choosingCheckboxes = state.choosingCheckboxes.includes(id)
                ? state.choosingCheckboxes.filter((el) => el !== id)
                : [...state.choosingCheckboxes, id];
        },
    },
});

export default promotion;
