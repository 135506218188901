import VerificationInput from "react-verification-input";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import InputMask from "react-input-mask";

import { ButtonsGroup } from "../icons/ButtonsGroup";
import getHost from "../../store/host-store";
import { UserIcon } from "../icons/UserIcon";
import { PostIcon } from "../icons/PostIcon";
import s from "./style.module.sass";
import "./login.sass";

const Registration = (props) => {
    const [name, setName] = useState({ value: "", invalid: false, error: "" });
    const [email, setEmail] = useState({ value: "", invalid: false, error: "" });
    const [phone, setPhone] = useState({ value: "", invalid: false, error: "" });
    const [invalidCode, setInvalidCode] = useState(false);
    const [modal, setModal] = useState(false);

    const [currentPage, setCurrentPage] = useState("register");
    const [code, setCode] = useState("");

    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(setSeconds, 1000, seconds - 1);
        }
    }, [seconds]);

    useEffect(() => {
        if (code.length < 4) return;
        onLogin();
    }, [code]);

    const onRegister = (e) => {
        e.preventDefault();

        const data = {
            name: name.value,
            phone: phone.value,
            email: email.value,
            referal_code: props.referral,
        };

        fetch(
            getHost({
                controller: "user",
                action: "sign-up",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setCurrentPage("code");
                } else {
                    showErrors(result.data);
                }
            })
            .catch((e) => console.log(e));
    };

    function showErrors(errorsList) {
        setPhone({ ...phone, invalid: false });
        setName({ ...name, invalid: false });
        setEmail({ ...email, invalid: false });

        errorsList.forEach((error) => {
            switch (error.field) {
                case "phone":
                    setPhone({ ...phone, invalid: true, error: error.message });
                    break;
                case "email":
                    setEmail({ ...email, invalid: true, error: error.message });
                    break;
                case "name":
                    setName({ ...name, invalid: true, error: error.message });
                    break;
                default:
            }
        });
    }
    const onLogin = () => {
        window.ym(91815886, "reachGoal", "reg_ok");
        setInvalidCode(false);

        const data = {
            phone: phone.value,
            code,
        };

        fetch(
            getHost({
                controller: "user",
                action: "phone-login",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    localStorage.setItem("token", result.data.access_token);
                    window.location.replace("/");
                } else {
                    setInvalidCode(true);
                }
            })
            .catch((e) => console.log(e));
    };

    const getCode = (e) => {
        e.preventDefault();
        setCode("");

        fetch(
            getHost({
                controller: "user",
                action: "get-code",
                phone: phone.value,
            }),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    setSeconds(30);
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="login__registration" onSubmit={onLogin}>
            {currentPage === "register" && (
                <form>
                    <div className="login__box">
                        <div className="input required">
                            <div className="icon">
                                <UserIcon />
                            </div>
                            <input
                                type="text"
                                required
                                name="name"
                                placeholder="Имя/ИП/ООО *"
                                value={name.value}
                                onChange={(e) => setName({ ...name, value: e.target.value })}
                            />

                            {name.invalid && <span className="error">{name?.error}</span>}
                        </div>
                        <div className="input requiredTel">
                            <div className="icon">
                                <ButtonsGroup />
                            </div>

                            <InputMask
                                mask="+7 (999) 999 99 99"
                                required
                                name="inputMask"
                                inputMode="decimal"
                                maskChar={null}
                                placeholder="Телефон *"
                                value={phone.value}
                                onChange={(e) => setPhone({ ...phone, value: e.target.value })}
                            />
                            {phone.invalid && <span className="error">{phone?.error}</span>}
                        </div>
                        <div className="input requiredEmail">
                            <div className="icon">
                                <PostIcon />
                            </div>

                            <input
                                type="email"
                                name="email"
                                required
                                placeholder="Email *"
                                value={email.value}
                                onChange={(e) => setEmail({ ...email, value: e.target.value })}
                            />
                            {email.invalid && <span className="error">{email?.error}</span>}
                        </div>
                    </div>
                    <p className="terms">
                        При регистрации Вы подтверждаете согласие с условиями <br />
                        <NavLink to="/userTerm" target="_blank">
                            &nbsp;пользовательского соглашения&nbsp;
                        </NavLink>
                        и
                        <NavLink to="/personalData" target="_blank">
                            &nbsp;политикой обработки данных.
                        </NavLink>
                    </p>
                    <button className="login__btn" onClick={onRegister} type="submit">
                        Регистрация
                    </button>
                </form>
            )}
            {currentPage === "code" && (
                <form className="auth-code">
                    <h3>Авторизация</h3>
                    <div className={s.auth}>
                        <div className={s.nums}>
                            <VerificationInput
                                autoFocus
                                placeholder={""}
                                validChars="0-9"
                                length={4}
                                value={code}
                                onChange={setCode}
                                classNames={inputStyles}
                            />
                            {invalidCode && <span className={s.error}>Неверный код</span>}
                        </div>
                        <p className={s.get_code} onClick={!seconds ? getCode : undefined}>
                            Отправить код повторно <span>({seconds} сек.)</span>
                        </p>
                        <button className={s.btn_blue} onClick={onLogin} type="button">
                            Вход
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};
const inputStyles = {
    container: s.auth_container,
    character: s.auth_input,
};
export default Registration;
