import {createSlice} from "@reduxjs/toolkit";

let initialState = {
    referralToggle: false,
    agreementID: undefined
}

const referralSlice = createSlice({
    name: "referral",
    initialState,
    reducers: {
        toggleReferral(state, action){
            state.referralToggle = !action.payload
        },
        getAgreementId(state, action){
            state.agreementID = action.payload
        },
    }
})

export default referralSlice;