import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import CustomScrollbar from "../../../UI/Scrollbar/CustomScrollbar";
import ObjectsTypes from "../../../UI/ObjectsTypes/ObjectsTypes";
import PaginationPattern from "../../../UI/PaginationPattern";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import ArrowBack from "../../request/svg/ArrowBack";
import { link } from "../../../store/host-store";
import ChooseButton from "../ChooseButoon";
import { token } from "../../../App";
import s from "../style.module.sass";
import Object from "./Object";

const ChoosePage = () => {
    const [category, setCategory] = useState([]);
    const [objects, setObjects] = useState([]);
    const [type, setType] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const checkboxArr = useSelector((state) => state.promotion);
    const ctx = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(`${link}/category/account-list`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.data) {
                    setCategory(result.data);
                }
            });
    }, []);

    useEffect(() => {
        localStorage.setItem("promo", "item=" + checkboxArr.choosingCheckboxes.map((item) => item));
    }, [checkboxArr]);

    useEffect(() => {
        // добавить фильтрацию по полю продвижение
        fetch(
            `${link}/object/user-objects?filter[type]=${type}&filter[user_id]=${ctx.isUserId}&expand=city&pagination[pageSize]=10&page=${page}`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                if (result.data.success) {
                    setPageCount(parseInt(result.headers.get("X-Pagination-Page-Count")));
                    setObjects(result.data.data);
                    setLoading(false);
                }
            });
    }, [type, page]);

    return (
        <div className="cabinet__redesign_container">
            <div className="flex">
                <NavLink to="/lc/promotion" className="margR6">
                    <ArrowBack />
                </NavLink>
                <h3 className="cabinet__redesign_title">Выберите объявления</h3>
            </div>
            <div className={`${s.container} ${s.objects_container}`}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={s.types}>
                            <h6 className="cabinet__redesign_subtitle">Категории:</h6>
                            <ObjectsTypes toggleCategory={setType} type={type} category={category} setPage={setPage} />
                        </div>
                        <div className={s.objects_list}>
                            <div>
                                {objects.length > 0 ? (
                                    objects.map((item) => {
                                        return <Object item={item} key={item.id} />;
                                    })
                                ) : (
                                    <p>В данной категории нет объявлений на продвижении</p>
                                )}
                            </div>
                        </div>
                        <div className={s.pagination}>
                            <PaginationPattern
                                pageCount={pageCount}
                                setChange={(e, value) => setPage(value)}
                                page={page}
                            />
                        </div>
                        <div className={s.bottom__button}>
                            {checkboxArr.choosingCheckboxes.length > 0 && (
                                <ChooseButton title="Продолжить" to="/lc/promotion/rate" />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ChoosePage;
