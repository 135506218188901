import styles from "../../subscribe/subscribe.module.sass";
import RocketSvg from "../../../adRole/launch/svg/RocketSvg";
import AccessPeopleSvg from "./svg/AccessPeopleSvg";
import TimeCheckSvg from "./svg/TimeCheckSvg";

const StubAdsTop = () => {
  return(
      <div className={styles.promotion_top}>
          <div className={styles.promotion_left} style={{justifyContent: "space-between", width: "100%"}}>
              <div className={styles.promotion_left_block}>
                  <AccessPeopleSvg />
                  <span className={styles.promotion_number}>01</span>
                  <p style={{marginLeft: "38px"}} className={`${styles.promotion_top_p} ${styles.prom_top_p}`}>
                      Зарегистрируйтесь <br/>
                      и введите все <br/> необходимые данные
                  </p>
              </div>
              <div className={styles.promotion_left_block}>
                  <TimeCheckSvg />
                  <span className={styles.promotion_number}>02</span>
                  <p style={{marginLeft: "38px"}} className={styles.promotion_top_p}>
                      Дождитесь проверки <br/> предоставленных <br/> данных
                  </p>
              </div>
              <div className={styles.promotion_left_block}>
                  <RocketSvg />
                  <span className={styles.promotion_number}>03</span>
                  <p style={{marginLeft: "38px"}} className={styles.promotion_top_p}>
                      Запустите рекламную <br/> компанию на сервисе <br/> CityWORK
                  </p>
              </div>
          </div>


      </div>
  )
}

export default StubAdsTop;