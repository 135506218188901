import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";

import Loader from "../../components/loader/Loader";
import AuthContext from "../../store/auth-context";
import {checkboxAction, promoAction} from "../../store/redux";
import { link } from "../../store/host-store";
import ObjectsList from "./main/ObjectsList";
import ChooseButton from "./ChooseButoon";
import s from "./style.module.sass";
import { token } from "../../App";
import Empty from "./main/Empty";

const PromoPage = () => {
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);
    const [objects, setObjects] = useState([]);
    const [type, setType] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [page, setPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState(true);

    const ctx = useContext(AuthContext);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkboxAction.resetStates())
        window.scrollTo(0, 0);

        dispatch(promoAction.resetCheckboxes());

        // категории (типы)
        fetch(`${link}/category/account-list`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.data) {
                    setCategory(result.data);
                }
            });

        // проверка есть ли у пользователя какие-то объявления на продвижении
        fetch(`${link}/object/user-objects?filter[user_id]=${ctx.isUserId}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.data.length > 0) {
                    setIsEmpty(false);
                }
            });
    }, []);

    useEffect(() => {
        // получаем объявления, которые находятся на продвижении
        fetch(`${link}/object/on-promotion?expand=city&filter[type]=${type}&pagination[pageSize]=10&page=${page}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                if (result.data.success) {
                    setPageCount(parseInt(result.headers.get("X-Pagination-Page-Count")));
                    setObjects(result.data.data);
                    setLoading(false);
                    console.log(result.data.data)
                }
            });
    }, [type, page]);

    return (
        <div className="cabinet__redesign_container">
            <h3 className="cabinet__redesign_title">Продвижение</h3>
            <div className={`${s.container} ${s.objects_container}`}>
                <div className={s.top}>
                    <div className={s.steps}>
                        <div className={s.step}>
                            <span>01</span>
                            <p>Выберите объявление, которые Вы хотите продвигать</p>
                        </div>
                        <div className={s.step}>
                            <span>02</span>
                            <p>Укажите способ продвижения, подходящий Вам</p>
                        </div>
                    </div>
                    <ChooseButton title="Выбрать объявление" to="/lc/promotion/choose" />
                </div>
                {loading ? (
                    <Loader />
                ) : isEmpty ? (
                    <Empty />
                ) : (
                    <ObjectsList
                        type={type}
                        setType={setType}
                        objects={objects}
                        category={category}
                        pageCount={pageCount}
                        page={page}
                        setPage={setPage}
                    />
                )}
            </div>
        </div>
    );
};

export default PromoPage;
