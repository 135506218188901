export const SupportIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C2.69164 0 0 2.69164 0 6C0 9.30836 2.69164 12 6 12C9.30836 12 12 9.30836 12 6C12 2.69164 9.30833 0 6 0ZM6.39028 9.55995C6.10523 9.60747 5.53802 9.72615 5.24999 9.75C5.00619 9.77019 4.77612 9.63063 4.63587 9.43029C4.49525 9.22998 4.46156 8.97363 4.54504 8.74365L5.67919 5.62501H4.49999C4.49898 4.97517 4.98641 4.49736 5.60983 4.31398C5.90734 4.22645 6.46163 4.10666 6.74998 4.12502C6.92274 4.13601 7.22386 4.24439 7.36411 4.44473C7.50473 4.64504 7.53842 4.9014 7.45494 5.13138L6.32079 8.25002H7.49962C7.49985 8.8991 7.0306 9.45323 6.39028 9.55995ZM6.74998 3.75001C6.33576 3.75001 5.99998 3.41418 5.99998 3C5.99998 2.58577 6.33576 2.24999 6.74998 2.24999C7.16421 2.24999 7.49999 2.58577 7.49999 3C7.49999 3.4142 7.16423 3.75001 6.74998 3.75001Z"
                fill="white"
            />
        </svg>
    );
};
