import RateCard from "./card/RateCard";

import eye from "../../../assets/images/promotion/rate-eye.webp";
import fix from "../../../assets/images/promotion/rate-fix.webp";
import s from "./style.module.sass";

const Promotion = () => {
    return (
        <div className="admin__layout">
            <h3>Продвижение</h3>
            <div className={s.wrapper}>
                <RateCard title="Показы" src={eye} to="edit/shows" />
                <RateCard title="Закрепление" src={fix} fix to="edit/fix" />
            </div>
        </div>
    );
};

export default Promotion;
