import moment from "moment";

const Marks = ({ data }) => {
    return (
        <>
            <div className="admin__list-titles admin__list-titles_statistic_mark">
                <p>ID</p>
                <p>Текст отзыва</p>
                <p>Изменено</p>
                <p>Статус</p>
            </div>
            <div>
                {data.map((el) => (
                    <div className="admin__objects_object admin__objects_object_statistic_mark" key={el.id}>
                        <span>#{el.object?.id}</span>
                        <p>{el.object?.comment}</p>
                        <p>{moment(el.date).format("DD.MM.YYYY")}</p>
                        {el.status === 1 ? (
                            <p className="admin__done">Подтверждено</p>
                        ) : el.status === 2 ? (
                            <p className="admin__rejected">Отклонено</p>
                        ) : (
                            ""
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default Marks;
