export const ResponseIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.508 0.491916C12.2918 0.274641 12.0199 0.121084 11.7222 0.048067C11.4245 -0.0249505 11.1124 -0.0146128 10.8202 0.0779478L1.17472 3.10985C0.845929 3.21287 0.556885 3.41477 0.346956 3.68802C0.137027 3.96128 0.0164186 4.29263 0.00155954 4.63692C-0.0132995 4.98122 0.0783132 5.32172 0.263919 5.61206C0.449524 5.9024 0.720099 6.12845 1.03879 6.25943L4.62048 7.73483L6.39853 5.95642C6.48519 5.87565 6.59982 5.83168 6.71825 5.83377C6.83669 5.83586 6.94969 5.88385 7.03345 5.96762C7.11721 6.0514 7.16519 6.16442 7.16728 6.28288C7.16937 6.40134 7.1254 6.51599 7.04465 6.60267L5.2666 8.38109L6.74171 11.9635C6.86615 12.2712 7.08007 12.5345 7.35576 12.7193C7.63146 12.9041 7.95626 13.0019 8.28813 13H8.36371C8.70843 12.9873 9.04066 12.8677 9.31428 12.6576C9.5879 12.4475 9.78934 12.1574 9.89063 11.8276L12.9219 2.1801C13.0146 1.88783 13.025 1.57567 12.952 1.27788C12.879 0.980079 12.7254 0.708148 12.508 0.491916Z"
                fill="white"
            />
        </svg>
    );
};
