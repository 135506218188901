import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { useState, useEffect } from "react";
import axios from "axios";

import { DropdownIndicator } from "../../../components/header/headerTop/headerSelects/selects";
import { customStyles2 } from "../../../components/header/headerTop/headerSelects/selects";
import InputPhoto from "../../../cabinet/advertisement/InputPhoto";
import { ArrowLeftIcon } from "../../../components/icons/ArrowLeftIcon";
import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import getHost, { link, url } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import { token } from "../../../App";
import s from "./style.module.sass";

const PartnersEdit = () => {
    const [select, setSelect] = useState([]);
    const [success, setSuccess] = useState(false);
    const [title, setTitle] = useState("");
    const [linkInput, setLinkInput] = useState("");
    //текущее и добавленое изображения
    const [currentImages, setCurrentImages] = useState(null);
    const [downloadImages, setDownloadImages] = useState([]);

    const history = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(
            getHost({
                controller: "partner",
                expand: "section",
                action: params.id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);

                if (result.success) {
                    setTitle(result.data.title);
                    setLinkInput(result.data.link);
                    setCurrentImages(result.data.image);
                    setSelect({ label: result.data.section.title, value: result.data.section.id });
                }
            });
    }, [params.id]);

    const loadOptions = (inputValue, callback) => {
        let options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`partner-section`)
            .then((response) => {
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.title,
                        value: permission.id,
                    });
                    callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
                });
            });
    };

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();

        let selectedImg =
            downloadImages.length > 0
                ? downloadImages[0]
                : currentImages !== undefined && currentImages !== null
                ? currentImages[0]
                : null;

        formData.append("title", title);
        formData.append("section_id", select.value);
        formData.append("link", linkInput);
        formData.append("image", selectedImg);

        axios
            .put(
                getHost({
                    controller: "partner",
                    action: params.id,
                }),
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
            .then((result) => {
                if (result.data.success) {
                    setSuccess(true);
                }
            });
    };

    const deleteCurrentImage = (e) => {
        if (currentImages === undefined) {
            return;
        }
        e.preventDefault();
        setCurrentImages(null);
    };

    const onDelete = () => {
        fetch(
            getHost({
                controller: "partner",
                action: params.id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
                method: "DELETE",
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    history(-1);
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="admin__layout">
            {loading ? (
                <Loader />
            ) : (
                <div className={s.create}>
                    <div className={s.create__top_wrapper}>
                        <div className={s.left}>
                            <div className="admin__back-arrow" onClick={() => history(-1)}>
                                <ArrowLeftIcon />
                            </div>
                            <h3>Редактировать партнёра</h3>
                        </div>
                        <div className={s.right}>
                            <button type="button" className="admin__btn-blue" onClick={submitHandler}>
                                Сохранить
                            </button>
                            <button type="button" className="admin__btn-transparent" onClick={onDelete}>
                                Удалить
                            </button>
                            {success && (
                                <div className={s.success}>
                                    <span className="admin__success">Изменения сохранены</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={s.photo}>
                        {currentImages !== undefined && currentImages !== null && (
                            <div className="imagesMultiple">
                                <div className="input_foto_wrap">
                                    <img src={url + currentImages} alt="" />
                                    <button title="назад" type="button" onClick={(e) => deleteCurrentImage(e)}>
                                        <DeleteIcon />
                                    </button>
                                </div>
                            </div>
                        )}
                        {currentImages === null && (
                            <InputPhoto
                                images={downloadImages}
                                onLoad={setDownloadImages}
                                onDelete={setDownloadImages}
                                limitText={"Вы можете добавить 1 фото."}
                            />
                        )}
                    </div>

                    <div className={s.inputs_wrapper}>
                        <input
                            className={s.input}
                            type="text"
                            value={title}
                            placeholder="Название партнера"
                            onChange={(e) => setTitle(e.currentTarget.value)}
                        />

                        <input
                            className={s.input}
                            type="text"
                            value={linkInput}
                            placeholder="Ссылка"
                            onChange={(e) => setLinkInput(e.currentTarget.value)}
                        />

                        <div className={s.selects}>
                            <AsyncSelect
                                key={select}
                                components={{ DropdownIndicator }}
                                placeholder={"Секция"}
                                cacheOptions
                                defaultOptions
                                onChange={setSelect}
                                value={select}
                                styles={customStyles2}
                                loadOptions={loadOptions}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PartnersEdit;
