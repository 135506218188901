import "../cabinet.sass";
import { ImageIcon } from "../../components/icons/ImageIcon";
import { DeleteIcon } from "../../components/icons/DeleteIcon";
import uuid from "react-uuid";
import {useEffect, useState} from "react";
import {url} from "../../store/host-store";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

let newFiles = [];
const InputPhoto = ({ images, onLoad, onDelete, maxCount = 1, limitText, id = "select_img", custom, avatar}) => {
    const [selectedFiles, setSelectedFiles] = useState([])
    let [updatedSelectedFiles, setUpdatedSelectedFiles] = useState([])
    const [toggleAvatar, setToggleAvatar] = useState(true);
    const advertiserPage = window.location.href.indexOf('advertiser') > -1;
    const handleMultipleImages = (event) => {
        newFiles = [...event.target.files].slice(0, maxCount);
        // Добавить новые файлы к selectedFiles
        updatedSelectedFiles = [...selectedFiles, ...newFiles].slice(0, maxCount);
        // Обновить state
        if(updatedSelectedFiles?.length < maxCount){
            setSelectedFiles(updatedSelectedFiles)
        }
            onLoad(updatedSelectedFiles);
        if(custom){
            setToggleAvatar(false)
        }
    };


    useEffect(() => {
        setSelectedFiles([])
    }, [])


    const deleteImg = (e, url) => {
        e.preventDefault();
        onDelete(images?.filter((urls) => urls !== url));
        setSelectedFiles(images?.filter((urls) => urls !== url))
    };


    const valueRefreshHandler = (event) => {
        event.currentTarget.value = null;
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            // Item was dropped outside of drop zone
            return;
        }

        const { source, destination } = result;

        // Clone the `images` array to avoid mutating the original state
        const newImages = [...images];

        // Remove the dragged item from its original position
        const [draggedItem] = newImages.splice(source.index, 1);

        // Insert the dragged item at the new position
        newImages.splice(destination.index, 0, draggedItem);

        // Update the state with the reordered array
        images = newImages
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
        <div className="input_foto_wrap">
            {!custom &&
                <div className="input_photo_text">
                    {!advertiserPage &&
                        <>
                            <span>* </span>Фото не должно содержать контактные данные и прочую информацию, которая нарушает правила
                            сервиса CityWork.
                        </>
                    }
                    <span className="db mt14">{limitText}</span>
                </div>
            }
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
            <div className="imagesMultiple imagesMultiple2">
                    {images?.length !== 0 ? images?.map((file, index) => {
                    return        (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                        <img src={URL.createObjectURL(file)} alt="" />
                        <button title="удалить" type="button" onClick={(e) => deleteImg(e, file)}>
                            <DeleteIcon />
                        </button>
                    </div>)}
                            </Draggable>

                    )
                    }) : ''}
                {provided.placeholder}
                {(custom && avatar) &&
                    images?.length === 0 || images?.length === undefined &&
                    <div key={uuid()}>
                        <img src={url + avatar} alt=""/>
                    </div>
                }
            </div>
                    </div>)}
                    </ Droppable>

            {((selectedFiles?.length === 0 || !advertiserPage) && images?.length < maxCount) &&
            <div className={`input_foto_div ${images?.length > 0 && "true"}`}>
                <input
                    accept="image/*"
                    type="file"
                    id={id}
                    style={{ display: "none" }}
                    name="images[]"
                    multiple
                    onInput={handleMultipleImages}
                    onClick={valueRefreshHandler}
                />
                <label htmlFor={id} className="input_foto">
                    {custom ? <h5>
                        <ImageIcon/>
                            Выберите фотографию
                    </h5>
                        :
                        <h5>
                        <ImageIcon/>
                            Добавить <br/> фото
                        </h5>}
                    </label>
            </div>
            }
        </div>
        </DragDropContext>
    );
};

export default InputPhoto;
