import {customStyles2, DropdownIndicator} from "../../components/header/headerTop/headerSelects/selects";
import {useGetAbbreviationsQuery} from "../../store/redux/queries/abbreviation-query";
import AsyncSelect from "react-select/async";
import uuid from "react-uuid";

export const AdvertiserAbbreviation = ({setAbbreviation, abbreviation}) => {
    const {data} = useGetAbbreviationsQuery()
    let options = []
    const loadCategoryParent = (inputValue, callback) => {
        data?.data.map((item) => {
            options.push({
                label: item.title,
                value: item.id,
        });
            callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
        });
    };

  return(
      <div style={{marginBottom: '10px'}}>
      <div
          className="adv_cabinet_title">Аббревиатура:
      </div>
          <AsyncSelect
              key={uuid()}
              className="selects"
              styles={customStyles2}
              components={{ DropdownIndicator }}
              cacheOptions
              defaultOptions
              loadOptions={loadCategoryParent}
              placeholder={"Выбрать аббревиатуру"}
              value={abbreviation}
              onChange={(value) => setAbbreviation(value)}
          />
      </div>
  )
}