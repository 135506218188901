import style from '../subscribe.module.sass'
import SubscribeTop from "./SubscribeTop";
import SubscribeBottomEmpty from "./SubscribeBottomEmpty";
import {useEffect, useState} from "react";
import SubscribeBottom from "./SubscribeBottom";
import {link} from "../../../store/host-store";
import {token} from "../../../App";
import Loader from "../../../components/loader/Loader";


const SubscribePage = () => {
    const [subscribeArray, setSubscribeArray] = useState([]);
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`${link}/subscription/cities`, {
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setSubscribeArray(result.data)
                setIsLoading(false)
            });
    }, [reload])

    return(
        <div className='cabinet__redesign_container'>
            <h3 className='cabinet__redesign_title'>Мои подписки</h3>
            <div className={style.promotion_block}>
                <SubscribeTop />
                {!isLoading && subscribeArray.length === 0 && <SubscribeBottomEmpty />}
                {isLoading ? <Loader /> : subscribeArray.length > 0 && <SubscribeBottom subscribeArray={subscribeArray} setReload={setReload}/>}
            </div>
        </div>
    )
}

export default SubscribePage;