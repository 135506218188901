import AdvertiserAside from "./aside/AdvertiserAside";
import {Route, Routes} from "react-router-dom";
import AdminInfo from "../admin/main/AdminInfo";
import AdvertiserMainPage from "./main/AdvertiserMainPage";
import {useContext} from "react";
import DeviceContext from "../store/device-context";
import AdsMainPage from "./ads/AdsMainPage";
import CreateAds from "./ads/createAds/CreateAds";
import LaunchAds from "./launch/LaunchAds";
import LaunchItems from "./launch/LaunchItems";
import LaunchAdsBuy from "./launch/LaunchAdsBuy";
import ModalsContext from "../store/modals-context";

const Advertiser = () => {
    const ctx = useContext(DeviceContext);
    const modal = useContext(ModalsContext);

    return(
        <section className="container cabinet__content" style={{minHeight: "inherit"}}>
            {ctx.openAside && ctx.isMobile && <AdvertiserAside />}
            {window.innerWidth <= 1025 && (
                <button type="button" className="open-admin-menu open_adv_cabinet" onClick={modal.toggleMenu}>
                    <div className="line">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    Меню
                </button>
            )}
            <div className="cabinet__box cab_box_column">
                    {!ctx.isMobile && <AdvertiserAside />}
                <div className="advertiser1" style={{width: "100%"}}>
                    <Routes>
                        <Route path="/" element={<AdvertiserMainPage />}></Route>
                        <Route path="/ad" element={<AdsMainPage />}></Route>
                        <Route path="ad/create" element={<CreateAds />}></Route>
                        <Route path="ad/edit/:id" element={<CreateAds />}></Route>
                        <Route path="launch" element={<LaunchAds />}></Route>
                        <Route path="launch/ads" element={<LaunchItems />}></Route>
                        <Route path="launch/ads_items" element={<LaunchAdsBuy />}></Route>
                    </Routes>
                </div>
            </div>
        </section>
    )
}

export default Advertiser;