import { useLocation } from "react-router-dom";
import { useState } from "react";

import style from "../style.module.sass";
import ObjectsList from "./ObjectsList";
import RequestList from "./RequestList";
import { useEffect } from "react";

const ObjectsWithRequests = ({ mobileVisibleCart, setMobileVisibleCart, type }) => {
    const location = useLocation();

    // необходимо для подсвечивания карточки, если перешли с конкретной заявки
    const [objectId, setObjectId] = useState(location.state === null ? 0 : location.state.objectId);
    // пришлось поднять, чтобы сбрасывать при переключении типов
    const [page, setPage] = useState(1); // Страница пагинации объявлений
    const [responseTab, setResponseTab] = useState(0); // 0 - active, 1 - archive

    useEffect(() => {
        //если не перешли со страницы с заявками, то при смене табов сбрасывать ид объявления, чтобы заявки скрывались
        if (location.state === null) {
            setObjectId(0);
            setPage(1);
            setResponseTab(0);
        }
    }, [type]);

    return (
        <div className={style.response__list_contractor}>
            <div className={style.response__objects}>
                <h6 className="cabinet__redesign_subtitle">Объявления:</h6>
                <ObjectsList
                    setObjectId={setObjectId}
                    setMobileVisibleCart={setMobileVisibleCart}
                    objectId={objectId}
                    type={type}
                    page={page}
                    setPage={setPage}
                />
            </div>
            <RequestList
                activeCard={location.state !== null && location.state.orderId}
                objectId={objectId}
                responseTab={responseTab}
                setResponseTab={setResponseTab}
                mobileVisibleCart={mobileVisibleCart}
            />
        </div>
    );
};

export default ObjectsWithRequests;
