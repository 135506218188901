import Select from "react-select";
import {customStyles2} from "../../../components/header/headerTop/headerSelects/selects";

export const FilterRole = ({roleInput, setRoleInput}) => {

    const options = [
        { value: "admin", label: "Главный администратор" },
        { value: "user", label: "Пользователь" },
        { value: "moderator", label: "Модератор" },
        { value: "tech", label: "Администратор" },
        { value: "ad_moderator", label: "Модератор рекламы"},
        { value: "advertiser", label: "Рекламодатель"},
        { value: "fin_admin", label: "Финансовый администратор"},
        { value: "registrar", label: "Регистратор объявлений"},
    ]

    console.log(roleInput?.value)

  return(
      <div style={{marginTop: "10px"}}>
      <Select
        styles={customStyles2}
        placeholder="Поиск по роли"
        options={options}
        value={roleInput}
        onChange={setRoleInput}
      />
      </div>
  )
}