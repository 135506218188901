import LocationBigSvg from "../../../cabinet/advertisement/desktopPages/svg/LocationBigSvg";
import {useContext} from "react";
import DeviceContext from "../../../store/device-context";
import uuid from "react-uuid";

const AdvText = ({name, description, city}) => {
    const device = useContext(DeviceContext);

  return(
      <div className='ads__descr_preview'>
          <h3 className="title_adv">{name}</h3>
          <div className="about_adv">Описание:</div>
          <p className="adv_description_text">{description}</p>
          {window.innerWidth > 1337 &&
              <div className="adv_city_array">
                  {city?.map((item, i) => (
                      i < 6 ?
                          <div className='adv_city' key={uuid()}>
                              <LocationBigSvg/>
                              <span>г.{item.city?.name}</span>
                          </div> :
                          i === 6 ? <span key={uuid()} className='adv_city_rest'>+{city.length - 6}</span> : ''
                  ))}
              </div>
          }
          {window.innerWidth < 1337 && window.innerWidth > 1024 &&
              <div className="adv_city_array">
                  {city?.map((item, i) => (
                      i < 3 ?
                          <div className='adv_city' key={uuid()}>
                              <LocationBigSvg/>
                              <span>г.{item.city?.name}</span>
                          </div> :
                          i === 3 ? <span key={uuid()} className='adv_city_rest'>+{city.length - 3}</span> : ''
                  ))}
              </div>
          }
          {device.isMobile &&
              <div className="adv_city_array">
                  {city?.map((item, i) => (
                      i < 4 ?
                          <div className='adv_city' key={uuid()}>
                              <LocationBigSvg/>
                              <span>г.{item.city?.name}</span>
                          </div> :
                          i === 4  ? <span key={uuid()} className='adv_city_rest'>+{city.length - 4}</span> : ''
                  ))}
              </div>
          }
      </div>
  )
}

export default AdvText;