export const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.1569 7.67508C8.14027 7.67543 8.12368 7.67661 8.10719 7.67868C8.01604 7.68757 7.92935 7.72234 7.85732 7.77889C7.78529 7.83544 7.73095 7.91142 7.70069 7.99786C7.67043 8.08429 7.66553 8.17758 7.68656 8.26671C7.70758 8.35584 7.75367 8.43711 7.81936 8.5009L11.321 12.0025L7.81936 15.5022C7.77454 15.5469 7.73896 15.5999 7.71467 15.6584C7.69038 15.7168 7.67784 15.7795 7.67779 15.8427C7.67773 15.906 7.69015 15.9687 7.71434 16.0271C7.73852 16.0856 7.774 16.1387 7.81875 16.1834C7.86349 16.2282 7.91662 16.2637 7.97508 16.2879C8.03355 16.312 8.09622 16.3245 8.1595 16.3245C8.22277 16.3244 8.28542 16.3118 8.34384 16.2875C8.40227 16.2632 8.45533 16.2277 8.5 16.1829L11.9997 12.6812L15.5013 16.1829C15.5457 16.2288 15.5988 16.2654 15.6575 16.2905C15.7162 16.3157 15.7793 16.3288 15.8431 16.3293C15.9069 16.3297 15.9702 16.3174 16.0293 16.2931C16.0883 16.2688 16.1419 16.233 16.1869 16.1878C16.2319 16.1425 16.2675 16.0887 16.2914 16.0295C16.3154 15.9704 16.3273 15.907 16.3265 15.8432C16.3257 15.7793 16.3122 15.7163 16.2867 15.6578C16.2613 15.5992 16.2244 15.5463 16.1782 15.5022L12.6785 12.0025L16.1782 8.5009C16.2468 8.43347 16.2935 8.34699 16.3123 8.2527C16.3312 8.15841 16.3213 8.06065 16.2839 7.97206C16.2465 7.88346 16.1834 7.8081 16.1027 7.7558C16.022 7.7035 15.9275 7.67663 15.8313 7.67868C15.7066 7.6824 15.5883 7.73453 15.5013 7.82401L11.9997 11.3237L8.5 7.82401C8.4556 7.77765 8.40236 7.74065 8.34342 7.71522C8.28449 7.68979 8.22105 7.67645 8.15686 7.67597L8.1569 7.67508Z"
                fill="black"
            />
        </svg>
    );
};
