import { useContext } from "react";

import object from "../../../assets/images/stubs/object-mob.png";
import DeviceContext from "../../../store/device-context";
import RequestOrdersSvg from "../svg/RequestOrdersSvg";
import { url } from "../../../store/host-store";
import {useSelector} from "react-redux";

const OfferObject = ({ item, objectId, getObjectId, setMobileVisibleCart }) => {
    const device = useContext(DeviceContext);

    let selectorNotification = useSelector(state => state.notification.data);

    if(selectorNotification.id !== 0){
        setTimeout(() => {
            document.getElementById(selectorNotification?.id)?.scrollIntoView({ behavior: 'smooth' });
            const yOffset = -40;
            const element =  document.getElementById(selectorNotification?.id);
            const y = element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }, 100)
    }

    return (
        <div
            dataindex={item.id}
            id={item.id}
            className={`cabinet_ads__item ${item.amountOfNewOrders < 1 && "empty"} ads_item_request ${
                objectId === item.id ? "active" : ""
            }`}
            onClick={() => {
                getObjectId(item.id);
                if (device.isMobile) {
                    setMobileVisibleCart(true);
                }
            }}>
            <div className="img">
                {item.image === null ? (
                    <img src={object} alt="object" />
                ) : (
                    <img src={url + item.image.filename} alt="object" />
                )}
            </div>

            <div className="radio_box">
                <h3 className="title">{item.name}</h3>
                <div className="radio_request__flex">
                    <div className="radio">
                        <h5>{item.price_1} ₽</h5>
                        <span>{item.price_1_name}</span>
                    </div>
                    <div className="radio">
                        <h5>{item.price_2} ₽</h5>
                        <span>{item.price_2_name}</span>
                    </div>
                </div>
            </div>
            <div className="request_top_cart_right">
                <div className="request_orders__item">
                    <RequestOrdersSvg />
                    <div className="timed_number">{item.amountOfNewOrders}</div>
                </div>
            </div>
        </div>
    );
};

export default OfferObject;
