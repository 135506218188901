import { NonViewedIcon } from "../../components/icons/NotViewedIcon";
import { ViewedIcon } from "../../components/icons/ViewedIcon";
import { url } from "../../store/host-store";
import style from "./style.module.sass";
import moment from "moment";
import {EditIcon} from "../../components/icons/EditIcon";
import {useContext, useState} from "react";
import AuthContext from "../../store/auth-context";
import {CloseIcon} from "../../components/icons/CloseIcon";
import {usePutMessageTextMutation} from "../../store/redux/queries/support-message";

const Dialog = ({ data }) => {
    const keys = Object.keys(data);
    const ctx = useContext(AuthContext)
    const [messageId, setMessageId] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const ifAdmin = true
    const [putMessage] = usePutMessageTextMutation();

    const clickEditMessage = async (id, text) => {
        setInputValue(text)
        setMessageId(id)

        if(messageId !== 0){
            await putMessage({id: messageId, body: {text: inputValue}})
            setMessageId(0)
            ctx.setRerender(prev => !prev)
        }
    }

    const closeMessageInput = () => {
        setMessageId(0)
    }

    return keys.map((key) => {
        return (
            <div key={key.id}>
                <p className={style.message__date} key={key}>
                    {key}
                </p>
                {data[key].map((message) => {
                    if (isCurrentUserMessage(message)) {
                        return (
                            <div className={`${style.from_user} ${style.message}`} key={message.id}>
                                {getImages(message).map((img) => (
                                    <img key={img.id} alt={img.id} src={url + img.file}></img>
                                ))}
                                {messageId !== message.id && <p key={message.id} className={style.message__text}>{message.text}</p>}
                                {messageId === message.id && <input
                                                                    type="text"
                                                                    onChange={(event) => setInputValue(event.target.value)}
                                                                    defaultValue={message.text}/>}

                                <div className={style.message__bottom}>
                                    <span>Ваше сообщение</span>
                                    <div className='flex'>
                                        {ifAdmin && <div className={style.edit} onClick={() => clickEditMessage(message.id, message.text)}>
                                            <EditIcon/>
                                        </div>}
                                        {ifAdmin && messageId === message.id && <div className={style.edit} onClick={closeMessageInput}>
                                            <CloseIcon/>
                                        </div>}
                                        <span className={style.date}>{moment(message.created).format("HH:mm")}</span>
                                        {message.viewed ? <ViewedIcon /> : <NonViewedIcon />}
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className={`${style.from_support} ${style.message}`} key={message.id}>
                                {getImages(message).map((img) => (
                                    <img key={img.id} alt={img.id} src={url + img.file}></img>
                                ))}
                                {messageId !== message.id && <p key={message.id} className={style.message__text}>{message.text}</p>}
                                {messageId === message.id && <input
                                    type="text"
                                    onChange={(event) => setInputValue(event.target.value)}
                                    defaultValue={message.text}/>}
                                <div className={style.message__bottom}>
                                    <span>Служба поддержки</span>
                                    <div className="flex">
                                        {!ifAdmin && <div className={style.edit} onClick={() => clickEditMessage(message.id, message.text)}>
                                            <EditIcon/>
                                        </div>}
                                        {!ifAdmin && messageId === message.id && <div className={style.edit} onClick={closeMessageInput}>
                                            <CloseIcon/>
                                        </div>}
                                        <span className={style.date}>{moment(message.created).format("HH:mm")}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        );
    });
};

function isCurrentUserMessage(message) {
    return message.to_user_id === null;
}
function getImages(message) {
    return message?.files || [];
}

export default Dialog;
