import {useContext} from "react";
import AdModeratorContext from "../../store/ad-moderator-context";
import s from "../../moderator/main/object/components/style.module.sass";
import Loader from "../../components/loader/Loader";
import OrganizationInfo from "./components/OrganizationInfo";


const OrganizationObject = () => {
    const ctx = useContext(AdModeratorContext)
    console.log(ctx.errorOrganization)
    return ctx.loading ? (
        <div className={s.cards_wrapper}>
            <Loader />
        </div>
    ) : Object.keys(ctx.currentObj).length > 0 ? (
        <>
            {ctx.errorOrganization &&
                <div style={{padding: "0 15px", marginTop: "10px"}}>
                    <div className="toast_advertiser toast_moderation_failed">
                        <h4>ИНН некорректно введён!</h4>
                        <p>Пользователь некорректно заполнил поле ИНН. Необходимо отклонить модерацию для данного пользователя</p>
                    </div>
                </div>
            }
            <div className={s.cards_wrapper}>
                <OrganizationInfo
                    data={ctx.currentObj}
                    incorrectClick={ctx.incorrectClick}
                    correctClick={ctx.correctClick}
                    isActive={ctx.isCorrectOrganization.data}
                />
            </div>
        </>
    ) : (
        <div className={s.cards_wrapper_empty}>Нет реклам для модерации</div>
    );
}

export default OrganizationObject;