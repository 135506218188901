import s from "../../../moderator/main/object/components/style.module.sass";
import CorrectBtn from "../../../moderator/buttons/CorrectBtn";
import IncorrectBtn from "../../../moderator/buttons/IncorrectBtn";

const OrganizationInfo = ({ data, incorrectClick, correctClick, isActive }) => {
    return(
        <div className={`${s.card} ${s.card_org} ${isActive ? s.active : ""}`}>
            <div className={s.card__organization}>
                <div className={s.card__organization_left}>
                    <h5>{data.type !== 2 ? 'ФИО' : 'Название компании'}</h5>
                    <p>{data?.legal_form?.length > 0 ? data?.legal_form : ''} {data?.name}</p>
                    <h5>{data.type !== 2 ? 'Адрес регистрации' : 'Юридический адрес'}</h5>
                    <p>{data?.address}</p>
                    <h5>ОКВЭД</h5>
                    <p>{data?.okved !== null ? data?.okved : 'не указано'}</p>
                </div>

                <div className={s.card__organization_right}>
                    <h5>ИНН</h5>
                    <p>{data?.inn}</p>
                    <h5>{data.type !== 2 ? 'ОГРНИП' : 'ОГРН'}</h5>
                    <p>{data?.ogrn}</p>
                    {data?.legal_form?.length > 0 &&
                        <>
                            <h5>Аббревиатура</h5>
                            <p>{data?.legal_form}</p>
                        </>
                    }
                </div>
            </div>

            <CorrectBtn isActive={isActive} correctClick={() => correctClick("data")} />
            <IncorrectBtn incorrectClick={incorrectClick} />
        </div>
    )
}

export default OrganizationInfo;