import CheckedConfirmedUserIcon from "./svg/CheckedConfirmedUserIcon";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {token} from "../../../App";
import {link} from "../../../store/host-store";

const OneUserIsPersonConfirmed = ({confirmed}) => {
    const params = useParams();
    const id = params.id;
    const [isAccept, setIsAccept] = useState(confirmed);


    const confirmUser = () => {
        let data = {
            confirmed: isAccept === 0 ? 1 : 0
        }
        fetch(`${link}/user/${id}` ,{
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify(data)
        }).then(req => req.json())
            .then(req => setIsAccept(req.data.confirmed))
    }

  return(
      <div className="block_user_is_confirmed" onClick={confirmUser}>
          {isAccept === 1 ? <div className="block_user_is_confirmed_checked_true"><CheckedConfirmedUserIcon fill="#2AA020"/></div> : null}
          {isAccept === 0 ? <div className="block_user_is_confirmed_checked"></div> : null}
        <div className="block_user_is_confirmed_text">Личность подтверждена</div>
      </div>
  )
}

export default OneUserIsPersonConfirmed;