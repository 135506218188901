const PauseDesktopSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="none">
            <circle cx="14" cy="14" r="14" fill="#6FE1B8" />
            <path
                fill="#546EDB"
                fillRule="evenodd"
                d="M10.75 8c.414 0 .75.288.75.643v10.714c0 .355-.336.643-.75.643s-.75-.288-.75-.643V8.643c0-.355.336-.643.75-.643Zm6.5 0c.414 0 .75.288.75.643v10.714c0 .355-.336.643-.75.643s-.75-.288-.75-.643V8.643c0-.355.336-.643.75-.643Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default PauseDesktopSvg;