import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { useState } from "react";
import axios from "axios";

import { DropdownIndicator } from "../../../components/header/headerTop/headerSelects/selects";
import { customStyles2 } from "../../../components/header/headerTop/headerSelects/selects";
import InputPhoto from "../../../cabinet/advertisement/InputPhoto";
import { ArrowLeftIcon } from "../../../components/icons/ArrowLeftIcon";
import getHost, { link } from "../../../store/host-store";
import { token } from "../../../App";
import s from "./style.module.sass";

const PartnersCreate = () => {
    const [select, setSelect] = useState([]);
    const [success, setSuccess] = useState(false);
    const [image, setImage] = useState([]);
    const [title, setTitle] = useState("");
    const [linkInput, setLinkInput] = useState("");

    const history = useNavigate();

    const loadOptions = (inputValue, callback) => {
        let options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`partner-section`)
            .then((response) => {
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.title,
                        value: permission.id,
                    });
                    callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
                });
            });
    };

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append("title", title);
        formData.append("section_id", select.value);
        formData.append("link", linkInput);
        formData.append("image", image[0]);

        axios
            .post(
                getHost({
                    controller: "partner",
                }),
                formData,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
            .then((result) => {
                if (result.data.success) {
                    setSuccess(true);
                }
            });
    };

    return (
        <div className="admin__layout">
            <div className={s.create}>
                <div className={s.create__top_wrapper}>
                    <div className={s.left}>
                        <div className="admin__back-arrow" onClick={() => history(-1)}>
                            <ArrowLeftIcon />
                        </div>
                        <h3>Создание партнёра</h3>
                    </div>
                    <div className={s.right}>
                        <button type="button" className="admin__btn-blue" onClick={submitHandler}>
                            Сохранить
                        </button>
                        {success && (
                            <div className={s.success}>
                                <span className="admin__success">Вопрос создан успешно</span>
                            </div>
                        )}
                    </div>
                </div>

                <div className={s.photo}>
                    <InputPhoto
                        images={image}
                        onLoad={setImage}
                        onDelete={setImage}
                        limitText={"Вы можете добавить 1 фото."}
                    />
                </div>

                <div className={s.inputs_wrapper}>
                    <input
                        className={s.input}
                        type="text"
                        value={title}
                        placeholder="Название партнера"
                        onChange={(e) => setTitle(e.currentTarget.value)}
                    />

                    <input
                        className={s.input}
                        type="text"
                        value={linkInput}
                        placeholder="Ссылка"
                        onChange={(e) => setLinkInput(e.currentTarget.value)}
                    />

                    <div className={s.selects}>
                        <AsyncSelect
                            key={select}
                            components={{ DropdownIndicator }}
                            placeholder={"Секция"}
                            cacheOptions
                            defaultOptions
                            onChange={setSelect}
                            value={select}
                            styles={customStyles2}
                            loadOptions={loadOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnersCreate;
