import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import completed from "./svg/completed.svg";
import {useDispatch} from "react-redux";
import {adsSearchAction} from "../../../store/redux";

const ModalAccountComplete = () => {
    const ctx = useContext(ModalsContext);
    const dispatch = useDispatch();

  return(
      <div className="modal modal__card3">
          <div className="closeModal" onClick={ctx.closeModal}>
              <ModalCloseSvg/>
          </div>
          <div className="modal_block">
              <h2 className="modal_title_ads">Поздравляем!</h2>
          </div>
          <div className="modal_adv_cloud cloud_blue">предварительная модерация</div>
          <div className="modal_adv_cloud cloud_blue" style={{marginTop: "-10px"}}>завершена</div>

          <img src={completed} alt="onCheck" style={{marginTop: "18px", marginBottom: "6px"}}/>

          <div className="modal_advers_text">
              <p><span>Нам необходимо убедится что Вы указали реквизиты правильно</span>, для этого
                  Вам потребуется оплатить любую сумму с расчётного счёта на кошелёк CityWork, вся сумма зачислится Вам в кошелёк. </p>
              <p>
                  После проверки в профиле появится значок «Реквизиты проверены» и вы сможете пользоваться рекламным кабинетом.
              </p>
          </div>
          <button onClick={() => {
              dispatch(adsSearchAction.adsModerationPay(true))
              ctx.paymentModal()
          }}
                  type="button" className="modal__btn">Выписать счет</button>
      </div>
  )
}

export default ModalAccountComplete;