import blue from "../../components/index/hero/svg/blue.png";
import {HeroAnimation} from "../../components/index/hero/HeroAnimation";
import {HeroIconRuble} from "./images/HeroIconRuble";

export const PartnerProgramAnimations = () => {
  return(
      <>
      <HeroAnimation text='+100 рублей' color={blue} element={<HeroIconRuble />} positionClassName='pl1' />
      <HeroAnimation text='+200 рублей' color={blue} element={<HeroIconRuble />} positionClassName='pl2' />
      <HeroAnimation text='+75 рублей' color={blue} element={<HeroIconRuble />} positionClassName='pl3' />

      <HeroAnimation text='+200 рублей' color={blue} element={<HeroIconRuble />} positionClassName='rl1' />
      <HeroAnimation text='+750 рублей' color={blue} element={<HeroIconRuble />} positionClassName='rl2' />
      <HeroAnimation text='+100 рублей' color={blue} element={<HeroIconRuble />} positionClassName='rl3' />
      </>
  )
}