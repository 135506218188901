import { useContext, useEffect, useState } from "react";

import { LogoIcon } from "../../../components/icons/LogoIcon";
import ModalsContext from "../../../store/modals-context";
import VerificationInput from "react-verification-input";
import Loader from "../../../components/loader/Loader";
import getHost from "../../../store/host-store";
import s from "./style.module.sass";
import {useDispatch, useSelector} from "react-redux";
import {adsSearchAction} from "../../../store/redux";

const CodeAuthorization = () => {
    const selector = useSelector(state => state.adsSearchLogic.redirect);
    const dispatch = useDispatch();
    const modal = useContext(ModalsContext);
    const [invalidCode, setInvalidCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");

    const [seconds, setSeconds] = useState(30);


    useEffect(() => {
        if (seconds > 0) {
            setTimeout(setSeconds, 1000, seconds - 1);
        }
    }, [seconds]);

    const getCode = () => {
        setCode("");

        fetch(
            getHost({
                controller: "user",
                action: "get-code",
                phone: modal.loginPhone,
            }),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setSeconds(30);
                }
            })
            .catch((e) => console.log(e));
    };

    const onLogin = () => {
        setLoading(true);
        setInvalidCode(false);

        const data = {
            phone: modal.loginPhone,
            code,
        };

        fetch(
            getHost({
                controller: "user",
                action: "phone-login",
                expand: "organization",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (result.success) {
                    localStorage.setItem("token", result.data.access_token);
                    if (result.data.role === "moderator") {
                        localStorage.setItem('firstVisit', '1')
                        return window.location.replace("/moderator");
                    } else {
                        if(window.location.href.indexOf('/marketing') > -1){
                            return window.location.replace('/advertiser')
                        }
                        window.location.reload();
                    }
                } else {
                    setInvalidCode(true);
                }
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        if (code.length < 4) return;
        onLogin();
    }, [code]);

    return (
        <div className={s.modal}>
            <div className={s.modal__top}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={s.logo}>
                            <LogoIcon color={"#4088DD"}/>
                        </div>
                        <h2 className={s.title}>Введите код</h2>
                        <p className={s.descr}>
                            На номер <span>{modal.loginPhone}</span> отправлен код для авторизации
                        </p>

                        <div className={s.auth}>
                            <div className={s.nums}>
                                <VerificationInput
                                    autoFocus
                                    placeholder={""}
                                    validChars="0-9"
                                    length={4}
                                    value={code}
                                    onChange={setCode}
                                    classNames={inputStyles}
                                />
                                {invalidCode && <span className={s.error}>Неверный код</span>}
                            </div>
                            <p className={s.get_code} onClick={!seconds ? getCode : undefined}>
                                Отправить код повторно <span>({seconds} сек.)</span>
                            </p>
                            <button className={s.btn_blue} onClick={onLogin} type="button">
                                Вход
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const inputStyles = {
    container: s.auth_container,
    character: s.auth_input,
};

export default CodeAuthorization;
