const ErrorSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
            <circle cx="8.5" cy="8.5" r="8.5" fill="#D32121" />
            <g clipPath="url(#a)">
                <path
                    fill="#fff"
                    d="M8.682 13.436h-.635c-.027-.005-.05-.013-.077-.015a4.655 4.655 0 0 1-1.56-.377c-1.67-.74-2.688-2.009-3.043-3.807-.037-.182-.053-.37-.077-.556v-.635c.006-.032.014-.063.019-.095.037-.244.058-.493.114-.734a5.065 5.065 0 0 1 6.024-3.812c2.242.474 3.92 2.475 3.986 4.766.04 1.336-.373 2.523-1.255 3.531a4.965 4.965 0 0 1-2.94 1.658c-.186.031-.37.05-.556.076Zm2.395-8.379C9.467 3.7 6.932 3.723 5.32 5.351c-1.64 1.66-1.574 4.188-.262 5.724 2.007-2.004 4.01-4.008 6.02-6.018Zm-5.431 6.605c1.649 1.396 4.282 1.348 5.886-.423 1.607-1.771 1.319-4.255.127-5.592-2.003 2.005-4.007 4.006-6.013 6.015Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3.29 3.29h10.145v10.145H3.29z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ErrorSvg;
