import {useContext} from "react";
import AuthContext from "../../store/auth-context";
import ModalsContext from "../../store/modals-context";
import {PartnerProgramAnimations} from "./PartnerProgramAnimations";

export const PartnerProgramBanner = () => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);

  return(
      <div className='partner_program_banner'>
        <div className="container">
            <h1>Партнерская программа</h1>
            <p>CityWork открывает новые возможности для вас и ваших друзей. Вместе мы станем сильной командой.</p>

            {!ctx.isUserId ? (
                <button type='button' className="partner_program_btn" onClick={modal.unauthModal}>
                    Регистрация
                </button>
            ) : (
                <></>
            ) }

        <PartnerProgramAnimations />
        </div>
      </div>
  )
}