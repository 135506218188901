const PromotionSvg = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
            <circle cx="14" cy="14" r="14" fill="#546EDB"/>
            <mask id="a" width="12" height="12" x="8" y="8" maskUnits="userSpaceOnUse" style={{maskType:'luminance'}}>
                <path fill="#fff" d="M8 8h12v12H8V8Z"/></mask>
            <g stroke="#6FE1B8" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" mask="url(#a)">
                <path d="m13.659 16.993-2.652-2.652a.937.937 0 0 1 0-1.326l3.207-3.207a4.113 4.113 0 0 1 4.748-.77 4.113 4.113 0 0 1-.77 4.748l-3.207 3.207a.937.937 0 0 1-1.326 0ZM12.333 18.319c-1.474 1.473-3.841 1.189-3.841 1.189s-.287-2.364 1.19-3.84M19.531 8.469l-.569.569"/>
                <path d="M16.31 13.015a.938.938 0 1 1-1.325-1.326.938.938 0 0 1 1.325 1.326ZM8.469 12.898 9.68 11.69a1.875 1.875 0 0 1 2.652 0M15.102 19.531l1.208-1.212a1.875 1.875 0 0 0 0-2.652"/></g></svg>
    )
}

export default PromotionSvg;