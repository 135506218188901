import { NavLink } from "react-router-dom";

import objImg from "../../../assets/images/stubs/object-mob.png";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import { url } from "../../../store/host-store";

const ObjectsList = ({ dataObjects }) => {
    return (
        <>
            <div className="admin__list-titles admin__list-titles_obj">
                <p>ID</p>
                <p>Фото</p>
                <p>Название</p>
                <p>Автор объявления</p>
                <p>Статус</p>
            </div>
            <div>
                {dataObjects.map((el) => (
                    <div className="admin__objects_object" key={el.id}>
                        <span>#{el.id}</span>
                        {el.image === null ? (
                            <img src={objImg} alt="object" />
                        ) : el.image.webpFilename ? (
                            <img src={url + el.image?.webpFilename} alt="object" />
                        ) : (
                            <img src={url + el.image?.filename} alt="object" />
                        )}
                        <p className="admin__objects_object-name">{el.name}</p>
                        <div>
                            <p>{el.user?.name}</p>
                            <p>{el.user?.phone}</p>
                        </div>
                        {el.status === 0 ? (
                            <p className="admin__waiting">Ожидает</p>
                        ) : el.status === 1 ? (
                            <p className="admin__done">Подтверждено</p>
                        ) : el.status === 2 ? (
                            <p className="admin__rejected">Отклонено</p>
                        ) : el.status === 3 ? (
                            <p className="admin__deactive">Деактивировано пользователем</p>
                        ) : (
                            <p className="admin__delete">Удалено пользователем</p>
                        )}
                        <NavLink to={`${el.id}`}>
                            <EyeIcon />
                        </NavLink>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ObjectsList;
