import {useState} from "react";

const EditAdminQuantityAds = () => {
    const [quantity, setQuantity] = useState('0');

    const submitQuantity = () => {
        if(quantity.length > 1 || quantity.length === 0){
            alert('Укажите количество не меньше 1 и не больше 9')
        }else{

        }
    }

    return (
        <div className="edit_admin_city">
            <div className="info__block_title">Кол-во рекламы на странице каталога</div>
            <div className="flex">
                <div className="block_ad_city_inputs">
                    <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)}/>
                </div>
                <div className="block_ad_city_inputs">
                    <button type="button" className="admin__btn-blue" onClick={submitQuantity}>Сохранить</button>
                </div>
            </div>
        </div>
    )
}

export default EditAdminQuantityAds;