import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import {useContext, useEffect, useState} from "react";
import ModalsContext from "../../../store/modals-context";
import RequestRubleSvg from "../ModalSvg/RequestRubleSvg";
import AuthContext from "../../../store/auth-context";
import {
    useLazyGetModelAgreementQuery,
    useLazyIsProgramExistQuery,
    usePostMoneyProgramMutation,
} from "../../../store/redux/queries/partnerProgram/partner-program-query";

export const AccountWithdrawModal = () => {
    const ctx = useContext(ModalsContext);
    const user = useContext(AuthContext)
    const [withdraw, setWithdraw] = useState(3000);
    const [error, setError] = useState('')
    const [setMoneyProgram, data] = usePostMoneyProgramMutation()
    const [trigger, {data: dataExisting}] = useLazyIsProgramExistQuery()
    const [triggerAgreement, {data: dataAgreement}] = useLazyGetModelAgreementQuery()

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const withdrawChange = (e) => {
        setWithdraw(e.target.value)
        setError("")
        if (e.target.value.startsWith('0') && e.target.value.length === 1) {
            setWithdraw(1)
        }
        if (e.target.value.startsWith('-') && e.target.value.length === 1) {
            setWithdraw(1)
        }
    }

    useEffect(() => {
        trigger()
    }, [])

    const onWithdraw = async () => {
        if(withdraw < 0){
           return setError("Введите положительную сумму")
        }
        if(user.userData.wallet.affiliate_money < withdraw){
            return setError("Вывод денег превышает количество")
        }if(withdraw.length === 0){
           return setError("Введите сумму")
        }if(withdraw < 3000){
            return setError("Минимальное количество вывода 3000")
        }
            setMoneyProgram({amount: {amount: +withdraw}})
            await trigger()
            await triggerAgreement()
            const dataNotExist = dataExisting?.data.confirmed === 0
            if(dataNotExist){
                return alert(`Предыдущая сумма в ${dataExisting?.data.amount} рублей ещё не прошла модерацию`)
            }
            await ctx.closeModal();
    }
    useEffect(() => {
        if(dataAgreement?.data.confirmed === 0 && dataAgreement?.data.file === null){
             ctx.closeModal()
             ctx.accountPartnerAgreement()
        }
    }, [dataAgreement])

  return(
      <div className="modal modalOrdered modal_account_withdraw">
          <div className="closeModal" onClick={() => ctx.closeModal()}>
              <ModalCloseSvg />
          </div>
          <div className="title_account_confirmed">Сумма вывода</div>
          <div className="withdraw_modal_text">Введите сумму:</div>
          <div className="input input_payment">
              <div className="icon">
                  <RequestRubleSvg />
              </div>
              <input
                  type="number"
                  onKeyDown={preventMinus}
                  value={withdraw} onChange={withdrawChange} placeholder="Введите стоимость" />
          </div>
          <div className="error">{error}</div>

          <button type="button" className="acc_btn_blue" onClick={onWithdraw}>Вывести</button>
      </div>
  )
}