import { useContext } from "react";
import ModalsContext from "../../../store/modals-context";
import AuthContext from "../../../store/auth-context";

const Wallet = () => {
    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);
    return (
        <div className="cabinet__wallet cabinet__right___block">
            <div className="cabinet_wallet_title">Кошелек</div>
            <div className="cabinet_wallet__summary">
                <span className="cabinet_wallet__rubles">
                    {ctx.userData.wallet?.money ? ctx.userData.wallet?.money : 0} ₽
                </span>
                <span className="wallet_def"> / </span>
                <span className="cabinet_wallet__points">
                    {ctx.userData.wallet?.points ? ctx.userData.wallet?.points : 0} баллов
                </span>
            </div>
            <button type="button" className="cabinet_wallet_add" onClick={modal.paymentModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
                    <path
                        fill="#546EDB"
                        fillRule="evenodd"
                        d="M.842 10.261c0-5.4 4.378-9.777 9.778-9.777s9.778 4.377 9.778 9.777c0 5.4-4.378 9.778-9.778 9.778S.842 15.661.842 10.261Zm10.756-3.91a.978.978 0 0 0-1.955 0v2.933H6.709a.978.978 0 0 0 0 1.955h2.934v2.933a.978.978 0 1 0 1.955 0V11.24h2.933a.978.978 0 0 0 0-1.955h-2.933V6.35Z"
                        clipRule="evenodd"
                    />
                </svg>
                Пополнить
            </button>
        </div>
    );
};

export default Wallet;
