import bg from "../../../assets/images/cabinet/promo-bg.webp";
import ChooseButton from "../ChooseButoon";
import s from "../style.module.sass";

const Empty = () => {
    return (
        <div className={s.bottom}>
            <h3>
                Продвигайте
                <br />
                <span>свои объявления!</span>
            </h3>
            <img src={bg} alt="promotion" />
            <p className={s.text}>Продвигайте свои объявления у нас на сервисе и получайте больше заказов!</p>
            <div className={s.bottom__button}>
                <ChooseButton title="Выбрать объявление" to="/lc/promotion/choose" />
            </div>
        </div>
    );
};

export default Empty;
