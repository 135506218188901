import CustomScrollbar from "../../UI/Scrollbar/CustomScrollbar";
import PaginationPattern from "../../UI/PaginationPattern";
import OfferObject from "./ObjectsTypes/OfferObject";
import SeekObject from "./ObjectsTypes/SeekObject";

const RequestItem = (props) => {
    return (
        <div className="scroll_if_needed scroll_if_needed_request">
            <div className="advers___title">Объявления:</div>
            <CustomScrollbar style={{ paddingRight: "21px" }}>
                {props?.object.length > 0
                    ? props?.object.map((item) =>
                          item.seek ? (
                              // я ищу
                              <SeekObject
                                  item={item}
                                  key={item.id}
                                  objectId={props.objectId}
                                  getObjectId={props.getObjectId}
                                  setMobileVisibleCart={props.setMobileVisibleCart}
                              />
                          ) : (
                              // я предлагаю
                              <OfferObject
                                  item={item}
                                  key={item.id}
                                  objectId={props.objectId}
                                  getObjectId={props.getObjectId}
                                  setMobileVisibleCart={props.setMobileVisibleCart}
                              />
                          )
                      )
                    : "Нет объявлений"}
                <div className="pagination_banks" style={{ paddingBottom: "20px" }}>
                    <PaginationPattern
                        setChange={(e, value) => props.setPage(value)}
                        pageCount={props.pageCount}
                        page={props.page}
                    />
                </div>
            </CustomScrollbar>
        </div>
    );
};

export default RequestItem;
