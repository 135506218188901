import axios from "axios";
import {link} from "../../../../store/host-store";
import {token} from "../../../../App";
import AsyncSelect from "react-select/async";
import {customStyles2, DropdownIndicator} from "../../../../components/header/headerTop/headerSelects/selects";
import {convertTypeInputToString} from '../../create/offer/CreateForm'
import React, {useEffect, useReducer} from "react";
import {initialCount, reducer} from "../SelectCity";
import Select from "react-select";
import {offerStatus} from "../../create/offer/offerStatus";

const EditSearchCategories = ({
    type,
    setCategoryParent,
    categoryParent,
    setCategoryChild,
    categoryChild,
    errors,
    ad,
    setCategoryFetch,
    getCategoryFetch,
    activeStatus,
    setActiveStatus
}) => {

    const [state, dispatch] = useReducer(reducer, initialCount);

    const loadCategoryParent = (inputValue, callback) => {
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`category?filter[type]=${ad.type}&filter[depth]=1&pagination=0&sort=title`)
            .then(({ data }) => {
                if (ad.category.type === 2 && ad.category.equipment === 0) {
                    categoryParent = data.data.find((parent) => parent.id === ad.category.id);
                } else {
                    categoryParent = data.data.find((parent) => parent.id === ad.category.parent_id);
                }
                setCategoryParent({
                    label: categoryParent?.title,
                    value: categoryParent?.id,
                });
                callback(
                    data.data
                        .map(({ title, id }) => ({
                            label: title,
                            value: id,
                        }))
                        .filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))
                );
            });
    };

    const loadCategory = (inputValue, callback) => {
        // запрашиваем список для "вида техники"
        if (categoryParent == null) {
            return;
        }
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`category?filter[parent_id]=${categoryParent.value}&pagination=0`)
            .then(({ data }) => {
                const category = data.data.find((item) => item.id === ad.category_id);
                if (state.category === 0) {
                    setCategoryChild({
                        label: category?.title,
                        value: category?.id,
                    });
                }
                callback(
                    data.data
                        .map(({ title, id }) => ({
                            label: title,
                            value: id,
                        }))
                        .filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))
                );
            });
    };

    const placeholderType = (event) => {
        setCategoryParent(event);
        setCategoryChild("");
        dispatch({ type: "plus" });
    };

    console.log(type)

    useEffect(() => {
        if (categoryChild?.value || categoryParent?.value) {
            fetch(`${link}/category/${categoryChild?.value !== undefined ? categoryChild?.value : categoryParent?.value}`, {
                method: "GET",
                headers: {
                    Accept: "application/json,",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    setCategoryFetch(res.data);
                });
        }
    }, [categoryChild?.value, categoryParent?.value]);

    return(
        <div className="create__ads_top__block create__ads_block">
            <div className={`input_wrap  ${getCategoryFetch.equipment === 1 || +ad.type !== 2 ? "" : "input_wrap_100"}`}>
                        <AsyncSelect
                            key={ad.type}
                            components={{ DropdownIndicator }}
                            placeholder={"Выберите категорию " + convertTypeInputToString(type)}
                            cacheOptions
                            defaultOptions
                            styles={customStyles2}
                            value={categoryParent}
                            onChange={placeholderType}
                            loadOptions={loadCategoryParent}
                            noOptionsMessage={() => ""}
                        />
            </div>

            <div
                className={`input_wrap ${+ad.type !== 2 || getCategoryFetch.equipment  === 1 ? "" : "dn"} ${
                    getCategoryFetch.equipment === 1 || +ad.type !== 2 ? "" : "input_wrap_100"
                }`}>
                        <AsyncSelect
                            key={categoryParent?.value}
                            components={{ DropdownIndicator }}
                            placeholder={"Подкатегория " + convertTypeInputToString(type)}
                            cacheOptions
                            value={categoryChild}
                            defaultOptions
                            styles={customStyles2}
                            onChange={setCategoryChild}
                            loadOptions={loadCategory}
                            noOptionsMessage={() => ""}
                        />
                <p className="error">{errors.category?.message}</p>
            </div>

            <div className={`input_wrap  ${+ad.type === 1 ? "input_wrap_" : "dn"}`}>
                <Select
                    key={type}
                    components={{ DropdownIndicator }}
                    onChange={setActiveStatus}
                    placeholder="Статус"
                    options={offerStatus}
                    value={activeStatus}
                    styles={customStyles2}
                    noOptionsMessage={() => ""}
                />
            </div>
        </div>
    )
}

export default EditSearchCategories;