import {link} from "../../../host-store";
import {token} from "../../../../App";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export const partnerProgramQuery = createApi({
    reducerPath: "partnerProgram",
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["PartnerProgram"],
    endpoints: builder => ({
        togglePartnerOrBonus: builder.mutation({
            query: ({toggleArg, userId}) => ({
                url: `/user/${userId}`, // Переключатель бонус или партнерка
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(toggleArg)
            }),
            invalidatesTags: ["PartnerProgram"],
        }),
        postMoneyProgram: builder.mutation({
            query: ({amount}) => ({
                url: `/affiliate-withdrawal`, // cоздать запрос
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(amount)
            }),
            invalidatesTags: ["PartnerProgram"],
        }),
        isProgramExist: builder.query({
            query: () => ({
                url: `/affiliate-withdrawal/not-confirmed-exist`, // есть ли неподтвержденный запрос
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["PartnerProgram"],
        }),
        getModelAgreement: builder.query({
            query: () => ({
                url: `/affiliate-contract/user-contract`, // Получить модель договора
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["PartnerProgram"],
        }),
        putFile: builder.mutation({
            query: ({ id, file }) => {
                const formData = new FormData();
                formData.append('file', file); // Append file data with key 'file'
                console.log({file})
                return {
                    url: `/affiliate-contract/load-file?id=${id}`,
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                    body: formData,
                };
            },
            invalidatesTags: ["PartnerProgram"],
        }),
    }),
})

export const {useTogglePartnerOrBonusMutation,
    usePostMoneyProgramMutation,
    useLazyIsProgramExistQuery, useLazyGetModelAgreementQuery, usePutFileMutation} = partnerProgramQuery