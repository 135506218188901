import style from '../subscribe.module.sass'
import CitySelects from "../../advertisement/create/CitySelects";

const SubscribeCity = ({getCity}) => {

  return(
      <div className={style.create_city}>
          <h4 className={style.create_title}>Укажите адрес:</h4>
          <CitySelects getCity={getCity}/>
      </div>
  )
}

export default SubscribeCity;