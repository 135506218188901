import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    statusAds: 0,
    urlCreate: "/lc/advertisement/create-offer",
    isPageExist: '',
    checkingAccount: false,
    redirect: 0
};

const adsSearchLogic = createSlice({
    name: "adsSearchLogic",
    initialState,
    reducers: {
        toggleStatus(state, action) {
            state.statusAds = action.payload;
            state.statusAds === 0
                ? (state.urlCreate = "/lc/advertisement/create-offer")
                : (state.urlCreate = "/lc/advertisement/create-search");
        },
        isPageExistence(state, action){
            state.isPageExist = action.payload;
        },
        adsModerationPay(state, action){
            state.checkingAccount = action.payload
        },
        takeFooterLink(state, action){
            state.redirect = action.payload
        },
        redirectFromFooter(state){
            state.redirect === 1 ? window.location.replace('/marketing') : window.location.replace('/lc/support')
        }
    },
});

export default adsSearchLogic;
