export const PartnerPaymentBlock = ({img, dots, number, title, description}) => {
  return(
      <div className={`partner_program_payment_block ${`partner_program_block` + number}`}>
        <div className="partner_program_payment_topFlex">
            <img src={img} alt=""/>
            <div className="partner_program_payment_top_right">
                <img src={dots} alt=""/>
                <p className="partner_program_number">{number}</p>
            </div>
        </div>
          <div className="partner_program_payment_title">{title}</div>
          <p className="partner_program_payment_description">{description}</p>
      </div>
  )
}