import React, {useEffect, useState} from "react";
import {adsSearchAction} from "./redux";
import {useDispatch} from "react-redux";

const ModalsContext = React.createContext({
    openModal: "",
    isModal: false,
    openMobMenu: false,
    isCategories: false,
    setMoney: 0,
    money: 0,
    tel: 0,
    feedbackObjectId: 0,
    moderatorNotFound: () => {},
    unconfirm: () => {},
    closeModal: () => {},
    videoModal: () => {},
    toggleMenu: () => {},
    requestModal: () => {},
    contactsModal: () => {},
    paymentRequest: () => {},
    orderId: () => {},
    modalTel: () => {},
    setIsModal: () => {},
    isOrderedModal: () => {},
    setOpenMobMenu: () => {},
    calendarModal: () => {},
    toggleCategories: () => {},
    deleteProfileModal: () => {},
    paymentModal: () => {},
    openBankChecksModal: () => {},
    blockModal: () => {},
    advertiserModerationConfirmed: () => {},
    advertiserOnCheckingPayment: () => {},
    accountPartnerNotConfirmed: () => {},
    partnerProgramModal: () => {},
    accountPartnerAgreement: () => {},
});

export const ModalsContextProvide = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [openModal, setOpenModal] = useState("accountAgreement");
    const [openMobMenu, setOpenMobMenu] = useState(false);
    const [isCategories, setIsCategories] = useState(false);
    const [money, setMoney] = useState(0);
    const [orderId, setOrderId] = useState(0);
    const [promotion, setPromotion] = useState({});
    const [tel, setTel] = useState(0);
    const dispatch = useDispatch();

    const toggleMenu = () => {
        if (isCategories) {
            toggleCategories();
        }
        if (openMobMenu) {
            setOpenMobMenu(false);
        } else {
            setOpenMobMenu(true);
        }
    };

    const accountPartnerAgreement = () => {
        setIsModal(true)
        setOpenModal("accountAgreement")
    }

    const advertiserModerationConfirmed = () => {
        setIsModal(true)
        setOpenModal("ads_complete")
    }

    const advertiserOnCheckingPayment = () => {
        setIsModal(true)
        setOpenModal("ads_checking")
    }

    const accountPartnerNotConfirmed = () => {
        setIsModal(true)
        setOpenModal("accountNeedConfirm")
    }

    const partnerProgramModal = () => {
        setIsModal(true)
        setOpenModal("accountWithdraw")
    }

    const moderatorNotFound = () => {
        setIsModal(true)
        setOpenModal("moderatorNotFound")
    }

    //Вернуть

    useEffect(() => {
        if(!localStorage.getItem('firstVisit')){
            setIsModal(true);
            setOpenModal("city");
            document.querySelector('body').classList.add('noscroll')
        }
    }, [])

    const toggleCategories = () => {
        if (isCategories) {
            setIsModal(false);
            setIsCategories(false);
            document.body.classList.remove("noscroll");
        } else {
            setOpenModal("");
            setIsModal(true);
            setIsCategories(true);
            document.body.classList.add("noscroll");
        }
    };

    const contactsModal = () => {
        setIsModal(true);
        setOpenModal("contacts");
    };

    const blockModal = () => {
        setIsModal(true);
        setOpenModal("block");
    };

    const paymentRequest = (id, type, price, count) => {
        setIsModal(true);
        setOrderId(id);
        setPromotion({id, type, price, count });
        setOpenModal("paymentRequest");
    };

    const paymentModal = () => {
        setIsModal(true);
        setOpenModal("payment");
    };

    const modalTel = (tel) => {
        setIsModal(true);
        setTel(tel)
        setOpenModal("tel");
    }

    const closeModal = () => {
        setIsModal(false);
        setOpenModal("");
        dispatch(adsSearchAction.adsModerationPay(false))
        if (isCategories) {
            document.body.classList.remove("noscroll");
            setIsCategories(false);
        }
        setOrderId(0);
    };

    const requestModal = () => {
        setIsModal(true);
        setOpenModal("request");
    };

    const requestOfferModal = () => {
        setIsModal(true);
        setOpenModal("request-offer");
    };

    const unconfirm = () => {
        setIsModal(true);
        setOpenModal("unconfirm");
    };

    // const videoModal = () => {
    //     setIsModal(true);
    //     setOpenModal("youtube");
    // };

    const isOrderedModal = () => {
        setIsModal(true);
        setOpenModal("ordered");
    };

    const deleteProfileModal = () => {
        setIsModal(true);
        setOpenModal("deleteProfile");
    };

    const searchingModal = () => {
        setIsCategories(false);
        setIsModal(true);
        setOpenModal("searching");
    };

    const unauthModal = () => {
        setIsModal(true);
        setOpenModal("unauth");
    };

    const calendarModal = () => {
        setIsModal(true);
        setOpenModal("calendar");
    };

    const [feedbackId, setFeedbackId] = useState(-1);
    const [feedbackObjectId, setFeedbackObjectId] = useState(-1);
    const feedbackModal = (id, idObj) => {
        setFeedbackId(id);
        setFeedbackObjectId(idObj)
        setIsModal(true);
        setOpenModal("feedback");
    };

    const registerModal = () => {
        setIsModal(true);
        setOpenModal("registration");
    };

    const onLoadAuthorization = () => {
        setIsModal(true);
        setOpenModal("loadAuthorization");
    };
    const [loginPhone, setLoginPhone] = useState(null);

    const codeAuthorization = () => {
        setIsModal(true);
        setOpenModal("codeAuthorization");
    };

    const chooseRole = () => {
        setIsModal(true);
        setOpenModal("choose-catalogPages-role");
    };

    const openBankChecksModal = () => {
        setIsModal(true);
        setOpenModal("openBankChecksModal")
    }

    const [counterNewResponse, setCounterNewResponse] = useState(0);
    const [newResponses, setNewResponses] = useState([]);

    return (
        <ModalsContext.Provider
            value={{
                tel,
                isModal,
                openModal,
                unconfirm,
                closeModal,
                counterNewResponse,
                setCounterNewResponse,
                setNewResponses,
                newResponses,
                toggleMenu,
                // videoModal,
                unauthModal,
                chooseRole,
                openMobMenu,
                isCategories,
                requestModal,
                calendarModal,
                feedbackId,
                feedbackModal,
                partnerProgramModal,
                searchingModal,
                requestOfferModal,
                contactsModal,
                isOrderedModal,
                paymentRequest,
                paymentModal,
                modalTel,
                openBankChecksModal,
                setMoney,
                money,
                setOpenMobMenu,
                toggleCategories,
                orderId,
                feedbackObjectId,
                deleteProfileModal,
                promotion,
                registerModal,
                onLoadAuthorization,
                setLoginPhone,
                loginPhone,
                codeAuthorization,
                advertiserModerationConfirmed,
                advertiserOnCheckingPayment,
                blockModal,
                accountPartnerNotConfirmed,
                accountPartnerAgreement,
                moderatorNotFound
            }}>
            {props.children}
        </ModalsContext.Provider>
    );
};

export default ModalsContext;
