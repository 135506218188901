import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import SearchInput from "../../../UI/SearchInput/SearchInput";
import PaginationPattern from "../../../UI/PaginationPattern";
import FormModal from "../../../UI/Modal/FormModal/FormModal";
import getHost, { link } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import { token } from "../../../App";
import s from "./style.module.sass";

const StopWords = () => {
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации
    const [dataWords, setDataWords] = useState([]);
    const [page, setPage] = useState(null);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //необходимо для передачи параметра страницы в url
    const navigate = useNavigate();
    //получаем параметры текущей страницы
    const params = new URLSearchParams(window.location.search);

    const ctx = useContext(AuthContext);

    const [openModal, setOpenModal] = useState(false);
    const [input, setInput] = useState("");

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть, то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            //иначе присваиваем стартовое значение 1
            setPage(1);
        }
    }, [params]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (page !== null) {
            let fetchLink = `${link}/dictionary?pagination[pageSize]=50&page=${page}`;

            //если что-то вбито в поиске
            if (search.length > 0) {
                fetchLink += `&DictionarySearch[query]=${search}`;
            }

            fetch(fetchLink, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json().then((data) => ({ data, headers: res.headers })))

                .then((result) => {
                    setLoading(false);
                    if (result.data.success) {
                        setDataWords(result.data.data);
                        setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [page, search, ctx.rerender]);

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    const onDelete = (id) => {
        fetch(`${link}/dictionary/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
            method: "DELETE",
        })
            .then((res) => res.json())

            .then((result) => {
                if (result.success) {
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    const createWord = (e) => {
        e.preventDefault();
        const data = {
            word: input,
        };

        fetch(
            getHost({
                controller: "dictionary",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setOpenModal(false);
                    setInput("");
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="admin__layout">
            {openModal && (
                <FormModal
                    title="Добавить новое слово"
                    button="Добавить"
                    fn={createWord}
                    isOpenModal={setOpenModal}
                >
                    {<input value={input} onChange={(e) => setInput(e.target.value)} />}
                </FormModal>
            )}
            <h3>Стоп-слова</h3>
            <div className={s.top}>
                <div className="admin__search-wrapper">
                    <SearchInput setSearch={setSearch} />
                </div>
                <button type="button" className="admin__btn-blue" onClick={() => setOpenModal(true)}>
                    Добавить слово
                </button>
            </div>

            {loading ? (
                <Loader />
            ) : dataWords.length > 0 ? (
                <>
                    {dataWords.map((el) => {
                        return (
                            <div className={s.word} key={el.id}>
                                <p>{el.word}</p>

                                <button
                                    title="удалить"
                                    type="button"
                                    className={s.delete}
                                    onClick={() => onDelete(el.id)}>
                                    <DeleteIcon />
                                </button>
                            </div>
                        );
                    })}
                    <PaginationPattern
                        setChange={(e, value) => setChange(value)}
                        pageCount={pageCount}
                        page={page}
                    />
                </>
            ) : (
                <>Пусто</>
            )}
        </div>
    );
};

export default StopWords;
