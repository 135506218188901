import { useEffect, useState } from "react";

import PaginationPattern from "../../../UI/PaginationPattern";
import Loader from "../../../components/loader/Loader";
import { link } from "../../../store/host-store";
import { token } from "../../../App";
import moment from "moment";

const HistoryTab = ({ userId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        fetch(`${link}/payment-history?filter[user_id]=${userId}&pagination[pageSize]=6&page=${page}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                console.log(result);
                setLoading(false);
                if (result.data.success) {
                    setData(result.data.data);
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                }
            });
    }, [page, userId]);

    return (
        <div className="admin_one-user_objects_tabs">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="admin__list-titles admin__list-titles_history">
                        <p>Дата</p>
                        <p>Название операции</p>
                        <p>Сумма</p>
                        <p>Статус</p>
                    </div>
                    <div className="admin_objects_wrapper">
                        {data.length > 0 ? (
                            data.map((el) => (
                                <div className="admin__objects_object_history" key={el.id}>
                                    <span>{moment(el.date).format("DD.MM.YYYY HH:MM")}</span>
                                    <p>{el.description}</p>
                                    <p>{el.amount}</p>
                                    {el.status === 1 ? (
                                        <p className="admin__done">Платеж совершен</p>
                                    ) : el.status === 2 ? (
                                        <p className="admin__rejected">Платеж провален</p>
                                    ) : (
                                        "Что-то пошло не так"
                                    )}
                                </div>
                            ))
                        ) : (
                            <>Нет операций</>
                        )}
                        <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page} />
                    </div>
                </>
            )}
        </div>
    );
};

export default HistoryTab;
