export const RequestIcon = () => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.42437 1.39999V1.69538C4.59456 1.76328 4.7542 1.86968 4.89075 2.01528L5.82352 3.00507C6.33871 3.55177 6.33871 4.43794 5.82352 4.98463L3.84452 7.08461C3.09778 7.877 1.90313 7.90434 1.12605 7.16515V8.39992C1.12605 9.9462 2.30751 11.1999 3.7647 11.1999H9.42858L11.874 13.7947C12.0626 13.9949 12.3463 14.0552 12.593 13.9467C12.839 13.8382 13 13.5827 13 13.2999C13 13.2999 13 7.44303 13 4.19996C13 2.65367 11.8185 1.39999 10.3613 1.39999H4.42437ZM1.78571 3.91016L0.932766 3.00507L0 3.99486L1.97899 6.09482C2.23626 6.36852 2.65449 6.36852 2.91176 6.09482L4.89075 3.99486L3.95798 3.00507L3.10504 3.91016V0H1.78571V3.91016Z"
                fill="white"
            />
            <circle cx="11" cy="6" r="1" fill="#4F68CF" />
            <circle cx="8" cy="6" r="1" fill="#4F68CF" />
        </svg>
    );
};
