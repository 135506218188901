import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getHost, { link } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import ObjectsTab from "./ObjectsTab";
import { token } from "../../../App";
import MoneyTab from "./MoneyTab";
import HistoryTab from "./HistoryTab";
import AdminBackIcon from "../../../components/icons/AdminBackIcon";
import moment from "moment";
import StarMiniIcon from "../../../components/icons/adIcons/StarMiniIcon";
import OneUserDataAndWallet from "./OneUserDataAndWallet";
import OneUserFormAccept from "./OneUserFormAccept";

const OneObject = () => {
    const params = useParams();
    const id = params.id;
    const [currentUser, setCurrentUser] = useState({});
    const history = useNavigate();

    const [tab, setTab] = useState("objects");
    const [rerender, setRerender] = useState(false);
    const [money, setMoney] = useState("");
    const [points, setPoints] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetch(
            getHost({
                controller: "user",
                action: id,
                expand: "account, wallet, bill, organization",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    setCurrentUser(result.data);
                }
            });
    }, [rerender, id]);

    console.log(currentUser)

    const onDelete = () => {
        fetch(
            getHost({
                controller: "user",
                action: id,
            }),
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    window.location.replace("/admin/users");
                }
            });
    };

    const addMoney = (id) => {
        const data = {
            amount: money,
            type: 1,
        };

        fetch(`${link}/user/replenish-by-admin?id=${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            method: "PUT",
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setMoney("");
                    setRerender((prev) => !prev);
                }
            });
    };

    const addPoints = (id) => {
        const data = {
            amount: points,
            type: 2,
        };

        fetch(`${link}/user/replenish-by-admin?id=${id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            method: "PUT",
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setPoints("");
                    setRerender((prev) => !prev);
                }
            });
    };

    if (Object.keys(currentUser).length > 0) {
        return (
            <div className="admin_one-user_container">
                <div className="admin_one-user_card">
                    <div className="admin_one-user_card-top">
                        <div>
                            <div className="admin_one-user_back">
                                <div className="admin_back_wrap_back_btn">
                                    <button
                                        title="назад"
                                        type="button"
                                        className="admin__back-arrow"
                                        onClick={() => history(-1)}>
                                        <AdminBackIcon />
                                    </button>
                                </div>
                                <div className="admin_block_flex">
                                    <div className="admin_wrapper">
                                        <h3>{currentUser.name}</h3>
                                        <div className="flex_admin_center">
                                            {currentUser.mark === null || currentUser.mark === 0 ? (
                                                <>
                                                    <div className="admin__rating  no-rating">Нет рейтинга</div>
                                                    <div className="star_admin">
                                                        <StarMiniIcon />
                                                        {currentUser.mark}</div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="admin__rating  no-rating">Рейтинг</div>
                                                    <div className="star_admin">
                                                        <StarMiniIcon />
                                                        {currentUser.mark}</div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex">Дата регистрации: <div className="date_admin">{moment(currentUser.account?.created)
                                        .format("YYYY.MM.DD HH:mm:ss")}</div></div>
                                </div>
                            </div>
                        </div>
                        <div className="admin_one-user_btns">
                            <button type="button" className="delete_in_admin_btn" onClick={onDelete}>
                                Удалить
                            </button>
                        </div>
                    </div>

                    <OneUserDataAndWallet currentUser={currentUser}
                                          addMoney={addMoney}
                                          addPoints={addPoints}
                                          setPoints={setPoints}
                                          setMoney={setMoney}
                                          points={points}
                                          money={money}/>

                    <div className="admin_one-user_card-bottom">
                        <div className="admin_one-user_data-left">
                            <span>Имя / ИП / ООО</span>
                            <p className="admin_data-user">{currentUser.name}</p>
                        </div>

                        <div className="admin_one-user_data-left">
                            <span>E-mail</span>
                            <p className="admin_data-user">{currentUser.email}</p>
                        </div>

                        <div className="admin_one-user_data-left">
                            <span>Номер телефона</span>
                            <p className="admin_data-user">{currentUser.phone}</p>
                        </div>
                    </div>

                    {currentUser?.organization?.status === 1 && <OneUserFormAccept organizationStatus={currentUser?.organization.type}
                                                                                   organization={currentUser?.organization}
                                                                                   id={currentUser?.id}/>}
                </div>

                <div className="admin_one-user_posters">
                    <div className="admin_one-user_obj-container">
                        <div className="admin_one-user_posters_tabs">
                            <p className={tab === "objects" ? "active" : undefined} onClick={() => setTab("objects")}>
                                Объявления
                            </p>
                            <p className={tab === "money" ? "active" : undefined} onClick={() => setTab("money")}>
                                Счета
                            </p>
                            <p className={tab === "history" ? "active" : undefined} onClick={() => setTab("history")}>
                                История операций
                            </p>
                        </div>
                        {tab === "objects" ? (
                            <ObjectsTab userId={id} />
                        ) : tab === "money" ? (
                            <MoneyTab userId={id} />
                        ) : tab === "history" ? (
                            <HistoryTab userId={id} />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="admin__layout">
                <Loader />
            </div>
        );
    }
};

export default OneObject;
