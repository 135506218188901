import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import InputMask from "react-input-mask";

import { ButtonsGroup } from "../../../components/icons/ButtonsGroup";
import { LogoIcon } from "../../../components/icons/LogoIcon";
import ModalsContext from "../../../store/modals-context";
import getHost from "../../../store/host-store";
import {isAppstore, token} from "../../../App";
import s from "./style.module.sass";

const LoginModal = () => {
    const modal = useContext(ModalsContext);
    const [phone, setPhone] = useState({ value: "", invalid: false, error: "" });

    const onLogin = (e) => {
        e.preventDefault();

        //длина номера с маской
        if (phone.value.length < 18) {
            setPhone({ ...phone, invalid: true, error: "Некорректный номер телефона" });
            return;
        }

        const data = {
            phone: phone.value,
        };

        fetch(
            getHost({
                controller: "user",
                action: "mts-authorize",
            }),
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                modal.setLoginPhone(phone.value);
                if (result.success) {
                    modal.onLoadAuthorization();
                } else {
                    fetch(
                        getHost({
                            controller: "user",
                            action: "get-code",
                            phone: phone.value,
                        }),
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                Authorization: token,
                            },
                        }
                    )
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.success) {
                                modal.codeAuthorization();
                            } else {
                                showErrors(result.data);
                            }
                        });
                }
            });
    };

    function showErrors(errorsList) {
        setPhone({ ...phone, invalid: false });

        errorsList.forEach((error) => {
            switch (error.field) {
                case "phone":
                    setPhone({ ...phone, invalid: true, error: error.message });
                    break;
                default:
            }
        });
    }

    return (
        <div className={s.modal}>
            <form onSubmit={onLogin} className={s.modal__top}>
                <div className={s.logo}>
                    <LogoIcon color={'#4088DD'} />
                </div>
                <h2 className={s.title}>Вход {!isAppstore && "на сайт"} </h2>
                <p className={s.descr}>Необходимо пройти авторизацию</p>
                <div className={s.phone_wrapper}>
                    <ButtonsGroup />
                    <InputMask
                        mask={"+7 (999) 999 99 99"}
                        placeholder="Телефон"
                        maskChar={null}
                        name="inputMask"
                        inputMode="numeric"
                        value={phone.value}
                        onChange={(e) => {
                            setPhone({ ...phone, value: e.target.value });
                        }}
                    />
                    {phone.invalid && <span className={s.error}>{phone.error}</span>}
                </div>
                <button type="submit" className={s.btn_blue}>
                    Войти
                </button>
                <p className={s.terms}>
                    При авторизации Вы подтверждаете согласие с условиями
                    <br />
                    <NavLink to="/userTerm" onClick={() => modal.closeModal()}>
                        &nbsp;пользовательского соглашения&nbsp;
                    </NavLink>
                    и
                    <NavLink to="/personalData" onClick={() => modal.closeModal()}>
                        &nbsp;политикой обработки данных.
                    </NavLink>
                </p>
            </form>
            <div className={s.modal__bottom}>
                <p>Впервые пользуетесь услугами нашего сервиса?</p>
                <button type="button" className={s.btn_green} onClick={() => modal.registerModal()}>
                    Регистрация
                </button>
            </div>
        </div>
    );
};

export default LoginModal;
