import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";

import {ArrowLeftIcon} from "../../../components/icons/ArrowLeftIcon";
import PaginationPattern from "../../../UI/PaginationPattern";
import SearchInput from "../../../UI/SearchInput/SearchInput";
import Loader from "../../../components/loader/Loader";
import DateRangePickerExample from "./CalendarFromTo";
import {link} from "../../../store/host-store";
import UsersList from "../users/UsersList";
import Objects from "./models/Objects";
import {token} from "../../../App";
import Orders from "./models/Orders";
import Marks from "./models/Marks";
import {useSelector} from "react-redux";
import UserModeratedList from "../users/UsersModeratedList";

const UserModeratedStatistic = () => {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]); // инфо по объектам
    const [userData, setUserData] = useState({});
    const [search, setSearch] = useState("");

    const [page, setPage] = useState(1); // текущая страница
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации

    const [model, setModel] = useState("organization"); // модель текущих объектов
    const [status, setStatus] = useState(null); // статус null - все, approve - 1, reject - 2

    const history = useNavigate(); // для возможности вернуться на предыдущую страницу
    const id = useParams().id;

    let pagination = `&pagination[pageSize]=30&page=${page}`;

    //поиск календарь
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // инфо по пользователю и счетчикам модели
    useEffect(() => {
        fetch(`${link}/moderator-statistics/counters?moderator_id=${id}&model=${model}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setUserData(result.data);
                }
            })
            .catch((e) => console.log(e));
    }, [model, id]);

    // вывод объектов в зависимости от модели, страницы пагинации, статуса, календаря и поиска
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        setData([]);
        let fetchLink = `${link}/moderator-statistics?filter[moderator_id]=${id}&filter[model]=${model}${pagination}`;

        // статус одобрено или отклонено
        if (status !== null) {
            fetchLink += `&filter[status]=${status}`;
        }

        // если применяется календарь
        if (startDate !== null) {
            fetchLink += `&filter[date][gte]=${moment(startDate).format("YYYY-MM-DD")}`;
        }
        if (endDate !== null) {
            fetchLink += `&filter[date][lte]=${moment(endDate).format("YYYY-MM-DD")}`;
        }

        //если что-то вбито в поиске
        if (search.length > 0) {
            fetchLink += `&ModeratorStatisticsSearch[query]=${search}&ModeratorStatisticsSearch[model]=${model}`;
        }

        fetch(fetchLink, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json().then((data) => ({data, headers: res.headers})))
            .then((result) => {
                setLoading(false);
                console.log(result.data)
                if (result.data.success) {
                    console.log(result.data)
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    setData(result.data.data);
                }
            })
            .catch((e) => console.log(e));
    }, [status, model, id, pagination, endDate, startDate, search]);

    const onChangeModel = (model) => {
        setLoading(true);
        setPage(1);
        setStatus(null);
        setModel(model);
    };

    const onChangeStatus = (status) => {
        setLoading(true);
        setPage(1);
        setStatus(status);
    };

    return (
        <div className="admin__layout">
            <div className="admin__statistic_back">
                <button title="назад" type="button" className="admin__back-arrow" onClick={() => history(-1)}>
                    <ArrowLeftIcon/>
                </button>
                <h3>{userData.moderator?.name}</h3>
            </div>
            <div className="admin__statistic_model-tabs">
                <div
                    className={`${model === "organization" ? "active" : ""}`}
                    onClick={() => {
                        model !== "organization" && onChangeModel("organization");
                    }}>
                    Профили
                </div>
                <div
                    className={`${model === "advertisement" ? "active" : ""}`}
                    onClick={() => {
                        model !== "advertisement" && onChangeModel("advertisement");
                    }}>
                    Рекламы
                </div>
            </div>
            <div className="admin__statistic_navigation">
                <div className="admin__statistic_navigation_left">
                    <SearchInput setSearch={setSearch}/>
                    <div className="admin__statistic_navigation_calendar">
                        <DateRangePickerExample
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                        <button
                            type="button"
                            className="admin__btn-blue"
                            onClick={() => {
                                setStartDate(null);
                                setEndDate(null);
                            }}>
                            Сброс
                        </button>
                    </div>
                </div>
                <div className="admin__statistic_navigation_right">
                    <div
                        onClick={() => status !== null && onChangeStatus(null)}
                        className={`${status === null ? "active" : ""} admin__statistic_navigation_right_all`}>
                        <p>Обработано</p>
                        <span>{userData.total}</span>
                    </div>
                    <div
                        onClick={() => status !== 1 && onChangeStatus(1)}
                        className={`${status === 1 ? "active" : ""} admin__statistic_navigation_right_approve`}>
                        <p>Опубликованы</p>
                        <span>{userData.approved}</span>
                    </div>
                    <div
                        onClick={() => status !== 2 && onChangeStatus(2)}
                        className={`${status === 2 ? "active" : ""} admin__statistic_navigation_right_reject`}>
                        <p>Отклонены</p>
                        <span>{userData.rejected}</span>
                    </div>
                </div>
            </div>
            {loading ? (
                <Loader/>
            ) : (
                <>
                    {data.length > 0 ? (
                        model === "object" ? (
                            <Objects data={data}/>
                        ) : model === "order" ? (
                            <Orders data={data}/>
                        ) : model === "mark" ? (
                            <Marks data={data}/>
                        ) : (
                            <UserModeratedList
                                dataUsers={data}
                                model={model}
                            />
                        )
                    ) : (
                        <>Нет объектов</>
                    )}

                    <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page}/>
                </>
            )}
        </div>
    );
};

export default UserModeratedStatistic;
