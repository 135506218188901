import {EditIcon} from "../../../../components/icons/EditIcon";
import {CloseIcon} from "../../../../components/icons/CloseIcon";
import {useState} from "react";
import FormModal from "../../../../UI/Modal/FormModal/FormModal";
import {
    useDeleteAbbreviationTagMutation,
    useGetAbbreviationsQuery, useLazyGetAbbreviationTagQuery, usePutAbbreviationTagMutation
} from "../../../../store/redux/queries/abbreviation-query";

export const AbbreviationAdminList = () => {
    const [editAbbreviation, setEditAbbreviation] = useState(false)
    const [name, setName] = useState('')
    const [idTag, setIdTag] = useState()
    const {data: abbreviations} = useGetAbbreviationsQuery()
    const [trigger, {data: abbreviationTag}] = useLazyGetAbbreviationTagQuery()
    const [setEditTag] = usePutAbbreviationTagMutation()
    const [deleteTag] = useDeleteAbbreviationTagMutation()

    const onEdit = async (id) => {
        await trigger(id)
        await setIdTag(id)
        await setEditAbbreviation(true)
    }

    const onDelete = (id) => {
        deleteTag({id})
    }

    const onSave = () => {
        setEditTag({title: {title: name}, id: idTag})
        setEditAbbreviation(false)
    }

  return(
      <div className="abbreviation_list">
          {editAbbreviation && <FormModal title="Редактировать" button="Сохранить" fn={onSave} isOpenModal={setEditAbbreviation}>
              <span>Название</span>
              <input type="text" defaultValue={abbreviationTag.data.title} onChange={(e) => setName(e.currentTarget.value)} />
          </FormModal>}
          {abbreviations?.data.map(item => (
              <div className='abbreviation_list_item' key={item.id}>
                  {item.title}

                  <div className="abbreviation_list_item_edit">
                      <div className="abbreviation_edit_icon" onClick={() => onEdit(item.id)}>
                          <EditIcon />
                      </div>
                      <div className="abbreviation_edit_icon" onClick={() => onDelete(item.id)}>
                          <CloseIcon />
                      </div>
                  </div>
              </div>
          ))}
      </div>
  )
}