import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import { push_unsubscribe, push_subscribe } from "../../pushes";
import { NotificationIcon } from "../../components/icons/NotificationIcon";
import { FavoritesIcon } from "../../components/icons/FavoritesIcon";
import { AsideFeedback } from "../../components/icons/AsideFeedback";
import { ResponseIcon } from "../../components/icons/ResponseIcon";
import { SupportIcon } from "../../components/icons/SupportIcon";
import { RequestIcon } from "../../components/icons/RequestIcon";
import BankCheckIcon from "../../components/icons/BankCheckIcon";
import { RatingIcon } from "../../components/icons/RatingIcon";
import { ObjectIcon } from "../../components/icons/ObjectIcon";
import { AsideUser } from "../../components/icons/AsideUser";
import { PromoIcon } from "../../components/icons/PromoIcon";
import { TermsIcon } from "../../components/icons/TermsIcon";
import { ExitIcon } from "../../components/icons/ExitIcon";
import avatar from "../../assets/images/stubs/avatar.svg";
import DeviceContext from "../../store/device-context";
import ModalsContext from "../../store/modals-context";
import AuthContext from "../../store/auth-context";
import RoleTabs from "../../UI/RoleTabs/RoleTabs";
import { blockerAction } from "../../store/redux";
import { url } from "../../store/host-store";
import s from "./style.module.sass";
import PromotionIcon from "../../components/icons/PromotionIcon";
import AdsIconSvg from "../../components/icons/adIcons/AdsIconSvg";
import {PartnerProgramIcon} from "../../components/icons/PartnerProgramIcon";
import {TwoPeoplesIcon} from "../../components/icons/TwoPeoplesIcon";

const CabinetAside = (props) => {
    const ctx = useContext(AuthContext);
    const device = useContext(DeviceContext);
    const modal = useContext(ModalsContext);

    const [rerender, setRerender] = useState(false);

    const dispatch = useDispatch();
    const blockSelector = useSelector((state) => state.blockerPage.blockPage);

    const navigate = useNavigate();

    const exitAccount = () => {
        localStorage.removeItem("token");
        window.location.replace("/");
    };

    let isPushEnabled = localStorage.getItem("pushes") === "true";

    useEffect(() => {
        isPushEnabled = localStorage.getItem("pushes") === "true";
    }, [rerender]);

    const onNotifications = () => {
        if (isPushEnabled) {
            new Promise((resolve, reject) => {
                resolve(push_unsubscribe());
                reject("failure reason");
            }).then(() => setRerender((prev) => !prev));
        } else {
            new Promise((resolve, reject) => {
                resolve(push_subscribe());
                reject("failure reason");
            }).then(() => setRerender((prev) => !prev));
        }
    };


    return (
        <div className="cabinet__aside">
            {window.innerWidth <= 1026 && (
                <button
                    type="button"
                    title="закрыть меню"
                    className="cabinet__aside_close"
                    onClick={device.toggleAside}></button>
            )}
            <div className={s.top_wrapper}>
                <div className="cabinet__person">
                    {props.data.avatar === null ? (
                        <div className="cabinet__img no_img">
                            <img src={avatar} alt="" />
                        </div>
                    ) : (
                        <div className="cabinet__img">
                            <img src={url + props.data.avatar} alt="" />
                        </div>
                    )}
                    <div>
                        <p className="cabinet__name">{props.data.name}</p>
                        <span>ID {ctx.userData.id}</span>
                        <p className="cabinet__grade">
                            {props.data.mark === null ? "Нет рейтинга" : props.data.mark}
                            <RatingIcon />
                        </p>
                    </div>
                </div>
                <div className={s.toggle}>
                    <label className="switch">
                        <input type="checkbox" onChange={onNotifications} checked={isPushEnabled} />
                        <span className={s.round}></span>
                    </label>
                    <p>Получать уведомления</p>
                </div>
                <NavLink
                    to="/lc/"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={() => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                    }}>
                    <div className="icon">
                        <AsideUser />
                    </div>
                    Главная
                </NavLink>
            </div>
            <div className="cabinet__nav">
                {!window.innerWidth <= 1024 && (
                    <div className={s.tabs_wrapper}>
                        <RoleTabs isSquare firstTitle="Я ищу" secondTitle="Я предлагаю" />
                    </div>
                )}
                <NavLink
                    to="/lc/advertisement"
                    className={(navData) => (navData.isActive ? "cabinet__item_white active" : "cabinet__item_white")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/advertisement");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/advertisement"));
                    }}>
                    <div className="icon">
                        <ObjectIcon />
                    </div>
                    Мои объявления
                    <span className="cabinet__item_counter">{ctx.adsNumber}</span>
                </NavLink>
                <NavLink
                    to="/lc/request"
                    className={(navData) => (navData.isActive ? "cabinet__item_white active" : "cabinet__item_white")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/request");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/request"));
                    }}>
                    <div className="icon">
                        <RequestIcon />
                    </div>
                    Заявки
                    <span className="cabinet__item_counter">{ctx.requestsCount}</span>
                </NavLink>
                <NavLink
                    to="/lc/responses"
                    className={(navData) => (navData.isActive ? "cabinet__item_white active" : "cabinet__item_white")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/responses");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/responses"));
                    }}>
                    <div className="icon">
                        <ResponseIcon />
                    </div>
                    Отклики
                    <span className="cabinet__item_counter">{ctx.responseCount}</span>
                </NavLink>
                {/*!isAppstore  && */ <NavLink
                    to="/lc/promotion "
                    className={(navData) => (navData.isActive ? "cabinet__item_white active" : "cabinet__item_white")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/promotion");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/promotion"));
                    }}>
                    <div className="icon">
                        <PromoIcon/>
                    </div>
                    Продвижение
                </NavLink>}
            </div>
            <div className={s.nav_bottom}>
                <NavLink
                    to="/lc/notifications"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/notifications");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/notifications"));
                    }}>
                    <div className="icon">
                        <NotificationIcon />
                    </div>
                    Уведомления
                    {ctx.notificationsCount > 0 && (
                        <span className="cabinet__item_counter cabinet__item_notif">{ctx.notificationsCount}</span>
                    )}
                </NavLink>
                {/*!isAppstore  && */ <NavLink
                    to="/lc/subscribe"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/subscribe");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/subscribe"));
                    }}>
                    <div className="icon">
                        <PromotionIcon/>
                    </div>
                    Мои подписки
                </NavLink>}
                <NavLink
                    to={(ctx.userData.organization?.status !== 1 || ctx.userData.organization?.confirmed !== 1) ? "/advertiser" : "/advertiser/ad"}
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/advertiser/");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        (ctx.userData.organization?.status !== 1 || ctx.userData.organization?.confirmed !== 1) ? dispatch(blockerAction.getUrl("/advertiser/")) : dispatch(blockerAction.getUrl("/advertiser/ad"));
                        // window.location.replace('/advertiser/')
                    }}>
                    <div className="icon" style={{padding: 0}}>
                        <AdsIconSvg rectfill={'rgba(0, 0, 0, 0.05)'}/>
                    </div>
                    Реклама
                </NavLink>
                <NavLink
                    to="/lc/favorites"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/favorites");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/favorites"));
                    }}>
                    <div className="icon">
                        <FavoritesIcon />
                    </div>
                    Избранное
                </NavLink>
                <NavLink
                    to="/lc/referral"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/referral");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/referral"));
                    }}>
                    <div className="icon">
                        <TwoPeoplesIcon />
                    </div>
                    Бонусная программа
                </NavLink>
                <NavLink
                    to="/lc/referral"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/referral");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/referral"));
                    }}>
                    <div className="icon">
                        <PartnerProgramIcon />
                    </div>
                    Партнерская программа
                </NavLink>
                {/* <NavLink
                    to="/lc/calendar"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={() => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                    }}>
                    <div className="icon">
                        <AsideCalendar />
                    </div>
                    Календарь работы
                </NavLink> */}
                {/*!isAppstore  && */ <NavLink
                    to="/lc/Checks"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/Checks");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/Checks"));
                    }}>
                    <div className="icon">
                        <BankCheckIcon/>
                    </div>
                    Бухгалтерские документы
                </NavLink>}
                <NavLink
                    to="/lc/feedback"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/feedback");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/feedback"));
                    }}>
                    <div className="icon">
                        <AsideFeedback />
                    </div>
                    Отзывы
                </NavLink>
                <NavLink
                    to="/lc/support"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/support");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/support"));
                    }}>
                    <div className="icon">
                        <SupportIcon />
                    </div>
                    Служба поддержки
                </NavLink>
                <NavLink
                    to="/lc/term"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={(e) => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                        blockSelector ? e.preventDefault() : navigate("/lc/term");
                        blockSelector ? modal.blockModal() : modal.closeModal();
                        dispatch(blockerAction.getUrl("/lc/term"));
                    }}>
                    <div className="icon">
                        <TermsIcon />
                    </div>
                    Условия использования
                </NavLink>
                {/* <NavLink
                    to="/lc/settings"
                    className={(navData) => (navData.isActive ? "cabinet__item_blue active" : "cabinet__item_blue")}
                    onClick={() => {
                        device.toggleAside();
                        ctx.setRerender((prev) => !prev);
                    }}>
                    <div className="icon">
                        <SettingsIcon />
                    </div>
                    Настройки
                </NavLink> */}
            </div>
            <div className="exit_link" onClick={exitAccount}>
                <ExitIcon />
                Выйти
            </div>
        </div>
    );
};

export default CabinetAside;
