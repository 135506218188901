export const CategoryIcon = () => {
  return (<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M14.7371 1.83398H17.8409C19.1264 1.83398 20.1679 2.88435 20.1679 4.18062V7.31063C20.1679 8.6069 19.1264 9.65727 17.8409 9.65727H14.7371C13.4517 9.65727 12.4102 8.6069 12.4102 7.31063V4.18062C12.4102 2.88435 13.4517 1.83398 14.7371 1.83398Z"
      fill="white"
    />
    <path
      d="M7.26477 12.344C8.55018 12.344 9.59175 13.3944 9.59175 14.6907V17.8207C9.59175 19.1161 8.55018 20.1673 7.26477 20.1673H4.16096C2.87555 20.1673 1.83398 19.1161 1.83398 17.8207V14.6907C1.83398 13.3944 2.87555 12.344 4.16096 12.344H7.26477ZM17.8403 12.344C19.1257 12.344 20.1673 13.3944 20.1673 14.6907V17.8207C20.1673 19.1161 19.1257 20.1673 17.8403 20.1673H14.7365C13.4511 20.1673 12.4096 19.1161 12.4096 17.8207V14.6907C12.4096 13.3944 13.4511 12.344 14.7365 12.344H17.8403ZM7.26477 1.83398C8.55018 1.83398 9.59175 2.88435 9.59175 4.18062V7.31063C9.59175 8.6069 8.55018 9.65726 7.26477 9.65726H4.16096C2.87555 9.65726 1.83398 8.6069 1.83398 7.31063V4.18062C1.83398 2.88435 2.87555 1.83398 4.16096 1.83398H7.26477Z"
      fill="white"
    />
  </svg>)
}