import {useEffect} from "react";
import {
    useGetDocumentQuery,
    useIsMtsConfirmedQuery,
    useLazyMtsGetLinkQuery
} from "../../store/redux/queries/mts/mts-queries";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const MtsButton = () => {
    const {data: isMtsConfirmed} = useIsMtsConfirmedQuery('');
    const [trigger, {data: linkMts}] = useLazyMtsGetLinkQuery('');
    const isMainCabinetPage = window.location.href.indexOf('/lc') > -1;
    const navigate = useNavigate();
    const organizationRedux = useSelector(state => state.status.organizationRedux)
    // const t1 = <div className="face_div_title">Физ.лицо</div>;
    // const t2 = <div className="face_div_title">Юр.лицо</div>;

    const getMtsLink = async (e) => {
        e.preventDefault()
        trigger()
        if(linkMts?.status === 500){
            alert("Сервис временно недоступен. Попробуйте позже или выберите юр. лицо для подтверждения аккаунта")
        }
    }


    useEffect(() => {
        if(linkMts?.status === 500){
            alert("Сервис временно недоступен. Попробуйте позже или выберите юр. лицо для подтверждения аккаунта")
        }
        console.log(linkMts)
        if(linkMts?.status === 200){
            window.location.href = linkMts?.data
        }
    }, [linkMts])

    const navigateToAdvertiser = () => {
        navigate('/advertiser')
    }
    return(
        <>
        {organizationRedux === 0 &&
        <>
            {!isMainCabinetPage ?
                <>
                    {!isMtsConfirmed || !isMtsConfirmed?.data?.confirmed &&
                        <button style={{marginTop: "10px"}} className="acc_btn_blue" onClick={getMtsLink}>Подтвердить данные</button>}
                </>
                :
                !isMtsConfirmed?.data?.confirmed && <button style={{marginTop: "10px"}} className="acc_btn_blue" onClick={navigateToAdvertiser}>Подтвердить данные</button>
            }


            {isMtsConfirmed?.data?.confirmed === 1 && <div className="mts_confirmed">Ваши паспортные данные проверены</div>}
            {/*{isMtsConfirmed?.data?.confirmed === 1 && <a style={{marginTop: "20px"}} className="acc_btn_blue" download href={getDocument?.data.url}>*/}
            {/*    Скачать документ*/}
            {/*</a>}*/}
        </>
        }
        </>
    )
}
