const LikeSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="none">
            <path
                fill="#B6BFE7"
                fillRule="evenodd"
                d="M5.575 9.736 9.1 5.824c1.2-1.332 1.2-3.494 0-4.825C7.98-.245 6.206-.327 5 .752 3.794-.328 2.02-.245.9 1-.295 2.325-.3 4.475.886 5.808l3.539 3.928c.152.169.36.264.575.264a.775.775 0 0 0 .575-.264Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default LikeSvg;