import {useContext } from "react";
import ModalsContext from "../../../store/modals-context";
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import ModeratorContext from "../../../store/moderator-context";
import AdModeratorContext from "../../../store/ad-moderator-context";


export const ModeratorNotFoundModal = () => {
    const ctx = useContext(ModalsContext);
    const moderator = useContext(ModeratorContext);
    const adModerator = useContext(AdModeratorContext)

    return(
        <div className="modal modalOrdered modal_account_withdraw">
            <div className="closeModal" onClick={() => {
                ctx.closeModal()
                moderator.setOnDelete(false)
                moderator.setRerender(prev => !prev)
                adModerator.setOnDelete(false)
                adModerator.setRerender(prev => !prev)
            }}>
                <ModalCloseSvg />
            </div>
            <div className="title_account_confirmed">Объект не найден</div>
            <div className="withdraw_modal_text">Данное объявление было удалено пользователем и недоступно для проверки. Нажмите "ОК" и подберем новый объект!</div>

            <button type="button" className="acc_btn_blue" onClick={() =>
            {
                moderator.setOnDelete(false)
                moderator.setRerender(prev => !prev)
                adModerator.setOnDelete(false)
                adModerator.setRerender(prev => !prev)
                ctx.closeModal()
            }}>Ок</button>
        </div>
    )
}