export const LikeIcon = () => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.69041 10.7092L10.9204 6.40588C12.3599 4.94133 12.3599 2.56307 10.9204 1.09851C9.57592 -0.269499 7.44779 -0.359791 6.00001 0.827638C4.55223 -0.359791 2.4241 -0.269499 1.07957 1.09851C-0.354254 2.55739 -0.359835 4.92259 1.06283 6.38828L5.30961 10.7092C5.49267 10.8955 5.74104 11 6.00001 11C6.25898 11 6.50734 10.8955 6.69041 10.7092Z"
                fill="#A7C9F0"
            />
        </svg>
    );
};
