const PlaySvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="none">
            <circle cx="14" cy="14" r="14" fill="#6FE1B8" />
            <path
                fill="#546EDB"
                fillRule="evenodd"
                d="M9 9.623c0-1.245 1.33-2.08 2.388-1.352l6.916 4.758c.969.667.918 2.18-.102 2.77l-6.916 3.995C10.226 20.406 9 19.57 9 18.378V9.623Zm1.692-.284c-.162-.112-.442-.015-.442.284v8.755c0 .285.26.39.423.296l6.917-3.996c.202-.117.218-.444.019-.581l-6.917-4.758Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default PlaySvg;