import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import noAvatar from "../../../assets/images/stubs/avatar.svg";
import PaginationPattern from "../../../UI/PaginationPattern";
import SearchInput from "../../../UI/SearchInput/SearchInput";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import Loader from "../../../components/loader/Loader";
import { link, url } from "../../../store/host-store";
import { token } from "../../../App";
import {FilterRole} from "./FilterRole";

const Roles = () => {
    const [page, setPage] = useState(null);
    const [dataRoles, setDataRoles] = useState([]);
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [roleInput, setRoleInput] = useState();

    //необходимо для передачи параметра страницы в url
    const navigate = useNavigate();
    //получаем параметры текущей страницы
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть, то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            //иначе присваиваем стартовое значение 1
            setPage(1);
        }
    }, [params]);

    useEffect(() => {
        window.scrollTo(0, 0);
        //

        if (page !== null) {
            let fetchLink = `${link}/user?expand=account&pagination[pageSize]=100&page=${page}${roleInput?.value ? `&filter[account.role]=${roleInput?.value}` : ''}`;

            //если что-то вбито в поиске
            if (search.length > 0) {
                fetchLink += `&UserSearch[query]=${search}`;
            }

            fetch(fetchLink, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
                .then((result) => {
                    setLoading(false);
                    console.log(result.data)
                    if (result.data.success) {
                        setDataRoles(result.data.data);
                        setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [page, search, roleInput]);

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    return (
        <div className="admin__layout">
            <div className="admin__roles_top">
                <h3>Роли</h3>
                <NavLink className="admin__btn-blue" to={"create"}>
                    Создать
                </NavLink>
            </div>
            <div className="admin__search-wrapper">
                <SearchInput setSearch={setSearch} />
                <FilterRole roleInput={roleInput} setRoleInput={setRoleInput}/>
            </div>
            {loading ? (
                <Loader />
            ) : dataRoles.length > 0 ? (
                <>
                    <div className="admin__list-titles admin__list-titles_roles">
                        <p>Фото</p>
                        <p>Имя</p>
                        <p>E-mail</p>
                        <p>Дата создания</p>
                        <p>Роль</p>
                    </div>
                    <div>
                        {dataRoles.map((el) => (
                            <div className="admin__content-card admin__content-card_roles" key={el.id}>
                                {el.avatar === null ? (
                                    <img src={noAvatar} alt="avatar" />
                                ) : el.webpFilename ? (
                                    <img src={url + el.webpFilename} alt="avatar" />
                                ) : (
                                    <img src={url + el.avatar} alt="avatar" />
                                )}
                                <p>{el.name}</p>
                                <p>{el.email}</p>
                                <p>{el.account.created.split(" ")[0]}</p>

                                <p>
                                    {el.account.role === "admin"
                                        ? "Главный администратор"
                                        : el.account?.role === "user"
                                        ? "Пользователь"
                                        : el.account?.role === "moderator"
                                        ? "Модератор"
                                        : el.account?.role === "ad_moderator"
                                        ? "Модератор рекламы"
                                        : el.account?.role === "advertiser"
                                        ? "Рекламодатель"
                                        : el.account?.role === "fin_admin"
                                        ? "Финансовый администратор"
                                        : el.account?.role === "registrar"
                                        ? "Регистратор объявлений"
                                        : "Администратор"}
                                </p>
                                <div className="admin__content-card-icons">
                                    <NavLink to={`edit/${el.id}`}>
                                        <EyeIcon />
                                    </NavLink>
                                </div>
                            </div>
                        ))}
                    </div>
                    {pageCount > 1 && (
                        <PaginationPattern
                            setChange={(e, value) => setChange(value)}
                            pageCount={pageCount}
                            page={page}
                        />
                    )}
                </>
            ) : (
                <>Пусто</>
            )}
        </div>
    );
};

export default Roles;
