import {NavLink, useNavigate} from "react-router-dom";
import ArrowBack from "../../../request/svg/ArrowBack";
import TypeList from "../../desktopPages/TypeList";
import CreateSearchForm from "./CreateSearchForm";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const CreateSearch = ({ onTypeChange, type, category }) => {
    const navigate = useNavigate();
    const selector = useSelector(state => state.adsSearchLogic)

    useEffect(() => {
        if(selector.statusAds === 0){
            navigate(selector.urlCreate)
            console.log(selector.urlCreate)
        }
    }, [selector.statusAds])

    return(
        <>
            <div className="flex">
                <NavLink to="/lc/advertisement" className="margR6">
                    <ArrowBack />
                </NavLink>
                <h2 className="h2_cabinet_main">Разместить объявление</h2>
            </div>
            <div className="create_ads cabinet_ads__left">
                <div className="advers__add_top">
                    <div className="advers___title">Выберите категорию объявления:</div>
                    <TypeList category={category} type={type} onTypeChange={onTypeChange} />
                </div>
                <CreateSearchForm type={type}/>
            </div>
        </>
    )
}

export default CreateSearch;