import { useContext } from "react";

import { RatingIcon } from "../../../../components/icons/RatingIcon";
import { BlueStarIcon } from "../../../../components/icons/BlueStarIcon";
import object from "../../../../assets/images/stubs/object-mob.png";
import { DottsIcon } from "../../../../components/icons/DottsIcon";
import avatar from "../../../../assets/images/stubs/avatar.svg";
import ModalsContext from "../../../../store/modals-context";
import { url } from "../../../../store/host-store";
import style from "../../style.module.sass";

const SeekResponse = ({ item, isArchive, openSettings, toggleSettings }) => {
    const modal = useContext(ModalsContext);

    return (
        <>
            <div className={style.response__item_top_wrapper}>
                <div className={style.user_info}>
                    {item?.object?.user.avatar === null ? (
                        <img src={avatar} alt="user"></img>
                    ) : (
                        <img src={url + item?.object?.user.avatar} alt="user"></img>
                    )}
                    <p>{item?.object?.user.name}</p>
                    {item?.object?.mark === null ? (
                        <span className={style.norating}>
                            <RatingIcon />
                            0.0
                        </span>
                    ) : (
                        <span>
                            <RatingIcon />
                            {item?.object?.mark}
                        </span>
                    )}
                </div>

                <div className={`${style.object_photo} ${isArchive ? style.disabled : ""}`}>
                    {item.object.image === null ? (
                        <img src={object} alt="object"></img>
                    ) : (
                        <img src={url + item.object.image.filename} alt="object"></img>
                    )}
                    {/*<span>Ваш заказ завершен</span>*/}
                </div>
            </div>

            <div className={style.object_info}>
                <div className={`${style.object_info__title_wrapper} ${isArchive ? style.disabled : ""}`}>
                    <p>{item.object.name}</p>
                    <span>
                        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.5 0C2.57206 0.00107198 1.68243 0.380711 1.02628 1.05563C0.370126 1.73054 0.00104217 2.64562 0 3.60009C0 5.06488 1.03381 6.28126 2.12888 7.56875C2.47538 7.97646 2.83413 8.39812 3.1535 8.82473C3.19437 8.87927 3.24686 8.92343 3.30693 8.95381C3.36701 8.9842 3.43306 9 3.5 9C3.56694 9 3.63299 8.9842 3.69307 8.95381C3.75314 8.92343 3.80563 8.87927 3.8465 8.82473C4.16587 8.39812 4.52462 7.97646 4.87112 7.56875C5.96619 6.28126 7 5.06488 7 3.60009C6.99896 2.64562 6.62987 1.73054 5.97372 1.05563C5.31757 0.380711 4.42794 0.00107198 3.5 0ZM3.5 4.95013C3.24041 4.95013 2.98665 4.87095 2.77081 4.72261C2.55497 4.57426 2.38675 4.36342 2.28741 4.11673C2.18807 3.87004 2.16208 3.5986 2.21272 3.33671C2.26336 3.07483 2.38837 2.83428 2.57192 2.64547C2.75548 2.45667 2.98934 2.32809 3.24394 2.276C3.49854 2.22391 3.76244 2.25064 4.00227 2.35282C4.2421 2.455 4.44708 2.62804 4.5913 2.85005C4.73552 3.07207 4.8125 3.33308 4.8125 3.60009C4.8125 3.95815 4.67422 4.30153 4.42808 4.55471C4.18194 4.80789 3.8481 4.95013 3.5 4.95013Z"
                                fill="#B6BFE7"
                            />
                        </svg>
                        {item.object.city.name}
                    </span>
                </div>
                <p className={style.descr}>{item.object.about}</p>
                <div className={`${style.response__info} ${isArchive ? style.disabled : ""}`}></div>
            </div>
            {isArchive ? (
                <div className={`${style.contact} ${style.contact_disabled}`}>
                    <div className={style.contact__call}>
                        <p className={style.contact__call_link}>Позвонить</p>
                        <div className={style.contact__settings}>
                            <DottsIcon />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={style.contact}>
                    <div className={style.contact__call}>
                        <a className={style.contact__call_link} onClick={() => modal.modalTel(item?.object.user?.phone)}>
                            Позвонить
                        </a>
                        {(!item?.markExist && item.object?.on_moderation !== 1 && item.object?.moderator_id !== null) && (
                            <div className={style.contact__settings} onClick={() => toggleSettings(item.id)}>
                                <DottsIcon />

                                {openSettings === item.id && (
                                    <div className={style.contact__settings_btns}>
                                        <button onClick={() => modal.feedbackModal(item.object.user.id, item.object.id)}>
                                            <BlueStarIcon />
                                            Оставить отзыв
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SeekResponse;
