import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {link} from "../../../host-store";
import {token} from "../../../../App";

export const partnerProgramAdminQuery = createApi({
    reducerPath: "partnerProgramAdmin",
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["PartnerProgramAdmin, PartnerWalletAdmin"],
    endpoints: (builder) => ({
        getUserFromPartnerProgram: builder.query({
            query: () => ({
                url: "/affiliate-withdrawal?expand=user, user.affiliateContract",
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["PartnerProgramAdmin"],
        }),
        getAllDataWallet: builder.query({
            query: () => ({
                url: "/wallet/general-statistics",
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["PartnerWalletAdmin"],
        }),
        getUserFromPartnerProgramProcessed: builder.query({
            query: () => ({
                url: "/affiliate-withdrawal/processed?expand=user, user.affiliateContract",
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["PartnerProgramAdmin"],
        }),
        acceptUserPartnerProgram: builder.mutation({
            query: ({id}) => ({
                url: `/affiliate-withdrawal/confirm?id=${id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token
                }
            }),
            invalidatesTags: ["PartnerProgramAdmin"],
        }),
        declineUserPartnerProgram: builder.mutation({
            query: ({id}) => ({
                url: `/affiliate-withdrawal/reject?id=${id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token
                }
            }),
            invalidatesTags: ["PartnerProgramAdmin"],
        }),
    })
})

export const {useGetUserFromPartnerProgramQuery, useGetUserFromPartnerProgramProcessedQuery, useAcceptUserPartnerProgramMutation,
    useDeclineUserPartnerProgramMutation, useGetAllDataWalletQuery} = partnerProgramAdminQuery