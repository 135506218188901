const AccordionSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
            <path fill="#546EDB" d="M0 4a4 4 0 0 1 4-4h17a4 4 0 0 1 4 4v17a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z" />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M8.251 10.258a.9.9 0 0 0 0 1.248l3.143 3.236a.84.84 0 0 0 1.212 0l3.143-3.236a.9.9 0 0 0 0-1.248.84.84 0 0 0-1.212 0L12 12.87l-2.537-2.612a.84.84 0 0 0-1.212 0Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default AccordionSvg;
