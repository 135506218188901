import { useContext } from "react";

import { CloseIcon } from "../../../components/icons/CloseIcon";
import ModalsContext from "../../../store/modals-context";
import s from "./style.module.sass";

const ModalOpenedContacts = (props) => {
    const ctx = useContext(ModalsContext);

    return (
        <div className={`modal ${s.modal}`}>
            <div className={s.close} onClick={ctx.closeModal}>
                <CloseIcon />
            </div>
            <h3>Контакты</h3>
            <p className={s.descr}>Телефон для связи:</p>
            <a style={{color: '#000'}} className={s.phone_wrapper} href={`${ctx.tel !== 0 ? 'tel:' + ctx.tel : 'tel:' + props.userPhone}`}>
                {ctx.tel !== 0 ? ctx.tel : props.userPhone}
            </a>

            <button type="button" onClick={ctx.closeModal}>
                Закрыть
            </button>
        </div>
    );
};

export default ModalOpenedContacts;
