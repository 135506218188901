export const EyeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="white" />
            <g clipPath="url(#clip0_6_572)">
                <path
                    d="M12.0002 9.82031C10.7965 9.82031 9.81836 10.7985 9.81836 12.0021C9.81836 13.2058 10.7965 14.1839 12.0002 14.1839C13.2038 14.1839 14.182 13.2058 14.182 12.0021C14.182 10.7985 13.2038 9.82031 12.0002 9.82031Z"
                    fill="#4088DD"
                />
                <path
                    d="M12 6.54688C8.36364 6.54688 5.25819 8.80867 4 12.0014C5.25819 15.1941 8.36364 17.456 12 17.456C15.64 17.456 18.7418 15.1941 20 12.0014C18.7418 8.80867 15.64 6.54688 12 6.54688ZM12 15.6378C9.99274 15.6378 8.36364 14.0087 8.36364 12.0014C8.36364 9.99412 9.99274 8.36505 12 8.36505C14.0073 8.36505 15.6364 9.99415 15.6364 12.0014C15.6364 14.0087 14.0073 15.6378 12 15.6378Z"
                    fill="#4088DD"
                />
            </g>
            <defs>
                <clipPath id="clip0_6_572">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>
    );
};
