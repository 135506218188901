import s from "../../../moderator/main/object/components/style.module.sass";
import CorrectBtn from "../../../moderator/buttons/CorrectBtn";
import IncorrectBtn from "../../../moderator/buttons/IncorrectBtn";

const LinkAds = ({ object, incorrectClick, correctClick, isActive }) => {
  return(
      <div className={`${s.card} ${isActive ? s.active : ""}`}>
          <ul>
              <li>
                  <h5>Ссылка</h5>
                  <p className={s.card__about}>
                      <a className={s.card__link} target='_blank' href={object.link}>{object.link}</a>
                  </p>
              </li>
          </ul>
          <div>
              <CorrectBtn isActive={isActive} correctClick={() => correctClick("link")} />
              <IncorrectBtn incorrectClick={incorrectClick} />
          </div>
      </div>
  )
}

export default LinkAds;