const TelegramSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            fill="none">
            <path
                fill="#B6BFE7"
                d="M8.66.34A1.156 1.156 0 0 0 7.49.055L.814 2.153a1.16 1.16 0 0 0-.094 2.18L3.2 5.355l1.23-1.231a.316.316 0 0 1 .448.447l-1.23 1.231 1.02 2.48A1.148 1.148 0 0 0 5.737 9h.053a1.15 1.15 0 0 0 1.057-.812L8.946 1.51A1.156 1.156 0 0 0 8.659.341Z"
            />
        </svg>
    )
}

export default TelegramSvg;