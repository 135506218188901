import React, { useEffect, useState } from "react";

import ModalRegister from "../../UI/Modal/ModalRegister";
import Registration from "./Registration";
import "./login.sass";
import { useLocation } from "react-router-dom";

const LoginMain = () => {
    const [modal, setModal] = useState(false);

    const location = useLocation();
    const referral = new URLSearchParams(location.search).get("ref") || "";

    useEffect(() => {
        if (!referral) {
            window.location.replace("/");
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="login">
            {modal && (
                <ModalRegister
                    title="Регистрация завершена"
                    onClick={() => setModal(false)}
                    className="modal_register"
                    titleBtn="Войти"
                    classNameTitle="modal_title"
                    classNameBtn="modal_btn"
                />
            )}

            <div className="login__banner"></div>
            <div className="login__block">
                <div className="links">
                    <div className="active link">Регистрация</div>
                </div>
                <Registration referral={referral} />
            </div>
        </section>
    );
};

export default LoginMain;
