import Slider from "react-slick";

import noImage from "../../../../assets/images/stubs/object-mob.png";
import IncorrectBtn from "../../../buttons/IncorrectBtn";
import CorrectBtn from "../../../buttons/CorrectBtn";
import {link, url} from "../../../../store/host-store";
import s from "./style.module.sass";
import {token} from "../../../../App";
import {useContext} from "react";
import ModeratorContext from "../../../../store/moderator-context";

import uuid from "react-uuid";
import {Fancybox} from "../../../../UI/Fancybox/Fancybox"


const ImageAdv = ({ data, incorrectClick, correctClick, isActive }) => {
    const adModeratorLink = window.location.href.indexOf('ad_moderator') > -1;

    const ctx = useContext(ModeratorContext);
    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
    };

    const declineImage = (event) => {
        fetch(`${link}/image/${event.target.getAttribute('dataindex')}` , {
            method: 'DELETE',
            headers: {
                Accept: "application/json",
                Authorization: token,
                "Content-Type": "application/json",
            }
        })
            .then(res => res.json()).then(() => window.location.reload())
    }

    return (
        <div className={`${s.card} ${s.card__with_photo} ${isActive && s.active}`}>
            {adModeratorLink ?
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}>
                    <a data-fancybox="gallery" href={url + data?.image}>
                <img style={{width: "-webkit-fill-available"}} src={url + data?.image} alt="object" />
                    </a>
                </Fancybox>
                    :
                data.images !== undefined && data.images.length > 0 ? (
                    <Fancybox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}>
                <Slider {...settings} className={`slick_List slick_List_moderator`}>
                    {data.images.map((img, i) => {
                        return (
                            <div key={uuid()} dataindex={img.id} className={s.img_relative}>
                                <span onClick={declineImage} dataindex={img.id} className={s.decline}>x</span>
                                <a data-fancybox="gallery" href={url + img.filename} key={i}>
                                <img style={{borderRadius: '10px'}} src={url + img.filename} alt="object" />
                                </a>
                            </div>
                        );
                    })}
                </Slider>
                    </Fancybox>
            ) : (
                <img src={noImage} alt="object" />
            )}

            <div className={s.btns}>
                <CorrectBtn isActive={isActive} correctClick={() => correctClick("img")} />
                <IncorrectBtn incorrectClick={incorrectClick} />
            </div>
        </div>
    );
};

export default ImageAdv;
