import { useContext, useState, useEffect } from "react";

import CustomScrollbar from "../../../UI/Scrollbar/CustomScrollbar";
import getHost, { link } from "../../../store/host-store";
import ModalsContext from "../../../store/modals-context";
import AuthContext from "../../../store/auth-context";
import OfferResponse from "./responseTypes/OfferResponse";
import { useSelector } from "react-redux";
import style from "../style.module.sass";
import { token } from "../../../App";

const RequestList = ({ activeCard, objectId, mobileVisibleCart, responseTab, setResponseTab }) => {
    let heightScroll = mobileVisibleCart ? 570 : 400;

    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);
    const [requestsArchive, setRequestArchive] = useState([]);
    const [requests, setRequests] = useState([]);

    const [activeContractorCount, setActiveContractorCount] = useState(0);
    const [archiveContractorCount, setArchiveContractorCount] = useState(0);
    const seekType = useSelector((state) => state.adsSearchLogic.statusAds);

    useEffect(() => {
        setRequests([]);
        setRequestArchive([]);
        //получаем количество архив, активные
        fetch(`${link}/object/confirmed-order-implementer-status-list?id=${objectId}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setActiveContractorCount(result.data.amountOfActive);
                    setArchiveContractorCount(result.data.amountOfArchive);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    }, [objectId]);

    useEffect(() => {
        if (objectId !== 0) {
            if (responseTab === 0) {
                getActive();
            }
            if (responseTab === 1) {
                getArchive();
            }
        }
    }, [objectId, responseTab]);

    function getArchive() {
        fetch(
            `${link}/order/archive?filter[object_id]=${objectId}&filter[object.user_id]=${ctx.isUserId}&expand=object, user, object.city`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);

                if (result.success) {
                    setRequestArchive(result.data);
                }
            });
    }

    function getActive() {
        //получаем сами заявки
        fetch(
            `${link}/order?filter[object_id]=${objectId}&filter[order.confirmed]=1&filter[object.user_id]=${ctx.isUserId}&expand=object, user, bookings, object.user`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log("objectId", result);
                if (result.success) {
                    setRequests(result.data);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    }

    const onDeleteResponse = (id) => {
        fetch(
            getHost({
                controller: "order",
                action: "add-deleted",
                id,
            }),
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    //перерисовать эту компоненту!
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    };

    return (
        objectId !== 0 && (
            <div className={`${style.response__order} ${mobileVisibleCart && style.visible}`}>
                <div className={style.response__order_tabs}>
                    <p
                        className={`${style.response__order_tab} ${responseTab === 0 ? style.active : ""}`}
                        onClick={() => {
                            setResponseTab(0);
                        }}>
                        Активные ({activeContractorCount})
                    </p>
                    <p
                        className={`${style.response__order_tab} ${responseTab === 1 ? style.active : ""}`}
                        onClick={() => {
                            setResponseTab(1);
                        }}>
                        Архив ({archiveContractorCount})
                    </p>
                </div>
                <CustomScrollbar style={{ height: heightScroll }} renderTrack={renderTrack}>
                    {/* мапятся активные заявки */}
                    <div style={{ padding: "0 20px" }}>
                        {responseTab === 0
                            ? requests.map((item) => {
                                  return (
                                      <OfferResponse
                                          item={item}
                                          key={item.id}
                                          onDeleteResponse={onDeleteResponse}
                                          activeCard={activeCard}
                                          modal={modal}
                                          isSeek={seekType}
                                      />
                                  );
                              })
                            : requestsArchive.map((item) => {
                                  // мапятся архивные заявки
                                  return (
                                      <OfferResponse
                                          item={item}
                                          key={item.id}
                                          onDeleteResponse={onDeleteResponse}
                                          activeCard={activeCard}
                                          modal={modal}
                                          isSeek={seekType}
                                          isArchive
                                      />
                                  );
                              })}
                    </div>
                </CustomScrollbar>
            </div>
        )
    );
};

export default RequestList;
function renderTrack({ style, ...props }) {
    const track = {
        background: "#E7EAF5",
        borderRadius: "20px",
        height: "100%",
        right: "5px",
    };
    return <div style={{ ...style, ...track }} {...props} />;
}
