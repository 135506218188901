import {url} from "../../../store/host-store";

const UserAdsInfo = ({data}) => {
    console.log(data)

    return (
        <>
            {data !== null &&
                <div className="advs_moderated_block">
                    <div className="advs_moderated_block_left">
                        <div className="advs_moderated_title">Изображение рекламы:</div>
                        <img width={244} src={url + data?.image} alt=""/>
                    </div>
                    <div className="advs_moderated_block_right">
                        <div className="advs_moderated_flex">
                            <div className="advs_moderated_title">Название рекламы: <span>{data?.title}</span></div>
                        </div>
                        <div className="advs_moderated_flex">
                            <div className="advs_moderated_title">Описание рекламы: <span>{data?.description}</span></div>
                        </div>
                        <div className="advs_moderated_flex">
                            <div className="advs_moderated_title">Ссылка: <span>{data?.link}</span></div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserAdsInfo;