import CustomScrollbar from "../../../UI/Scrollbar/CustomScrollbar";
import AdsBottom from "../../advertisement/desktopPages/AdsBottom";
import ObjectsTypes from "../../../UI/ObjectsTypes/ObjectsTypes";
import object from "../../../assets/images/stubs/object-mob.png";
import PaginationPattern from "../../../UI/PaginationPattern";
import { url } from "../../../store/host-store";
import s from "../style.module.sass";
import AdvertisementPromotion from "../../advertisement/AdvertisementPromotion";
import {useNavigate} from "react-router-dom";

const ObjectsList = ({ type, setType, objects, category, pageCount, page, setPage }) => {
    const navigate = useNavigate();

    const promotionExtend = async (id) => {
        await localStorage.setItem('promo', "extendItem=" + id + '');
        await navigate('/lc/promotion/rate');
    };

    return (
        <div className={s.objects_wrapper}>
            <div className={s.types}>
                <h6 className="cabinet__redesign_subtitle">Категории:</h6>
                <ObjectsTypes setPage={setPage} toggleCategory={setType} type={type} category={category} />
            </div>
            <div className={s.objects_list}>
                <div>
                    {objects.length > 0 ? (
                        <CustomScrollbar style={{ height: 600 }}>
                            {objects.map((item) => {
                                return (
                                    <div key={item.id} style={{width: "98%"}} className={"cabinet_ads__item advers_promoted"}>
                                        <div className="adver_wrap__flex">
                                            <div className="img imgAdvers">
                                                {item.image === null ? (
                                                    <img src={object} alt="object" />
                                                ) : (
                                                    <img src={url + item.image.filename} alt="object" />
                                                )}
                                            </div>
                                            <div className="ads__descr_preview">
                                                <h3 className="title">{item.name}</h3>
                                                <div className="advers__medium__flex">
                                                    <p className="advers__about">{item.about.replaceAll(`<br />`, '').slice(0, 100)}</p>
                                                    {item.seek === 0 && (
                                                        <div className="advers_prices">
                                                            <div className="advs_price">
                                                                <h5>{item.price_1} ₽</h5>
                                                                <span>{item.price_1_name}</span>
                                                            </div>
                                                            <div className="advs_price price_sm">
                                                                <h5>{item.price_2} ₽</h5>
                                                                <span>{item.price_2_name}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <AdsBottom status={item.status} seek={item.seek} item={item} />
                                            </div>
                                        </div>
                                        {(item.activeShow !== null || item.activeFixation !== null) || (item.activeShow !== null && item.activeFixation !== null)
                                            ?
                                            <AdvertisementPromotion promotionExtend={promotionExtend} item={item} /> : ''}
                                    </div>
                                );
                            })}
                        </CustomScrollbar>
                    ) : (
                        <p>В данной категории нет объявлений на продвижении</p>
                    )}
                </div>
                <div className={s.pagination}>
                    <PaginationPattern pageCount={pageCount} setChange={(e, value) => setPage(value)} page={page} />
                </div>
            </div>
        </div>
    );
};
export default ObjectsList;
