export const BucketIcon = () => {
    return (
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.75 1.3612C2.75 0.609431 3.36561 0 4.125 0H6.875C7.63439 0 8.25 0.609431 8.25 1.3612V1.94363H9.625C10.3844 1.94363 11 2.55306 11 3.30483V4.47158C11 4.79377 10.7362 5.05495 10.4107 5.05495H10.2143V12.6388C10.2143 13.3906 9.59868 14 8.83929 14H2.16071C1.40132 14 0.785714 13.3906 0.785714 12.6388V5.05495H0.589286C0.263832 5.05495 0 4.79377 0 4.47158V3.30483C0 2.55306 0.615609 1.94363 1.375 1.94363H2.75V1.3612ZM7.07143 1.94363H3.92857V1.3612C3.92857 1.25381 4.01652 1.16675 4.125 1.16675H6.875C6.98348 1.16675 7.07143 1.25381 7.07143 1.3612V1.94363ZM3.30547 3.11038H1.375C1.26652 3.11038 1.17857 3.19744 1.17857 3.30483V3.88821H1.37433C1.37455 3.88821 1.37478 3.88821 1.375 3.88821H9.625C9.62522 3.88821 9.62545 3.88821 9.62567 3.88821H9.82143V3.30483C9.82143 3.19744 9.73348 3.11038 9.625 3.11038H7.69453C7.68334 3.111 7.67206 3.11132 7.66071 3.11132H3.33929C3.32794 3.11132 3.31666 3.111 3.30547 3.11038ZM1.96429 5.05495V12.6388C1.96429 12.7462 2.05223 12.8333 2.16071 12.8333H8.83929C8.94777 12.8333 9.03571 12.7462 9.03571 12.6388V5.05495H1.96429ZM3.53571 6.2217C3.86117 6.2217 4.125 6.48288 4.125 6.80507V11.0831C4.125 11.4053 3.86117 11.6665 3.53571 11.6665C3.21026 11.6665 2.94643 11.4053 2.94643 11.0831V6.80507C2.94643 6.48288 3.21026 6.2217 3.53571 6.2217ZM5.5 6.2217C5.82545 6.2217 6.08929 6.48288 6.08929 6.80507V11.0831C6.08929 11.4053 5.82545 11.6665 5.5 11.6665C5.17455 11.6665 4.91071 11.4053 4.91071 11.0831V6.80507C4.91071 6.48288 5.17455 6.2217 5.5 6.2217ZM7.46429 6.2217C7.78974 6.2217 8.05357 6.48288 8.05357 6.80507V11.0831C8.05357 11.4053 7.78974 11.6665 7.46429 11.6665C7.13883 11.6665 6.875 11.4053 6.875 11.0831V6.80507C6.875 6.48288 7.13883 6.2217 7.46429 6.2217Z"
                fill="#546EDB"
            />
        </svg>
    );
};
