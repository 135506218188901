import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ObjectsWithRequests from "./objectsWithRequests/ObjectsWithRequests";
import ResponsesList from "./responsesList/ResponsesList";
import Loader from "../../components/loader/Loader";
import ArrowBack from "../request/svg/ArrowBack";
import { link } from "../../store/host-store";
import ResponseTypes from "./ResponseTypes";
import ResponseTabs from "./ResponseTabs";
import style from "./style.module.sass";
import { token } from "../../App";

const ResponseMain = () => {
    let location = useLocation();
    const seekType = useSelector((state) => state.adsSearchLogic.statusAds);

    // табы я заказчик-я исполнитель
    // !!проверка на state необходима для подсвечивания заявки при переходе с заявок!!
    const [activeTab, setActiveTab] = useState(
        location.state === null ? "customer" : seekType ? "customer" : "contractor"
    );

    const [categories, setCategory] = useState([]); // инфа по основным категориям

    const [loading, setLoading] = useState(true);

    // !!проверка на state необходима для подсвечивания объявления при переходе с заявок!!
    const [type, setType] = useState(location.state === null ? 0 : location.state.type); //активный таб категории

    const [mobileVisibleCart, setMobileVisibleCart] = useState(false);

    useEffect(() => {
        //получили категории (type)
        fetch(`${link}/category/account-list?seek=${seekType}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (result.success) {
                    setCategory(result.data);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    }, [seekType]);

    //счетчики для табов я заказчик-я исполнитель
    const customerResponsesCounter = categories.reduce(function (accumulator, currentValue) {
        if (seekType) {
            return accumulator + Number(currentValue.confirmedOrdersCountByTypeImplementer);
        } else {
            return accumulator + Number(currentValue.confirmedOrdersCountByTypeClient);
        }
    }, 0);

    const contractorResponsesCounter = categories.reduce(function (accumulator, currentValue) {
        if (seekType) {
            return accumulator + Number(currentValue.confirmedOrdersCountByTypeClient);
        } else {
            return accumulator + Number(currentValue.confirmedOrdersCountByTypeImplementer);
        }
    }, 0);

    return (
        <div className="cabinet__redesign_container">
            <div className={style.response__back}>
                {mobileVisibleCart && (
                    <div className="arrow_back" onClick={() => setMobileVisibleCart(false)}>
                        <ArrowBack />
                    </div>
                )}
                <h3 className="cabinet__redesign_title">Отклики</h3>
            </div>

            <div className={style.response}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <ResponseTabs
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            contractorCounter={contractorResponsesCounter}
                            customerCounter={customerResponsesCounter}
                        />

                        <ResponseTypes
                            toggleCategory={toggleCategory}
                            type={type}
                            category={categories}
                            activeTab={activeTab}
                            isSeek={seekType}
                        />
                        {seekType === 0 ? (
                            //я предлагаю
                            //я заказчик
                            activeTab === "customer" ? (
                                <ResponsesList type={type} />
                            ) : (
                                //я исполнитель
                                <ObjectsWithRequests
                                    setLoading={setLoading}
                                    mobileVisibleCart={mobileVisibleCart}
                                    setMobileVisibleCart={setMobileVisibleCart}
                                    type={type}
                                />
                            )
                        ) : // я ищу
                        activeTab === "customer" ? (
                            //я заказчик
                            <ObjectsWithRequests
                                setLoading={setLoading}
                                mobileVisibleCart={mobileVisibleCart}
                                setMobileVisibleCart={setMobileVisibleCart}
                                type={type}
                            />
                        ) : (
                            //я исполнитель
                            <ResponsesList type={type} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
    function toggleCategory(type) {
        setType(type);
        location.state = null;
    }
};

export default ResponseMain;
