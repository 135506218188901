import { SearchIcon } from "../../components/icons/SearchIcon";

const SearchInput = ({ setSearch, fetch = p => {}}) => {
    return (
        <div className="admin__statistic_navigation_left_search">
            <input
                type="text"
                placeholder="Поиск"
                onChange={(e) => {
                    setSearch(e.target.value);
                }}></input>
            <button title="поиск" type="button" onClick={() => fetch(prev => !prev)}>
                <SearchIcon />
            </button>
        </div>
    );
};

export default SearchInput;
