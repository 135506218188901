import { useEffect, useState, useContext } from "react";

import Points from "../cabinetInfo/walletAndPoints/Points";
import PaginationPattern from "../../UI/PaginationPattern";
import DeviceContext from "../../store/device-context";
import Loader from "../../components/loader/Loader";
import DesktopReferral from "./DesktopReferral";
import MobileReferral from "./MobileReferral";
import getHost from "../../store/host-store";
import style from "./style.module.sass";
import {token} from "../../App";
import {ReferralToPartnerChange} from "./ReferralToPartnerChange";
import AuthContext from "../../store/auth-context";

const Referral = () => {
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1); // Страница пагинации
    const [users, setUsers] = useState([]);
    const [usedCounter, setUsedCounter] = useState(0);
    const [activateCounter, setActivateCounter] = useState(0);
    const device = useContext(DeviceContext);
    const ctx = useContext(AuthContext);
    console.log(ctx.userData?.wallet.affiliate_money, ctx.userData?.wallet.points)

    useEffect(() => {
        fetch(
            getHost({
                controller: "user",
                action: "referrals",
                pagination: {
                    pageSize: 10,
                    page,
                },
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                console.log(result);
                setLoading(false);
                if (result.data.success) {
                    setPageCount(parseInt(result.headers.get("X-Pagination-Page-Count")));
                    setUsers(result.data.data);
                }
            });

        fetch(
            getHost({
                controller: "user",
                action: "referrals-count",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    setUsedCounter(result.data.referals_used);
                    setActivateCounter(result.data.referals_active);
                }
            });
    }, [page]);

    return (
        <div className="cabinet__redesign_container">
            <h3 className="cabinet__redesign_title">Статистика бонусной и партнёрской программы</h3>

            <div className={style.ref}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className={style.top}>
                            <div className={style.top__left}>
                                <div className={style.top__info}>
                                    <div className={style.top__info_card}>
                                        <span>{activateCounter}</span>
                                        <p>Воспользовалось ссылкой и реф. кодом</p>
                                    </div>
                                    <div className={style.top__info_card}>
                                        <span className={style.points}>{usedCounter}</span>
                                        <p>Активировало программу</p>
                                    </div>
                                    <div className={style.top__info_card}>
                                        <span className={style.points}>{ctx.userData?.wallet.affiliate_money} ₽</span>
                                        <div className={style.points_money}>{ctx.userData?.wallet.points} баллов</div>
                                        <p>Баланс</p>
                                    </div>
                                </div>

                                <p className={style.top__text}>
                                    <span>*</span> Дополнительная информацию по работе бонусной и партнерской программы расположена в разделе «Часто задаваемые вопросы»."
                                </p>
                            </div>
                            <div className={style.top__right}>
                                <Points />
                            </div>
                        </div>
                        <div className={style.bottom}>
                            <div className={style.flex}>
                                <h2>Статистика</h2>

                                <ReferralToPartnerChange />
                            </div>
                            {device.isMobile ? <MobileReferral users={users} /> : <DesktopReferral users={users} />}
                            <PaginationPattern
                                setChange={(e, value) => setPage(value)}
                                pageCount={pageCount}
                                page={page}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Referral;
