import {useState} from "react";
import FormModal from "../../../../UI/Modal/FormModal/FormModal";
import {AbbreviationAdminList} from "./AbbreviationAdminList";
import {usePostAbbreviationTagMutation} from "../../../../store/redux/queries/abbreviation-query";

export const EditAbbreviation = () => {
    const [modal, setModal] = useState(false)
    const [name, setName] = useState('')
    const [setTitleAbbreviation, data] = usePostAbbreviationTagMutation()

    const onAdd = () => {
        setModal(true)
        setName('')
    }

    const submit = () => {
        setTitleAbbreviation({title: {title: name}})
        setModal(false)
        console.log(name)
    }

  return(
      <div className="edit_abbreviation">
          {modal && (
              <FormModal
                  title="Добавить новую аббревиатуру"
                  button="Добавить"
                  fn={submit}
                  isOpenModal={setModal}>
                  <span>Название</span>
                  <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} />
              </FormModal>
          )}
          <div className="flex">
              <h3>Список аббревиатур</h3>
              <div className="admin__btn-blue" onClick={onAdd}>Добавить</div>
          </div>

          <AbbreviationAdminList />
      </div>
  )
}