export const PostIcon = () => {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 2.75C0 1.23122 1.23122 0 2.75 0H15.25C16.7688 0 18 1.23122 18 2.75V11.25C18 12.7688 16.7688 14 15.25 14H2.75C1.23122 14 0 12.7688 0 11.25V2.75ZM2.75 1.5C2.05964 1.5 1.5 2.05964 1.5 2.75V11.25C1.5 11.9404 2.05964 12.5 2.75 12.5H15.25C15.9404 12.5 16.5 11.9404 16.5 11.25V2.75C16.5 2.05964 15.9404 1.5 15.25 1.5H2.75ZM3.18056 3.26466C3.45012 2.95017 3.9236 2.91375 4.23809 3.18331L9 7.26495L13.7619 3.18331C14.0764 2.91375 14.5499 2.95017 14.8194 3.26466C15.089 3.57916 15.0526 4.05263 14.7381 4.3222L9.48809 8.8222C9.20723 9.06294 8.79277 9.06294 8.51191 8.8222L3.26191 4.3222C2.94741 4.05263 2.91099 3.57916 3.18056 3.26466Z"
                fill="black"
            />
        </svg>
    );
};
