import {PartnerProgramBanner} from "./PartnerProgramBanner";
import {PartnerProgramVideo} from "./PartnerProgramVideo";
import {PartnerProgramPayment} from "./PartnerProgramPayment";
import {PartnerProgramStatistic} from "./PartnerProgramStatistic";
import "./partner_programm.scss";

export const PartnerProgram = () => {
  return(
      <div className="partner_programms">
          <PartnerProgramBanner />

          <div className="container">
              <PartnerProgramVideo />
              <PartnerProgramPayment />
              <PartnerProgramStatistic />
          </div>
      </div>
  )
}