import { useContext, useEffect, useRef, useState } from "react";
import {NavLink, useNavigate} from "react-router-dom";

import userIcon from "../../../assets/images/stubs/avatar.svg";
import ModalsContext from "../../../store/modals-context";
import AuthContext from "../../../store/auth-context";
import SelectsDrop from "./headerSelects/selects";
import { url } from "../../../store/host-store";
import { isAppstore } from "../../../App";
import "../header.sass";
import {useDispatch, useSelector} from "react-redux";
import {blockerAction, modalsSliceAction} from "../../../store/redux";

const Headertop = () => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);
    const modalSelector = useSelector(state => state.modalsSlice.headerCityOpen);
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const cityLabel = ctx.location?.city ? ctx.location?.city : "Город не выбран";

    const dispatch = useDispatch();
    const selector = useSelector(state => state.blockerPage.blockPage);

    const navigate = useNavigate();

    useEffect(() => {
        if(modalSelector){
            setIsOpenPopup(true)
        }else {
            setIsOpenPopup(false)
        }
    }, [modalSelector])

    return (
        <div className="header__top">
            <div className="container">
                <div className="box">
                    <div className="left">
                        <NavLink to="/about" onClick={(e) => {
                            modal.closeModal()
                            selector ? e.preventDefault() : navigate('/about')
                            selector ? modal.blockModal() : modal.closeModal()
                            dispatch(blockerAction.getUrl('/about'))
                        }} className="item">
                            О проекте
                        </NavLink>
                        {/*!isAppstore  && */<NavLink to="/administrants-info"
                                  onClick={(e) => {
                                      modal.closeModal()
                                      selector ? e.preventDefault() : navigate('/administrants-info')
                                      selector ? modal.blockModal() : modal.closeModal()
                                      dispatch(blockerAction.getUrl('/administrants-info'))
                                  }}
                                  className="item">
                            Информация для исполнителей
                        </NavLink>}
                        {/*!isAppstore  && */<NavLink to="/customers-info"
                                  onClick={(e) => {
                                      modal.closeModal()
                                      selector ? e.preventDefault() : navigate('/customers-info')
                                      selector ? modal.blockModal() : modal.closeModal()
                                      dispatch(blockerAction.getUrl('/customers-info'))
                                  }} className="item">
                            Информация для заказчиков
                        </NavLink>}
                        {/*!isAppstore  && */ (
                            <NavLink to="/application"
                                     onClick={(e) => {
                                         modal.closeModal()
                                         selector ? e.preventDefault() : navigate('/application')
                                         selector ? modal.blockModal() : modal.closeModal()
                                         dispatch(blockerAction.getUrl('/application'))
                                     }}
                                     className="item">
                                Мобильное приложение
                            </NavLink>
                        )}
                    </div>
                    <div className="right">
                        <button className="city" title="выбрать город" type="button">
                            <p onClick={() => {
                                setIsOpenPopup(true)
                                dispatch(modalsSliceAction.changeHeaderCity(true))
                            }}>
                                <span>{cityLabel}</span>
                            </p>
                            {isOpenPopup && (
                                <OutsideAlerter>
                                    <SelectsDrop />
                                </OutsideAlerter>
                            )}
                        </button>
                        {ctx.isUserId ? (
                            <NavLink to={"/lc/"} onClick={modal.closeModal} className="link">
                                <div className="personal__header">
                                    <div className="personal_header__img">
                                        {ctx.userData.avatar === null && <img src={userIcon} alt="avatar" />}
                                        {ctx.userData.avatar !== null && (
                                            <img src={url + ctx.userData.avatar} alt="avatar" />
                                        )}
                                    </div>
                                    <div className="personal_header__name">{ctx.userData.name}</div>
                                </div>
                            </NavLink>
                        ) : (
                            <div
                                className="lc link"
                                onClick={() => {
                                    modal.closeModal();
                                    modal.unauthModal();
                                }}>
                                Личный кабинет
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpenPopup(false);
                    dispatch(modalsSliceAction.changeHeaderCity(false))
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    function OutsideAlerter(props) {
        const wrapperRef = useRef(null);
        useOutsideAlerter(wrapperRef);

        return <div ref={wrapperRef}>{props.children}</div>;
    }
};

export default Headertop;
