import styles from "../../cabinet/subscribe/subscribe.module.sass";
import {NavLink} from "react-router-dom";
import GramophoneSvg from "./svg/GramophoneSvg";
import RocketSvg from "./svg/RocketSvg";
import {useContext} from "react";
import ModalsContext from "../../store/modals-context";
import AuthContext from "../../store/auth-context";

const LaunchEmptyTop = () => {
    const auth = useContext(AuthContext);
    const modal = useContext(ModalsContext)

  return(
      <div className={styles.promotion_top}>
        <div className={styles.promotion_left}>
          <div className={styles.promotion_left_block}>
            <GramophoneSvg />
            <span className={styles.promotion_number}>01</span>
            <p className={`${styles.promotion_top_p} ${styles.prom_top_p}`}>
              Выберите объявление, <br/> которые Вы хотите <br/> рекламировать
            </p>
          </div>
          <div className={styles.promotion_left_block}>
            <RocketSvg />
            <span className={styles.promotion_number}>02</span>
            <p className={styles.promotion_top_p}>
              Укажите время <br/> рекламы, <br/> подходящее Вам
            </p>
          </div>
        </div>

        <div className={styles.promotion_btn}>

            {(auth.userData.organization?.confirmed === 0 && auth.userData?.organization.status === 1)
                ? <div className={styles.promotion_btn_save} onClick={modal.advertiserModerationConfirmed}>
                    Начать
                </div>
                : (auth.userData.organization?.confirmed === 2 && auth.userData?.organization.status === 1)
                    ? <div className={styles.promotion_btn_save} onClick={modal.advertiserOnCheckingPayment}>
                        Начать
                    </div>
                    :
                    <NavLink className={styles.promotion_btn_save} to={`/advertiser/launch/ads`}>Начать</NavLink>
            }
        </div>
      </div>
  )
}

export default LaunchEmptyTop;