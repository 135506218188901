import "./Docs.sass";

const Rules = () => {
    return (
    <div className="rules">
        <h5 className="text-align">
            Соглашение об использовании ресурса «CityWork» (citywork.ru) (далее по тексту «Соглашение»)
        </h5>
        <p className="strong text-align m0">1. Универсальные положения</p>
        <p>
            Компания предлагает Вам услуги (Сервисы) Сайта на условиях, являющихся предметом настоящего Соглашения
            об использовании Интернет-ресурса citywork.ru. В этой связи Вам необходимо внимательно ознакомиться с
            условиями настоящего Соглашения. Условия использования ресурса регулируются настоящим Соглашением,
            Гражданским Кодексом РФ.
        </p>
        <p>Компания предоставляет сервис, который помогает клиентам и специалистам встретиться.</p>
        <p>
            Настоящие Условия использования Сервиса (далее - Условия) определяют общие условия правоотношений между
            вами и Компанией, и не являются публичными, Компания оставляет за собой право отказать в доступе к
            Сервису без объяснения причин. Условия считаются принятыми с момента вашего безоговорочного акцепта.
            Полным и безоговорочным акцептом считается факт начала использования Сервиса и любого другого продукта
            Компании.
        </p>
        <p>
            Продолжая использовать Сервис, вы соглашаетесь с текущей редакцией Условий и Политикой
            конфиденциальности, Правилами политики обработки персональных данных, Согласием на обработку
            персональных данных. Если вы не согласны с Условиями, Правилами политики обработки персональных данных и
            Согласием на обработку персональных данных, то вправе прекратить пользоваться услугами Компании.
            Компания имеет право пересмотреть Условия в любое время без предварительного уведомления, а также
            передать полностью или частично свои права и обязанности по этим Условиям любому третьему лицу. Новая
            редакция Условий считается вступившей в силу с момента их размещения на сайте citywork.ru. Компания не
            оказывает услуги по вашим заказам, не является работодателем специалистов и не несёт ответственности
            перед вами за результат их работы. Компания не является владельцем агрегатора информации об услугах,
            следовательно не выступает стороной правоотношений, регулируемых Законом «О защите прав потребителей».
            Принимая Условия, вы обязуетесь не публиковать на Сайте изображения, комментарии, отзывы, содержащие или
            продвигающие ненормативную лексику, материалы оскорбительного, непристойного характера, пропагандирующие
            насилие, экстремистские взгляды, дискриминацию, разжигание религиозной, расовой, политической или
            межнациональной розни, нарушающие права авторов или правообладателей, содержащие личные данные других
            пользователя(ей) без их согласия, а также иной пользовательский контент, запрещенный законом или
            нарушающий права и интересы третьих лиц.
        </p>
        <p>В настоящем Пользовательском соглашении нижеуказанные термины имеют следующее значение:</p>
        <p>
            Сайт — Интернет ресурс citywork.ru, представляющий собой совокупность содержащихся в информационной
            системе информации и объектов интеллектуальной собственности (в том числе, программа для ЭВМ, база
            данных, графическое оформление интерфейса (дизайн) и др.), доступ к которому обеспечивается с различных
            пользовательских устройств, подключенных к сети Интернет, посредством специального программного
            обеспечения для просмотра веб-страниц (браузер) по адресу citywork.ru (включая домены следующих
            уровней, относящихся к данным адресам).
        </p>
        <p className="strong">
            Администрация — Общество с ограниченной ответственностью «Миговеб» (ООО «Миговеб»), ИНН 0411167510 ОГРН
            1140411000124, также упоминаемое как Компания, Общество. Адрес: 119296, г. Москва, муниципальный округ
            Гагаринский, ул. Молодёжная, д. 3, пом. XVII, офис 6. E-mail: info@migoweb.ru
        </p>
        <p>
            129301, г. Москва, ул. Касаткина, д.3, стр.2, этаж 2, оф. 27 Тел.: + 74951287300 E-mail:
            <a href="mailto:info@citywork.ru"> info@citywork.ru</a>
        </p>
        <p>
            <b>Сервисы </b> — службы, услуги, функциональные возможности, инструменты, доступные для Пользователей
            на Сайте.
        </p>
        <p>
            <b>Пользователь </b> — посетитель ресурсов сети Интернет, в том числе Сайта, юридическое или физическое
            лицо, имеющее предложение для опубликования информации на сайте.
        </p>
        <p>
            <b>Объявление </b> — информационное сообщение с предложением об оказании услуг (включая контактную
            информацию, фотографии и любую сопутствующую информацию), размещаемое Пользователем на Сайте,
            адресованное неопределенному кругу лиц; в том числе сведения о квалификации специалиста в конкретной
            области.
        </p>
        <p>
            <b>Пользовательское соглашение </b> — настоящее соглашение и иные правила и документы, размещенные на
            Сайте, регламентирующие работу Сайта citywork.ru и/или определяющие порядок использования Сервисов
            Сайта.
        </p>
        <p>
            <b>Исполнитель </b> — Пользователь, размещающий на Сайте Объявление с предложением заключить/оказать
            определенные услуги.
        </p>
        <p>
            <b>Сведения </b> — любые материалы и информация, предоставляемые Пользователем в связи с использованием
            Сайта.
        </p>
        <p>
            <b>Заказчик </b> — Пользователь, осуществляющий взаимодействие с Исполнителем в отношении услуг,
            выполняемых Исполнителем.
        </p>
        <p>
            <b>Календарь работ (бронирования) </b> – статус (временной промежуток времени), при котором заявки
            Исполнителя в течение определенного периода времени, не подлежат к исполнению и принятию в работу
        </p>
        <p>В настоящем Соглашении могут быть использованы термины, не определённые в настоящем соглашении.</p>
        <p>
            В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае
            отсутствия однозначного толкования термина в тексте Соглашения следует руководствоваться толкованием
            термина, определённым: в первую очередь — законодательством РФ, во вторую очередь — на Сайте
            citywork.ru, затем — сложившимся (общеупотребимым) в сети Интернет.
        </p>
        <p className="strong text-align m0">2. Общие положения</p>
        <p>
            2.1. Администрация сайта предлагает Пользователю на условиях настоящего Пользовательского соглашения
            воспользоваться доступными на Сайте Сервисами, включая поиск, размещение, просмотр Объявлений и иными
            предлагаемыми на Сайте Сервисами. Администрация сайта имеет право в одностороннем порядке устанавливать
            дополнительные условия, правила и ограничения в отношении использования отдельных Сервисов.
            Администрация сайта имеет безусловное право в любое время пересматривать или изменять условия
            предоставления Сервисов, изменять, ограничивать, дополнять, расширять функциональные возможности Сайта
            и/или Сервисов, в том числе условия доступа Пользователя к Сервисам или отдельным функциональным
            возможностям Сайта.
        </p>
        <p>
            2.2. Сайт является площадкой, позволяющей Исполнителям размещать предложения, адресованные
            неопределенному кругу лиц для нахождения заказов на оказания услуг, а Заказчикам принимать на свое
            усмотрение и под свою ответственность предложения, размещенные на Сайте Исполнителями, заключая
            соответствующую сделку с Исполнителями.
        </p>
        <p>
            Администрация сервиса не является организатором (стороной) сделки, Исполнителем, Заказчиком,
            посредником, агентом или представителем какого-либо Пользователя и/или иным заинтересованным лицом в
            отношении предлагаемой/заключаемой между Пользователями сделки, если специально не указано иное. Все
            совершаемые благодаря размещению Объявления на Сайте сделки между Пользователями заключаются и
            исполняются без прямого или косвенного участия Администрации сайта, если специально не указано иное.
        </p>
        <p>
            2.3. Предоставление отдельных Сервисов может регулироваться специальными правилами и/или соглашениями,
            являющимися неотъемлемой частью настоящего Пользовательского соглашения, в том числе индивидуальными
            соглашениями, составленными в письменной форме и подписанными Администрацией сайта и Пользователем. В
            случае противоречия или несоответствия между текстом настоящего Пользовательского соглашения и
            специальными правилами и/или соглашениями применению подлежат последние.
        </p>
        <p>
            2.4. Осуществляя доступ к сайту citywork.ru и заключая таким образом настоящее Пользовательское
            соглашение, Пользователь гарантирует, что обладает всеми правами и полномочиями, необходимыми для
            заключения и исполнения Пользовательского соглашения, в том числе является совершеннолетним и полностью
            дееспособным лицом. Администрация сайта имеет безусловное право в любое время потребовать от
            Пользователя предоставление информации и документов, подтверждающих права и полномочия, как указано
            выше.
        </p>
        <p className="strong text-align m0">3. Обязательства Пользователя</p>
        <p>
            3.1. Пользователь обязуется действовать исключительно в соответствии с действующим законодательством
            Российской Федерации и Пользовательским соглашением, а также нести в соответствии с законодательством
            Российской Федерации полную ответственность за собственные действия/бездействие на Сайте и при
            использовании Сервисов.
        </p>
        <p>
            3.2. Пользователь обязуется не использовать автоматические и иные программы для получения доступа на
            Сайт без письменного разрешения Администрации. Без разрешения Администрации также не допускается
            распространение, использование, копирование и/или извлечение с Сайта ручным или автоматическим способом
            (с использованием программных средств) любых материалов или информации (включая Объявления, тексты
            описаний Техники, фотографии и прочее).
        </p>
        <p>
            3.3. Доступные Пользователю Сервисы могут быть использованы исключительно в целях, для которых такие
            Сервисы предназначены. Пользователю запрещается использовать Сервисы, а также любую полученную на Сайте
            информацию не по назначению.
        </p>
        <p>
            Администрация имеет безусловное право в любое время по своему усмотрению проводить выборочную проверку
            Объявлений, сообщений Заказчиков, направляемых Исполнителем через форму связи и/или условий
            использования Пользователями Сервисов на предмет соблюдения Пользовательского соглашения, в том числе в
            автоматическом режиме с использованием программных средств. В случае выявления нарушений, а также при
            получении информации от третьих лиц о таких нарушениях или выявлении совокупности признаков, которые
            могут свидетельствовать по мнению Администрации о нарушениях, Администрация сайта имеет безусловное
            право приостанавливать или прекращать доступ Пользователя к тем или иным Сервисам, включая отклонение
            или блокирование Объявления и/или доступа к Личному кабинету.
        </p>
        <p>
            По единоличному решению Администрации блокирование Объявлений и/или учетных записей на Сайте может быть
            временным или постоянным, в зависимости от объема и количества допущенных Пользователем нарушений
            настоящего Пользовательского соглашения и иных, установленных Администрацией, правил использования
            Сайта. В случае устранения Пользователем допущенных нарушений Администрация сайта имеет безусловное
            право восстановить заблокированные ранее Объявления или доступ Пользователя к учетной записи на Сайте.
        </p>
        <p>
            3.4. Использование компьютерных программ, позволяющих просматривать или размещать на Сайте Объявления,
            минуя обычный порядок размещения Объявлений (к примеру, использование программ по автозагрузке
            Объявлений), без письменного разрешения Администрации сайта строго запрещено и может привести к
            прекращению и/или приостановлению публикации Объявлений, блокировке доступа в Личный кабинет и/или к
            Сайту.
        </p>
        <p>
            3.5. В целях пресечения или предотвращения нарушения Пользовательского соглашения и/или причинения
            ущерба Администрации сайта, имеет безусловное право ограничивать доступ Пользователей или третьих лиц к
            Сайту путем блокирования доступа к Сайту соответствующего ip-адреса или диапазона ip-адресов.
        </p>
        <p>
            3.6. Осуществляя доступ к Сайту, Пользователь выражает свое согласие на получение рекламной информации,
            размещенной на Сайте третьими лицами. Пользователь понимает и соглашается, что Администрация сайта не
            определяет содержание и не несет ответственности за такую информацию, включая сайты, ссылки на которые
            могут содержаться в соответствующих материалах.
        </p>
        <p>
            3.7. Пользователь наделяет Администрацию сайта правом на продвижение объявлений, размещенных
            Пользователем на Сайте, в сети Интернет.
        </p>
        <p>
            3.8. Если у Пользователя возникают претензии к другому Пользователю в связи с использованием последним
            Сервисов и/или размещенными им Объявлениями, Пользователь обязан предъявлять эти требования надлежащему
            лицу (Исполнителю) и разрешать претензии самостоятельно и без участия Администрации.
        </p>
        <p>
            3.9. Пользователь обязуется не использовать любые данные, предоставленные другим Пользователем, без
            письменного разрешения лица, разместившего такие Сведения, или без подтверждения каким-либо другим
            способом права использовать такие Сведения. Все сведения, полученные Пользователем о других
            Пользователях в связи с использованием Сайта, могут быть использованы только для совершения и исполнения
            сделок в отношении предложенных услуг Пользователем. Таким образом, Пользователю запрещается
            использовать электронный адрес, и/или номер мобильного телефона другого Пользователя для целей прямой
            рекламной рассылки или другой рассылки нежелательных электронных сообщений, а также для иных
            противоправных действий или действий, совершаемых без ведома и/или согласия другой стороны.
        </p>
        <p>
            Чтобы облегчить взаимодействие между Пользователями, Сервисы подразумевают ограниченный доступ к
            некоторой контактной информации других Пользователей. Право использования Сведений, предоставленных
            другими Пользователями, ограничивается настоящим Пользовательским соглашением.
        </p>
        <p>
            3.10. Пользователь обязуется соблюдать осмотрительность при выборе контрагента (лицо, которое будет
            оказывать определенные услуги), под свою ответственность принимает решение о сделке, исходя из факта
            размещения Объявления на Сайте, самостоятельно удостоверяясь, что предложение по услуги в Объявлении на
            Сайте является действительным и легальным.
        </p>
        <p className="strong text-align m0">4. Сведения, предоставляемые Пользователями</p>
        <p>
            4.1. В рамках использования Сайта Пользователь обязуется предоставлять только достоверные Сведения и
            несет ответственность за предоставленную им информацию. Пользователь обязуется своевременно
            актуализировать Сведения посредством их редактирования на Сайте. Администрация сайта имеет безусловное
            право запрашивать, а Пользователь обязан предоставлять по такому запросу документы и информацию,
            необходимые для определения Пользователя как стороны Пользовательского соглашения и/или стороны,
            использующей соответствующий Сервис, а также документы, подтверждающие достоверность представленных
            Сведений, указанных в Объявлении.
        </p>
        <p>
            4.2. Размещая на Сайте Объявления, Пользователь делает Сведения, указанные в Объявлении, общедоступными
            для неопределенного круга лиц зарегистрированных на Сайте, и понимает, что размещенная информация
            публикуется на Сайте в открытом доступе, то есть доступна для ознакомления любому посетителю Сайта
            (неограниченному кругу лиц) на территории, где имеется возможность использования сети Интернет и доступа
            к сайту, соответственно Пользователь понимает и принимает на себя все риски, связанные с таким
            размещением информации. За отдельную плату, зарегистрированному пользователю, сведения о контактном
            телефоне Пользователя, могут быть открыты неопределенному кругу лиц (другому Пользователю Сайта,
            Заказчику), при срочности условий.
        </p>
        <p>
            4.3. В процессе пользования Сервисами Пользователь самостоятельно и добровольно принимает решение о
            предоставлении Администрации сайта или размещении в открытом доступе персональных и иных сведений о
            Пользователе для целей исполнения Пользовательского соглашения, а также настоящим заявляет о своем
            согласии на обработку Администрацией сайта и его аффилированными лицами персональных и иных данных
            Пользователя, их передачу для обработки другим Пользователям и/или третьим лицам, действующим по
            поручению Администрации сайта, в том числе для целей: предоставления консультационной поддержки
            Пользователям в связи с использованием Сайта, проверки Объявлений на предмет соответствия
            Пользовательскому соглашению, доставки сообщений другим Пользователям, получения статистических и
            аналитических данных для улучшения функционирования Сайта и/или Сервисов, расширения спектра оказываемых
            Сервисов, получения информационных и/или рекламных сообщений Администрации сайта или третьих лиц,
            предупреждения или пресечения незаконных и/или несанкционированных действий Пользователей или третьих
            лиц, обеспечения соблюдения требований действующего законодательства Российской Федерации. Администрация
            сайта принимает все необходимые меры для защиты персональных данных Пользователя от несанкционированного
            доступа третьих лиц.
        </p>
        <p>
            4.4. Администрация сайта не обязана осуществлять предварительную проверку Сведений любого вида,
            размещаемых и/или распространяемых Пользователем посредством Сайта. Администрация сайта имеет право по
            своему усмотрению отказать Пользователю в размещении и/или распространении им любых Сведений или удалить
            любые Сведения, которые размещены Пользователем на Сайте. Пользователь осознает и согласен с тем, что он
            должен самостоятельно оценивать все риски, связанные с размещением и распространением любых Сведений,
            включая оценку надежности, полноты или полезности такового.
        </p>
        <p>
            4.5. Обращения Пользователя к Администрации сайта по вопросам, связанным с использованием Сайта,
            рассматриваются в порядке, предусмотренном на Сайте. Взаимодействие Администрацией сайта с Пользователем
            в рамках рассмотрения обращения Пользователя осуществляется с использованием указанного Пользователем
            адреса электронной почты.
        </p>
        <p>
            4.6. Администрация сайта имеет безусловное право не рассматривать обращения Пользователя: не содержащие
            информацию и документы, необходимые для рассмотрения обращения; содержащие ложную информацию и (или)
            документы, не обладающими признаками достоверности; в отношении вопросов, по которым ранее
            Администрацией сайта был направлен ответ Пользователю (повторные обращения).
        </p>
        <p className="strong text-align m0">5. Права и обязанности</p>
        <p>
            5.1. Администрация сайта имеет право рассылать Пользователям сообщения информационного или технического
            характера, связанного с использованием Сайта.
        </p>
        <p>
            5.2. Администрация сайта осуществляет текущее управление Сайтом, определяет его внешний вид и структуру,
            разрешает или ограничивает доступ Пользователя к Сайту и осуществляет иные действия, необходимые для
            нормального функционирования Сайта.
        </p>
        <p>
            5.3. Администрация сайта имеет право управлять Сайтом исключительно по своему усмотрению,
            приостанавливать либо изменять условия работы Сайта без предварительного уведомления Пользователя, в том
            числе для проведения необходимых плановых профилактических и ремонтных работ на технических ресурсах
        </p>
        <p>
            5.4. Администрация сайта имеет право в целях сбора статистических данных и идентификации Пользователя
            устанавливать, сохранять личные данные и иную информацию о Пользователях.
        </p>
        <p>
            5.5. Администрация сайта, на условиях, изложенных в настоящем Соглашении, обязуется предоставить
            Пользователю возможность использовать Сайт.
        </p>
        <p>
            5.6. Администрация сайта имеет право в любое время прекратить доступ Пользователя к Сайту на условиях
            настоящего Соглашения и/или Приложений к нему, без возможности последующего возобновления предоставления
            доступа к Сайту.
        </p>
        <p>
            5.7. Администрация сайта не занимается рассмотрением и разрешением споров и конфликтных ситуаций,
            возникающих между Пользователями Сайта, однако оставляет за собой право заблокировать страницу
            Пользователя в случае получения от других Пользователей мотивированных жалоб на некорректное поведение
            данного Пользователя.
        </p>
        <p>
            5.8. Администрация сайта обязана предоставить Пользователю возможность безвозмездного использования
            Сайта, если настоящим Соглашением не предусмотрено иное.
        </p>
        <p>
            5.9. Администрация сайта имеет право использовать информацию о действиях Пользователя в целях улучшения
            работы Сайта.
        </p>
        <p>
            5.10. Администрация сайта не несёт ответственности за раскрытие Пользователем своих Личных данных и
            персональной информации.
        </p>
        <p>
            5.11. Администрация сайта оставляет за собой право вводить любые ограничения в отношении пользования
            Сайтом как в целом, так и для отдельных Пользователей без уведомления или без объяснения причин.
        </p>
        <p>
            5.12. В случае нарушения Пользователем условий данного Соглашения или Приложения, а также по запросу
            Пользователя Администрация сайта имеет безусловное право удалить Учетную запись Пользователя.
        </p>
        <p className="strong text-align m0">6. Обмен информацией при использовании Сайта</p>
        <p>
            6.1. Сообщения Администрации сайта, предназначенные для Пользователей, публикуются для всеобщего доступа
            на Сайте и/или рассылаются индивидуально по электронным адресам, номерам мобильных телефонов,
            предоставленным Пользователями при Регистрации или размещении Объявлений на Сайте. При этом Пользователь
            понимает, принимает и соглашается, что рассылаемые сообщения и/или их отдельные части могут иметь
            рекламный характер, а также могут содержать рекламные, информационные и иные сообщения контрагентов
            Администрации. Сообщения, опубликованные на Сайте, считаются доставленными Пользователю с момента их
            публикации.
        </p>
        <p>
            6.2. Сообщения Пользователя Исполнителю могут быть направлены посредством специально предложенной формы
            (чат) связи с Исполнителем, при использовании которой сообщение направляется на электронный адрес, номер
            мобильного телефона Исполнителя, указанный при Регистрации на Сайте или размещении Объявления, или в
            виде комментария Пользователя к Объявлению, о котором Исполнитель будет уведомлен через Личный кабинет.
            Сообщения Пользователей, предназначенные для Администрации сайта, пересылаются способами, предложенными
            на Сайте, включая форму обратной связи для обращений на Сайте.
        </p>
        <p>
            6.3. Осуществляя доступ к Сайту, Пользователь выражает свое согласие с тем, что Администрация сайта и
            администратор сайта citywork.ru, на котором могут быть опубликованы Объявления, могут направлять
            Пользователю электронные письма (e-mail) и/или короткие сообщения (SMS) на указанный им на Сайте,
            соответственно, адрес электронной почты или номер мобильного телефона.
        </p>
        <p className="strong text-align m0">7. Ответственность</p>
        <p>
            7.1. Пользователь несет ответственность за действия, совершаемые на Сайте, в соответствии с действующим
            законодательством Российской Федерации, включая ответственность за содержание размещаемой им информации
            и нарушение прав третьих лиц в отношении Услуг и/или информации, размещаемой на Сайте.
        </p>
        <p>
            7.1.1. Пользователь обязуется не размещать или иным способом не использовать на Сайте Информацию,
            охраняемую законодательством об интеллектуальной собственности (в том числе, но не ограничиваясь,
            которая затрагивает какой-либо патент, товарный знак, авторские и/или смежные с ними права), и иную
            охраняемую законом Информацию без соответствующих на то прав и разрешений от правообладателя такой
            Информации. В случае нарушения действующих норм по защите объектов интеллектуальной собственности,
            ответственность целиком и полностью возлагается на Пользователя.
        </p>
        <p>
            7.2. Администрация сайта не несет ответственности за потерю информации Пользователем, а также за
            искажение информации или потерю сообщения, получаемого с использованием форм связи на Сайте.
        </p>
        <p>
            7.3. Пользователь согласен, что Администрация сайта не несет ответственности за возможные убытки,
            причиненные Пользователю в связи с принятием мер пресечения или предотвращения нарушений на Сайте,
            связанных с ограничением/блокировкой доступа Пользователей к Сайту, а также IP-адресов согласно
            настоящего Пользовательского соглашения.
        </p>
        <p>
            7.4. Пользователь несет ответственность за предлагаемые в отношении услуги и заключаемые в связи с ними
            сделки, за выбор контрагентов для сделки и вытекающие из сделки последствия. Все сделки в отношении
            услуг, размещаемые Пользователями на Сайте, заключаются между Пользователями напрямую. Администрация
            сайта не является участником и/или посредником сделок, совершаемых Пользователями, не контролирует и не
            несет ответственности за такие сделки, если специально не указано иное.
        </p>
        <p>
            7.5. Сайт является инструментом, который предоставляет возможность Пользователям размещать Объявления в
            отношении услуг.
        </p>
        <p className="m0">
            7.6. Учитывая принципы построения и функционирования сети Интернет, Администрация сайта не предоставляет
            каких-либо гарантий в отношении Сервисов, в частности, Администрация сайта не гарантирует Пользователю,
            что:
        </p>
        <ul>
            <li>Сервисы будут предоставляться непрерывно, надежно и без ошибок;</li>
            <li>
                Сервисы, их прямой или косвенный эффект и качество будут соответствовать требованиям и целям
                Пользователя;
            </li>
            <li>
                Результаты, которые будут получены посредством использования Сервисов, будут точными, надежными и
                соответствовать ожиданиям Пользователя.
            </li>
        </ul>
        <p>
            7.7. Пользователь принимает и согласен, что предоставленная Администрацией сайта возможность направления
            сообщения Пользователю посредством формы связи на Сайте может быть использована любыми третьими лицами в
            целях, отличных от совершения сделки с Пользователем без какого-либо влияния и контроля со стороны
            Администрации. В связи с этим, Администрация сайта не несет ответственности за пользование другими
            Пользователями и/или автоматизированными системами (роботами) размещенной на Сайте формой для отправки
            сообщений Пользователям, равно как и за пользование ими телефонными номерами, размещенными Пользователем
            на страницах Сайта.
        </p>
        <p>
            7.8. Администрация сайта не несет ответственности за неисполнение или затруднения в исполнении
            обязательств из-за обстоятельств непреодолимой силы, последствия которых невозможно избежать или
            преодолеть.
        </p>
        <p>
            7.9. При использовании Заказчиками формы связи с Исполнителем на Сайте Администрация сайта не может
            гарантировать доставку такого сообщения Заказчика и корректность электронного адреса, указанного
            самостоятельно Исполнителем.
        </p>
        <p>
            7.10. Размещаемые Пользователями Сведения не могут содержать ссылки на сайты третьих лиц в сети
            Интернет.
        </p>
        <p className="strong text-align m0">8. Срок действия Пользовательского соглашения</p>
        <p>
            8.1. Настоящее Пользовательское соглашение вступает в силу с момента начала пользования Пользователем
            Сервисами Сайта, независимо от факта Регистрации Пользователя или размещения Объявления на Сайте, и
            действуют бессрочно.
        </p>
        <p>
            8.2. Администрация сайта оставляет за собой право по собственному усмотрению прекратить доступ
            Пользователя, нарушающего настоящее Пользовательское соглашение, а также условия любого из Сервисов,
            иных правил, регламентирующих функционирование Сайта, к Сервисам как в целом, так и в части, в том числе
            прекратить или временно приостановить доступ Пользователя в Личный кабинет.
        </p>
        <p className="strong text-align m0">9. Передача прав</p>
        <p>
            9.1. Администрация сайта имеет безусловное право, а Пользователь настоящим дает свое согласие на это,
            передать свои права и/или обязанности по настоящему Пользовательскому соглашению, как в целом, так и в
            части, третьей стороне. В случае передачи прав и/или обязанностей, как в целом, так и в части, по
            настоящему Пользовательскому соглашению третьей стороне, третья сторона имеет право предоставлять
            аналогичные или похожие услуги на другом сайте.
        </p>
        <p className="strong text-align m0">10. Споры и действующее законодательство, прочие условия соглашения</p>
        <p>
            10.1. При разрешении всех споров по настоящему Пользовательскому соглашению применяется действующее
            законодательство Российской Федерации.
        </p>
        <p>
            10.2. Все споры, возникшие в рамках настоящего Соглашения, должны быть переданы на рассмотрение в суд в
            соответствии с территориальной подсудностью по месту нахождения Компании.
        </p>
        <p>
            10.3. Признание отдельных частей настоящего Пользовательского соглашения недействительными не отменяет
            действие других положений настоящего Пользовательского соглашения.
        </p>
        <p>10.4. Компания не гарантирует доступность Сайта и Сервисов круглосуточно.</p>
        <p>
            10.5. Пользователи самостоятельно оценивают правомерность использования ими Сайта и Сервисов, в том
            числе и с точки зрения законодательства страны, резидентами которой они являются.
        </p>
        <p>
            10.6. Посетитель, совершая любые действия по использованию Сайта и его Сервисов (просмотр страниц Сайта,
            Регистрация, аутентификация, отправка Заявки с указанием контактного телефона, звонок по контактным
            телефонам Компании, указанным на Сайте и иные действия), в том числе по использованию Сайта и его
            Сервисов в особом режиме – «спящий» Профиль, выражает своё полное и безоговорочное согласие (Акцепт) с
            условиями Соглашения об использовании Ресурса Сайта, размещённого в сети Интернет по адресу:
            citywork.ru в качестве оферты. Соглашение об использовании Ресурса, заключаемое в форме оферты,
            предусмотренной Гражданским Кодексом Российской Федерации, не требует двустороннего подписания и
            действительно в электронном виде.
        </p>
        <p className="m0">10.7. Регистрация Пользователя:</p>
        <ul>
            <li>
                - Лицо, желающее стать Пользователем, обязано пройти процедуру Регистрации на соответствующей
                странице Ресурса, где указывает: Имя/ИП/ООО; контактный телефон; Email, Пароль.
            </li>
            <li>
                - При Регистрации Пользователю присваивается выбранная им пара Логин плюс Пароль, которая
                используется в дальнейшем Пользователем при работе с Сайтом.
            </li>
            <li>Пользователь может пройти процедуру Регистрации только один раз.</li>
            <li>
                Пользователь самостоятельно несёт ответственность за сохранность и конфиденциальность
                регистрационных данных (логин и пароль), в том числе от третьих лиц.
            </li>
            <li>
                Компания не несёт никакой ответственности в случае нарушения прав Пользователя третьими лицами,
                получившими несанкционированный доступ к паре Логин плюс Пароль Пользователя.
            </li>
            <li>
                При регистрации на Сайте Пользователь обязан предоставить Администрации Сайта необходимую
                достоверную и актуальную информацию для формирования персональной страницы Пользователя, включая
                уникальные для каждого Пользователя Логин (адрес электронной почты) и Пароль доступа к Сайту, а
                также фамилию и имя.
            </li>
            <li>
                Регистрационная форма Сайта может запрашивать у Пользователя дополнительную информацию. Пользователь
                несёт ответственность за достоверность, актуальность, полноту и соответствие законодательству
                Российской Федерации предоставленной при Регистрации информации и её чистоту от претензий третьих
                лиц.
            </li>
            <li>
                При Регистрации Пользователь выражает своё согласие с настоящим Соглашением и принимает на себя
                указанные в нем права и обязанности, связанные с использованием и функционированием Сайта.
            </li>
        </ul>
        <p>
            10.8. Использование некоторых Сервисов и Услуг Сайта возможно только после верификации. После
            прохождения процедуры Регистрации Пользователь может пройти процедуру верификации, что подразумевает под
            собой размещение на сайте и подтверждение Пользователем следующих данных: имя, фамилия, адрес
            электронной почты, дата рождения, контактный телефон. В случае необходимости для прохождения Верификации
            Компания оставляет за собой право на подтверждение личности с помощью документа, удостоверяющую личность
            и/или данные Пользователя. Статус Верифицированного пользователя предоставляется Администрацией по
            своему усмотрению после получения от Пользователя запрашиваемой информации. Администрация имеет право
            отказать Пользователю в верификации без объяснения причин отказа независимо от затрат и усилий,
            понесённых Пользователем при прохождении процедуры верификации.
        </p>
        <p>
            После успешного прохождения процедуры верификации Пользователь заполняет Профиль. Заполняя Профиль,
            Пользователь настоящим подтверждает, что делает информацию, содержащуюся в своём Профиле, общедоступной,
            то есть потенциально доступной всем Посетителям, за исключением некоторых данных, которые могут быть
            скрыты и предоставляться за отдельную плату.
        </p>
        <p>
            Статус Верифицированного Пользователя и/или Исполнителя может быть снят с Пользователя в любой момент по
            усмотрению Администрации без объяснения причин.
        </p>
        <p className="m0">
            Нарушения, которые могут повлечь за собой строгое предупреждение (временный бан) или лишение статуса
            Верифицированного пользователя (постоянный бан):
        </p>
        <ul>
            <li>
                Пользователь откликнулся на Объявление, но при этом не выполнил принятые на себя (согласованные с
                другом Пользователем) обязательства;
            </li>
            <li>Исполнитель получил пять и более негативных Отзывов;</li>
            <li>
                Исполнитель указал в своём Профиле контактные данные, включая сайты для демонстрации своих работ или
                портфолио;
            </li>
            <li>
                Исполнитель нарушил требования к фотографии, размещаемой в Профиле. На фотографии должен быть
                изображён непосредственно сам Исполнитель (лицо крупным планом) без посторонних людей, предметов или
                животных;
            </li>
            <li>
                Исполнитель отозвался на Задание, но отказался его выполнять, не согласовав с Заказчиком и не удалив
                своё Предложение, что привело к возникновению конфликтной ситуации или невыполнению Задания;
            </li>
            <li>
                Исполнитель отозвался на Задание, но отправил выполнять Задание вместо себя другого человека, не
                прошедшего процедуру верификации (друг, родственник, сосед) без согласия Заказчика;
            </li>
            <li>
                в чате со службой поддержки или в Предложении на Задание Исполнитель использовал ненормативную
                лексику, оскорбления;
            </li>
            <li>
                Администрацией получены жалобы на Исполнителя от Заказчиков (грубость, хамство, неадекватное
                поведение, нарушение договорённостей, т.п.) или материальная (имущественного характера) претензия;
            </li>
            <li>
                Исполнитель нарушил правила общения на Сайте. Запрещена критика и оскорбления Пользователей и
                Администрации, ненормативная лексика, разжигание конфликтных ситуаций, призывы к нарушению
                Соглашения об использовании; Ресурса сайта и законодательства РФ, размещение сообщений, нарушающих
                законодательство Российской Федерации, нормы международного права, содержащих рекламную информацию,
                спам, схемы «финансовых пирамид», «письма счастья»; являющихся незаконными, вредоносными,
                угрожающими, оскорбляющими нравственность, клеветническими, нарушающими авторские права,
                пропагандирующими ненависть и/или дискриминацию людей по расовому, этническому, половому,
                социальному признакам; содержащие ссылки на Интернет-сайты, принадлежащие Пользователям или третьим
                лицам; нарушающих права третьих лиц.
            </li>
        </ul>
        <p className="m0">10.9. Запрещается размещение Заданий, целью или предметом которых является:</p>
        <ul>
            <li>
                привлечение Пользователей на сторонние ресурсы, сайты, либо регистрация Пользователей на таких
                ресурсах, сайтах;
            </li>
            <li>реклама своих услуг и товаров или услуг и товаров, принадлежащих третьим лицам;</li>
            <li>фабрикация отзывов;</li>
            <li>услуги эротического характера;</li>
            <li>накрутка или изменение статистики сайтов, числа подписчиков в социальных сетях и т. д.;</li>
            <li>лекарства, пищевые добавки, биологически активные добавки;</li>
            <li>яды;</li>
            <li>лекарственное сырье, получаемое от северного оленеводства (панты и эндокринное сырье);</li>
            <li>
                наркотические средства, психотропные вещества и их прекурсоры, культивирование наркосодержащих
                растений;
            </li>
            <li>
                услуги хранения, перевозки, изготовления, переработки наркотических средств, прекурсоров,
                психотропных веществ или их аналогов, а также незаконные приобретение, хранение, перевозка растений,
                содержащих наркотические средства или психотропные вещества, либо их частей, содержащих
                наркотические средства или психотропные вещества;
            </li>
            <li>услуги по организации занятий проституцией, вовлечение в занятие проституцией;</li>
            <li>
                контент для взрослых, любая порнография, секс-видеочаты, сайты с указанием на размещение откровенных
                фотографий;
            </li>
            <li>табачные изделия;</li>
            <li>алкогольная продукция, этиловый спирт;</li>
            <li>
                драгоценные, редкие металлы и камни, ювелирные изделия, в т. ч. отходы, содержащие драгоценные и
                редкоземельные металлы и драгоценные камни;
            </li>
            <li>
                золотые слитки, инвестиции в золотые слитки, любой оборот драгоценных металлов, природных
                драгоценных камней или жемчуга, в том числе продажа изделий из драгоценных металлов;
            </li>
            <li>оружие и боеприпасы (гражданское, служебное, огнестрельное):</li>
            <li>разработка, производство, испытание, хранение, ремонт и утилизация.</li>
            <li>торговля, в том числе основными частями огнестрельного оружия.</li>
            <li>боеприпасы, в том числе патроны и их составные части типа гильз и капсюлей.</li>
            <li>
                военная техника, запасные части, комплектующие изделия и приборы к ней, взрывчатые вещества,
                средства взрывания, пороха, все виды ракетного топлива, а также специальные материалы и специальное
                оборудование для их производства, специальное снаряжение личного состава военизированных организаций
                и нормативно-техническая продукция на их производство и эксплуатацию;
            </li>
            <li>
                боевые отравляющие вещества, средства защиты от них и нормативно-техническая документация на их
                производство и использование;
            </li>
            <li>
                услуги по изготовлению/переделке/ремонту огнестрельного оружия, его основных частей, боеприпасов,
                взрывчатых веществ или взрывных устройств;
            </li>
            <li>пестициды и агрохимикаты, ограниченные в обороте;</li>
            <li>сильнодействующие или ядовитые вещества;</li>
            <li>
                музейные предметы и музейные коллекции, включённые в состав Музейного фонда Российской Федерации
                (Музейный фонд - совокупность постоянно находящихся на территории Российской Федерации музейных
                предметов и музейных коллекций, гражданский оборот которых допускается только с соблюдением
                ограничений, установленных Федеральным законом от 26.05.1996 N 54-ФЗ «О Музейном фонде Российской
                Федерации и музеях в Российской Федерации»);
            </li>
            <li>азартные игры, включая лотереи, букмекерские конторы и тотализаторы;</li>
            <li>форекс, бинарные аукционы, биткоины;</li>
            <li>финансовые пирамиды, HYIP;</li>
            <li>
                услуги по размещению сведений, материалов, порочащих честь и достоинство человека, нарушение тайны
                переписки, личной жизни;
            </li>
            <li>
                услуги по размещению публичных призывов к осуществлению экстремистской деятельности, возбуждению
                ненависти либо вражды, а равно унижение человеческого достоинства;
            </li>
            <li>услуги по организации экстремистского сообщества;</li>
            <li>любые иные товары или услуги, запрещенные согласно законодательству Российской Федерации.</li>
        </ul>
        <p>
            10.10. Компания может оказывать Верифицированному пользователю (Исполнитель) Услуги (далее – «Услуги»)
            по предоставлению информации о размещении на Сайте Задания Заказчика, а также услуги по предоставлению
            возможности оставить Предложение к Заданию.
        </p>
        <p>Услуги оказываются Компанией Верифицированному Пользователю на платной основе.</p>
        <p className="m0">10.11. Компания имеет право:</p>
        <ul>
            <li>
                временно приостановить оказание Верифицированному пользователю (в том числе Исполнителю) Услуг по
                техническим, технологическим или иным причинам, препятствующим оказанию Услуг, на время устранения
                таких причин;
            </li>
            <li>
                приостановить оказание Услуг и/или досрочно расторгнуть Соглашение в одностороннем внесудебном
                порядке путём направления соответствующего уведомления Верифицированному пользователю на адрес
                электронной почты, указанный последним при использовании Сайта, в случаях нарушения Верифицированным
                пользователем обязательств и/или гарантий, принятых в соответствии с Соглашением;
            </li>
            <li>
                досрочно расторгнуть Соглашение в одностороннем внесудебном порядке путём направления
                соответствующего уведомления Верифицированному пользователю на адрес электронной почты, указанный
                при использовании Сайта, в случае нарушения Верифицированным пользователем срока и порядка оплаты
                Услуг.
            </li>
        </ul>
        <p className="m0"> 10.12. Верифицированный пользователь обязуется:</p>
        <ul>
            <li>
                при пользовании Услугами Сайта соблюдать все условия оказания Услуг, установленные в Соглашении;
            </li>
            <li>своевременно оплачивать Услуги Компании;</li>
            <li>при оплате Услуг Компании указывать номер данные, позволяющие идентифицировать Пользователя;</li>
            <li>
                не злоупотреблять предоставленными возможностями, не использовать самостоятельно или с привлечением
                третьих лиц оказание Услуг и/или возможности использования Сервисов Компании в целях, которые могут
                быть квалифицированы как нарушение прав третьих лиц на объекты интеллектуальной собственности,
                недобросовестная конкуренция, иное нарушение закона; не осуществлять действий, которые влияют на
                нормальную работу Сайта.
            </li>
        </ul>
        <p>
            10.13. Стоимость Услуг Компании определяется в зависимости от типа Задания, а также категории
            Пользователя, выделяемой Компанией по объективным критериям.
        </p>
        <p>
            10.14. Стоимость Услуг Компании оплачивается в рублях РФ. Конкретная стоимость Услуг Компании
            отображается на Интернет-страницах Сайта.
        </p>
        <p>10.15. Компания оказывает Услуги Верифицированному пользователю на условиях авансовой оплаты.</p>
        <p>
            10.16. Услуги считаются оплаченными Верифицированным пользователем с момента получения Компанией
            подтверждения из банка о поступлении всей суммы оплаты на расчётный счёт Компании либо признания
            Компанией факта платежа в пользу Компании через платёжную систему в случае осуществления
            Верифицированным пользователем электронного платежа.
        </p>
        <p className="m0">10.17. Услуги считаются оказанными Компанией Верифицированному Пользователю:</p>
        <ul>
            <li>в момент размещения Предложения</li>
            <li>
                в случае, если Предложение размещается на условиях оплаты непосредственно за размещение Предложения;
            </li>
            <li>
                в момент инициирования Заказчиком взаимодействия с Верифицированным пользователем, разместившим
                Предложение, путем использования интерфейса Ресурса, позволяющего Заказчику получить Контактные
                данные Верифицированного пользователя,
            </li>
            <li>
                в случае, если Предложение размещается на условиях оплаты направления Заказчику через интерфейс
                Ресурса Контактных данных для взаимодействия;
            </li>
            <li>по истечении соответствующих суток оказания услуги;</li>
            <li>при использовании Услуг Компании в режиме «спящий» Профиль.</li>
        </ul>
        <p>
            10.18. Особенности режима пассивного использования Услуг Компании, оказываемых Верифицированным
            пользователям со «спящими» Профилями.
        </p>
        <p>
            10.19. «Спящим» Профилем признается учетная запись Верифицированного пользователя, действовавшего в
            качестве Исполнителя, которая используется им в особом режиме, а именно: в течение 365 (трехсот
            шестидесяти пяти) и более календарных дней Верифицированный пользователь не пополнял Кошелек, или не
            оплачивал тарифы Компании, или не оставлял Предложения (отклики) на Задания (в зависимости от того, что
            наступило позднее). При этом, Верифицированный пользователь понимает и соглашается с тем, что даже в
            таком режиме ему в полном объеме оказываются Услуги Компании, то есть ему предоставляется возможность
            получить информация о размещении на Сайте Задания Заказчика, а также предоставляется функциональная
            возможность оставить Предложение к Заданию при условии выполнения условий настоящего Соглашения, в том
            числе при условии оплаты соответствующих тарифов Компании в целях активации функциональных возможностей
            Профиля, позволяющих осуществление направления Предложений (откликов). Факт неиспользования
            Верифицированным пользователем предоставляемой информации (или факт не ознакомления с такой информацией)
            и факт неиспользования Верифицированным пользователем обеспеченных Компанией функциональных возможностей
            Профиля не влияют на его обязательства по оплате Услуг Компании согласно тарифам. То есть услуги
            Компанией оказываются до тех пор, пока профиль Исполнителя и/или верифицированного пользователя не
            удален им самостоятельно.
        </p>
        <p>
            10.20. Компания оставляет за собой право удалять со своих серверов любую информацию или материалы,
            которые, по мнению Компании, являются неприемлемыми, нежелательными или нарушающими настоящее
            Соглашение.
        </p>
        <p>
            10.21. Компания не контролирует информацию, услуги и продукты, находящиеся или предлагаемые посредством
            сети Интернет. Вследствие этого Пользователь принимает условие, в соответствии с которым все товары,
            информация и услуги, предлагаемые или доступные через Сервис или в сети Интернет (за исключением явно
            указанных как предоставляемые непосредственно Компанией), предоставляются третьими сторонами, которые
            никак не связаны с Компанией. Пользователь принимает на себя полную ответственность и риски за
            использование Сервисов и сети Интернет. Компания не предоставляет никаких гарантий на любые товары,
            информацию и услуги, поставляемые посредством Сервисов или через сеть Интернет вообще. Компания не будет
            нести ответственности за любые затраты или ущерб, прямо или косвенно возникшие в результате подобных
            действий.
        </p>
        <p>
            10.22. Все права на материалы, размещаемые на Сайте Посетителями (пользовательский контент), в том числе
            интеллектуальные права, принадлежат соответствующим Посетителям и/или иным правообладателям. Посетитель
            предоставляет Компании гарантии в отношении пользовательского контента в соответствии с условиями
            настоящего Соглашения.
        </p>
        <p>
            10.23. С размещением Посетителями пользовательского контента на Сайте Компания на безвозмездной основе
            получает бессрочное неисключительное право (простую лицензию) на использование такого пользовательского
            контента и включенных в него объектов интеллектуальной собственности на территории всего мира в пределах
            использования его любыми разрешенными в соответствии с законодательством РФ способами, в том числе без
            указания имени (псевдонима) автора, в целях 1) исполнения Компанией условий настоящего Соглашения, 2)
            защиты прав и законных интересов Компании, 3) в маркетинговых целях и в проектах Компании (в том числе
            коммерческих).
        </p>
        <p className="m0">10.24. Любые уведомления могут направляться одной Стороной другой Стороне:</p>
        <ul>
            <li>
                в электронном виде: на адрес электронной почты Пользователя, указанный им при регистрации; с
                использованием чата службы поддержки сайта;
            </li>
            <li>почтой с уведомлением о вручении или курьерской службой с подтверждением доставки.</li>
        </ul>
        <p>
            Компания имеет право направлять смс, в том числе рекламного характера, Посетителям (Пользователям) на
            номера телефонов, указанные последними при использовании Сайта.
        </p>
    </div>
);
};

export default Rules;

// <div className="rules">
//     <h5 className="text-align">
//         Соглашение об использовании ресурса «CityWork» (citywork.ru) (далее по тексту «Соглашение»)
//     </h5>
//     <p className="strong text-align m0">1. Универсальные положения</p>
//     <p>
//         Компания предлагает Вам услуги (Сервисы) Приложения на условиях, являющихся предметом настоящего
//         Соглашения об использовании Ресурса citywork.ru. В этой связи Вам необходимо внимательно ознакомиться с
//         условиями настоящего Соглашения. Условия использования ресурса регулируются настоящим Соглашением,
//         Гражданским Кодексом РФ.
//     </p>
//     <p>Компания предоставляет сервис, который помогает клиентам и специалистам встретиться. </p>
//     <p>
//         Настоящие Условия использования Сервиса (далее - Условия) определяют общие условия правоотношений между
//         вами и Компанией, и не являются публичными, Компания оставляет за собой право отказать в доступе к
//         Сервису без объяснения причин. Условия считаются принятыми с момента вашего безоговорочного акцепта.
//         Полным и безоговорочным акцептом считается факт начала использования Сервиса и любого другого продукта
//         Компании.
//     </p>
//     <p>
//         Продолжая использовать Сервис, вы соглашаетесь с текущей редакцией Условий и Политикой
//         конфиденциальности, обработкой персональных данных. Если вы не согласны с Условиями, обработкой
//         персональных данных или Политикой, то вправе прекратить пользоваться услугами Компании. Компания имеет
//         право пересмотреть Условия в любое время без предварительного уведомления, а также передать полностью
//         или частично свои права и обязанности по этим Условиям любому третьему лицу. Новая редакция Условий
//         считается вступившей в силу с момента их размещения на Сервисе. Компания не оказывает услуги по вашим
//         заказам, не является работодателем специалистов и не несёт ответственности перед вами за результат их
//         работы. Компания не является владельцем агрегатора информации об услугах, следовательно не выступает
//         стороной правоотношений, регулируемых Законом «О защите прав потребителей». Принимая Условия, вы
//         обязуетесь не публиковать на Сервисе изображения, комментарии, отзывы, содержащие или продвигающие
//         ненормативную лексику, материалы оскорбительного, непристойного характера, пропагандирующие насилие,
//         экстремистские взгляды, дискриминацию, разжигание религиозной, расовой, политической или межнациональной
//         розни, нарушающие права авторов или правообладателей, содержащие личные данные других пользователя(ей)
//         без их согласия, а также иной пользовательский контент, запрещенный законом или нарушающий права и
//         интересы третьих лиц.
//     </p>
//     <p>В настоящем Пользовательском соглашении нижеуказанные термины имеют следующее значение:</p>
//     <p>
//         Приложение — Интернет ресурс, представляющий собой совокупность содержащихся в информационной системе
//         информации и объектов интеллектуальной собственности (в том числе, программа для ЭВМ, база данных,
//         графическое оформление интерфейса (дизайн) и др.), доступ к которому обеспечивается с различных
//         пользовательских устройств, подключенных к сети Интернет, посредством специального программного
//         обеспечения для просмотра веб-страниц (браузер) по адресу citywork.ru (включая домены следующих уровней,
//         относящихся к данным адресам).
//     </p>
//     <p className="strong">
//         АДМИНИСТРАЦИЯ — Общество с ограниченной ответственностью «Миговеб» (ООО «Миговеб»), Юридический адрес:
//         ИНН 0411167510 ОГРН 1140411000124
//     </p>
//     <p>
//         129301, г. Москва, ул. Касаткина, д.3, стр.2, этаж 2, оф. 27 Тел.: + 74951287300 E-mail:
//         <a href="mailto:info@citywork.ru"> info@citywork.ru</a>
//     </p>
//     <p>
//         <b>Сервисы</b> — службы, услуги, функциональные возможности, инструменты, доступные для Пользователей в
//         Приложении.
//     </p>
//     <p>
//         <b>Пользователь</b> — посетитель ресурсов сети Интернет, в том числе Приложения, юридическое или
//         физическое лицо, имеющее предложение для опубликования информации в Приложении.
//     </p>
//     <p>
//         <b>Объявление</b> — информационное сообщение с предложением об Аренде техники/оказании услуг техникой
//         (включая контактную информацию, фотографии и любую сопутствующую информацию), размещаемое Пользователем
//         в Приложении, адресованное неопределенному кругу лиц; информацию об оказании услуг по строительству (в
//         том числе сведения о квалификации специалиста в конкретной области); информационное сообщение о сдаче в
//         аренду оборудования, специального инструмента; вспомогательного здания (вагончика; бытовки и т.д.), а
//         также иные услуги и возможности.
//     </p>
//     <p>
//         <b>Пользовательское соглашение</b> — настоящее соглашение и иные правила и документы, размещенные в
//         Приложении, регламентирующие работу Приложения и/или определяющие порядок использования Сервисов.
//     </p>
//     <p>
//         <b>Исполнитель</b> — Пользователь, размещающий в Приложении Объявление с предложением заключить/оказать
//         определенные услуги.
//     </p>
//     <p>
//         <b>Сведения</b> — любые материалы и информация, предоставляемые Пользователем в связи с использованием
//         Приложения.
//     </p>
//     <p>
//         <b>Заказчик</b> — Пользователь, осуществляющий взаимодействие с Исполнителем в отношении услуг,
//         выполняемых Исполнителем.
//     </p>
//     <p>
//         <b>Календарь работ</b> – статус (временной промежуток времени), при котором заявки Исполнителя в течении
//         определенного периода времени, не подлежат к исполнению и принятию в работу
//     </p>
//     <p>В настоящем Соглашении могут быть использованы термины, не определённые в настоящем соглашении.</p>
//     <p>
//         В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае
//         отсутствия однозначного толкования термина в тексте Соглашения следует руководствоваться толкованием
//         термина, определённым: в первую очередь — законодательством РФ, во вторую очередь — в Приложении
//         citywork.ru, затем — сложившимся (общеупотребимым) в сети Интернет.
//     </p>
//     <p className="strong text-align m0">2. Общие положения</p>
//     <p>
//         2.1. Администрация Приложения предлагает Пользователю на условиях настоящего Пользовательского
//         соглашения воспользоваться доступными в Приложении Сервисами, включая поиск, размещение, просмотр
//         Объявлений и иными предлагаемыми в Приложении Сервисами. Администрация Приложения имеет право в
//         одностороннем порядке устанавливать дополнительные условия, правила и ограничения в отношении
//         использования отдельных Сервисов. Администрация Приложения имеет безусловное право в любое время
//         пересматривать или изменять условия предоставления Сервисов, изменять, ограничивать, дополнять,
//         расширять функциональные возможности Приложения и/или Сервисов, в том числе условия доступа Пользователя
//         к Сервисам или отдельным функциональным возможностям Приложения.
//     </p>
//     <p>
//         2.2. Приложение является площадкой, позволяющей Исполнителям размещать предложения, адресованные
//         неопределенному кругу лиц на совершение сделки в сфере строительства (делать предложения), а Заказчикам
//         принимать на свое усмотрение и под свою ответственность предложения, размещенные в Приложении
//         Исполнителями, заключая соответствующую сделку с Исполнителями.
//     </p>
//     <p>
//         Администрация сервиса не является организатором сделки, Исполнителем, Заказчиком, посредником, агентом
//         или представителем какого-либо Пользователя и/или иным заинтересованным лицом в отношении
//         предлагаемой/заключаемой между Пользователями сделки, если специально не указано иное. Все совершаемые
//         благодаря размещению Объявления в Приложении сделки между Пользователями заключаются и исполняются без
//         прямого или косвенного участия Администрации Приложения, если специально не указано иное.
//     </p>
//     <p>
//         2.3. Предоставление отдельных Сервисов может регулироваться специальными правилами и/или соглашениями,
//         являющимися неотъемлемой частью настоящего Пользовательского соглашения, в том числе индивидуальными
//         соглашениями, составленными в письменной форме и подписанными Администрация Приложения и Пользователем.
//         В случае противоречия или несоответствия между текстом настоящего Пользовательского соглашения и
//         специальными правилами и/или соглашениями применению подлежат последние.
//     </p>
//     <p>
//         2.4. Осуществляя доступ к Приложению citywork.ru. и заключая таким образом настоящее Пользовательское
//         соглашение, Пользователь гарантирует, что обладает всеми правами и полномочиями, необходимыми для
//         заключения и исполнения Пользовательского соглашения, в том числе является совершеннолетним и полностью
//         дееспособным лицом. Администрация Приложения имеет безусловное право в любое время потребовать от
//         Пользователя предоставление информации и документов, подтверждающих права и полномочия, как указано
//         выше.
//     </p>
//     <p className="strong text-align m0">3. Обязательства Пользователя</p>
//     <p>
//         3.1. Пользователь обязуется действовать исключительно в соответствии с действующим законодательством
//         Российской Федерации и Пользовательским соглашением, а также нести в соответствии с законодательством
//         Российской Федерации полную ответственность за собственные действия/бездействие в Приложении и при
//         использовании Сервисов.
//     </p>
//     <p>
//         3.2. Пользователь обязуется не использовать автоматические и иные программы для получения доступа на
//         Приложение без письменного разрешения Администрации. Без разрешения Администрации также не допускается
//         распространение, использование, копирование и/или извлечение с Приложения ручным или автоматическим
//         способом (с использованием программных средств) любых материалов или информации (включая Объявления,
//         тексты описаний Техники, фотографии и прочее).
//     </p>
//     <p>
//         3.3. Доступные Пользователю Сервисы могут быть использованы исключительно в целях, для которых такие
//         Сервисы предназначены. Пользователю запрещается использовать Сервисы, а также любую полученную в
//         Приложении информацию не по назначению.
//     </p>
//     <p>
//         Администрация имеет безусловное право в любое время по своему усмотрению проводить выборочную проверку
//         Объявлений, сообщений Заказчиков, направляемых Исполнителем через форму связи и/или условий
//         использования Пользователями Сервисов на предмет соблюдения Пользовательского соглашения, в том числе в
//         автоматическом режиме с использованием программных средств. В случае выявления нарушений, а также при
//         получении информации от третьих лиц о таких нарушениях или выявлении совокупности признаков, которые
//         могут свидетельствовать по мнению Администрации о нарушениях, Администрация Приложения имеет безусловное
//         право приостанавливать или прекращать доступ Пользователя к тем или иным Сервисам, включая отклонение
//         или блокирование Объявления и/или доступа к Личному кабинету.
//     </p>
//     <p>
//         По единоличному решению Администрации блокирование Объявлений и/или учетных записей в Приложении может
//         быть временным или постоянным, в зависимости от объема и количества допущенных Пользователем нарушений
//         настоящего Пользовательского соглашения и иных, установленных Администрацией, правил использования
//         Приложения. В случае устранения Пользователем допущенных нарушений Администрация Приложения имеет
//         безусловное право восстановить заблокированные ранее Объявления или доступ Пользователя к учетной записи
//         в Приложении.
//     </p>
//     <p>
//         3.4. Использование компьютерных программ, позволяющих просматривать или размещать в Приложении
//         Объявления, минуя обычный порядок размещения Объявлений (к примеру, использование программ по
//         автозагрузке Объявлений), без письменного разрешения Администрации Приложения строго запрещено и может
//         привести к прекращению и/или приостановлению публикации Объявлений, блокировке доступа в Личный кабинет
//         и/или к Приложению.
//     </p>
//     <p>
//         3.5. В целях пресечения или предотвращения нарушения Пользовательского соглашения и/или причинения
//         ущерба Администрации Приложения, имеет безусловное право ограничивать доступ Пользователей или третьих
//         лиц к Приложению путем блокирования доступа к Приложению соответствующего ip-адреса или диапазона
//         ip-адресов.
//     </p>
//     <p>
//         3.6. Осуществляя доступ к Приложению, Пользователь выражает свое согласие на получение рекламной
//         информации, размещенной в Приложении третьими лицами. Пользователь понимает и соглашается, что
//         Администрация Приложения не определяет содержание и не несет ответственности за такую информацию,
//         включая Приложения, ссылки на которые могут содержаться в соответствующих материалах.
//     </p>
//     <p>
//         3.7. Пользователь наделяет Администрацию Приложения правом на продвижение объявлений, размещенных
//         Пользователем в Приложении, в сети Интернет.
//     </p>
//     <p>
//         3.8. Если у Пользователя возникают претензии к другому Пользователю в связи с использованием последним
//         Сервисов и/или размещенными им Объявлениями, Пользователь обязан предъявлять эти требования надлежащему
//         лицу (Исполнителю) и разрешать претензии самостоятельно и без участия Администрации.{" "}
//     </p>
//     <p>
//         3.9. Пользователь обязуется не использовать любые данные, предоставленные другим Пользователем, без
//         письменного разрешения лица, разместившего такие Сведения, или без подтверждения каким-либо другим
//         способом права использовать такие Сведения. Все сведения, полученные Пользователем о других
//         Пользователях в связи с использованием Приложения, могут быть использованы только для совершения и
//         исполнения сделок в отношении услуг по строительству. Таким образом, Пользователю запрещается
//         использовать электронный адрес, и/или номер мобильного телефона другого Пользователя для целей прямой
//         рекламной рассылки или другой рассылки нежелательных электронных сообщений, а также для иных
//         противоправных действий или действий, совершаемых без ведома и/или согласия другой стороны.
//     </p>
//     <p>
//         Чтобы облегчить взаимодействие между Пользователями, Сервисы подразумевают ограниченный доступ к
//         некоторой контактной информации других Пользователей. Право использования Сведений, предоставленных
//         другими Пользователями, ограничивается настоящим Пользовательским соглашением.
//     </p>
//     <p>
//         3.10. Пользователь обязуется соблюдать осмотрительность при выборе контрагента, под свою ответственность
//         принимает решение о сделке, исходя из факта размещения Объявления в Приложении, самостоятельно
//         удостоверяясь, что предложение по услуги в Объявлении в Приложении является действительным и легальным.
//     </p>
//     <p className="strong text-align m0">4. Сведения, предоставляемые Пользователями</p>
//     <p>
//         4.1. В рамках использования Приложения Пользователь обязуется предоставлять только достоверные Сведения
//         и несет ответственность за предоставленную им информацию. Пользователь обязуется своевременно
//         актуализировать Сведения посредством их редактирования в Приложении. Администрация Приложения имеет
//         безусловное право запрашивать, а Пользователь обязан предоставлять по такому запросу документы и
//         информацию, необходимые для определения Пользователя как стороны Пользовательского соглашения и/или
//         стороны, использующей соответствующий Сервис, а также документы, подтверждающие достоверность
//         представленных Сведений с указанной в Объявлении.
//     </p>
//     <p>
//         4.2. Размещая в Приложении Объявления, Пользователь делает Сведения, указанные в Объявлении,
//         общедоступными для неопределенного круга лиц зарегистрированных в Приложении, и понимает, что
//         размещенная информация публикуется в Приложении в открытом доступе, то есть доступна для ознакомления
//         любому посетителю Приложения (неограниченному кругу лиц) на территории всех стран мира, где имеется
//         возможность использования сети Интернет и доступа к Приложению, соответственно Пользователь понимает и
//         принимает на себя все риски, связанные с таким размещением информации. За отдельную плату, поступающую
//         на Администрацию, сведения о контактном телефоне Исполнителя, могут быть открыты неопределенному кругу
//         лиц (Заказчику), при срочности условий.
//     </p>
//     <p>
//         4.3. В процессе пользования Сервисами Пользователь самостоятельно и добровольно принимает решение о
//         предоставлении Администрации Приложения или размещении в открытом доступе персональных и иных сведений о
//         Пользователе для целей исполнения Пользовательского соглашения, а также настоящим заявляет о своем
//         согласии на обработку Администрация Приложения и его аффилированными лицами персональных и иных данных
//         Пользователя, их передачу (в том числе трансграничную передачу на территорию иностранных государств,
//         обеспечивающих адекватную защиту прав субъектов персональных данных) для обработки другим Пользователям
//         и/или третьим лицам, действующим по поручению Администрации Приложения, в том числе для целей:
//         предоставления консультационной поддержки Пользователям в связи с использованием Приложения, проверки
//         Объявлений на предмет соответствия Пользовательскому соглашению, доставки сообщений другим
//         Пользователям, получения статистических и аналитических данных для улучшения функционирования Приложения
//         и/или Сервисов, расширения спектра оказываемых Сервисов, получения информационных и/или рекламных
//         сообщений Администрации Приложения или третьих лиц, предупреждения или пресечения незаконных и/или
//         несанкционированных действий Пользователей или третьих лиц, обеспечения соблюдения требований
//         действующего законодательства Российской Федерации. Администрация Приложения принимает все необходимые
//         меры для защиты персональных данных Пользователя от несанкционированного доступа третьих лиц.
//     </p>
//     <p>
//         4.4. Администрация Приложения не обязано осуществлять предварительную проверку Сведений любого вида,
//         размещаемых и/или распространяемых Пользователем посредством Приложения. Администрация Приложения имеет
//         право по своему усмотрению отказать Пользователю в размещении и/или распространении им любых Сведений
//         или удалить любые Сведения, которые размещены Пользователем в Приложении. Пользователь осознает и
//         согласен с тем, что он должен самостоятельно оценивать все риски, связанные с размещением и
//         распространением любых Сведений, включая оценку надежности, полноты или полезности такового.
//     </p>
//     <p>
//         4.5. Обращения Пользователя к Администрации Приложения по вопросам, связанным с использованием
//         Приложения, рассматриваются в порядке, предусмотренном в Приложении. Взаимодействие Администрацией
//         Приложения с Пользователем в рамках рассмотрения обращения Пользователя осуществляется с использованием
//         указанного Пользователем адреса электронной почты.
//     </p>
//     <p>
//         4.6. Администрация Приложения имеет безусловное право не рассматривать обращения Пользователя: не
//         содержащие информацию и документы, необходимые для рассмотрения обращения; содержащие ложную информацию
//         и (или) документы, не обладающими признаками достоверности; в отношении вопросов, по которым ранее
//         Администрацией Приложения был направлен ответ Пользователю (повторные обращения).
//     </p>
//     <p className="strong text-align m0">5. Права и обязанности</p>
//     <p>
//         5.1. Администрация Приложения имеет право рассылать Пользователям сообщения информационного или
//         технического характера, связанного с использованием Приложения.
//     </p>
//     <p>
//         5.2. Администрация Приложения осуществляет текущее управление Приложением, определяет его внешний вид и
//         структуру, разрешает или ограничивает доступ Пользователя к Приложению и осуществляет иные действия,
//         необходимые для нормального функционирования Приложения.
//     </p>
//     <p>
//         5.3. Администрация Приложения имеет право управлять Приложением исключительно по своему усмотрению,
//         приостанавливать либо изменять условия работы Приложения без предварительного уведомления Пользователя,
//         в том числе для проведения необходимых плановых профилактических и ремонтных работ на технических
//         ресурсах
//     </p>
//     <p>
//         5.4. Администрация Приложения имеет право в целях сбора статистических данных и идентификации
//         Пользователя устанавливать, сохранять личные данные и иную информацию о Пользователях.
//     </p>
//     <p>
//         5.5. Администрация Приложения, на условиях, изложенных в настоящем Соглашении, обязуется предоставить
//         Пользователю возможность использовать Приложение.
//     </p>
//     <p>
//         5.6. Администрация Приложения имеет право в любое время прекратить доступ Пользователя к Приложению на
//         условиях настоящего Соглашения и/или Приложений к нему, без возможности последующего возобновления
//         предоставления доступа к Приложению.
//     </p>
//     <p>
//         5.7. Администрация Приложения не занимается рассмотрением и разрешением споров и конфликтных ситуаций,
//         возникающих между Пользователями Приложения, однако оставляет за собой право заблокировать страницу
//         Пользователя в случае получения от других Пользователей мотивированных жалоб на некорректное поведение
//         данного Пользователя.
//     </p>
//     <p>
//         5.8. Администрация Приложения обязана предоставить Пользователю возможность безвозмездного использования
//         Приложения, если настоящим Соглашением не предусмотрено иное.
//     </p>
//     <p>
//         5.9. Администрация Приложения имеет право использовать информацию о действиях Пользователя в целях
//         улучшения работы Приложения.
//     </p>
//     <p>
//         5.10. Администрация Приложения не несёт ответственности за раскрытие Пользователем своих Личных данных и
//         персональной информации.
//     </p>
//     <p>
//         5.11. Администрация Приложения оставляет за собой право вводить любые ограничения в отношении
//         пользования Приложением как в целом, так и для отдельных Пользователей без уведомления или без
//         объяснения причин.
//     </p>
//     <p>
//         5.12. В случае нарушения Пользователем условий данного Соглашения или Приложения, а также по запросу
//         Пользователя Администрация Приложения имеет безусловное право удалить Учетную запись Пользователя.
//     </p>
//     <p className="strong text-align m0">6. Обмен информацией при использовании Приложения</p>
//     <p>
//         6.1. Сообщения Администрации Приложения, предназначенные для Пользователей, публикуются для всеобщего
//         доступа в Приложении и/или рассылаются индивидуально по электронным адресам, номерам мобильных
//         телефонов, предоставленным Пользователями при Регистрации или размещении Объявлений в Приложении. При
//         этом Пользователь понимает, принимает и соглашается, что рассылаемые сообщения и/или их отдельные части
//         могут иметь рекламный характер, а также могут содержать рекламные, информационные и иные сообщения
//         контрагентов Администрации. Сообщения, опубликованные в Приложении, считаются доставленными Пользователю
//         с момента их публикации.
//     </p>
//     <p>
//         6.2. Сообщения Пользователя Исполнителю могут быть направлены посредством специально предложенной формы
//         (чат) связи с Исполнителем, при использовании которой сообщение направляется на электронный адрес, номер
//         мобильного телефона Исполнителя, указанный при Регистрации в Приложении или размещении Объявления, или в
//         виде комментария Пользователя к Объявлению, о котором Исполнитель будет уведомлен через Личный кабинет.
//         Сообщения Пользователей, предназначенные для Администрации Приложения, пересылаются способами,
//         предложенными в Приложении, включая форму обратной связи для обращений в Приложении.
//     </p>
//     <p>
//         6.3. Осуществляя доступ к Приложению, Пользователь выражает свое согласие с тем, что Администрация
//         Приложения и администратор Приложения citywork.ru, на котором могут быть опубликованы Объявления, могут
//         направлять Пользователю электронные письма (e-mail) и/или короткие сообщения (SMS) на указанный им в
//         Приложении, соответственно, адрес электронной почты или номер мобильного телефона.
//     </p>
//     <p className="strong text-align m0">7. Ответственность</p>
//     <p>
//         7.1. Пользователь несет ответственность за действия, совершаемые в Приложении, в соответствии с
//         действующим законодательством Российской Федерации, включая ответственность за содержание размещаемой им
//         информации и нарушение прав третьих лиц в отношении Услуг и/или информации, размещаемой в Приложении.
//     </p>
//     <p>
//         7.1.1. Пользователь обязуется не размещать или иным способом не использовать в Приложении Информацию,
//         охраняемую законодательством об интеллектуальной собственности (в том числе, но не ограничиваясь,
//         которая затрагивает какой-либо патент, товарный знак, авторские и/или смежные с ними права), и иную
//         охраняемую законом Информацию без соответствующих на то прав и разрешений от правообладателя такой
//         Информации. В случае нарушения действующих норм по защите объектов интеллектуальной собственности,
//         ответственность целиком и полностью возлагается на Пользователя.
//     </p>
//     <p>
//         7.2. Администрация Приложения не несет ответственности за потерю информации Пользователем, а также за
//         искажение информации или потерю сообщения, получаемого с использованием форм связи в Приложении.
//     </p>
//     <p>
//         7.3. Пользователь согласен, что Администрация Приложения не несет ответственности за возможные убытки,
//         причиненные Пользователю в связи с принятием мер пресечения или предотвращения нарушений в Приложении,
//         связанных с ограничением/блокировкой доступа Пользователей к Приложению, а также IP-адресов согласно
//         настоящего Пользовательского соглашения.
//     </p>
//     <p>
//         7.4. Пользователь несет ответственность за предлагаемые в отношении услуги и заключаемые в связи с ними
//         сделки, за выбор контрагентов для сделки и вытекающие из сделки последствия. Все сделки в отношении
//         услуг связанных со строительством заключаются между Пользователями напрямую. Администрация Приложения не
//         является участником и/или посредником сделок, совершаемых Пользователями, не контролирует и не несет
//         ответственности за такие сделки, если специально не указано иное.
//     </p>
//     <p>
//         7.5. Приложение является инструментом, который предоставляет возможность Пользователям размещать
//         Объявления в отношении услуг.
//     </p>
//     <p className="m0">
//         7.6. Учитывая принципы построения и функционирования сети Интернет, Администрация Приложения не
//         предоставляет каких-либо гарантий в отношении Сервисов, в частности, Администрация Приложения не
//         гарантирует Пользователю, что:
//     </p>
//     <ul>
//         <li>Сервисы будут предоставляться непрерывно, надежно и без ошибок;</li>
//         <li>
//             Сервисы, их прямой или косвенный эффект и качество будут соответствовать требованиям и целям
//             Пользователя;
//         </li>
//         <li>
//             Результаты, которые будут получены посредством использования Сервисов, будут точными, надежными и
//             соответствовать ожиданиям Пользователя.
//         </li>
//     </ul>
//     <p>
//         7.7. Пользователь принимает и согласен, что предоставленная Администрацией Приложения возможность
//         направления сообщения Пользователю посредством формы связи в Приложении может быть использована любыми
//         третьими лицами в целях, отличных от совершения сделки с Пользователем без какого-либо влияния и
//         контроля со стороны Администрации. В связи с этим, Администрация Приложения не несет ответственности за
//         пользование другими Пользователями и/или автоматизированными системами (роботами) размещенной в
//         Приложении формой для отправки сообщений Пользователям, равно как и за пользование ими телефонными
//         номерами, размещенными Пользователем на страницах Приложения.
//     </p>
//     <p>
//         7.8. Администрация Приложения не несет ответственности за неисполнение или затруднения в исполнении
//         обязательств из-за обстоятельств непреодолимой силы, последствия которых невозможно избежать или
//         преодолеть.
//     </p>
//     <p>
//         7.9. При использовании Заказчиками формы связи с Исполнителем в Приложении Администрация Приложения не
//         может гарантировать доставку такого сообщения Заказчика и корректность электронного адреса, указанного
//         самостоятельно Исполнителем.
//     </p>
//     <p>
//         7.10. Размещаемые Пользователями Сведения не могут содержать ссылки на Приложения третьих лиц в сети
//         Интернет.
//     </p>
//     <p className="strong text-align m0">8. Срок действия Пользовательского соглашения</p>
//     <p>
//         8.1. Настоящее Пользовательское соглашение вступает в силу с момента начала пользования Пользователем
//         Сервисами Приложения, независимо от факта Регистрации Пользователя или размещения Объявления в
//         Приложении, и действуют бессрочно.
//     </p>
//     <p>
//         8.2. Администрация Приложения оставляет за собой право по собственному усмотрению прекратить доступ
//         Пользователя, нарушающего настоящее Пользовательское соглашение, а также условия любого из Сервисов,
//         иных правил, регламентирующих функционирование Приложения, к Сервисам как в целом, так и в части, в том
//         числе прекратить или временно приостановить доступ Пользователя в Личный кабинет.
//     </p>
//     <p className="strong text-align m0">9. Передача прав</p>
//     <p>
//         9.1. Администрация Приложения имеет безусловное право, а Пользователь настоящим дает свое согласие на
//         это, передать свои права и/или обязанности по настоящему Пользовательскому соглашению, как в целом, так
//         и в части, третьей стороне. В случае передачи прав и/или обязанностей, как в целом, так и в части, по
//         настоящему Пользовательскому соглашению третьей стороне, третья сторона имеет право предоставлять
//         аналогичные или похожие услуги на другом Приложении.
//     </p>
//     <p className="strong text-align m0">10. Споры и действующее законодательство, прочие условия соглашения</p>
//     <p>
//         10.1. При разрешении всех споров по настоящему Пользовательскому соглашению применяется действующее
//         законодательство Российской Федерации.
//     </p>
//     <p>
//         10.2. Все споры, возникшие в рамках настоящего Соглашения, должны быть переданы на рассмотрение в суд в
//         соответствии с территориальной подсудностью по месту нахождения Администрация Приложения.
//     </p>
//     <p>
//         10.3. Признание отдельных частей настоящего Пользовательского соглашения недействительными не отменяет
//         действие других положений настоящего Пользовательского соглашения.
//     </p>
//     <p>10.4. Компания не гарантирует доступность Приложения и Сервисов круглосуточно.</p>
//     <p>
//         10.5. Пользователи самостоятельно оценивают правомерность использования ими Приложения и Сервисов, в том
//         числе и с точки зрения законодательства страны, резидентами которой они являются.
//     </p>
//     <p>
//         10.6. Посетитель, совершая любые действия по использованию Приложения и его Сервисов (просмотр страниц
//         Приложения, Регистрация, аутентификация, отправка Заявки с указанием контактного телефона, звонок по
//         контактным телефонам Компании, указанным в Приложении и иные действия), в том числе по использованию
//         Приложения и его Сервисов в особом режиме – «спящий» Профиль, выражает своё полное и безоговорочное
//         согласие (Акцепт) с условиями Соглашения об использовании Ресурса Приложения, размещённого в сети
//         Интернет по адресу: citywork.ru в качестве оферты. Соглашение об использовании Ресурса, заключаемое в
//         форме оферты, предусмотренной Гражданским Кодексом Российской Федерации, не требует двустороннего
//         подписания и действительно в электронном виде.
//     </p>
//     <p className="m0">10.7. Регистрация Пользователя:</p>
//     <ul>
//         <li>
//             - Лицо, желающее стать Пользователем, обязано пройти процедуру Регистрации на соответствующей
//             странице Ресурса, где указывает: Имя/ИП/ООО; Свой контактный телефон; Email Пароль.
//         </li>
//         <li>
//             - При Регистрации Пользователю присваивается выбранная им пара Логин плюс Пароль, которая
//             используется в дальнейшем Пользователем при работе с Приложением.{" "}
//         </li>
//         <li>Пользователь может пройти процедуру Регистрации только один раз.</li>
//         <li>
//             Пользователь самостоятельно несёт ответственность за сохранность и конфиденциальность
//             регистрационных данных (логин и пароль), в том числе от третьих лиц.
//         </li>
//         <li>
//             Компания не несёт никакой ответственности в случае нарушения прав Пользователя третьими лицами,
//             получившими несанкционированный доступ к паре Логин плюс Пароль Пользователя.
//         </li>
//         <li>
//             При регистрации в Приложении Пользователь обязан предоставить Администрации Приложения необходимую
//             достоверную и актуальную информацию для формирования персональной страницы Пользователя, включая
//             уникальные для каждого Пользователя Логин (адрес электронной почты) и Пароль доступа к Приложению, а
//             также фамилию и имя.
//         </li>
//         <li>
//             Регистрационная форма Приложения может запрашивать у Пользователя дополнительную информацию.
//             Пользователь несёт ответственность за достоверность, актуальность, полноту и соответствие
//             законодательству Российской Федерации предоставленной при Регистрации информации и её чистоту от
//             претензий третьих лиц.
//         </li>
//         <li>
//             При Регистрации Пользователь выражает своё согласие с настоящим Соглашением и принимает на себя
//             указанные в нем права и обязанности, связанные с использованием и функционированием Приложения.
//         </li>
//     </ul>
//     <p>
//         10.8. Использование некоторых Сервисов и Услуг Компании возможно только после верификации. После
//         прохождения процедуры Регистрации Пользователь может пройти процедуру верификации, что подразумевает под
//         собой размещение в Приложении и подтверждение Пользователем следующих данных: имя, фамилия, адрес
//         электронной почты, дата рождения, контактный телефон. В случае необходимости для прохождения Верификации
//         Компания оставляет за собой право на подтверждение личности с помощью паспортных данных Пользователя.
//         Статус Верифицированного пользователя предоставляется Администрацией по своему усмотрению после
//         получения от Пользователя запрашиваемой информации. Администрация имеет право отказать Пользователю в
//         верификации без объяснения причин отказа независимо от затрат и усилий, понесённых Пользователем при
//         прохождении процедуры верификации.
//     </p>
//     <p>
//         После успешного прохождения процедуры верификации Пользователь заполняет Профиль. Заполняя Профиль,
//         Пользователь настоящим подтверждает, что делает информацию, содержащуюся в своём Профиле, общедоступной,
//         то есть потенциально доступной всем Посетителям, за исключением некоторых данных, которые могут быть
//         скрыты и предоставляться за отдельную плату.
//     </p>
//     <p>
//         Статус Верифицированного Пользователя и/или Исполнителя может быть снят с Пользователя в любой момент по
//         усмотрению Администрации без объяснения причин.
//     </p>
//     <p className="m0">
//         Нарушения, которые могут повлечь за собой строгое предупреждение (временный бан) или лишение статуса
//         Верифицированного пользователя (постоянный бан):
//     </p>
//     <ul>
//         <li>Исполнитель не выполнил своё первое Задание;</li>
//         <li>Исполнитель получил пять и более негативных Отзывов;</li>
//         <li>
//             Исполнитель указал в своём Профиле Контактные данные, включая Приложения для демонстрации своих
//             работ или портфолио;
//         </li>
//         <li>
//             Исполнитель нарушил требования к фотографии, размещаемой в Профиле. На фотографии должен быть
//             изображён непосредственно сам Исполнитель (лицо крупным планом) без посторонних людей, предметов или
//             животных;
//         </li>
//         <li>
//             Исполнитель отозвался на Задание, но отказался его выполнять, не согласовав с Заказчиком и не удалив
//             своё Предложение, что привело к возникновению конфликтной ситуации или невыполнению Задания;
//         </li>
//         <li>
//             Исполнитель отозвался на Задание, но отправил выполнять Задание вместо себя другого человека, не
//             прошедшего процедуру верификации (друг, родственник, сосед) без согласия Заказчика;
//         </li>
//         <li>
//             в чате со службой поддержки или в Предложении на Задание Исполнитель использовал ненормативную
//             лексику, оскорбления;
//         </li>
//         <li>
//             Администрацией получены жалобы на Исполнителя от Заказчиков (грубость, хамство, неадекватное
//             поведение, нарушение договорённостей, т.п.) или материальная (имущественного характера) претензия;
//         </li>
//         <li>
//             Исполнитель нарушил правила общения в Приложении. Запрещена критика и оскорбления Пользователей и
//             Администрации, ненормативная лексика, разжигание конфликтных ситуаций, призывы к нарушению
//             Соглашения об использовании; Ресурса Приложения и законодательства РФ, размещение сообщений,
//             нарушающих законодательство Российской Федерации, нормы международного права, содержащих рекламную
//             информацию, спам, схемы «финансовых пирамид», «письма счастья»; являющихся незаконными,
//             вредоносными, угрожающими, оскорбляющими нравственность, клеветническими, нарушающими авторские
//             права, пропагандирующими ненависть и/или дискриминацию людей по расовому, этническому, половому,
//             социальному признакам; содержащие ссылки на Интернет-Приложения, принадлежащие Пользователям или
//             третьим лицам; нарушающих права третьих лиц;
//         </li>
//     </ul>
//     <p className="m0">10.9. Запрещается размещение Заданий, целью или предметом которых является:</p>
//     <ul>
//         <li>
//             привлечение Пользователей на сторонние ресурсы, Приложения, либо регистрация Пользователей на таких
//             ресурсах, Приложениях;
//         </li>
//         <li>реклама своих услуг и товаров или услуг и товаров, принадлежащих третьим лицам;</li>
//         <li>фабрикация отзывов;</li>
//         <li>услуги эротического характера;</li>
//         <li>накрутка или изменение статистики Приложений, числа подписчиков в социальных сетях и т. д.;</li>
//         <li>лекарства, пищевые добавки, биологически активные добавки;</li>
//         <li>яды;</li>
//         <li>лекарственное сырье, получаемое от северного оленеводства (панты и эндокринное сырье);</li>
//         <li>
//             наркотические средства, психотропные вещества и их прекурсоры, культивирование наркосодержащих
//             растений;
//         </li>
//         <li>
//             услуги хранения, перевозки, изготовления, переработки наркотических средств, прекурсоров,
//             психотропных веществ или их аналогов, а также незаконные приобретение, хранение, перевозка растений,
//             содержащих наркотические средства или психотропные вещества, либо их частей, содержащих
//             наркотические средства или психотропные вещества;
//         </li>
//         <li>услуги по организации занятий проституцией, вовлечение в занятие проституцией;</li>
//         <li>
//             контент для взрослых, любая порнография, секс-видеочаты, Приложения с указанием на размещение
//             откровенных фотографий;
//         </li>
//         <li>табачные изделия;</li>
//         <li>алкогольная продукция, этиловый спирт;</li>
//         <li>
//             драгоценные, редкие металлы и камни, ювелирные изделия, в т. ч. отходы, содержащие драгоценные и
//             редкоземельные металлы и драгоценные камни;
//         </li>
//         <li>
//             золотые слитки, инвестиции в золотые слитки, любой оборот драгоценных металлов, природных
//             драгоценных камней или жемчуга, в том числе продажа изделий из драгоценных металлов;
//         </li>
//         <li>оружие и боеприпасы (гражданское, служебное, огнестрельное):</li>
//         <li>разработка, производство, испытание, хранение, ремонт и утилизация.</li>
//         <li>торговля, в том числе основными частями огнестрельного оружия.</li>
//         <li>боеприпасы, в том числе патроны и их составные части типа гильз и капсюлей.</li>
//         <li>
//             военная техника, запасные части, комплектующие изделия и приборы к ней, взрывчатые вещества,
//             средства взрывания, пороха, все виды ракетного топлива, а также специальные материалы и специальное
//             оборудование для их производства, специальное снаряжение личного состава военизированных организаций
//             и нормативно-техническая продукция на их производство и эксплуатацию;
//         </li>
//         <li>
//             боевые отравляющие вещества, средства защиты от них и нормативно-техническая документация на их
//             производство и использование;
//         </li>
//         <li>
//             услуги по изготовлению/переделке/ремонту огнестрельного оружия, его основных частей, боеприпасов,
//             взрывчатых веществ или взрывных устройств;
//         </li>
//         <li>пестициды и агрохимикаты, ограниченные в обороте;</li>
//         <li>сильнодействующие или ядовитые вещества;</li>
//         <li>
//             музейные предметы и музейные коллекции, включённые в состав Музейного фонда Российской Федерации
//             (Музейный фонд - совокупность постоянно находящихся на территории Российской Федерации музейных
//             предметов и музейных коллекций, гражданский оборот которых допускается только с соблюдением
//             ограничений, установленных Федеральным законом от 26.05.1996 N 54-ФЗ «О Музейном фонде Российской
//             Федерации и музеях в Российской Федерации»);
//         </li>
//         <li>азартные игры, включая лотереи, букмекерские конторы и тотализаторы;</li>
//         <li>форекс, бинарные аукционы, биткоины;</li>
//         <li>финансовые пирамиды, HYIP;</li>
//         <li>
//             услуги по размещению сведений, материалов, порочащих честь и достоинство человека, нарушение тайны
//             переписки, личной жизни;
//         </li>
//         <li>
//             услуги по размещению публичных призывов к осуществлению экстремистской деятельности, возбуждению
//             ненависти либо вражды, а равно унижение человеческого достоинства;
//         </li>
//         <li>услуги по организации экстремистского сообщества;</li>
//         <li>любые иные товары или услуги, запрещенные согласно законодательству Российской Федерации.</li>
//     </ul>
//     <p>
//         10.10. Компания может оказывать Верифицированному пользователю (Исполнитель) Услуги (далее – «Услуги»)
//         по предоставлению информации о размещении в Приложении Задания Заказчика, а также услуги по
//         предоставлению возможности оставить Предложение к Заданию.
//     </p>
//     <p>Услуги оказываются Компанией Верифицированному пользователю (Исполнителю) на платной основе.</p>
//     <p className="m0">10.11. Компания имеет право:</p>
//     <ul>
//         <li>
//             временно приостановить оказание Верифицированному пользователю (в том числе Исполнителю) Услуг по
//             техническим, технологическим или иным причинам, препятствующим оказанию Услуг, на время устранения
//             таких причин;
//         </li>
//         <li>
//             приостановить оказание Услуг и/или досрочно расторгнуть Соглашение в одностороннем внесудебном
//             порядке путём направления соответствующего уведомления Верифицированному пользователю на адрес
//             электронной почты, указанный последним при использовании Приложения, в случаях нарушения
//             Верифицированным пользователем обязательств и/или гарантий, принятых в соответствии с Соглашением;
//         </li>
//         <li>
//             досрочно расторгнуть Соглашение в одностороннем внесудебном порядке путём направления
//             соответствующего уведомления Верифицированному пользователю на адрес электронной почты, указанный
//             при использовании Приложения, в случае нарушения Верифицированным пользователем срока и порядка
//             оказания Услуг.
//         </li>
//     </ul>
//     <p className="m0"> 10.12. Верифицированный пользователь обязуется:</p>
//     <ul>
//         <li>
//             при пользовании Услугами Компании соблюдать все условия оказания Услуг, установленные в Соглашении;
//         </li>
//         <li>своевременно оказывать Услуги Компании;</li>
//         <li>
//             при оказании Услуг Компании указывать номер данные, позволяющие Компании идентифицировать
//             Пользователя;
//         </li>
//         <li>
//             не злоупотреблять предоставленными возможностями, не использовать самостоятельно или с привлечением
//             третьих лиц оказание Услуг и/или возможности использования Сервисов Компании в целях, которые могут
//             быть квалифицированы как нарушение прав третьих лиц на объекты интеллектуальной собственности,
//             недобросовестная конкуренция, иное нарушение закона; не осуществлять действий, которые влияют на
//             нормальную работу Приложения.
//         </li>
//     </ul>
//     <p>
//         10.13. Стоимость Услуг Компании определяется в зависимости от типа Задания, а также категории
//         Пользователя, выделяемой Компанией по объективным критериям.
//     </p>
//     <p className="m0">10.17. Услуги считаются оказанными Компанией Верифицированному Пользователю:</p>
//     <ul>
//         <li>в момент размещения Предложения</li>
//         <li>
//             в случае, если Предложение размещается на условиях оказания непосредственно за размещение
//             Предложения;
//         </li>
//         <li>
//             в момент инициирования Заказчиком взаимодействия с Верифицированным пользователем, разместившим
//             Предложение, путем использования интерфейса Ресурса, позволяющего Заказчику получить Контактные
//             данные Верифицированного пользователя,
//         </li>
//         <li>
//             в случае, если Предложение размещается на условиях оказания за направление Заказчику через интерфейс
//             Ресурса Контактных данных для взаимодействия;
//         </li>
//         <li>по истечении соответствующих суток оказания услуги</li>
//         <li>при использовании Услуг Компании в режиме «спящий» Профиль.</li>
//     </ul>
//     <p>
//         10.18. Особенности режима пассивного использования Услуг Компании, оказываемых Верифицированным
//         пользователям со «спящими» Профилями.
//     </p>
//     <p>
//         10.20. Компания оставляет за собой право удалять со своих серверов любую информацию или материалы,
//         которые, по мнению Компании, являются неприемлемыми, нежелательными или нарушающими настоящее
//         Соглашение.
//     </p>
//     <p>
//         10.21. Компания не контролирует информацию, услуги и продукты, находящиеся или предлагаемые посредством
//         сети Интернет. Вследствие этого Пользователь принимает условие, в соответствии с которым все товары,
//         информация и услуги, предлагаемые или доступные через Сервис или в сети Интернет (за исключением явно
//         указанных как предоставляемые непосредственно Компанией), предоставляются третьими сторонами, которые
//         никак не связаны с Компанией. Пользователь принимает на себя полную ответственность и риски за
//         использование Сервисов и сети Интернет. Компания не предоставляет никаких гарантий на любые товары,
//         информацию и услуги, поставляемые посредством Сервисов или через сеть Интернет вообще. Компания не будет
//         нести ответственности за любые затраты или ущерб, прямо или косвенно возникшие в результате подобных
//         действий.
//     </p>
//     <p>
//         10.22. Все права на материалы, размещаемые в Приложении Посетителями (пользовательский контент), в том
//         числе интеллектуальные права, принадлежат соответствующим Посетителям и/или иным правообладателям.
//         Посетитель предоставляет Компании гарантии в отношении пользовательского контента в соответствии с
//         условиями настоящего Соглашения.
//     </p>
//     <p>
//         10.23. С размещением Посетителями пользовательского контента в Приложении Компания на безвозмездной
//         основе получает бессрочное неисключительное право (простую лицензию) на использование такого
//         пользовательского контента и включенных в него объектов интеллектуальной собственности на территории
//         всего мира в пределах использования его любыми разрешенными в соответствии с законодательством РФ
//         способами, в том числе без указания имени (псевдонима) автора, в целях 1) исполнения Компанией условий
//         настоящего Соглашения, 2) защиты прав и законных интересов Компании, 3) в маркетинговых целях и в
//         проектах Компании (в том числе коммерческих).
//     </p>
//     <p className="m0">10.24. Любые уведомления могут направляться одной Стороной другой Стороне:</p>
//     <ul>
//         <li>
//             в электронном виде: на адрес электронной почты Пользователя, указанный им при регистрации; с
//             использованием чата службы поддержки Приложения;
//         </li>
//         <li>почтой с уведомлением о вручении или курьерской службой с подтверждением доставки.</li>
//     </ul>
//     <p>
//         Компания имеет право направлять смс, в том числе рекламного характера, Посетителям (Пользователям) на
//         номера телефонов, указанные последними при использовании Приложения.
//     </p>
// </div>