import { NavLink } from "react-router-dom";
import { useContext } from "react";

import { TwoPeoplesIcon } from "../../components/icons/TwoPeoplesIcon";
import { UserWhiteIcon } from "../../components/icons/UserWhiteIcon";
import { CategoryIcon } from "../../components/icons/CategoryIcon";
import { SettingsIcon } from "../../components/icons/SettingsIcon";
import { QuestionIcon } from "../../components/icons/QuestionIcon";
import { SupportIcon } from "../../components/icons/SupportIcon";
import { CharityIcon } from "../../components/icons/CharityIcon";
import { RatingIcon } from "../../components/icons/RatingIcon";
import { ObjectIcon } from "../../components/icons/ObjectIcon";
import { RolesIcon } from "../../components/icons/RolesIcon";
import { PromoIcon } from "../../components/icons/PromoIcon";
import noAvatar from "../../assets/images/stubs/avatar.svg";
import { ExitIcon } from "../../components/icons/ExitIcon";
import { LockIcon } from "../../components/icons/LockIcon";
import { BlogIcon } from "../../components/icons/BlogIcon";
import DeviceContext from "../../store/device-context";
import AuthContext from "../../store/auth-context";
import { url } from "../../store/host-store";
import s from "./style.module.sass";
import { TermsIcon } from "../../components/icons/TermsIcon";
import AdsIconSvg from "../../components/icons/adIcons/AdsIconSvg";
import {StatisticRegistratorIcon} from "../../components/icons/StatisticRegistratorIcon";
import {CityIcon} from "../../components/icons/CityIcon";
import {AsideUser} from "../../components/icons/AsideUser";

const AdminAside = () => {
    const device = useContext(DeviceContext);
    const { userData } = useContext(AuthContext);

    const exitAccount = () => {
        localStorage.removeItem("token");
        window.location.replace("/");
    };

    if(userData.account.role === "fin_admin"){
        return (
            <div className={s.aside__wrapper}>
                <button
                    type="button"
                    className="cabinet__aside_close"
                    title="открыть меню"
                    onClick={device.toggleAside}></button>
                <div className={s.user_info}>
                    <NavLink className="cabinet__person" to="/admin/" onClick={device.toggleAside}>
                        <div className="cabinet__img">
                            {userData.avatar === null ? (
                                <img src={noAvatar} alt="" />
                            ) : userData.webpFilename ? (
                                <img src={url + userData.webpFilename} alt="avatar"></img>
                            ) : (
                                <img src={url + userData.avatar} alt="avatar" />
                            )}
                        </div>

                        <div>
                            <p className="cabinet__name">{userData.name}</p>
                            <p className="cabinet__grade">
                                <RatingIcon />
                                {userData.mark == null ? "Нет рейтинга" : userData.mark}
                            </p>
                        </div>
                    </NavLink>
                </div>

                <div className="cabinet__nav">
                    <NavLink to="users" className="cabinet__item_white" onClick={device.toggleAside}>
                        <div className="icon">
                            <UserWhiteIcon />
                        </div>
                        Пользователи
                    </NavLink>
                    <NavLink to="ads/edit" className="cabinet__item_white cabinet_ads_admin" onClick={device.toggleAside}>
                        <div className="icon" style={{padding: 0}}>
                            <AdsIconSvg />
                        </div>
                        Реклама
                    </NavLink>
                    <NavLink to="promocode" className="cabinet__item_white" onClick={device.toggleAside}>
                        <div className="icon">
                            <TermsIcon />
                        </div>
                        Промокоды
                    </NavLink>
                    <NavLink to="statistic" className="cabinet__item_white" onClick={device.toggleAside}>
                        <div className="icon">
                            <SettingsIcon />
                        </div>
                        Статистика
                    </NavLink>
                    <NavLink to="statisticRegistrator" className="cabinet__item_white" onClick={device.toggleAside}>
                        <div className="icon">
                            <StatisticRegistratorIcon />
                        </div>
                        Статистика регистраторов
                    </NavLink>
                </div>
                <button type="button" className="exit_link admin__exit_link" onClick={exitAccount}>
                    <ExitIcon />
                    Выйти
                </button>
            </div>
        )
    }

    return (
        <div className={s.aside__wrapper}>
            <button
                type="button"
                className="cabinet__aside_close"
                title="открыть меню"
                onClick={device.toggleAside}></button>
            <div className={s.user_info}>
                <NavLink className="cabinet__person" to="/admin/" onClick={device.toggleAside}>
                    <div className="cabinet__img">
                        {userData.avatar === null ? (
                            <img src={noAvatar} alt="" />
                        ) : userData.webpFilename ? (
                            <img src={url + userData.webpFilename} alt="avatar"></img>
                        ) : (
                            <img src={url + userData.avatar} alt="avatar" />
                        )}
                    </div>

                    <div>
                        <p className="cabinet__name">{userData.name}</p>
                        <p className="cabinet__grade">
                            <RatingIcon />
                            {userData.mark == null ? "Нет рейтинга" : userData.mark}
                        </p>
                    </div>
                </NavLink>
            </div>
            <div className="cabinet__nav">
                    <>
                        <NavLink to="objects" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <ObjectIcon />
                            </div>
                            Объявления
                        </NavLink>
                        <NavLink to="users" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <UserWhiteIcon />
                            </div>
                            Пользователи
                        </NavLink>
                        <NavLink to="category" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <CategoryIcon />
                            </div>
                            Категории
                        </NavLink>
                        {userData.account.role === "admin" && (
                        <NavLink to="roles" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <RolesIcon />
                            </div>
                            Роли
                        </NavLink>
                            )}
                        <NavLink to="statistic" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <SettingsIcon />
                            </div>
                            Статистика
                        </NavLink>
                        <NavLink to="statisticRegistrator" className="cabinet__item_white" onClick={device.toggleAside}>
                            <div className="icon">
                                <StatisticRegistratorIcon />
                            </div>
                            Статистика регистраторов
                        </NavLink>
                    </>


                <NavLink to="support" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <SupportIcon />
                    </div>
                    Поддержка
                </NavLink>
                <NavLink to="blog" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <BlogIcon />
                    </div>
                    Блог
                </NavLink>
                <NavLink to="charity" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <CharityIcon />
                    </div>
                    Благотворительность
                </NavLink>
                <NavLink to="faq" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <QuestionIcon />
                    </div>
                    FAQ
                </NavLink>
                <NavLink to="partners" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <TwoPeoplesIcon />
                    </div>
                    Партнеры
                </NavLink>
                <NavLink to="stop-words" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <LockIcon />
                    </div>
                    Стоп-слова
                </NavLink>
                <NavLink to="promotion" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <PromoIcon />
                    </div>
                    Продвижение
                </NavLink>
                <NavLink to="ads/edit" className="cabinet__item_white cabinet_ads_admin" onClick={device.toggleAside}>
                    <div className="icon" style={{padding: 0}}>
                        <AdsIconSvg />
                    </div>
                    Реклама
                </NavLink>
                <NavLink to="promocode" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <TermsIcon />
                    </div>
                    Промокоды
                </NavLink>
                {userData.account.role === "admin" && (
                    <NavLink to="cities" className="cabinet__item_white" onClick={device.toggleAside}>
                        <div className="icon">
                            <CityIcon />
                        </div>
                        Города
                    </NavLink>
                )}

                <NavLink to="partner_program" className="cabinet__item_white" onClick={device.toggleAside}>
                    <div className="icon">
                        <AsideUser />
                    </div>
                    Партнерская програма
                </NavLink>
            </div>

            <button type="button" className="exit_link admin__exit_link" onClick={exitAccount}>
                <ExitIcon />
                Выйти
            </button>
        </div>
    );
};

export default AdminAside;
