export const ViewedIcon = () => {
    return (
        <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 4.3721C10.044 4.14258 10.1101 3.93142 10.2569 3.76923C10.5971 3.38976 11.0547 3.4173 11.3948 3.84268C12.1021 4.72403 12.8093 5.6115 13.514 6.4959C13.5409 6.52957 13.5605 6.57241 13.5972 6.63362C13.6388 6.56629 13.6609 6.52651 13.6878 6.49284C15.3273 4.44248 16.9669 2.39213 18.604 0.341765C18.8487 0.0357416 19.1325 -0.0774872 19.4678 0.0541031C19.967 0.249958 20.1677 1.07622 19.8398 1.58728C19.8006 1.64849 19.7615 1.70663 19.7174 1.76172C17.8821 4.05689 16.0468 6.35207 14.2139 8.64725C13.9667 8.95634 13.6804 9.08793 13.3452 8.93798C13.2253 8.88289 13.1054 8.78496 13.0148 8.67173C12.0947 7.53027 11.1795 6.38268 10.2618 5.23815C10.1224 5.05759 10.0416 4.85256 10 4.61692C10 4.53429 10 4.45167 10 4.3721Z"
                fill="#546EDB"
            />
            <path
                d="M0 4.3721C0.0440477 4.14258 0.110119 3.93142 0.256945 3.76923C0.597091 3.38976 1.0547 3.4173 1.39484 3.84268C2.10205 4.72403 2.80926 5.6115 3.51403 6.4959C3.54094 6.52957 3.56052 6.57241 3.59723 6.63362C3.63883 6.56629 3.66085 6.52651 3.68777 6.49284C5.32732 4.44248 6.96687 2.39213 8.60398 0.341765C8.84869 0.0357416 9.13255 -0.0774872 9.4678 0.0541031C9.96701 0.249958 10.1677 1.07622 9.83976 1.58728C9.80061 1.64849 9.76145 1.70663 9.7174 1.76172C7.88209 4.05689 6.04677 6.35207 4.21389 8.64725C3.96674 8.95634 3.68043 9.08793 3.34518 8.93798C3.22527 8.88289 3.10536 8.78496 3.01482 8.67173C2.09471 7.53027 1.1795 6.38268 0.261839 5.23815C0.122355 5.05759 0.0416006 4.85256 0 4.61692C0 4.53429 0 4.45167 0 4.3721Z"
                fill="#546EDB"
            />
        </svg>
    );
};
