import s from "../style.module.scss";
import green from "./svg/green.png";

export const HeroAnimation = ({color, text, element, positionClassName}) => {
  return(
      <div className={`${s.hero_block_abs} ${color === green ? s.green : s.blue} ${positionClassName}`}>
          <div className={`relative ${s.hero_block_text}`}>
              <img className={s.img_abs} src={color} alt=""/>
              {element}
              <span>{text}</span>
          </div>
      </div>
  )
}