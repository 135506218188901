import {useEffect, useState} from "react";
import InputPhoto from "../../../cabinet/advertisement/InputPhoto";
import CreateAdsCity from "./CreateAdsCity";
import axios from "axios";
import {link, url} from "../../../store/host-store";
import {token} from "../../../App";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {DeleteIcon} from "../../../components/icons/DeleteIcon";
import ArrowBack from "../../../cabinet/request/svg/ArrowBack";

const CreateAds = () => {
    const editPage = window.location.href.indexOf('edit') > -1
    const [dataEdit, setDataEdit] = useState([]); // DefaultValue при редактировании
    const [currentImages, setCurrentImages] = useState(null); // Картинки при редактировании
    const [price, setPrice] = useState(0);
    const params = useParams();
    const [rerender, setRerender] = useState(false);
    const [arrayCityId, setArrayCityId] = useState([]);
    const [elementId, setElementId] = useState([0]);
    const [title, setTitle] = useState('');
    const [linkSocial, setLinkSocial] = useState('');
    const [cityId, setCityIid] = useState("");
    const [description, setDescription] = useState('');
    const [downloadImages, setDownloadImages] = useState([])
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let paramsGet = ''
        arrayCityId.map((item) => {
            paramsGet += `cities[]=${item}&`
        })
        fetch(`${link}/city/price?${paramsGet}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
                Authorization: token,
            }
        })
            .then((res) => res.json())
            .then((res) => setPrice(res.data.price))
    }, [rerender, arrayCityId])

    function showErrors(errorList) {
        setErrors({});
        errorList.map((error) => {
            switch (error.field) {
                case "title":
                    setErrors((prevState) => ({ ...prevState, title: error }));
                    break;
                case "description":
                    setErrors((prevState) => ({ ...prevState, description: error }));
                    break;
                default:
            }
        });
    }


    useEffect(() => {
        if(editPage){
            setElementId([])
            fetch(`${link}/advertisement/${params.id}?expand=city, city.region`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    Authorization: token,
                }
            })
                .then((res => res.json()))
                .then(res => {
                    setDataEdit(res.data);
                    setTitle(res.data.title);
                    setDescription(res.data.description)
                    setCurrentImages(res.data.image);
                    setLinkSocial(res.data.link)
                    if(res.data?.cities?.length > 0){
                        res.data?.cities?.map(() => {
                            setElementId(prev => [...prev, +prev.slice(-1) + 1])
                        })
                    }else{
                        setElementId([0])
                    }
                })
        }
    }, [])

    const getCity = (city) => {
        setCityIid(city?.value)
    }

    const titleChange = (event) => setTitle(event.target.value);
    const descriptionChange = (event) => setDescription(event.target.value);
    const linkSocialChange = (event) => setLinkSocial(event.target.value);

    const isLinkValid = /^((http|https):\/\/)[a-zа-я0-9]+([\-\.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(linkSocial)

    const submitForm = (e) => {
        e.preventDefault();

        let selected_images = downloadImages.length === 0 ? null : downloadImages[0];
        let cities = [...new Set(arrayCityId.filter(Boolean))];

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        if(currentImages === null){
            formData.append('image', selected_images);
        }
        formData.append('link', linkSocial);
        if(cities.length > 0){
            cities.map(item => formData.append('cities[]', item))
        }

        if(linkSocial.length > 0){
            if(!isLinkValid){
                return setErrors(prevState => ({
                    ...prevState, link: 'Ссылка должна начинаться с http:// или https://'
                }))
            }
        }

        if(!editPage){
            axios
                .post(`${link}/advertisement`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                })
                .then(({ data }) => {
                    if (data.success) {
                        console.log(data);
                        navigate('/advertiser/ad')
                    } else {
                        console.log("не удалось");
                        console.log(data);
                        showErrors(data.data)
                    }
                })
                .catch((err) => console.log(err));
        }else{
            axios
                .put(`${link}/advertisement/${params.id}`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: token,
                    },
                })
                .then(({ data }) => {
                    if (data.success) {
                        console.log(data);
                        navigate('/advertiser/ad')
                    } else {
                        console.log("не удалось");
                        console.log(data);
                        showErrors(data.data)
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    return(
      <>
          <div className="flex">
              <NavLink to="/advertiser/ad" className="margR6">
                  <ArrowBack />
              </NavLink>
              <h2 className="h2_cabinet_main">{!editPage ? 'Разместить' : 'Редактировать'} рекламу</h2>
          </div>
          <form onSubmit={submitForm} className="cabinet_ads__left">
              <div className="create_ads_top">
                  <div className="advers___title">Описание:</div>
                  <div className="input_wrap input_wrap_100 input_relative">
                      {window.innerWidth > 561 ?
                          <input type="text" className="input"
                                 defaultValue={dataEdit?.title}
                                 onChange={titleChange}
                                 placeholder='Введите заголовок до 55 символов'
                                 maxLength={55}/>
                          :
                          <textarea className="input"
                                    style={{resize: 'none'}}
                                    defaultValue={dataEdit?.title}
                                    onChange={titleChange}
                                    placeholder='Введите заголовок до 55 символов'
                                    maxLength={55}/>
                      }
                      <div className="input_length">{title.length}/55</div>
                      <p className="error">{errors.title?.message}</p>
                  </div>
                  <div className="input_wrap input_wrap_100 input_relative">
                      <textarea className='textarea'
                                defaultValue={dataEdit?.description}
                                onChange={descriptionChange}
                                placeholder='Описание услуги до 185 символов'
                                maxLength={185}
                      />
                      <div className="input_length">{description.length}/185</div>
                      <p className="error">{errors.description?.message}</p>
                  </div>
                  <div className="input_wrap input_wrap_100 input_relative_custom">
                      {window.innerWidth > 561 ?
                          <input type="text" className="input"
                                 placeholder='Ссылка на сайт, соц.сеть и т.п.'
                                 defaultValue={dataEdit?.link}
                                 onChange={linkSocialChange}
                          />
                          :
                          <textarea style={{height: '63px', resize: 'none'}} className="input"
                                    placeholder='Ссылка на сайт, соц.сеть и т.п.'
                                    defaultValue={dataEdit?.link}
                                    onChange={linkSocialChange}
                          />
                      }
                      <div className="input_before_adds">Ссылка</div>
                      <p className="error">{errors.link?.message}</p>
                      <p className="error">{errors.link}</p>
                  </div>
                  <div className="input_photo_in_advers">
                      {currentImages !== undefined && currentImages !== null && (
                          <div className="imagesMultiple">
                                      <div className="input_foto_wrap">
                                          <img src={url + currentImages} alt="" />
                                          <button
                                              title="удалить"
                                              type="button"
                                              onClick={() => setCurrentImages(null)}
                                              >
                                              <DeleteIcon />
                                          </button>
                                      </div>
                          </div>
                      )}
                      {currentImages === null &&
                      <InputPhoto
                          images={downloadImages}
                          onLoad={setDownloadImages}
                          onDelete={setDownloadImages}
                          limitText={"Рекомендуемый размер изображения 280x160 px."}
                      />
                      }
                      <p className='error'>{!downloadImages ? 'Загрузите изображение' : ''}</p>
                  </div>
              </div>

              <div className='create_ads_city'>
                  <div className="advers___title">Регион показов:</div>

                  {elementId.map((el, i) =>
                      <CreateAdsCity getCity={getCity} key={el} arrayCityId={arrayCityId}
                                     index={i}
                                     elementId={elementId}
                                     dataEdit={dataEdit?.cities}
                                     setRerender={setRerender}
                                     setElementId={setElementId}/>
                  )
                  }
              </div>
              <div className="create_ads_bottom">
                  <div className="create_ads_bottom_wrap">
                      <button className='btn_save' type='submit'>Разместить рекламу</button>
                  </div>

                  <div className="ads_create_bottom_info">
                      <div className="bottom_create_info_title">Итог: { price + '₽'} / день</div>
                      <div className="bottom_create_info_description">
                          <span>*</span> Реклама будет запущена после прохождения модерации.
                      </div>
                  </div>
              </div>
          </form>
      </>
  )
}

export default CreateAds;