export const ObjectIcon = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.31055 7.61719V11.0449C2.31055 11.2554 2.48089 11.4258 2.69141 11.4258H4.21484C4.42536 11.4258 4.5957 11.2554 4.5957 11.0449V7.65035C4.4706 7.62976 4.34406 7.61719 4.21484 7.61719H2.31055Z"
                fill="white"
            />
            <path
                d="M12.2383 3.87875V6.0236C12.6806 5.86584 13 5.44705 13 4.95117C13 4.45529 12.6806 4.0365 12.2383 3.87875Z"
                fill="white"
            />
            <path
                d="M1.9043 6.85547H3.83398V3.04688H1.9043C0.854318 3.04688 0 3.90119 0 4.95117C0 6.00115 0.854318 6.85547 1.9043 6.85547Z"
                fill="white"
            />
            <path
                d="M11.4766 9.52148V0.380859C11.4766 0.170346 11.3062 0 11.0957 0C9.63884 0 8.49766 0.811561 7.41868 1.59633C6.5032 2.26218 5.63177 2.89123 4.5957 3.01958V6.88276C5.63177 7.01114 6.5032 7.64017 7.41868 8.30601C8.49766 9.09078 9.63884 9.90234 11.0957 9.90234C11.3062 9.90234 11.4766 9.732 11.4766 9.52148Z"
                fill="white"
            />
        </svg>
    );
};
