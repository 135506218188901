import {useContext, useEffect, useReducer, useState} from "react";
import AuthContext from "../../../store/auth-context";
import axios from "axios";
import {link} from "../../../store/host-store";
import {token} from "../../../App";
import AsyncSelect from "react-select/async";
import {customStyles, DropdownIndicator} from "../../../cabinet/advertisement/create/CitySelects";
import AddCitySvg from "./svg/AddCitySvg";
import DeleteCitySvg from "./svg/DeleteCitySvg";

const initialCount = {
    city: 10000,
    region: 0,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "plus":
            return { city: state.city + 1, region: state.region + 1 };
    }
};

const CreateAdsCity = ({getCity, arrayCityId, index, setElementId, elementId, dataEdit, setRerender}) => {
    const ctx = useContext(AuthContext);
    const dataLocation = ctx.location;
    const editPage = window.location.href.indexOf('edit') > -1
    const [state, dispatch] = useReducer(reducer, initialCount); // Нужно для ререндера селектов
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    useEffect(() => {
        if(!editPage){
            setSelectedCountry({
                label: dataLocation.country,
                value: dataLocation.country_id,
            });
            // setSelectedRegion({
            //     label: dataLocation.region,
            //     value: dataLocation.region_id,
            // });
            // setSelectedCity({
            //     label: dataLocation.city,
            //     value: dataLocation.city_id,
            // });
        }else{
                setSelectedCountry({
                    label: dataLocation?.country,
                    value: dataLocation?.country_id,
                });
                if(dataEdit?.length > 0){
                    setSelectedRegion({
                        label: dataEdit[index]?.region?.name !== undefined ? dataEdit[index]?.region?.name : dataLocation.region,
                        value: dataEdit[index]?.region?.id !== undefined ? dataEdit[index]?.region?.id : dataLocation.region_id,
                    });
                    setSelectedCity({
                        label: dataEdit[index]?.city?.name !== undefined ? dataEdit[index]?.city?.name : dataLocation.city,
                        value: dataEdit[index]?.city?.id !== undefined ? dataEdit[index]?.city?.id : dataLocation.city_id,
                    });
                }
        }

    }, [dataLocation, dataEdit]);

    const handleCountry = (value) => {
        dispatch({ type: "plus" });
        setSelectedCountry(value);
        setSelectedRegion("");
        setSelectedCity("");
    };

    const handleRegion = (value) => {
        dispatch({ type: "plus" });
        setSelectedRegion(value);
        setSelectedCity("");
    };

    const handleCity = (value) => {
        setSelectedCity(value);
        getCity(selectedCity.value)
        setRerender(prev => !prev)
        console.log(selectedCity)
    };



    useEffect (() => {
        arrayCityId[index] = selectedCity.value
        setRerender(prev => !prev)
    }, [selectedCity])

    const loadOptions = (inputValue, callback) => {
        const options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`country?pagination=0&sort=name`)
            .then((response) => {
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.name,
                        value: permission.id,
                    });
                });
                callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
            });
    };

    const loadOptions2 = (inputValue, callback) => {
        let options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`region?filter[country_id]=${selectedCountry.value}&pagination=0&sort=name`)
            .then((response) => {
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.name,
                        value: permission.id,
                    });
                });
                callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
            });
    };

    const loadOptions3 = (inputValue, callback) => {
        let options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`city?filter[region_id]=${selectedRegion.value}&pagination=0&sort=name`)
            .then((response) => {
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.name,
                        value: permission.id,
                    });
                });
                callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
            });
    };


    return(
        <div className="city__select">
            <AsyncSelect
                className="dn"
                components={{ DropdownIndicator }}
                placeholder={"Выбор страны"}
                cacheOptions
                defaultOptions
                styles={customStyles}
                value={selectedCountry}
                onChange={handleCountry}
                loadOptions={loadOptions}
                noOptionsMessage={() => ""}
                menuPortalTarget={document.body}
            />

            <AsyncSelect
                key={selectedCountry.value}
                components={{ DropdownIndicator }}
                placeholder={"Выбрать регион"}
                cacheOptions
                defaultOptions
                styles={customStyles}
                value={selectedRegion}
                onChange={handleRegion}
                loadOptions={loadOptions2}
                noOptionsMessage={() => "Выберите область/край"}
                menuPortalTarget={document.body}
            />

            <AsyncSelect
                key={selectedRegion.value}
                components={{ DropdownIndicator }}
                placeholder={"Город"}
                cacheOptions
                defaultOptions
                styles={customStyles}
                value={selectedCity}
                onChange={handleCity}
                loadOptions={loadOptions3}
                noOptionsMessage={() => "Выберите населённый пункт"}
                menuPortalTarget={document.body}
            />
            {+elementId.slice(-1) === elementId[index] &&
                <div className='svg_city'
                     onClick={() => {
                         setElementId(prev => [...prev, +prev.slice(-1) + 1])
                         setRerender(prev => !prev)
                     }}

                >
                    <AddCitySvg/>
                </div>
            }
            {elementId.length > 1 && +elementId.slice(-1) !== elementId[index] &&
                <div className='svg_city'
                     onClick={() => {
                         arrayCityId.splice(index, 1)
                         setElementId(prev => prev.filter(el => el !== elementId[index]));
                         setRerender(prev => !prev)
                     }}
                ><DeleteCitySvg/></div>
            }
        </div>
  )
}

export default CreateAdsCity;