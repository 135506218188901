const LocationSvg = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="9"
            fill="none">
            <path
                fill="#B6BFE7"
                d="M3.5 0a3.455 3.455 0 0 0-2.474 1.056A3.657 3.657 0 0 0 0 3.6C0 5.065 1.034 6.281 2.129 7.57c.346.407.705.83 1.025 1.256A.44.44 0 0 0 3.5 9a.428.428 0 0 0 .346-.175c.32-.427.679-.849 1.025-1.256C5.966 6.28 7 5.065 7 3.6a3.657 3.657 0 0 0-1.026-2.544A3.455 3.455 0 0 0 3.5 0Zm0 4.95c-.26 0-.513-.08-.73-.227a1.343 1.343 0 0 1-.483-.606 1.386 1.386 0 0 1 .285-1.472c.183-.188.417-.317.672-.369.255-.052.518-.025.758.077.24.102.445.275.59.497a1.378 1.378 0 0 1-.164 1.705c-.246.253-.58.395-.928.395Z"
            />
        </svg>
    )
}

export default LocationSvg;