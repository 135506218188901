import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import ModalBlockSvg from "./svg/ModalBlockSvg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {blockerAction} from "../../../store/redux";

const ModalBlock = () => {
    const modal = useContext(ModalsContext);
    const dispatch = useDispatch();
    const selector = useSelector(state => state.blockerPage.navLink)
    const navigate = useNavigate();

    return(
        <div className="modal modal_block">
            <div className="closeModal" onClick={modal.closeModal}>
                <ModalCloseSvg />
            </div>
            <h5 className="title">Вы не сохранили
                изменения!</h5>
            <div className="modal_block_svg">
                <ModalBlockSvg />
            </div>
            <p className="modal_block_text">
                Изменения в профиле не сохранены, действительно хотите покинуть страницу
            </p>

            <button type="button" className="block_leave modal_block_btn" onClick={() => {
                navigate(selector)
                modal.closeModal()
                dispatch(blockerAction.blockFalse())
            }}>Покинуть</button>
            <button type="button" className="block_save modal_block_btn" onClick={modal.closeModal}>Остаться на странице</button>
        </div>
    )
}

export default ModalBlock;