import OpenSettingsSvg from "../svg/OpenSettingsSvg";
import PauseSvg from "../svg/PauseSvg";
import SettingsCalendarSvg from "../svg/SettingsCalendarSvg";
import {calendarAction} from "../../../store/redux";
import SettingsDeleteSvg from "../svg/SettingsDeleteSvg";
import {useDispatch, useSelector} from "react-redux";
import PromoteMobileSvg from "./svg/PromoteMobileSvg";
import {useContext} from "react";
import AuthContext from "../../../store/auth-context";

const AdsMobileOptions = ({status, showOptions, item, deactivateHandler, changeDateBySvg, getAway, isActive, activeToggle, deleteHandler, promotionOneItem}) => {
    const dispatch = useDispatch();
    const statusFilter = useSelector(state => +state.status.status)
    const ctx = useContext(AuthContext);
    const isRegistrarRole = ctx.userData?.account?.role === 'registrar'

    return(
        <>
            {status !== 2 && (
                <div className="options">
                    <button
                        type="button"
                        title="открыть опции"
                        onClick={() => showOptions(item.id)}
                        className={`btn ${
                            isActive === item.id && activeToggle ? "active" : ""
                        }`}>
                        <OpenSettingsSvg />
                    </button>
                    <div className="block">
                        <div className="modal_advers__flex">
                            <PauseSvg />
                            {item.status === 2 ? (
                                <div className="link deactivate">Отклонено</div>
                            ) : (
                                <a
                                    className="link deactivate"
                                    onClick={async () => {
                                        deactivateHandler(item.id, item.status);
                                        getAway(item.id);
                                    }}>
                                    {item.status === 1 ? "Остановить" : "Активировать"}
                                </a>
                            )}
                        </div>

                        <div className="modal_advers__flex" onClick={() => promotionOneItem(item.id)}>
                            <PromoteMobileSvg />
                            <a className="link promote">
                                Продвигать
                            </a>
                        </div>

                        <div className="modal_advers__flex">
                            <SettingsCalendarSvg />
                            <a
                                className="link delete"
                                onClick={() => {
                                    changeDateBySvg(item.id);
                                    dispatch(
                                        calendarAction.workOnWeekends(
                                            item.work_on_weekend === 1
                                        )
                                    );
                                }}>
                                Изменить дату работы
                            </a>
                        </div>
                        {!isRegistrarRole &&
                        statusFilter !== 2 &&
                            <div className="modal_advers__flex">
                                <SettingsDeleteSvg/>
                                <a className="link delete" onClick={() => deleteHandler(isActive)}>
                                    Удалить
                                </a>
                            </div>
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default AdsMobileOptions;