import axios from "axios";
import {link} from "../../../../store/host-store";
import {token} from "../../../../App";
import AsyncSelect from "react-select/async";
import {customStyles2, DropdownIndicator} from "../../../../components/header/headerTop/headerSelects/selects";
import {convertTypeInputToString} from "../offer/CreateForm";
import React, {useEffect} from "react";
import Select from "react-select";
import {offerStatus} from "../offer/offerStatus";

const CreateSearchCategories = ({
    type,
    setCategoryParent,
    categoryParent,
    setCategoryChild,
    setHaveSubCategories,
    haveSubCategories,
    errors,
    setActiveStatus,
    activeStatus
}) => {

    useEffect(() => {
        setCategoryParent(null);
        setCategoryChild(null);
    }, [type]);

    useEffect(() => {
        setCategoryChild(null);
    }, [categoryParent]);

    const loadCategoryParent = (inputValue, callback) => {
        let options = [];
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`category?filter[type]=${type}&filter[depth]=1&pagination=0&sort=title`)
            .then((response) => {
                response.data.data.forEach((permission) => {
                    options.push({
                        label: permission.title,
                        value: permission.id,
                    });
                });
                callback(options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())));
            });
    };

    const loadCategory = (inputValue, callback) => {
        // запрашиваем список для "вида техники"
        if (categoryParent == null) {
            return;
        }
        axios
            .create({
                baseURL: `${link}`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            })
            .get(`category?filter[parent_id]=${categoryParent.value}&pagination=0&sort=title`)
            .then(({ data }) => {
                setHaveSubCategories(data.data);
                callback(
                    data.data
                        .map(({ title, id }) => ({
                            label: title,
                            value: id,
                        }))
                        .filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))
                );
            });
    };

    return(
        <div className="create__ads_top__block create__ads_block">
            <div className={`input_wrap 
            ${
                +type === 2 ? "input_wrap_100" : ""
            }`}>
                        <AsyncSelect
                            key={type}
                            components={{ DropdownIndicator }}
                            placeholder={"Выберите категорию " + convertTypeInputToString(type)}
                            cacheOptions
                            defaultOptions
                            styles={customStyles2}
                            value={categoryParent}
                            onChange={setCategoryParent}
                            loadOptions={loadCategoryParent}
                            noOptionsMessage={() => ""}
                        />
            </div>

            <div
                className={`input_wrap ${haveSubCategories.length > 0 || +type !== 2 ? "" : "dn"} ${
                    haveSubCategories.length > 0 && +type === 2 ? "input_wrap_100" : ""
                }`}>
                        <AsyncSelect
                            key={categoryParent?.value}
                            components={{ DropdownIndicator }}
                            placeholder={"Подкатегория " + convertTypeInputToString(type)}
                            cacheOptions
                            defaultOptions
                            styles={customStyles2}
                            onChange={setCategoryChild}
                            loadOptions={loadCategory}
                            noOptionsMessage={() => ""}
                        />
                <p className="error">{errors.category?.message}</p>
            </div>

            <div className={`input_wrap  ${+type === 1 ? "input_wrap_" : "dn"}`}>
                <Select
                    key={type}
                    components={{ DropdownIndicator }}
                    onChange={setActiveStatus}
                    placeholder="Статус"
                    options={offerStatus}
                    value={activeStatus}
                    styles={customStyles2}
                    noOptionsMessage={() => ""}
                />
            </div>
        </div>
    )
}

export default CreateSearchCategories;