import { useContext, useEffect, useState } from "react";
import DeviceContext from "../../store/device-context";
import OpenSettingsSvg from "./svg/OpenSettingsSvg";
import SettingsDeleteSvg from "./svg/SettingsDeleteSvg";
import { useDispatch, useSelector } from "react-redux";
import { calendarAction, checkboxAction } from "../../store/redux";
import { link } from "../../store/host-store";
import { token } from "../../App";
import AuthContext from "../../store/auth-context";
import ModalsContext from "../../store/modals-context";
import SettingsCalendarSvg from "./svg/SettingsCalendarSvg";
import PromoteMobileSvg from "./desktopPages/svg/PromoteMobileSvg";
import {useNavigate} from "react-router-dom";

const AdvertisementCheckboxAll = (props) => {
    const device = useContext(DeviceContext);
    const modal = useContext(ModalsContext);
    const [isModal, setIsModal] = useState(false);
    const ctx = useContext(AuthContext);

    const getCheckboxesState = useSelector((state) => state.checkboxes);
    const getStatus = useSelector((state) => +state.status.status);
    const seek = useSelector(state => state.adsSearchLogic.statusAds)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isRegistrarRole = ctx.userData?.account?.role === 'registrar'

    let idsArr = props.arr.map((item) => item.id);
    useEffect(() => {
        idsArr = props.arr.map((item) => item.id);
    }, [props.id]);

    const handleMainCheckboxChange = (ids) => {
        dispatch(checkboxAction.toggleMainCheckbox(ids));
    };

    const openSettingsHandler = () => {
        setIsModal((prevState) => !prevState);
    };

    const deleteHandler = async () => {
        getCheckboxesState.checkedArr.forEach((item) => {
            fetch(`${link}/object/${item}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ status: 4 }),
            }).then(() => {
                ctx.setRerender((prev) => !prev);
            });
        });
    };

    const deactivateHandler = async () => {
        let data = 3;
        if (getStatus === 3) {
            data = 1;
        }
        getCheckboxesState.checkedArr.forEach((item) => {
            fetch(`${link}/object/${item}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ status: data }),
            }).then(() => {
                ctx.setRerender((prev) => !prev);
            });
        });
    };

    const promoteChecked = async () => {
        if (getCheckboxesState.checkedArr.length > 0) {
            await localStorage.setItem('promo', "item=" + getCheckboxesState.checkedArr.map(item => item));
            await navigate('/lc/promotion/rate');
        } else {
            alert("Выберите хотя бы одно объявление");
        }
    }

    const dateForAll = () => {
        dispatch(calendarAction.modalFetchBoolean(false));
        dispatch(calendarAction.workOnWeekends(true));
        if (getCheckboxesState.checkedArr.length > 0) {
            modal.calendarModal();
            dispatch(calendarAction.calendarCheckbox(true));
        } else {
            alert("Выберите хотя бы одно объявление");
        }
    };

    return (
        <div className="flex_advers__settings">
            <label className="container_checkbox">
                <input
                    type="checkbox"
                    className="advers__settings advers__settings_checkbox"
                    checked={getCheckboxesState.isCheckAll}
                    onChange={() => {
                        handleMainCheckboxChange(idsArr);
                    }}
                />
                <span className="checkmark"></span>
            </label>
            {getStatus !== 2 && (
                seek === 0 &&
                <div className="advers__settings advers__settings_stop">
                    {getStatus === 3 && <div onClick={deactivateHandler}>Возобновить</div>}
                    {getStatus !== 3 && <div onClick={deactivateHandler}>Остановить</div>}
                </div>
            )}
            {!device.isMobile && getStatus === 1 && (
            <div className="advers__settings advers__settings_promotion" onClick={promoteChecked}>
                Продвигать
            </div>
            )}
            {seek === 0 && !device.isMobile &&
            <div onClick={dateForAll} className="advers__settings advers__settings_changeDate">
                Изменить дату работы
            </div>
            }
            {!isRegistrarRole &&
            !device.isMobile && (
                <div onClick={deleteHandler} className="advers__settings advers__settings_delete">
                    Удалить
                </div>
            )}
            {(device.isMobile && getStatus === 2 || device.isMobile && getStatus === 1) && !isRegistrarRole &&(
                <div onClick={deleteHandler} className="advers__settings advers__settings_delete">
                    Удалить
                </div>
            )}

            {device.isMobile && getStatus !== 2 && (
                <div className="open_settings_advers">
                    <div className="open_setting_click" onClick={openSettingsHandler}>
                        <OpenSettingsSvg />
                    </div>
                    {isModal && (
                        <div className="modalSettings">
                            {getStatus === 1 &&
                                <div className="adv_modal_text adv_modal_settings_promotion" onClick={promoteChecked}>
                                    <div className="open_sett_svg">
                                        <PromoteMobileSvg />
                                    </div>
                                    <span>Продвигать</span>
                                </div>
                            }
                            <div className="adv_modal_settings_change__date adv_modal_text" onClick={dateForAll}>
                                <div className="open_sett_svg">
                                    <SettingsCalendarSvg />
                                </div>
                                <span>Изменить дату работы</span>
                            </div>
                            {!isRegistrarRole &&
                            <div className="adv_modal_settings_delete adv_modal_text" onClick={deleteHandler}>
                                <div className="open_sett_svg">
                                    <SettingsDeleteSvg />
                                </div>
                                <span>Удалить</span>
                            </div>
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdvertisementCheckboxAll;
