import RequestRubleSvg from "../ModalSvg/RequestRubleSvg";
import { useState } from "react";

const PaymentFromAcc = (props) => {
    const priceHandler = (e) => {
        props.setPrice(e.target.value);
    };

    return (
        <>
            <h5 className="title">{props.title}</h5>
            <div className="modal_title__sub">Введите сумму:</div>

            <div className="input input_payment">
                <div className="icon">
                    <RequestRubleSvg />
                </div>
                <input type="number" placeholder="Введите стоимость" onChange={priceHandler} value={props.price} />
            </div>
            <button type="button" className="payment__button_apply" onClick={props.onClick}>
                Пополнить
            </button>

            <div className="payment_info_text">
                Нажимая на кнопку "Отправить", вы принимаете условия <span> </span>
                <a href="/userTerm" target="_blank">
                    публичной оферты
                </a>
                <span> </span>и даете согласие на обработку <span> </span>
                <a href="/agreement"> персональных данных</a>.
            </div>
        </>
    );
};

export default PaymentFromAcc;
