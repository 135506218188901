import CabinetSocToggle from "../cabinetInfo/CabinetSocToggle";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../store/auth-context";
import {useLazyGetModelAgreementQuery, useTogglePartnerOrBonusMutation} from "../../store/redux/queries/partnerProgram/partner-program-query";
import {useDispatch} from "react-redux";
import {referralSliceAction} from "../../store/redux";
import ModalsContext from "../../store/modals-context";

export const ReferralToPartnerChange = () => {
    const ctx = useContext(AuthContext)
    const [checkedPartnerProgram, setCheckedPartnerProgram] = useState(ctx.userData.affiliate_active)
    const [setTogglePartnerProgram, {data}] = useTogglePartnerOrBonusMutation()
    const [triggerAgreement, {data: dataAgreement}] = useLazyGetModelAgreementQuery()
    const dispatch = useDispatch();
    const modal = useContext(ModalsContext)

    if(checkedPartnerProgram === 0){
        dispatch(referralSliceAction.toggleReferral(true))
    }else {
        dispatch(referralSliceAction.toggleReferral(false))
    }

    const togglePartnerOrBonus = () => {
        setCheckedPartnerProgram(checkedPartnerProgram === 0 ? 1 : 0)
        ctx.userData.affiliate_active = checkedPartnerProgram
        let data = {
            affiliate_active: checkedPartnerProgram === 1 ? 0 : 1
        }
        setTogglePartnerProgram({toggleArg: data, userId: ctx.isUserId})
    }

    useEffect(() => {
        triggerAgreement()
        dispatch(referralSliceAction.getAgreementId(dataAgreement?.data.id))
    }, [dataAgreement])


    const takeMoney = () => {
        if(ctx.userData?.confirmed === 0){
            return modal.accountPartnerNotConfirmed();
        }
        if(dataAgreement?.data.file === null){
           return modal.accountPartnerAgreement()
        }else if(dataAgreement?.data.confirmed === 0 && dataAgreement?.data.file){
            return alert("Ждите подтверждения администратора")
        }
        modal.partnerProgramModal()
    }
  return(
      //zxc
      <>
          <div className="partner_referral_flex">
              <CabinetSocToggle
                  toggle={togglePartnerOrBonus}
                  text="Партнерская программа"
                  checked={checkedPartnerProgram === 1 ? true : false}
              />

              {checkedPartnerProgram === 1 && <button className="btnTakeMoneyFromPartnerProgram" type="button"
                                                      onClick={takeMoney}>Запрос на вывод средств</button>}
          </div>
          </>
  )
}