import {createSlice} from "@reduxjs/toolkit";


let initialState = {
    idSubCategory: [],
    loader: false,
    moneyCheck: null,
    idRent: null,
    cartName: '',
}

const catalog = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        getIdCategory(state, action){
            state.idSubCategory = action.payload;
        },
        loaderTrue(state){
            state.loader = true;
        },
        loaderFalse(state){
            state.loader = false;
        },
        setMoneyCheck(state, action){
            state.moneyCheck = action.payload
        },
        getRent(state, action){
            state.idRent = action.payload;
        },
        getCartName(state, action){
            state.cartName = action.payload;
        }
    }
})

export default catalog;