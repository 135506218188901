import Select from "react-select";
import { customStyles2, DropdownIndicator } from "../../components/header/headerTop/headerSelects/selects";
import { ru } from "date-fns/locale";
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates";
import {useContext, useEffect, useState} from "react";
import moment from "moment";
import ModalsContext from "../../store/modals-context";
import {useLazyClosingDocsQuery} from "../../store/redux/queries/bills-query";

const options = [
    { value: "1", label: "Оплачен" },
    { value: "3", label: "Ожидает оплаты" },
    { value: "2", label: "Отклонен" },
];

const BankCheckFilter = ({ setData, data, fetchData }) => {
    const today = moment(new Date()).format("DD.MM.YYYY");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const modal = useContext(ModalsContext);
    const [trigger] = useLazyClosingDocsQuery()

    const [status, setStatus] = useState();

    const getStatus = (event) => {
        setStatus(event);
    };

    useEffect(() => {
        setData({
            ...data,
            value: status?.value,
            startDate: moment(startDate).format("YYYY.MM.DD"),
            endDate: moment(endDate).format("YYYY.MM.DD"),
        });
    }, [status, startDate, endDate]);


    const orderBankDocs = async () => {
        if(!startDate){
            return alert('Выберите начальную дату')
        }
        const result = await trigger({startDate: moment(startDate).format('DD.MM.YYYY'),
            endDate: moment(endDate).format('DD.MM.YYYY')}).unwrap();
        if(result.success){
            modal.openBankChecksModal();
        }else{
            alert("Произошла ошибка")
        }
    }

    return (
        <div className="bankCheckFilter">
            <div className="bankCheckStatus">
                <div className="bankFilter__title">Статус</div>
                <div className="bankSelect">
                    <Select
                        options={options}
                        components={{ DropdownIndicator }}
                        styles={customStyles2}
                        placeholder={"Выберите статус"}
                        value={status}
                        onChange={getStatus}
                    />
                </div>
            </div>
            <div className="bankCheckCalendar">
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    minimumLength={1}
                    format="dd MMM yyyy"
                    locale={ru}>
                    {({ startDateInputProps, endDateInputProps, focus }) => (
                        <div className="date-range date_range_flex">
                            <div className="block_calendar">
                                <div className="bankFilter__title">от:</div>
                                <input
                                    className={"input" + (focus === START_DATE ? " -focused" : "")}
                                    {...startDateInputProps}
                                    placeholder={today}
                                />
                            </div>
                            <div className="block_calendar">
                                <div className="bankFilter__title">до:</div>
                                <input
                                    className={"input input_end" + (focus === END_DATE ? " -focused" : "")}
                                    {...endDateInputProps}
                                    placeholder={today}
                                />
                            </div>
                        </div>
                    )}
                </DateRangePicker>
            </div>
            <div className="bankCheckBtn">
                <button type="button" onClick={fetchData}>
                    Найти
                </button>
            </div>

            <div className="bankCheck_block">
                <div className="bankFilter__title">Закрывающие документы:</div>

                <button type="button" onClick={orderBankDocs}>
                    Заказать
                </button>
            </div>
        </div>
    );
};

export default BankCheckFilter;
