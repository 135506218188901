import { useState } from "react";
import FormModal from "../../../UI/Modal/FormModal/FormModal";
import Loader from "../../../components/loader/Loader";
import { useEffect } from "react";
import style from './style.module.sass'
import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import PaginationPattern from "../../../UI/PaginationPattern";
import { token } from "../../../App";
import { link } from "../../../store/host-store";
import CreateSearchTime from "../../../cabinet/advertisement/create/search/CreateSearchTime";
import {useForm} from "react-hook-form";
import moment from "moment/moment";


function Promocode() {
    const [promo, setPromo] = useState('')
    const [amount, setAmount] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [dataCodes, setDataCodes] = useState([])
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [rerender, setRerender] = useState(false)
    const [error, setError] = useState({ field: '', message: '' })

    const {register, handleSubmit} = useForm();
    const [date, setDate] = useState();
    const [errors, setErrors] = useState({});


    useEffect(() => {
        window.scrollTo(0, 0);

        fetch(`${link}/promocode?pagination[pageSize]=50&page=${page}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))

            .then((result) => {
                if (result.data.success) {
                    console.log(result.data)
                    setDataCodes(result.data.data);
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                }
            })
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(false))
    }, [rerender, page])

    function createCode(data2) {
        const data = { code: promo, amount: Number(amount),
            active_till: date !== undefined ? moment(date).format('YYYY-MM-DD') + ' ' + data2.time + ':00' : null }

        fetch(`${link}/promocode`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((res) => res.json())

            .then((result) => {
                if (result.success) {
                    setOpenModal(false)
                    setPromo("")
                    setAmount("")
                    setRerender(prev => !prev)
                    setError({ field: '', message: '' })
                } else {
                    setError({ message: result.data[0].message, field: result.data[0].field })
                }
            }).then(() => setDate(undefined))
            .catch((e) => console.log(e))
    }

    function onDelete(id) {
        fetch(`${link}/promocode/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
                "Content-Type": "application/json",
            },
            method: "DELETE",
        })
            .then((res) => res.json())

            .then((result) => {
                if (result.success) {
                    setRerender(prev => !prev)
                }
            })
            .catch((e) => console.log(e))
    }

    return (
        <div className="admin__layout">
            {openModal && (
                <FormModal
                    title="Добавить промокод"
                    button="Добавить"
                    fn={handleSubmit(createCode)}
                    isOpenModal={setOpenModal}
                >
                        <div className={style.time_promocode}>
                            <CreateSearchTime register={register} setDate={setDate} date={date} errors={errors}/>
                        </div>
                        <div className={style.inputList}>
                            <input className={error.field == 'code' ? style.error : ''} value={promo} onChange={(e) => setPromo(e.target.value)} placeholder="Введите промокод" />
                            <input className={error.field == 'amount' ? style.error : ''} value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Введите сумму промокода" />
                            {error.field && <span>{error.message}</span>}
                        </div>
                </FormModal>
            )}
            <div className={style.head}>
                <h3>Промокоды</h3>
                <div className={style.top}>
                    <button type="button" className="admin__btn-blue" onClick={() => setOpenModal(true)}>
                        Добавить промокод
                    </button>
                </div>
            </div>

            {isLoading ? (
                <Loader />
            ) : dataCodes.length > 0 ? (
                <>
                    {dataCodes.map((el) => {
                        return (
                            <div className={style.code} key={el.id}>
                                <div>
                                    <p><b>Промокод:</b> {el.code}</p>
                                    <p><b>Количество баллов:</b> {el.amount}</p>
                                    <p><b>Количество использований:</b> {el.activationsCount}</p>
                                    {el.active_till !== null ? <p><b>Срок действия:</b> {moment(el.active_till).format('DD.MM.YYYY')}</p> : null}
                                </div>
                                <button
                                    title="удалить"
                                    type="button"
                                    className={style.delete}
                                    onClick={() => onDelete(el.id)}>
                                    <DeleteIcon />
                                </button>
                            </div>
                        );
                    })}
                    <PaginationPattern
                        setChange={(e, value) => setPage(value)}
                        pageCount={pageCount}
                        page={page}
                    />
                </>
            ) : (
                <>Пусто</>
            )}
        </div>
    )
}

export default Promocode