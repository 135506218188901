export const UserIcon = () => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clilrule="evenodd"
                d="M2.38095 4.23C2.38095 1.87562 4.33418 0 6.70238 0H7.29762C9.66582 0 11.619 1.87562 11.619 4.23V4.81C11.619 6.32233 10.8131 7.63712 9.60898 8.38414C12.142 9.14113 14 11.4411 14 14.1867C14 15.2064 13.1572 16 12.1587 16H1.84127C0.842818 16 0 15.2064 0 14.1867C0 11.4411 1.85798 9.14113 4.39102 8.38414C3.18687 7.63712 2.38095 6.32233 2.38095 4.81V4.23ZM6.70238 1.5C5.1257 1.5 3.88095 2.74048 3.88095 4.23V4.81C3.88095 6.29952 5.1257 7.54 6.70238 7.54H7.29762C8.8743 7.54 10.119 6.29952 10.119 4.81V4.23C10.119 2.74048 8.8743 1.5 7.29762 1.5H6.70238ZM6.20635 9.62C3.58865 9.62 1.5 11.6828 1.5 14.1867C1.5 14.3415 1.63434 14.5 1.84127 14.5H12.1587C12.3657 14.5 12.5 14.3415 12.5 14.1867C12.5 11.6828 10.4113 9.62 7.79365 9.62H6.20635Z"
                fill="black"
            />
        </svg>
    );
};
