import {NavLink} from "react-router-dom";
import PlaySvg from "./svg/PlaySvg";
import PauseDesktopSvg from "./svg/PauseDesktopSvg";
import {calendarAction} from "../../../store/redux";
import CalendarDesktopSvg from "./svg/CalendarDesktopSvg";
import DeleteDesktopSvg from "./svg/DeleteDesktopSvg";
import {useDispatch, useSelector} from "react-redux";
import PromotionSvg from "./svg/PromotionSvg";
import promo from "./svg/promotion.png"
import {useContext} from "react";
import AuthContext from "../../../store/auth-context";

const AdsButtons = ({seek, changeDateBySvg, item, deleteHandler, deactivateHandler, getAway, promotionOneItem}) => {
    const dispatch = useDispatch();
    const status = useSelector(state => +state.status.status)
    const ctx = useContext(AuthContext);
    const isRegistrarRole = ctx.userData?.account?.role === 'registrar'

    return(
        <div className={`advers__box___right ${seek === 1 ? "advers_box_right_search" : ""}`}>
            {/*{item.status === 0 || item.status === 2 ?  (*/}
            {/*    seek === 0 &&*/}
            {/*    <div*/}
            {/*        className={`moderation_advers_block ${*/}
            {/*            item.status === 2 ? "moderationRed" : ""*/}
            {/*        }`}>*/}
            {/*        Модерация*/}
            {/*    </div>*/}

            {/*) : (*/}
            {/*    ""*/}
            {/*)}*/}
            {seek === 0 &&
                <NavLink
                    to={`/lc/advertisement/edit-offer/${item.id}`}
                    className={`link redact`}>
                    {item.status === 2 ? "Исправить" : "Редактировать"}
                </NavLink>
            }
            {seek === 1 &&
                <NavLink
                    to={`/lc/advertisement/edit-search/${item.id}`}
                    className={`link redact ${item.status === 2 && "redactModeration"}`}>
                    {item.status === 2 ? "Исправить" : "Редактировать"}
                </NavLink>
            }
            {status === 2 &&
                <a style={{cursor: "pointer", marginTop: "10px"}} onClick={() => deleteHandler(item.id)} className="redact redactModeration">Удалить</a>}
            {window.innerWidth > 768 && item.status !== 0 && item.status !== 2 && seek === 0 && (
                <div className="advers__right_sub__buttons">
                    <div className="other_actions">
                        Другие <br /> действия:
                    </div>

                    {item.status === 2 ? (
                        <div className="link deactivate"></div>
                    ) : (
                        <a
                            className="link deactivate"
                            onClick={async () => {
                                deactivateHandler(item.id, item.status);
                                getAway(item.id);
                            }}>
                            {item.status === 3 ? (
                                <PlaySvg />
                            ) : (
                                <PauseDesktopSvg />
                            )}
                        </a>
                    )}
                    <a className="link ads_promotion" onClick={() => promotionOneItem(item.id)}>
                        <PromotionSvg />
                    </a>

                    <a
                        onClick={() => {
                            changeDateBySvg(item.id);
                            dispatch(
                                calendarAction.workOnWeekends(item.work_on_weekend === 1)
                            );
                        }}
                        className="link ads__modal_calendar">
                        <CalendarDesktopSvg />
                    </a>
                    {!isRegistrarRole &&
                    status !== 2 && (
                    <a className="link delete" onClick={() => deleteHandler(item.id)}>
                        <DeleteDesktopSvg />
                    </a>
                        )}
                </div>
            )}
            {seek === 1 &&
                <div className="advers__right_sub__buttons">
                    <div className="other_actions">
                        Другие <br /> действия:
                    </div>

                    <a className="link ads_promotion" onClick={() => promotionOneItem(item.id)}>
                        <img src={promo} alt=""/>
                    </a>
                    {!isRegistrarRole &&
                    status !== 2 && (
                        <a className="link delete" onClick={() => deleteHandler(item.id)}>
                            <DeleteDesktopSvg />
                        </a>
                    )}
                </div>
            }
        </div>
    )
}

export default AdsButtons;