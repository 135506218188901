import { useContext } from "react";

import ModalDeleteProfile from "./DeleteProfile/ModalDeleteProfile";
import MainCategories from "./HeaderCategories/MainCategories";
import ModalsContext from "../../store/modals-context";
import s from "./HeaderCategories/style.module.sass";
import ModalNonConfirm from "./ModalNonConfirm";
import ModalIsOrdered from "./ModalIsOrdered";
import ModalFeedback from "./ModalFeedback";
import ModalContacts from "./Contacts/ModalContacts";
import ModalRequest from "./ModalRequest";
import "./Modal.sass";
import ModalCalendar from "./ModalCalendar";

import { Transition } from "react-transition-group";
import { useDispatch } from "react-redux";
import { calendarAction } from "../../store/redux";
import ModalPayment from "./ModalPayments/ModalPayment";
import MainModalRequest from "./ModalPayments/PaymentsRequest/MainModalRequest";

import LoginModal from "./Authentification/LoginModal";
import RegistrationModal from "./Authentification/RegistrationModal";
import LoadAuthorization from "./Authentification/LoadAuthorization";
import CodeAuthorization from "./Authentification/CodeAuthorization";
import ModalChooseRole from "./ChooseRole/ModalChooseRole";
import ModalBlock from "./ModalBlock/ModalBlock";
import ModalRequestOffer from "./ModalRequestOffer";
import ModalOpenedContacts from "./Contacts/ModalOpenedContacts";
import ModalCity from "./City/ModalCity";
import ModalAccountOnCheck from "./Advertisement/ModalAccountOnCheck";
import ModalAccountComplete from "./Advertisement/ModalAccountComplete";
import BankCheckModal from "./BankCheck/BankCheckModal";
import {AccountNeedConfirmModal} from "./partnerProgramm/AccountNeedConfirmModal";
import {AccountWithdrawModal} from "./partnerProgramm/AccountWithdrawModal";
import {AccountAgreementModal} from "./partnerProgramm/AccountAgreementModal";
import {ModeratorNotFoundModal} from "./moderator/ModeratorNotFoundModal";

const Modal = ({ id }) => {
    const ctx = useContext(ModalsContext);
    const dispatch = useDispatch();

    return (
        <>
            <Transition in={ctx.isModal} mountOnEnter unmountOnExit timeout={400}>
                {(state) => (
                    <div className={`modal__overlay ${ctx.isCategories ? "modal__categories" : ""} ${state}`}>
                        <span
                            className="modal__close"
                            onClick={async () => {
                                await dispatch(calendarAction.closeCalendar());
                                await ctx.closeModal();
                                await document.querySelector('body').classList.remove('noscroll')
                            }}></span>
                        {ctx.openModal === "ordered" && <ModalIsOrdered />}
                        {ctx.openModal === "contacts" && <ModalContacts />}
                        {ctx.openModal === "deleteProfile" && <ModalDeleteProfile />}
                        {ctx.openModal === "request" && <ModalRequest id={id} />}
                        {ctx.openModal === "request-offer" && <ModalRequestOffer id={id} />}
                        {ctx.openModal === "unconfirm" && <ModalNonConfirm />}
                        {ctx.openModal === "feedback" && <ModalFeedback />}
                        {/* {ctx.openModal === "youtube" && <VideoModal />} */}
                        {ctx.openModal === "unauth" && <LoginModal />}
                        {ctx.openModal === "registration" && <RegistrationModal />}
                        {ctx.openModal === "loadAuthorization" && <LoadAuthorization />}
                        {ctx.openModal === "calendar" && <ModalCalendar />}
                        {ctx.openModal === "tel" && <ModalOpenedContacts />}
                        {ctx.openModal === "payment" && <ModalPayment />}
                        {ctx.openModal === "paymentRequest" && <MainModalRequest />}
                        {ctx.openModal === "codeAuthorization" && <CodeAuthorization />}
                        {ctx.openModal === "choose-catalogPages-role" && <ModalChooseRole />}
                        {ctx.openModal === "city" && <ModalCity />}
                        {ctx.openModal === "ads_checking" && <ModalAccountOnCheck />}
                        {ctx.openModal === "ads_complete" && <ModalAccountComplete />}
                        {ctx.openModal === "openBankChecksModal" && <BankCheckModal />}
                        {ctx.openModal === "accountNeedConfirm" && <AccountNeedConfirmModal />}
                        {ctx.openModal === "accountWithdraw" && <AccountWithdrawModal />}
                        {ctx.openModal === "accountAgreement" && <AccountAgreementModal />}
                        {ctx.openModal === "block" && <ModalBlock />}
                        {ctx.openModal === "moderatorNotFound" && <ModeratorNotFoundModal />}

                        {ctx.isCategories && (
                            <div className={`container ${s.container}`}>
                                <MainCategories />
                            </div>
                        )}
                    </div>
                )}
            </Transition>
        </>
    );
};

export default Modal;
