import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {link} from "../../host-store";
import {token} from "../../../App";

export const supportMessage = createApi({
    reducerPath: 'supportMessage',
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["Support"],
    endpoints: (builder) => ({
        putMessageText: builder.mutation({
            query: ({id, body}) => ({
                url: `/chat-message/${id}`,
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(body)
            }),
            invalidatesTags: ["Support"],
        }),
    })
})

export const {usePutMessageTextMutation} = supportMessage