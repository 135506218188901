import {useContext, useEffect, useState} from "react";

import s from "./style.module.sass";
import ModalsContext from "../../../store/modals-context";

const RateCard = ({ descr, src, onBuy, fix, children, data, priceAds }) => {
    const adsPage = window.location.href.indexOf('ads_items') > -1;
    const modal = useContext(ModalsContext);
    const [value, setValue] = useState(null);

    let totalCostPerDay = priceAds?.reduce(
        (acc, ad) => acc + ad?.costPerDay,
        0
    );

    useEffect(() => {
        if (data[0] !== undefined) {
            setValue(data[0].id);
        }
        modal.setMoney(priceAds?.reduce(
            (acc, ad) => acc + ad?.costPerDay,
            0
        ) * data[0]?.duration)
        totalCostPerDay = priceAds?.reduce(
            (acc, ad) => acc + ad?.costPerDay,
            0
        );
    }, [data, priceAds]);



    return (
        <div className={`${s.wrapper} ${fix ? s.fix : s.eye}`}>
            <img src={src} alt="about rate" />
            <div className={s.content}>
                <h3>{children}</h3>
                <p className={s.descr}>{descr}</p>

                <ul>
                    {data.map((el) => {
                        return (
                            <li key={el.id} className={s.item}>
                                <div className={s.input_wrapper}>
                                    <input
                                        type="radio"
                                        name={el.id}
                                        id={el.id}
                                        value={el.id}
                                        checked={value == el.id ? true : false}
                                        onClick={() => modal.setMoney(totalCostPerDay * el.duration)}
                                        onChange={(e) => setValue(e.currentTarget.value)}
                                    />

                                    {adsPage ?
                                        <label htmlFor={el.id} className={s.price} style={{cursor: 'pointer'}}
                                               onClick={() => modal.setMoney(totalCostPerDay * el.duration)}>
                                            <p>
                                                {el.name} - {totalCostPerDay * el.duration}₽
                                                {/*<span>{el.old_price}₽</span>*/}
                                            </p>
                                            <span className={s.sale}>
                                            {/*скидка {Math.round((el.price / el.old_price) * 100)} %*/}
                                        </span>
                                        </label>
                                        :
                                    <label htmlFor={el.id} className={s.price} style={{cursor: 'pointer'}}>
                                        <p>
                                            {el.price}₽ - {el.name} <span>{el.old_price}₽</span>
                                        </p>
                                        <span className={s.sale}>
                                            скидка {Math.round((el.price / el.old_price) * 100)} %
                                        </span>
                                    </label>
                                    }
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <button onClick={() => onBuy(value)}>
                {adsPage ? 'Купить рекламу' : 'Купить продвижение'}
            </button>
        </div>
    );
};

export default RateCard;
