import ObjCounter from "../../moderator/main/object/components/ObjCounter";
import OrganizationObject from "../object/OrganizationObject";
import {useContext} from "react";
import AdModeratorContext from "../../store/ad-moderator-context";
import OnPublic from "../../moderator/buttons/OnPublic";

const CompanyModeration = () => {
    const ctx = useContext(AdModeratorContext);

  return(
      <>
        <ObjCounter />
        <OrganizationObject/>
        <OnPublic isCorrect={ctx.isCorrectOrganization.data} />
      </>
  )
}

export default CompanyModeration;