import '../Modal.sass'
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import {useContext, useState} from "react";
import ModalsContext from "../../../store/modals-context";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import PaymentFromAcc from "./PaymentFromAcc";
import {link, publicId} from "../../../store/host-store";
import {token} from "../../../App";
import PaymentBody from "./PaymentBody";
import uuid from "react-uuid";
import AuthContext from "../../../store/auth-context";
import {useDispatch, useSelector} from "react-redux";
import {adsSearchAction} from "../../../store/redux";

const ModalPayment = () => {
    const modal = useContext(ModalsContext);
    const [price, setPrice] = useState();
    const payForAdsModeration = useSelector(state => state.adsSearchLogic.checkingAccount);
    const [modalCheck, setModalCheck] = useState(payForAdsModeration ? 2 : 1);
    const ctx = useContext(AuthContext);
    const dispatch = useDispatch();

    let receipt = {
        "Items": [{
            "label": "Пополнение кошелька", //наименование товара
            "price": +price, //цена
            "quantity": 1.00, //количество
            "amount": +price, //сумма
            "vat": 0, //ставка НДС
            "method": 0, // тег-1214 признак способа расчета - признак способа расчета
            "object": 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
            "measurementUnit": "руб" //единица измерения
        }],
        "calculationPlace": window.location.origin, //место осуществления расчёта, по умолчанию берется значение из кассы
        "taxationSystem": 1, //система налогообложения; необязательный, если у вас одна система налогообложения
        "email": false, //e-mail покупателя, если нужно отправить письмо с чеком
        "phone": "", //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
        "customerInfo": "", // тег-1227 Покупатель - наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя (клиента)
        "customerInn": "7708806063", // тег-1228 ИНН покупателя
        "isBso": false, //чек является бланком строгой отчётности
        "AgentSign": null, //признак агента, тег ОФД 1057
        "amounts":
            {
                "electronic": +price, // Сумма оплаты электронными деньгами
                "advancePayment": 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                "credit": 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
                "provision": 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
            }
    }
    let data = {
        "CloudPayments": {
            "CustomerReceipt": receipt, //онлайн-чек
        }
    }

    window.pay = function () {
        let widget = new window.cp.CloudPayments();
        const invoice_id = uuid();
        widget.pay(
            "auth",
            {
                publicId: publicId,
                description: "Пополнение кошелька",
                amount: +price,
                currency: "RUB",
                invoiceId: invoice_id,
                data,
            },
            {
                onSuccess: function (options) {
                    fetch(`${link}/user/replenish`,
                        {
                            method: "PUT",
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: token,
                            },
                            body: JSON.stringify({invoice_id: invoice_id})
                        }
                    )
                        .then((res) => res.json())
                        .then((res) => {
                            console.log(res);
                            ctx.setRefreshData(prevState => !prevState)
                        });
                },
                onFail: function (reason, options) {
                    console.log("onFail");
                },
                onComplete: function (paymentResult, options) {
                    console.log("onComplete");
                    modal.closeModal();
                },
            }
        );
    };

    const addToWallet = async () => {
        await window.pay();
        dispatch(adsSearchAction.adsModerationPay(false))
    }

    const addToBank = () => {
        fetch(`${link}/bill?expand=billUser`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({amount: +price, appointment: 'Счет на оплату'})
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if(res.success){
                    modal.closeModal();
                    window.location.href = `/bank/${res.data.id}`
                    console.log(res);
                }else {
                    alert(res.data[0]?.message);
                    // setDataMess(res.data.message);
                }
            })
    }

    return(
        <div className="modal modal_payment">
            <div className="closeModal" onClick={modal.closeModal}>
                <ModalCloseSvg/>
            </div>

            <SwitchTransition>
                <CSSTransition classNames="fade-payment" key={modalCheck} timeout={300}>
            {modalCheck === 1 ?
                <PaymentBody addToWallet={addToWallet}
                             setModalCheck={setModalCheck}
                             setPrice={setPrice}
                             price={price}
                />
               : modalCheck === 2 ?
                <div>
                    <PaymentFromAcc title='Расчетный счет'
                                    onClick={addToBank}
                                    setPrice={setPrice}
                                    price={price}
                    />
                </div>
                    :
                    <PaymentFromAcc title='Оплатить онлайн'
                                    onClick={addToWallet}
                                    setPrice={setPrice}
                                    price={price}/>
            }
            </CSSTransition>
            </SwitchTransition>
        </div>
    )
}

export default ModalPayment;