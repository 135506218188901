
export const RegistrarActivity = ({registrarActivity, setRegistrarActivity}) => {
    const changeRegistrarActivity = (number) => {
        setRegistrarActivity(number)
    }

  return(
      <div className='registrar_activity'>
        <div className="advers___title">Время активности объявлений:</div>
          <div className="advers_registrar_block_main">
              <div className={`advers_registrar_item ${registrarActivity === 30 ? `active`: ``}`}
              onClick={() => changeRegistrarActivity(30)}>30 дней</div>
              <div className={`advers_registrar_item ${registrarActivity === 60 ? `active`: ``}`}
              onClick={() => changeRegistrarActivity(60)}>60 дней</div>
              <div className={`advers_registrar_item ${registrarActivity === 90 ? `active`: ``}`}
              onClick={() => changeRegistrarActivity(90)}>90 дней</div>
              <div className={`advers_registrar_item ${registrarActivity === 0 || isNaN(registrarActivity) ? `active`: ``}`}
              onClick={() => changeRegistrarActivity(0)}>Постоянно</div>
          </div>
      </div>
  )
}