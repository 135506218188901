import objImg from "../../../../assets/images/stubs/object-mob.png";
import {url} from "../../../../store/host-store";
import moment from "moment/moment";
import {NavLink} from "react-router-dom";
import {EyeIcon} from "../../../../components/icons/EyeIcon";

export const ObjectRegistrar = ({data}) => {
  return(
      <>
          <div className="admin__list-titles admin__list-titles_statistic_obj">
              <p>ID</p>
              <p>Фото</p>
              <p>Название</p>
              <p>Изменено</p>
              <p>Статус</p>
          </div>
          <div>
              {data.map((el) => (
                  <div className="admin__objects_object admin__objects_object_statistic_obj" key={el.id}>
                      <span>#{el?.id}</span>
                      {el.object?.image === null ? (
                          <img src={objImg} alt="object" />
                      ) : (
                          <img src={url + el?.image?.filename} alt="object" />
                      )}
                      <p className="admin__objects_object-name">{el?.name}</p>
                      <p>{moment(el?.created).format("DD.MM.YYYY")}</p>
                      {el.status === 1 ? (
                          <p className="admin__done">Подтверждено</p>
                      ) : el.status === 2 ? (
                          <p className="admin__rejected">Отклонено</p>
                      ) : (
                          ""
                      )}
                      <NavLink to={`/admin/objects/${el?.id}`}>
                          <EyeIcon />
                      </NavLink>
                  </div>
              ))}
          </div>
      </>
  )
}