import { useEffect, useState } from "react";
import { link } from "../../store/host-store";
import { token } from "../../App";
import PaginationPattern from "../../UI/PaginationPattern";

const HistoryTable = () => {
    const [page, setPage] = useState(1); // Страница пагинации
    const [pageCount, setPageCount] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`${link}/payment-history/my-history?pagination[pageSize]=6&page=${page}&sort=-date`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                if (result.data.success) {
                    setData(result.data.data);
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                }
            });
    }, [page]);

    return (
        <div className="bankCheckTable">
            <div className="bank_check__table_top">
                <div className="bankCheck__title">Счет</div>
                <div className="bankCheck_sum">Сумма</div>
                <div className="bankCheck__status">Статус</div>
            </div>

            {data.length > 0 ? (
                data.map((item) => (
                    <div key={item.id} className="bank__check__block">
                        <div className="bank__check__block_dates">
                            <div className="dates_check_data">{item.description}</div>
                            <div className="dates_check_time">{item.date}</div>
                        </div>
                        <div className="bank__check__block_sum">
                            <span>
                                {new Intl.NumberFormat("ru-RU", { style: "currency", currency: "RUB" }).format(
                                    +item.amount
                                )}
                            </span>
                        </div>
                        <div className="bank__check__block_status">
                            {item.status === 1 && <span className="waiting_check check_status">Платеж совершен</span>}
                            {item.status === 2 && <span className="denied_check check_status">Платеж провален</span>}
                        </div>
                    </div>
                ))
            ) : (
                <p style={{ padding: "8px" }}>У вас нет расчетных счетов</p>
            )}

            <div className="pagination_banks">
                <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page} />
            </div>
        </div>
    );
};

export default HistoryTable;
