import {useEffect, useState} from "react";
import {link} from "../../store/host-store";
import {token} from "../../App";
import PaginationPattern from "../../UI/PaginationPattern";

const BankCheckTable = ({ setBank, bank}) => {
    const [page, setPage] = useState(null); // Страница пагинации
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        fetch(`${link}/bill?expand=billUser&sort=-date&pagination[pageSize]=10&page=${page}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then(res => {
                if(res.success){
                    setBank(res.data.data)
                }
                setBank(res.data.data)
                setPageCount(parseInt(res.headers.get("X-Pagination-Page-Count")));
                setPage(parseInt(res.headers.get("X-Pagination-Current-Page")));
            })
    }, [page])


    return(
        <div className="bankCheckTable">
            <div className="bank_check__table_top">
                <div className="bankCheck__title">Счет</div>
                <div className="bankCheck_sum">Сумма</div>
                <div className="bankCheck__status">Статус</div>
            </div>

            {bank.length > 0 ?
            bank.map(item =>
                <a key={item.id} className="bank__check__block" href={`/bank/${item.id}`} target="_blank">
                    <div className="bank__check__block_dates">
                        <div className="dates_check_data">
                            Счёт №{item.id} от {item.date}
                        </div>
                        <div className="dates_check_time">
                            {item.date}
                        </div>
                    </div>
                    <div className="bank__check__block_sum">
                        <span>
                            {new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(+item.amount)}
                            </span>
                    </div>
                    <div className="bank__check__block_status">
                        {item.bill_status === 3 && <span className="waiting_check check_status">Ожидает оплаты</span>}
                        {item.bill_status === 2 && <span className="denied_check check_status">Отменен</span>}
                        {item.bill_status === 1 && <span className="applied_check check_status">Оплачен</span>}
                    </div>
                </a>
            )
                :
                <p style={{padding: '8px'}}>У вас нет расчетных счетов</p>
            }

            <div className="pagination_banks">
                <PaginationPattern
                    setChange={(e, value) => setPage(value)}
                    pageCount={pageCount}
                    page={page}
                />
            </div>

        </div>
    )
}

export default BankCheckTable;