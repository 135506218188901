import { useContext } from "react";

import { RatingIcon } from "../../components/icons/RatingIcon";
import { RatingZero } from "../../components/icons/RatingZero";
import object from "../../assets/images/stubs/object-mob.png";
import { LikeIcon } from "../../components/icons/LikeIcon";
import AuthContext from "../../store/auth-context";
import { url } from "../../store/host-store";
import { NavLink } from "react-router-dom";
import s from "./style.module.sass";
import moment from "moment";
import FixationSvg from "./svg/FixationSvg";
import ShowPromoteSvg from "./svg/ShowPromoteSvg";
import {cityAlias} from "../../App";
import CheckedUserMiniIcon from "../../components/catalogPages/svg/CheckedUserMiniIcon";
import {LocationCitySvg} from "../../cabinet/advertisement/desktopPages/svg/LocationCitySvg";

const ObjectOfferCard = ({
    id,
    alias,
    fullAllias,
    mark,
    name,
    about,
    image,
    toggleFav, //устанавливаем функцию выше, добавить или удалить
    isFavorite, //проверка, добавлено ли объявление в избранное
    favId, //id объвления
    final_date,
     fixation,
     activeShow,
    item,
    cityName
}) => {
    const ctx = useContext(AuthContext);
    const checkedByStatusOrganization = item?.user?.confirmed === 1
    console.log(cityName)
    return (
        <div className={s.card}>
            <div className={s.card__rating}>
                {mark !== null ? (
                    <>
                        <RatingIcon />
                        {mark}
                    </>
                ) : (
                    <>
                        <RatingZero />
                        <span>0.0</span>
                    </>
                )}
            </div>

            {(fixation !== null || activeShow !== null) &&
                <div className={s.fixation}>
                    {fixation !== null && <FixationSvg/>}
                    {activeShow !== null && <ShowPromoteSvg/>}
                </div>
            }

            {/* кнопка избранное отображается только при авторизации */}
            {ctx.userData?.id && (
                <button
                    title="мне нравится"
                    type="button"
                    className={`${s.card__like} ${isFavorite ? s.active : ""}`}
                    onClick={() => toggleFav(favId ? favId : id)}>
                    <LikeIcon />
                </button>
            )}

            <NavLink to={`/${cityAlias}/${alias}`} className={s.img_wrapper}>
                {checkedByStatusOrganization &&
                    <div className={s.organization_checked}>
                        <div className={s.organization_flex}>
                            <CheckedUserMiniIcon />
                            <span className={s.organization_text}>Личность подтверждена</span>
                        </div>
                    </div>
                }
                {cityName && <p className={s.abs}><span> <LocationCitySvg />{cityName}</span></p>}
                {image === null ? (
                    <img width={278} height={160} src={object} alt="object" />
                ) : (
                    <img
                        width={278}
                        height={160}
                        src={image.webpFilename.length > 0 ? url + image.webpFilename : url + image.filename}
                        alt="object"
                    />
                )}
            </NavLink>
            <div className={s.content}>
                <div className={s.title_box}>
                    <NavLink to={`/${cityAlias}/${alias}`}>
                        <h3 className={s.title}>{name}</h3>
                    </NavLink>
                </div>
                <div>
                    <div className={s.adv__radios}>
                        <div>
                            <h5>Требуется</h5>
                        </div>
                        <div>
                            <h5>Выполнить до:</h5>
                            <span>
                                {moment(final_date).format("DD.MM.YYYY")} |{moment(final_date).format("HH:MM")}
                            </span>
                        </div>
                    </div>

                    <h5 className={s.character}>Описание</h5>
                    <p className={s.descr}>{about.replaceAll('<br />', '')}</p>
                    <NavLink to={`/${cityAlias}/${fullAllias}/${alias}`} className={s.btn}>
                        {item?.contact !== null ? 'Подробнее' : 'Подать заявку'}
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ObjectOfferCard;
