import React from "react";

const Input = React.forwardRef((props, ref) => {
    return (
        <div className={props.classNameParent}>
            {props.h6 && <h6>{props.h6}</h6>}
            {props.srcLeft && (
                <div className="iconLeft">
                    <img width={23} height={23} src={props.srcLeft} alt="icon" />
                </div>
            )}
            {props.className === "textarea" ? (
                <textarea
                    className={props.className}
                    placeholder={props.placeholder}
                    ref={ref}
                    defaultValue={props.defaultValue}
                    onChange={props.onChange}
                />
            ) : (
                <input
                    className={props.className}
                    type={props.type}
                    placeholder={props.placeholder}
                    ref={ref}
                    defaultValue={props.defaultValue}
                    onClick={props.onClick}
                    onChange={props.onChange}
                />
            )}
            {props.src && (
                <div className="icon">
                    <img width={23} height={23} src={props.src} alt="icon" />
                </div>
            )}
        </div>
    );
});

export default Input;
