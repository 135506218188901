import styles from "../../subscribe/subscribe.module.sass";
import {NavLink} from "react-router-dom";
import info from "./svg/infographic.svg";
import {useContext, useEffect} from "react";
import ModalsContext from "../../../store/modals-context";
import AuthContext from "../../../store/auth-context";
import {adsSearchAction, blockerAction} from "../../../store/redux";

const StubAdsBottom = () => {
    const modal = useContext(ModalsContext);
    const auth = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return(
      <div className={styles.promotion_bottom}>
          <h2 className={styles.promotion_h2}>Начни рекламироваться</h2>
          <h3 className={styles.promotion_h3}>и повышай свой заработок!</h3>

          <img src={info} alt="infographic"/>

          <div className={styles.promotion_bottom_p}>
              <p style={{marginBottom: "8px"}} className={styles.promotion_main_p}>Рекламируйте свои объявления у нас на сервисе</p>
              <p className={styles.promotion_main_p}>и получайте больше заказов!</p>
          </div>

          <div className={`${styles.promotion_btn} ${styles.promotion_bottom_Btn}`}>
              {auth.userData?.id ? (
                  <NavLink className={`${styles.promotion_btn_save} ${styles.promotion_btn_after}`} to={`/advertiser/`}>Войти в личный кабинет</NavLink>
              ) : (
                  <NavLink className={`${styles.promotion_btn_save}`} onClick={() => {
                      modal.unauthModal();
                  }}>
                      Реклама
                  </NavLink>
              )}
          </div>
      </div>
  )
}

export default StubAdsBottom;