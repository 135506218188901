import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import moment from "moment";

import DateRangePickerExample from "../statistic/CalendarFromTo";
import PaginationPattern from "../../../UI/PaginationPattern";
import SearchInput from "../../../UI/SearchInput/SearchInput";
import Loader from "../../../components/loader/Loader";
import { link } from "../../../store/host-store";
import { token } from "../../../App";
import UsersList from "./UsersList";
import AuthContext from "../../../store/auth-context";

const Users = () => {
    const [page, setPage] = useState(null);
    const [dataUsers, setDataUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const { userData } = useContext(AuthContext);
    //выгрузка по дате календарь
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    //необходимо для передачи параметра страницы в url
    const navigate = useNavigate();
    //получаем параметры текущей страницы
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть, то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            //иначе присваиваем стартовое значение 1
            setPage(1);
        }
    }, [params]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (page !== null) {
            let fetchLink = `${link}/user?expand=account&pagination[pageSize]=100&page=${page}`;

            //если что-то вбито в поиске
            if (search.length > 0) {
                fetchLink += `&UserSearch[query]=${search}`;
            }

            fetch(fetchLink, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
                .then((result) => {
                    setLoading(false);
                    if (result.data.success) {
                        setDataUsers(result.data.data);
                        setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    }
                });
        }
    }, [page, search]);

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    return (
        <div className="admin__layout">
            <h3>Пользователи</h3>
            <div className="admin__search-wrapper">
                <SearchInput setSearch={setSearch} />
            </div>

            {userData.account.role !== 'tech' &&
            <div className="admin__users_navigation_calendar admin__statistic_navigation_calendar">
                <DateRangePickerExample
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
                <div className="admin__users_btns-wrapper">
                    <button
                        type="button"
                        className="admin__btn-blue"
                        onClick={() => getStatistic("email", startDate, endDate)}>
                        Выгрузка по почте
                    </button>
                    <button
                        type="button"
                        className="admin__btn-blue"
                        onClick={() => getStatistic("phone", startDate, endDate)}>
                        Выгрузка по телефону
                    </button>
                </div>
            </div>
            }
            {loading ? (
                <Loader />
            ) : dataUsers.length > 0 ? (
                <>
                    <UsersList dataUsers={dataUsers} />
                    {pageCount > 1 && (
                        <PaginationPattern
                            setChange={(e, value) => setChange(value)}
                            pageCount={pageCount}
                            page={page}
                        />
                    )}
                </>
            ) : (
                <>Пусто</>
            )}
        </div>
    );
};


export default Users;

function getStatistic(field, start, end) {
    fetch( (start !== null || end !== null) ?
        `${link}/user/uploading?field=${field}&dateStart=${moment(start).format("YYYY-MM-DD")}&dateEnd=
        ${moment(end).format("YYYY-MM-DD")}`
            : `${link}/user/uploading?field=${field}`,
        {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        }
    )
        .then((res) => res.json())
        .then((result) => {
            window.open(result.data.link);
        })
        .catch((e) => console.log(e));
}
