import "./bankChecks.sass";
import BankCheckWallet from "./BankCheckWallet";
import BankCheckFilter from "./BankCheckFilter";
import BankCheckTable from "./BankCheckTable";
import { useState } from "react";
import { link } from "../../store/host-store";
import { token } from "../../App";
import HistoryTable from "./HistoryTable";

const BankChecks = () => {
    const [bank, setBank] = useState([]);
    const [data, setData] = useState({
        value: "",
        startDate: "",
        endDate: "",
    });

    const fetchData = () => {
        if (data.value?.length === 1 && data.endDate > data.startDate) {
            fetch(
                `${link}/bill?expand=billUser&filter[bill_status]=${data.value}&filter[date][gte]=${data.startDate}&filter[date][lte]=${data.endDate}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res.success) {
                        setBank(res.data);
                    }
                });
        } else {
            alert("Заполните статус и дату");
        }
    };
    const [currentTab, setCurrentTab] = useState("checks");

    return (
        <>
            <div className="h2_cabinet_main">Бухгалтерские документы</div>
            <div className="feedback__main">
                <BankCheckWallet />

                <div className="bankCheckBottom">
                    <div className="bankchecks_tabs">
                        <p
                            className={`${currentTab === "checks" ? "active" : undefined}`}
                            onClick={() => setCurrentTab("checks")}>
                            Мои счета
                        </p>
                        <p
                            className={`${currentTab === "history" ? "active" : undefined}`}
                            onClick={() => setCurrentTab("history")}>
                            История операций
                        </p>
                    </div>
                    {currentTab === "checks" ? (
                        <>
                            <BankCheckFilter setData={setData} data={data} fetchData={fetchData} />
                            <BankCheckTable data={data} bank={bank} setBank={setBank} />
                        </>
                    ) : (
                        <HistoryTable />
                    )}
                </div>
            </div>
        </>
    );
};

export default BankChecks;
