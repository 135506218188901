import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import style from "../../cabinet/subscribe/subscribe.module.sass";
import RateCard from "../../cabinet/promotion/rates/RateCard";
import calendar from './svg/calendarAds.svg'
import {link} from "../../store/host-store";
import {token} from "../../App";
import ModalsContext from "../../store/modals-context";
import ArrowBack from "../../cabinet/request/svg/ArrowBack";

const LaunchAdsBuy = () => {
    let linkAds = '/advertisement/user-advertisements?';
    const [arrayAdsId, setArrayAdsId] = useState([]);
    const [priceAds, setPriceAds] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    const [arr, setArr] = useState([]);
    const modal = useContext(ModalsContext);
    const navigate = useNavigate();

  useEffect(() => {
    setSearchParams(localStorage.getItem('ads') ? localStorage.getItem('ads') : '');
  }, [])

    window.addEventListener("popstate",function(e){
        setSearchParams('')
        navigate('/advertiser/ad')
    },false);

  useEffect(() => {
    setArrayAdsId(searchParams.get("item")?.replace(/, +/g, ",").split(",").map(Number));
  }, [searchParams])

    arrayAdsId?.map(item => {
        linkAds += `filter[id][in][]=${item}&`
    })

    useEffect(() => {
        fetch(`${link}/tariff-plan?filter[type]=2`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    setArr(result.data);
                }else{
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/");
                    }
                }
            })
            .catch((e) => console.log(e));

    }, []);


  useEffect(() => {
      if(linkAds.includes('filter[id][in]')) {
          fetch(`${link}${linkAds}`, {
              headers: {
                  Accept: "application/json",
                  Authorization: token,
              },
          })
              .then((res) => res.json())
              .then((result) => {
                  console.log(result);
                  if (result.success) {
                      console.log(result)
                      setPriceAds(result.data)
                  } else {
                      if (result.status === 401) {
                          localStorage.removeItem("token");
                          window.location.replace("/");
                      }
                  }
              })
      }
  }, [linkAds])


    const onBuy = (tariff) => {
        modal.paymentRequest(arrayAdsId, tariff);
    }

  return(
      <div className='cabinet__redesign_container'>
          <div className="flex">
              <NavLink to="/advertiser/ad" className="margR6">
                  <ArrowBack />
              </NavLink>
              <h3 className="cabinet__redesign_title">Выберите сроки показа рекламы</h3>
          </div>
        <div className={style.promotion_block}>
            <div className="ads_buy_wrapper">
                <RateCard
                    descr="Выберите подходящую Вам продолжительность рекламы:"
                    src={calendar}
                    onBuy={onBuy}
                    priceAds={priceAds}
                    data={arr}>
                    Запуск рекламы
                    <span> объявления</span>
                </RateCard>
            </div>
        </div>
      </div>
  )
}

export default LaunchAdsBuy;