const DeleteCitySvg = () => {
    return(
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" fill="none">
                <circle cx="24.941" cy="24.627" r="24" fill="#D32121" fillOpacity=".6" transform="rotate(-90 24.94 24.627)"/>
                <path fill="#fff" fillRule="evenodd" d="M18.07 16.596a1.88 1.88 0 0 1 2.652 0L25.142 21l4.42-4.404a1.88 1.88 0 0 1 2.65 0c.733.73.733 1.913 0 2.642l-4.419 4.404 4.42 4.404c.732.73.732 1.912 0 2.642a1.88 1.88 0 0 1-2.652 0l-4.42-4.404-4.419 4.404a1.88 1.88 0 0 1-2.651 0 1.863 1.863 0 0 1 0-2.642l4.419-4.404-4.42-4.404a1.863 1.863 0 0 1 0-2.642Z" clipRule="evenodd"/>
            </svg>
        </>
    )
}

export default DeleteCitySvg;