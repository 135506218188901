import { NavLink } from "react-router-dom";

import { RatingIcon } from "../../../components/icons/RatingIcon";
import { RatingZero } from "../../../components/icons/RatingZero";
import noAvatar from "../../../assets/images/stubs/avatar.svg";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import { url } from "../../../store/host-store";
import uuid from "react-uuid";

const UsersList = ({ dataUsers }) => {
    console.log(dataUsers)
    return (
        <>
            <div className="admin__list-titles admin__list-titles_users">
                <p>ID</p>
                <p>Фото</p>
                <p>Имя</p>
                <p>Телефон</p>
                <p>Почта</p>
                <p>Рейтинг</p>
            </div>
            <div>
                {dataUsers.map((el) =>
                    el !== null ?
                    <div className="admin__content-card admin__content-card_users" key={uuid()}>
                        <span>#{el?.id}</span>
                        {el?.avatar === null ? (
                            <img src={noAvatar} alt="avatar" />
                        ) : el?.webpFilename ? (
                            <img src={url + el.webpFilename} alt="avatar" />
                        ) : (
                            <img src={url + el?.avatar} alt="avatar" />
                        )}

                        <p>{el?.name}</p>
                        <p>{el?.phone}</p>
                        <p>{el?.email}</p>
                        {el?.mark === null || el?.mark === 0 ? (
                            <span className="admin__content-card_rating zero">
                                <RatingZero />
                                <p>0.0</p>
                            </span>
                        ) : (
                            <span className="admin__content-card_rating">
                                <RatingIcon />
                                <p>{el?.mark}</p>
                            </span>
                        )}
                        <div className="admin__content-card-icons">
                            <NavLink to={`/admin/users/${el?.id}`}>
                                <EyeIcon />
                            </NavLink>
                        </div>
                    </div>
                        :
                        <div className="admin__content-card admin__content-card_users" key={uuid()} style={{display: 'flex'}}>
                        Пользователь удален
                        </div>
                )}
            </div>
        </>
    );
};

export default UsersList;
