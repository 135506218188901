import {useState} from "react";
import CheckedConfirmedUserIcon from "./svg/CheckedConfirmedUserIcon";
import {useSetDocumentsIsApprovedMutation} from "../../../store/redux/queries/launch-advertisement";

const OneUserIsDocsConfirmed = ({confirmed, idOrganization, status}) => {
    const [isAccept, setIsAccept] = useState(confirmed);
    const [setDocumentsApproved, {data}] = useSetDocumentsIsApprovedMutation();
    console.log(isAccept);

        const confirmDocs = () => {
            let data = {
                confirmed: isAccept === 2 ? 1 : 2
            }
            isAccept === 2 ? setIsAccept(1) : setIsAccept(2)
            setDocumentsApproved({id: idOrganization, body: data})
        }

  return(
      <>
          {status === 1 &&
              <>
              <div className="block_user_is_confirmed docs" onClick={confirmDocs}>
                  {isAccept === 1 ? <div className="block_user_is_confirmed_checked_true docs"><CheckedConfirmedUserIcon fill="#546edb"/></div> : null}
                  {isAccept === 2 ? <div className="block_user_is_confirmed_checked docs"></div> : null}
                  <div className="block_user_is_confirmed_text docs">Документы подтверждены</div>
              </div>
                  {!data?.success && <p style={{maxWidth: "236px"}} className="error">{data?.data.message}</p>}
              </>
          }
      </>
  )
}

export default OneUserIsDocsConfirmed;