import { useContext, useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";

import Loader from "../../components/loader/Loader";
import AuthContext from "../../store/auth-context";
import { link } from "../../store/host-store";
import RequestsList from "./RequestsList";
import RequestType from "./RequestType";
import RequestItem from "./RequestItem";
import ArrowBack from "./svg/ArrowBack";
import { token } from "../../App";
import "../cabinet.sass";
import {notificationAction} from "../../store/redux";

const Request = () => {
    const notificationData = useSelector(state => state.notification.data)

    const [typeCategory, setTypeCategory] = useState([]);
    const [typeId, setTypeId] = useState(notificationData?.type);
    const [object, setObject] = useState([]);
    const [objectId, setObjectId] = useState(0);
    const [requestCart, setRequestCart] = useState([]);
    const [mobileVisibleCart, setMobileVisibleCart] = useState(false);
    const [activeTab, setActiveTab] = useState("newRequests");
    const [counterNewResponse, setCounterNewResponse] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [page, setPage] = useState(1); // Страница пагинации
    const [pageCount, setPageCount] = useState(0);

    const resetObjects = (type) => {
        setObjectId(0);
        setRequestCart([]);
        setTypeId(type);
    };

    const dispatch = useDispatch();

    const getObjectId = (id) => {
        setObjectId(id);
    };

    const ctx = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const seekType = useSelector((state) => state.adsSearchLogic.statusAds);
    const selectorNotification = useSelector((state) => state.notification.data);

    useEffect(() => {
        setObjectId(0);
    }, [seekType, dispatch]);

    useEffect(() => {
        setObjectId(selectorNotification?.id);
    }, [selectorNotification]);

    useEffect(() => {
        if(!window.location.href.includes('#')){
            dispatch(notificationAction.resetState())
        }
    }, [seekType])


    useEffect(() => {
        //верхние табы главных категорий и их счетчики
        fetch(`${link}/category/account-list?seek=${seekType}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setTypeCategory(result.data);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    }, [ctx.rerender, seekType]);


    useEffect(() => {
        // список объявлений в зависимости от типа
        fetch(
            `${link}/object/user-objects?&filter[type]=${typeId}&filter[seek]=${seekType}&expand=city&pagination[pageSize]=10&page=${page}&confirmed=0`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                setIsLoading(true);
                if (result.data.success) {

                    setObject(result.data.data);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
                setPageCount(parseInt(result.headers.get("X-Pagination-Page-Count")));
                setPage(parseInt(result.headers.get("X-Pagination-Current-Page")));
            })
            .catch((error) => console.log(error));
    }, [ctx.rerender, typeId, page, seekType]);


    useEffect(() => {
        // заявки на выбранное объявление
        if (objectId !== 0) {
            fetch(`${link}/order?filter[object_id]=${objectId}&expand=user, bookings, object&seek=${seekType}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        setRequestCart(result.data);
                    } else {
                        if (result.status === 401) {
                            localStorage.removeItem("token");
                            window.location.replace("/login");
                        }
                    }
                })
                .catch((error) => console.log(error));
        }
    }, [objectId, ctx.rerender, activeTab]);

    return (
        <>
            <div className="request_flex_title_withArrow">
                {mobileVisibleCart && (
                    <div className="arrow_back" onClick={() => setMobileVisibleCart(false)}>
                        <ArrowBack />
                    </div>
                )}
                <h2 className="h2_cabinet_main">Заявки</h2>
            </div>
            <div className="cabinet_ads request_main">
                <div className="cabinet_ads__left cabinet_ads__left_request">
                    <div className="request_block">
                        <div className="advers___title">Категории заявок:</div>
                        <RequestType typeCategory={typeCategory} resetObjects={resetObjects} typeId={typeId} />
                    </div>
                    <div className="request__flex">
                        {isLoading ? (
                            <>
                                <RequestItem
                                    object={object}
                                    getObjectId={getObjectId}
                                    objectId={objectId}
                                    page={page}
                                    pageCount={pageCount}
                                    setPage={setPage}
                                    setMobileVisibleCart={setMobileVisibleCart}
                                />
                                {objectId !== 0 && (
                                    <div
                                        className={`cabinet_ads__date ${
                                            mobileVisibleCart ? "cabinet_ads_date_mobile" : ""
                                        }`}>
                                        <RequestsList
                                            counterNewResponse={counterNewResponse}
                                            setCounterNewResponse={setCounterNewResponse}
                                            requestCart={requestCart}
                                            mobileVisibleCart={mobileVisibleCart}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                        />
                                    </div>
                                )}
                            </>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Request;
