import styles from '../subscribe.module.sass'
import DeleteDesktopSvg from "../../advertisement/desktopPages/svg/DeleteDesktopSvg";
import {link} from "../../../store/host-store";
import {token} from "../../../App";
import {NavLink} from "react-router-dom";
import uuid from "react-uuid";


const SubscribeBottom = ({subscribeArray, setReload}) => {

    const deleteSubscription = (id) => {
        fetch(`${link}/subscription/${id}`, {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: token,
            },
        }).then(() => setReload(prev => !prev))
    }

    const editSubscription = (id) => {
        console.log(id)
    }

  return(
      <div className={styles.bottom_flex}>
          {subscribeArray.map((item) => {
        return <div className={styles.bottom_block} key={uuid()}>
            <div className={styles.bottom_block_left}>
                <div className={styles.bottom_city}>{item.city.name}</div>
                <div className={styles.bottom_choose_category}>Выбранная категория:</div>

                <div className={styles.bottom_chosen_category}>
                    {item.children.length > 0 ?
                            item.children.map(el => (
                                <div key={uuid()}>{el.category?.title}</div>
                            ))
                        :
                        <div key={uuid()}>{item.subscription?.category.title}</div>}
                </div>
            </div>
            <div className={styles.bottom_block_right}>
                {item?.subscription.category.type !== 2 || item?.subscription.category.type === 2  && item?.subscription.category.equipment === 1 ?
                    <NavLink type='button' className={styles.bottom_btn} state={{parent: item.subscription.category_id}} onClick={() => editSubscription(item.subscription.id)} to={`edit/${item.subscription.id}`}>
                        Редактировать</NavLink>
                    :
                    <NavLink type='button'
                             style={{background: "rgb(211, 33, 33)"}}
                             className={styles.bottom_btn} onClick={() => deleteSubscription(item.subscription.id)} to={`edit/${item.subscription.id}`}>
                        Удалить</NavLink>
                }
                <div className={styles.bottom_block_right_sub}>

                    <span>Другие действия:</span>
                    <div className={styles.bottom_delete} onClick={() => deleteSubscription(item.subscription.id)}>
                        <span>Удалить</span>
                        <DeleteDesktopSvg />
                    </div>
                </div>
            </div>
        </div>
          })}
      </div>
  )
}

export default SubscribeBottom;