import { useDispatch, useSelector } from "react-redux";

import AdsBottom from "../../advertisement/desktopPages/AdsBottom";
import object from "../../../assets/images/stubs/object-mob.png";
import { promoAction } from "../../../store/redux";
import { url } from "../../../store/host-store";
import s from "../style.module.sass";

const Object = ({ item }) => {
    const checkedArr = useSelector((state) => state.promotion);
    const dispatch = useDispatch();

    const onChangeCheckbox = (id) => {
        dispatch(promoAction.changeCheckboxes(id));
    };

    return (
        <div className={"cabinet_ads__item"}>
            <div className="adver_wrap__flex">
                <div className="img imgAdvers">
                    {item.image === null ? (
                        <img src={object} alt="object" />
                    ) : (
                        <img src={url + item.image.filename} alt="object" />
                    )}
                    <label className="container_checkbox">
                        <input
                            datatype={item.id}
                            type="checkbox"
                            checked={checkedArr.choosingCheckboxes.find((el) => el === item.id) || false}
                            onChange={() => {
                                onChangeCheckbox(item.id);
                            }}
                            className="advers__settings advers__settings_checkbox"
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="ads__descr_preview">
                    <h3 className="title">{item.name}</h3>
                    <div className="advers__medium__flex">
                        <p className="advers__about">{item.about.replaceAll(`<br />`, '').slice(0, 100)}</p>
                        {item.seek === 0 && (
                            <div className="advers_prices">
                                <div className="advs_price">
                                    <h5>{item.price_1} ₽</h5>
                                    <span>{item.price_1_name}</span>
                                </div>
                                <div className="advs_price price_sm">
                                    <h5>{item.price_2} ₽</h5>
                                    <span>{item.price_2_name}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <AdsBottom status={item.status} seek={item.seek} item={item} />
                </div>
                <div className={`${s.object_choose_wrapper} advers__box___right`}>
                    <div className={`${s.object_choose_button}`} onClick={() => onChangeCheckbox(item.id)}>
                        Выбрать
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Object;
