import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import docsImg from "./images/accDocs.svg";
import {AccountAgreementPin} from "./AccountAgreementPin";

export const AccountAgreementModal = () => {
    const ctx = useContext(ModalsContext);
  return(
      <div className="modal modal_account_agreement">
          <div className="closeModal" onClick={() => ctx.closeModal()}>
              <ModalCloseSvg />
          </div>
          <div className="title_account_confirmed">Заключение договора</div>
          <div className="confirmed_modal_text_top">Остался последний шаг.</div>
          <img src={docsImg} alt=""/>
            <AccountAgreementPin />
            <div className="agreement_text_modal">
                <span>Договор заключается единожды.</span> <br/>
                В дальнейшем при выводе средств с баланса, данная процедура не потребуется
            </div>
      </div>
  )
}