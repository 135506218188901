import { Route, Routes } from "react-router-dom";
import { useContext, useState } from "react";
import { useEffect } from "react";

import AuthContext from "../../store/auth-context";
import getHost from "../../store/host-store";
import Create from "./create/offer/Create";
import Edit from "./edit/offer/Edit";
import { token } from "../../App";
import MainPage from "./MainPage";
import CreateSearch from "./create/search/CreateSearch";
import EditSearch from "./edit/search/EditSearch";
import NotFound from "../../components/404/NotFound";

const AdvertisementMain = () => {
    const [type, setType] = useState(0);
    const [adId, setAdId] = useState(0);
    const [category, setCategory] = useState([]);
    const [activeToggle, setActiveToggle] = useState(false);

    const ctx = useContext(AuthContext);

    useEffect(() => {
        fetch(
            getHost({
                controller: "category",
                action: "account-list",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setCategory(result.data);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [ctx.rerender]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Routes>
                    <Route
                        path="/create-offer"
                        element={
                            ctx.userData.mail_confirmed ? (
                                <Create category={category} type={type} onTypeChange={setType}/>
                            ) : (
                                <NotFound/>
                            )
                        }></Route>
                    <Route
                        path="/edit-offer/:id"
                        element={
                            ctx.userData.mail_confirmed ? (
                                <Edit id={adId} type={type} setActiveToggle={setActiveToggle}/>
                            ) : (
                                <NotFound/>
                            )
                        }></Route>
                    <Route
                        path="/create-search"
                        element={
                            ctx.userData.mail_confirmed ? (
                                <CreateSearch category={category} type={type} onTypeChange={setType}/>
                            ) : (
                                <NotFound/>
                            )
                        }></Route>
                    <Route
                        path="/edit-search/:id"
                        element={
                            ctx.userData.mail_confirmed ? (
                                <EditSearch category={category} type={type} onTypeChange={setType}/>
                            ) : (
                                <NotFound/>
                            )
                        }></Route>
            <Route
                path="/"
                element={
                    <MainPage
                        setActiveToggle={setActiveToggle}
                        category={category}
                        type={type}
                        setType={setType}
                        adId={adId}
                        setAdId={setAdId}
                        activeToggle={activeToggle}
                    />
                }></Route>
        </Routes>
    );
};

export default AdvertisementMain;
