import style from "./style.module.sass";
import moment from "moment";
import { SubscribeCard } from "./SubscribeCard";
import {link} from "../../store/host-store";
import {token} from "../../App";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {adsSearchAction, notificationAction} from "../../store/redux";
import {useContext} from "react";
import ModalsContext from "../../store/modals-context";
import ModalCloseSvg from "../../UI/Modal/ModalSvg/ModalCloseSvg";

const NotificationsList = ({ data, setRerender }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const modals = useContext(ModalsContext);

    console.log(data)

    const deleteNotification = (id) => {
        fetch(`${link}/notification/drop-new?id=${id}`,
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        ).then(res => res.json())
            .then(() => setRerender(prev => !prev))
    }

    const deleteAllNotifications = () => {
        fetch(`${link}/notification/drop-all-new`,
            {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        ).then(res => res.json())
            .then(() => window.location.reload())
    }

    const clickNotification = (data, typeNotification) => {
        // 8 я предлагаю
        // 7 я ищу
        fetch(`${link}/order/${data}?expand=object`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    console.log(result.data)
                    dispatch(notificationAction.getNotificationData({
                        id: result.data.object.id,
                        seek: result.data.object.seek,
                        type: result.data.object.type,
                        model_id: result.data.id
                    }))
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
                return  result.data.object.id
            }).then((result) => {
            if(typeNotification === 8 || typeNotification === 2){
                navigate('/lc/responses')
            }else{
                navigate('/lc/request#' + result)
            }
        })
            .catch((error) => console.log(error));
    }

    return (
        <>
            <div className={style.notifications_clear}>
                <button onClick={deleteAllNotifications}
                    type='button' className={style.notification_btn}>Очистить</button>
            </div>
            {data.length > 0 ? (
                // eslint-disable-next-line
                data.map((el) => {
                    if (el.type === 0) {
                        return (
                            <div key={el.id}
                                 className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Внимание!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el.text}. Частой причиной отклонения является указание контактных данных в тексте или фотоматериалах.
                                    Указать данные возможно только в разделе <a style={{color: "inherit", textDecoration: "underline"}} href="/marketing">«Реклама».</a>
                                </p>
                            </div>
                        );
                    } else if (el.type === 1) {
                        return (
                            <div style={{cursor: "pointer"}}
                                 key={el.id} className={style.item}
                                 onClick={() => {
                                     clickNotification(el.model_id)
                                 }}
                            >
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Заказчик оставил Вам заявку &#128188;</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>Перейдите в заявки для более подробного изучения заказа</p>
                            </div>
                        );
                    } else if (el.type === 2) {
                        return (
                            <div key={el.id} className={style.item}
                            onClick={() => {
                                clickNotification(el.model_id, el?.type)
                            }}
                            >
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Исполнитель откликнулся на заявку &#128119;</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Вы можете связаться с исполнителем для обсуждения заказа любым удобным Вам способом
                                </p>
                            </div>
                        );
                    } else if (el.type === 3) {
                        return (
                            <div key={el.id} className={style.item}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Новое сообщение</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>Вам пришло новое сообщение от техподдержки</p>
                            </div>
                        );
                    } else if (el.type === 4) {
                        return (
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Поздравляем!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Ваше объявление прошло модерацию и отображается в каталоге сервиса CityWork!
                                </p>
                            </div>
                        );
                    } else if (el.type === 5) {
                        return (
                            <div key={el.id} className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>{el.text}</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Ваша заявка не соответствует правилам сервиса CityWork
                                </p>
                            </div>
                        );
                    }
                    else if (el.type === 11) {
                        return (
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Поздравляем!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el.text}
                                </p>
                            </div>
                        );
                    }
                    else if (el.type === 12) {
                        return (
                            <div key={el.id} className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>{el.text}</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Ваша заявка не соответствует правилам сервиса CityWork
                                </p>
                            </div>
                        );
                    }
                    else if (el.type === 13){
                        return (
                            <div key={el.id} className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>{el.text}</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Продолжайте поиск объявлений в каталоге сервиса CityWork!
                                </p>
                            </div>
                        );
                    }else if(el.type === 14){
                        return (
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Поздравляем!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Предварительная проверка пройдена. Вам необходимо
                                    <span style={{color: "#546edb", cursor: "pointer"}}
                                          onClick={() => {
                                              modals.paymentModal();
                                              dispatch(adsSearchAction.adsModerationPay(true))
                                          }}> пополнить кошелёк с помощью расчётного счёта</span> для подтверждения юридической информации и
                                    дальнейшего запуска рекламы.
                                </p>
                            </div>
                        );
                    }else if(el.type === 15){
                        return (
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Поздравляем!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Теперь Вы можете размещать свою рекламу на сервисе CityWork.
                                </p>
                            </div>
                        );
                    } else if (el.type === 6) {
                        return (
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Объявление активировано</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Ваше объявление прошло модерацию и отображается в каталоге сервиса CityWork!
                                </p>
                            </div>
                        );
                    } else if (el.type === 7) {
                        return (
                            <div style={{cursor: "pointer"}} key={el.id} className={`${style.item} ${style.approuve}`}
                                 onClick={() => {
                                     clickNotification(el?.model_id, el?.type)
                                     dispatch(adsSearchAction.toggleStatus(1))
                                 }}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Поступила заявка по Вашему объявлению с поиском исполнителя</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Перейдите в заявки и переключатель "Я ищу" для более подробного изучения
                                </p>
                            </div>
                        );
                    } else if (el.type === 8) {
                        return (
                            <div key={el.id} className={`${style.item} ${style.approuve}`}
                                onClick={() => {
                                    clickNotification(el?.model_id, el?.type)
                                    dispatch(adsSearchAction.toggleStatus(0))
                                }}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Заказчик откликнулся на Вашу заявку</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Перейдите в отклики и переключатель "Я ищу" для более подробного изучения
                                </p>
                            </div>
                        );
                    } else if (el.type === 9) {
                        return (
                            <div key={el.id} className={style.item}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Истекает срок действия объявления</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    Осталось 24 часа. Если не нашли исполнителя, продлите срок выполнения объявления
                                </p>
                            </div>
                        );
                    } else if (el.type === 10) {
                        return <SubscribeCard el={el} key={el.id} onClick={() => deleteNotification(el.id)}/>;
                    }else if(el.type === 18){
                        return(
                            <div key={el.id} className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Ваше рекламное объявление не прошло проверку</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el?.text}
                                </p>
                            </div>
                        )
                    }else if(el.type === 17){
                        return(
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Ваше рекламное объявление прошло проверку</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el?.text}
                                </p>
                            </div>
                        )
                    }else if(el.type === 16){
                        return(
                            <div key={el.id} className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Рекламный аккаунт не прошел проверку!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el?.text}
                                </p>
                            </div>
                        )
                    } else if (el.type === 19) {
                        return(
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Добро пожаловать на сервис CityWork!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el?.text}
                                </p>
                            </div>
                        )
                    }else if (el.type === 20) {
                        return(
                            <div key={el.id} className={`${style.item} ${style.approuve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Заявка на вывод!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el?.text}
                                </p>
                            </div>
                        )
                    }else if (el.type === 21) {
                        return(
                            <div key={el.id} className={`${style.item} ${style.disapprouve}`}>
                                <div className={style.close} onClick={() => deleteNotification(el.id)}>
                                    <ModalCloseSvg fill={"red"}/>
                                </div>
                                <div className={style.title}>
                                    <h5>Заявка на вывод!</h5>
                                    <p className={style.date}>{moment(el.created).format("DD.MM.YYYY")}</p>
                                </div>
                                <p className={style.text}>
                                    {el?.text}
                                </p>
                            </div>
                        )
                    }
                })
            ) : (
                <div>Нет новых уведомлений</div>
            )}
        </>
    );
};

export default NotificationsList;
