export const TermsIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.00016 0C0.900722 0 -1.88992e-07 0.768688 0 1.71106V10.2856C0 11.228 0.900722 12 2.00016 12H9.99886C11.0983 12 12 11.228 12 10.2856V3.4263C12 2.90115 11.7574 2.39882 11.3242 2.02748L9.6336 0.578444C9.20036 0.207107 8.61138 0 7.9987 0H2.00016ZM3.01196 5.14071H8.98804C9.0537 5.14092 9.11867 5.15223 9.17924 5.17397C9.23981 5.19571 9.29479 5.22747 9.34105 5.26742C9.3873 5.30738 9.42392 5.35475 9.44881 5.40683C9.4737 5.45892 9.48638 5.5147 9.48612 5.57098C9.48587 5.68422 9.43334 5.79277 9.34001 5.87292C9.24669 5.95307 9.12015 5.9983 8.98804 5.99874H3.01196C2.87968 5.99852 2.75289 5.95338 2.65936 5.87321C2.56582 5.79304 2.51316 5.68436 2.5129 5.57098C2.51264 5.51462 2.52536 5.45878 2.55031 5.40664C2.57526 5.3545 2.61197 5.30709 2.65832 5.26713C2.70468 5.22716 2.75977 5.19542 2.82046 5.17373C2.88114 5.15204 2.94622 5.14081 3.01196 5.14071ZM3.01196 7.71315H6.99764C7.06339 7.71282 7.12857 7.72361 7.18945 7.74489C7.25032 7.76618 7.30571 7.79755 7.35243 7.8372C7.39914 7.87686 7.43628 7.92402 7.46171 7.97599C7.48713 8.02796 7.50035 8.08372 7.50061 8.14008C7.50087 8.19671 7.48803 8.25283 7.46283 8.30519C7.43763 8.35755 7.40058 8.40511 7.35381 8.44512C7.30704 8.48513 7.25148 8.5168 7.19035 8.53829C7.12922 8.55979 7.06372 8.57068 6.99764 8.57035H3.01196C2.94622 8.57024 2.88114 8.55902 2.82045 8.53733C2.75977 8.51563 2.70468 8.4839 2.65832 8.44393C2.61197 8.40396 2.57526 8.35655 2.55031 8.30442C2.52535 8.25228 2.51264 8.19643 2.5129 8.14008C2.51342 8.02684 2.56619 7.91838 2.6597 7.83839C2.75321 7.75839 2.87985 7.71337 3.01196 7.71315Z"
                fill="white"
            />
        </svg>
    );
};
