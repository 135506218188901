export const PromoIcon = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1702 0.635033C13.2421 0.494936 14.1255 1.37771 13.9853 2.44891C13.7804 4.01469 13.2177 6.67463 11.6266 8.38902C11.1203 8.93449 10.5089 9.43847 9.88001 9.88623L10.0638 11.1716C10.1155 11.5335 9.99372 11.8986 9.73501 12.1572L7.62152 14.2692C6.94423 14.946 5.7844 14.5597 5.64894 13.6121L5.36714 11.6409L5.36798 11.6408C5.18802 11.5538 5.02115 11.436 4.87577 11.2907L3.32229 9.73829C3.1769 9.59299 3.05908 9.42626 2.97197 9.24638L2.97185 9.24731L0.999266 8.96568C0.0510563 8.83035 -0.335554 7.67125 0.341738 6.99442L2.45522 4.88242C2.71392 4.6239 3.07932 4.50218 3.44151 4.55389L4.72772 4.7375C5.17582 4.10908 5.68016 3.498 6.22603 2.99209C7.94153 1.40208 10.6033 0.839818 12.1702 0.635033ZM1.98557 9.8105C2.18011 10.0049 2.18011 10.3201 1.98557 10.5145L1.04625 11.4531C0.851707 11.6476 0.53629 11.6476 0.341751 11.4531C0.147213 11.2587 0.147213 10.9436 0.341751 10.7492L1.28107 9.8105C1.47562 9.61609 1.79103 9.61609 1.98557 9.8105ZM3.39456 11.2185C3.5891 11.4129 3.5891 11.7281 3.39456 11.9225L1.51591 13.7999C1.32136 13.9943 1.00595 13.9943 0.811416 13.7999C0.616871 13.6055 0.616871 13.2903 0.811416 13.0958L2.69006 11.2185C2.88461 11.0241 3.20002 11.0241 3.39456 11.2185ZM4.80354 12.6265C4.99808 12.8209 4.99808 13.1361 4.80354 13.3305L3.86422 14.2692C3.66968 14.4636 3.35426 14.4636 3.15973 14.2692C2.96519 14.0748 2.96519 13.7596 3.15973 13.5652L4.09905 12.6265C4.29359 12.4321 4.609 12.4321 4.80354 12.6265ZM8.20841 6.40767C7.6896 5.88928 7.6896 5.04876 8.20841 4.53034C8.72715 4.01192 9.56823 4.01192 10.087 4.53034C10.6058 5.04876 10.6058 5.88928 10.087 6.40767C9.56823 6.92612 8.72715 6.92612 8.20841 6.40767Z"
                fill="#fff"
            />
        </svg>
    );
};
