import { useContext, useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import fix from "../../../assets/images/promotion/rate-fix.webp";
import ArrowBack from "../../request/svg/ArrowBack";
import { link } from "../../../store/host-store";
import style from "../style.module.sass";
import { token } from "../../../App";
import s from "./style.module.sass";
import RateCard from "./RateCard";
import ModalsContext from "../../../store/modals-context";
import Loader from "../../../components/loader/Loader";

const RatePage = () => {
    const modal = useContext(ModalsContext);
    const [fixed, setFixed] = useState([]);
    const [shows, setShows] = useState([]);
    const [arrayId, setArrayId] = useState([]);
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);
    let [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        window.scrollTo(0, 0);
        setSearchParams(localStorage.getItem('promo') ? localStorage.getItem('promo') : '');
        fetch(`${link}/tariff-plan?filter[type]=0`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    setFixed(result.data);
                }
            })
            .catch((e) => console.log(e));

        fetch(`${link}/tariff-plan?filter[type]=1`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    setShows(result.data);
                }else{
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/");
                    }
                }
            }).then(() => setLoading(false))
            .catch((e) => console.log(e));
    }, []);

    useEffect(() => {
        setArrayId(searchParams.get("extendItem")?.replace(/, +/g, ",").split(",").map(Number))
    }, [searchParams])

    useEffect(() => {
        if(arrayId?.length && localStorage.getItem('promo')?.includes('extend')){
            setLoading(true);
            const id = localStorage.getItem('promo').slice(11);
            fetch(`${link}/object/${id}`, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res.data);
                    setProduct(res.data);
                }).then(() => setLoading(false));
        }
    }, [arrayId])

    const onBuy = (id) => {
        fetch(`${link}/tariff-plan/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result.data);
                const id = result.data.id;
                const type = result.data.type;
                const price = result.data.price;
                let count;
                if (result.data.type === 0) {
                    count = result.data.duration;
                } else {
                    count = result.data.show_quantity;
                }
                if(searchParams.size !== 0){
                    modal.paymentRequest(id, type, price, count);
                }else{
                    alert('Нет выбранных объявлений')
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="cabinet__redesign_container">
            <div className="flex">
                <NavLink to="/lc/promotion/choose" className="margR6">
                    <ArrowBack />
                </NavLink>
                <h3 className="cabinet__redesign_title">Выберите тариф</h3>
            </div>
            <div className={style.container}>
                {!loading ?
                <div className={s.cards_list}>
                    {/*{product.activeShow !== null &&*/}
                    {/*<div className={s.rate_wrap}>*/}
                    {/*    <RateCard*/}
                    {/*        descr="Ваше объявление будет находится вверху каталога"*/}
                    {/*        src={eye}*/}
                    {/*        onBuy={onBuy}*/}
                    {/*        data={shows}>*/}
                    {/*        Показы <span>объявления</span>*/}
                    {/*    </RateCard>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {product.activeFixation !== null &&
                    <div className={s.rate_wrap}>
                        <RateCard
                            descr="Ваше объявление будет показываться только релевантной аудитории вашего объявления"
                            src={fix}
                            fix
                            onBuy={onBuy}
                            data={fixed}>
                            Закрепление <span>объявления</span>
                        </RateCard>
                    </div>
                    }
                </div>
                    : <Loader />
                }
            </div>
        </div>
    );
};

export default RatePage;
