import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {link} from "../../../host-store";
import {token} from "../../../../App";

export const mtsQueries = createApi({
    reducerPath: "mtsQueries",
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["mtsGetLink"],
    endpoints: builder => ({
        isMtsConfirmed: builder.query({
            query: () => ({
                url: `/account/check-identification`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ['mtsGetLink']
        }),
        mtsGetLink: builder.query({
            query: () => ({
                url: `/account/identification-url`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            invalidatesTags: ['mtsGetLink']
        }),
        getDocument: builder.query({
            query: () => ({
                url: `/affiliate-contract/get-contract-blank`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            invalidatesTags: ['mtsGetLink']
        }),
    })
})

export const {useIsMtsConfirmedQuery, useLazyMtsGetLinkQuery, useGetDocumentQuery} = mtsQueries

