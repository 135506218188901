import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { BucketIcon } from "../../../components/icons/BucketIcon";
import { CloseIcon } from "../../../components/icons/CloseIcon";
import { EditIcon } from "../../../components/icons/EditIcon";
import FormModal from "../../../UI/Modal/FormModal/FormModal";
import { AddIcon } from "../../../components/icons/AddIcon";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import getHost from "../../../store/host-store";
import { token } from "../../../App";
import s from "./style.module.sass";
import { Helmet } from "react-helmet";

const Faq = () => {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [sections, setSections] = useState([]);
    const [tabs, setTabs] = useState([]);

    const ctx = useContext(AuthContext);

    useEffect(() => {
        fetch(
            getHost({
                controller: "faq-type",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (result.success) {
                    setTabs(result.data);
                    setActiveTab(result.data[0].id);
                }
            })
            .catch((e) => console.log(e));
    }, [ctx.rerender]);

    useEffect(() => {
        if (activeTab !== null) {
            fetch(
                getHost({
                    controller: "faq-section",
                    filter: {
                        type_id: activeTab,
                    },
                    expand: "elements",
                }),
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        setSections(result.data);
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [activeTab]);

    const [newSectionModal, setNewSectionModal] = useState(false);
    const [newSectionInput, setNewSectionInput] = useState("");
    const createSection = (e) => {
        e.preventDefault();

        const data = {
            type_id: activeTab,
            active: 1,
            title: newSectionInput,
        };

        fetch(
            getHost({
                controller: "faq-section",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setNewSectionModal(false);
                    setNewSectionInput("");
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    const [newTabModal, setNewTabModal] = useState(false);
    const [newTabInput, setNewTabInput] = useState("");
    const createTab = (e) => {
        e.preventDefault();
        const data = {
            active: 1,
            title: newTabInput,
        };

        fetch(
            getHost({
                controller: "faq-type",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setNewTabModal(false);
                    setNewTabInput("");
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    const [deleteTabModal, setDeleteTabModal] = useState(false);
    const [tabInfo, setTabInfo] = useState({ id: null, title: null });
    const onDeleteTab = (e) => {
        e.preventDefault();

        fetch(
            getHost({
                controller: "faq-type",
                action: tabInfo.id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
                method: "DELETE",
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setDeleteTabModal(false);
                    setTabInfo({ ...tabInfo, id: null, title: null });
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    const [deleteSectionModal, setDeleteSectionModal] = useState(false);
    const [sectionInfo, setSectionInfo] = useState({ id: null, title: null });
    const onDeleteSection = (e) => {
        e.preventDefault();
        fetch(
            getHost({
                controller: "faq-section",
                action: sectionInfo.id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
                method: "DELETE",
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setDeleteSectionModal(false);
                    setSectionInfo({ ...sectionInfo, id: null, title: null });
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="admin__layout">
            {newSectionModal && (
                <FormModal
                    title="Добавить новую секцию"
                    button="Добавить"
                    fn={createSection}
                    isOpenModal={setNewSectionModal}
                >
                    {<input value={newSectionInput} onChange={(e) => setNewSectionInput(e.target.value)} />}
                </FormModal>
            )}
            {newTabModal && (
                <FormModal
                    title="Добавить новый раздел"
                    button="Добавить"
                    fn={createTab}
                    isOpenModal={setNewTabModal}
                >
                    {<input value={newTabInput} onChange={(e) => setNewTabInput(e.target.value)} />}
                </FormModal>
            )}
            {deleteTabModal && (
                <FormModal
                    title="Вы уверены?"
                    button="Удалить"
                    text={`Удалить категорию ${tabInfo.title}?`}
                    fn={onDeleteTab}
                    isOpenModal={setDeleteTabModal}
                />
            )}
            {deleteSectionModal && (
                <FormModal
                    title="Вы уверены?"
                    button="Удалить"
                    text={`Удалить секцию ${sectionInfo.title}?`}
                    fn={onDeleteSection}
                    isOpenModal={setDeleteSectionModal}
                />
            )}
            <Helmet>
                <title>Ответы на часто задаваемые вопросы | CityWork</title>
                <meta
                    name="description"
                    content="Ответы на часто задаваемые вопросы. «CityWork» — онлайн-агрегатор услуг в строительной отрасли без посредников.
                 Поиск исполнителей и заказчиков строительных работ в Москве."
                />
            </Helmet>
            {loading ? (
                <Loader />
            ) : (
                <div className={s.faq}>
                    <div className={s.faq__top_wrapper}>
                        <h3>Часто задаваемые вопросы</h3>
                        <div className={s.faq__top_wrapper_right}>
                            <div className={s.new_section} onClick={() => setNewSectionModal(true)}>
                                <AddIcon />
                                <p>Новая секция</p>
                            </div>
                            <NavLink className="admin__btn-blue" to="create" state={{ tabId: activeTab }}>
                                Новый вопрос
                            </NavLink>
                        </div>
                    </div>

                    <div className={s.faq__bottom_wrapper}>
                        <div className={`${s.tab_add} ${s.tab}`} onClick={() => setNewTabModal(true)}>
                            <AddIcon />
                            <p>Добавить</p>
                        </div>
                        {tabs.map((el) => {
                            return (
                                <div
                                    key={el.id}
                                    className={`${s.tab} ${activeTab === el.id ? s.active : ""}`}
                                    onClick={() => setActiveTab(el.id)}>
                                    <p>{el.title}</p>
                                    {activeTab === el.id && (
                                        <button
                                            title="удалить"
                                            type="button"
                                            onClick={() => {
                                                setTabInfo({ ...tabInfo, id: el.id, title: el.title });
                                                setDeleteTabModal(true);
                                            }}>
                                            <CloseIcon />
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    {sections.map((el) => {
                        return (
                            <div key={el.id} className={s.section}>
                                <div className={s.section_title}>
                                    <p>{el.title}</p>
                                    <div
                                        onClick={() => {
                                            setSectionInfo({ ...sectionInfo, id: el.id, title: el.title });
                                            setDeleteSectionModal(true);
                                        }}>
                                        <BucketIcon />
                                    </div>
                                </div>
                                <div className={s.section_titles}>
                                    <p>Вопрос</p>
                                    <p>Ответ</p>
                                </div>
                                {el.elements.map((el) => {
                                    return (
                                        <div key={el.id} className={s.section_content}>
                                            <div dangerouslySetInnerHTML={createMarkup(el.question)}></div>
                                            <div dangerouslySetInnerHTML={createMarkup(el.answer)}></div>
                                            <NavLink
                                                to={`edit/${el.id}`}
                                                state={{ id: el.id, section_id: el.section_id }}>
                                                <EditIcon />
                                            </NavLink>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Faq;

function createMarkup(text) {
    return { __html: text };
}
