import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { BucketIcon } from "../../../components/icons/BucketIcon";
import { EditIcon } from "../../../components/icons/EditIcon";
import FormModal from "../../../UI/Modal/FormModal/FormModal";
import object from "../../../assets/images/stubs/object.png";
import { AddIcon } from "../../../components/icons/AddIcon";
import getHost, { url } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import { token } from "../../../App";
import s from "./style.module.sass";

const Partners = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const ctx = useContext(AuthContext);

    useEffect(() => {
        fetch(
            getHost({
                controller: "partner-section",
                expand: "partners",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                setLoading(false);
                if (result.success) {
                    setData(result.data);
                }
            })
            .catch((e) => console.log(e));
    }, [ctx.rerender]);

    const [newSectionModal, setNewSectionModal] = useState(false);
    const [newSectionInput, setNewSectionInput] = useState("");
    const createSection = (e) => {
        e.preventDefault();

        const data = {
            title: newSectionInput,
        };

        fetch(
            getHost({
                controller: "partner-section",
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setNewSectionModal(false);
                    setNewSectionInput("");
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    const [deleteSectionModal, setDeleteSectionModal] = useState(false);
    const [sectionInfo, setSectionInfo] = useState({ id: null, title: null });
    const onDeleteSection = (e) => {
        e.preventDefault();

        fetch(
            getHost({
                controller: "partner-section",
                action: sectionInfo.id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
                method: "DELETE",
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setDeleteSectionModal(false);
                    setSectionInfo({ ...sectionInfo, id: null, title: null });
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="admin__layout">
            {newSectionModal && (
                <FormModal
                    title="Добавить новую секцию"
                    button="Добавить"
                    fn={createSection}
                    isOpenModal={setNewSectionModal}
                >
                    {<input value={newSectionInput} onChange={(e) => setNewSectionInput(e.target.value)} />}
                </FormModal>
            )}
            {deleteSectionModal && (
                <FormModal
                    title="Вы уверены?"
                    button="Удалить"
                    text={`Удалить секцию ${sectionInfo.title}?`}
                    fn={onDeleteSection}
                    isOpenModal={setDeleteSectionModal}
                />
            )}
            {loading ? (
                <Loader />
            ) : (
                <div className={s.partners}>
                    <div className={s.partners__top_wrapper}>
                        <h3>Наши партнёры</h3>
                        <div className={s.partners__top_wrapper_right}>
                            <div className={s.new_section} onClick={() => setNewSectionModal(true)}>
                                Добавить раздел
                                <AddIcon />
                            </div>
                            <NavLink className="admin__btn-blue" to="create">
                                Добавить партнёра
                            </NavLink>
                        </div>
                    </div>

                    {data.length > 0 ? (
                        data.map((el) => {
                            return (
                                <div key={el.id} className={s.section}>
                                    <div className={s.section_title}>
                                        <p>{el.title}</p>
                                        <div
                                            onClick={() => {
                                                setSectionInfo({ ...sectionInfo, id: el.id, title: el.title });
                                                setDeleteSectionModal(true);
                                            }}>
                                            <BucketIcon />
                                        </div>
                                    </div>
                                    {el.partners.length > 0 ? (
                                        <>
                                            <div className={s.section_titles}>
                                                <p>Фото</p>
                                                <p>Название партнёра</p>
                                            </div>
                                            {el.partners.map((el) => {
                                                return (
                                                    <div key={el.id} className={s.section_content}>
                                                        {el.image === null ? (
                                                            <img src={object} alt="" />
                                                        ) : el.webpFilename ? (
                                                            <img src={url + el.webpFilename} alt="" />
                                                        ) : (
                                                            <img src={url + el.image} alt="" />
                                                        )}
                                                        <p>{el.title}</p>
                                                        <NavLink to={`edit/${el.id}`}>
                                                            <EditIcon />
                                                        </NavLink>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <p>В данной категории нет партнеров</p>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <p>Пусто</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Partners;
