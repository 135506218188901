import { useContext, useEffect, useState } from "react";

import CustomScrollbar from "../../Scrollbar/CustomScrollbar";
import ModalsContext from "../../../store/modals-context";
import DeviceContext from "../../../store/device-context";
import AuthContext from "../../../store/auth-context";
import ParentsCategories from "./ParentsCategories";
import Subcategories from "./Subcategories";
import Childrens from "./Childrens";
import s from "./style.module.sass";

const MainCategories = () => {
    const [categories, setCategorires] = useState([]);
    const [sublist, setSublist] = useState([]);
    const [currentCategory, setCurrentCategory] = useState({});
    const [currentSubcategory, setCurrentSubcategory] = useState({});
    const [childrens, setChildrens] = useState([]);
    const [type, setType] = useState();
    const device = useContext(DeviceContext);
    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);

    const [page, setPage] = useState("main");

    useEffect(() => {
        setCategorires(ctx.categories);
    }, [ctx.categories]);

    const toggleParent = (category) => {
        if (device.isMobile) {
            setPage("list");
        }
        setType(category.type);
        setCurrentCategory(category);
        setCurrentSubcategory({});
        setSublist(categories.filter((item) => item.parent_id === category.id));
        setChildrens([]);
    };

    const toggleSub = (category) => {
        if (device.isMobile) {
            setPage("childrens");
        }
        setCurrentSubcategory(category);
        setChildrens(categories.filter((item) => item.parent_id === category.id));
    };

    return (
        <div className={s.categories}>
            {window.innerWidth <= 1026 ? (
                <>
                    {page === "main" && (
                        <>
                            <button type="button" className={s.back_mob} onClick={modal.toggleCategories}>
                                Вернуться назад
                            </button>
                            <ParentsCategories
                                categories={categories}
                                toggleParent={toggleParent}
                                currentCategory={currentCategory}
                            />
                        </>
                    )}
                    {page === "list" && (
                        <>
                            <button type="button" className={s.back_mob} onClick={() => setPage("main")}>
                                Вернуться назад
                            </button>
                            <button type="button" className={s.btn_back} onClick={() => setPage("main")}>
                                Назад
                            </button>
                            <Subcategories
                                sublist={sublist}
                                currentCategory={currentCategory}
                                currentSubcategory={currentSubcategory}
                                type={type}
                                toggleSub={toggleSub}
                            />
                        </>
                    )}
                    {page === "childrens" && (
                        <>
                            <button type="button" className={s.back_mob} onClick={() => setPage("list")}>
                                Вернуться назад
                            </button>
                            <button type="button" className={s.btn_back} onClick={() => setPage("list")}>
                                Назад
                            </button>
                            <Childrens currentSubcategory={currentSubcategory} childrens={childrens} type={type} />
                        </>
                    )}
                </>
            ) : (
                <>
                    <ParentsCategories
                        categories={categories}
                        toggleParent={toggleParent}
                        currentCategory={currentCategory}
                    />
                    {sublist.length > 0 && (
                        <CustomScrollbar>
                            <Subcategories
                                sublist={sublist}
                                currentCategory={currentCategory}
                                currentSubcategory={currentSubcategory}
                                type={type}
                                toggleSub={toggleSub}
                            />
                        </CustomScrollbar>
                    )}
                    {childrens.length > 0 && (
                        <CustomScrollbar>
                            <Childrens currentSubcategory={currentSubcategory} childrens={childrens} type={type} />
                        </CustomScrollbar>
                    )}
                </>
            )}
        </div>
    );
};

export default MainCategories;
