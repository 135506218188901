import "../Modal.sass";

const ModalWorkerSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="241" height="122" fill="none">
            <circle cx="29.53" cy="94.22" r="21.183" fill="#6FE1B8" transform="rotate(18.778 29.53 94.22)" />
            <path
                fill="url(#a)"
                d="M21.057 91.878c-.29.17-.494.44-.577.765-.083.324-.034.661.138.95l4.181 7.012a1.256 1.256 0 0 0 .786.58c.331.081.673.027.962-.15l14.204-8.75a1.246 1.246 0 0 0 .403-1.725l-1.332-2.13a1.267 1.267 0 0 0-1.733-.406l-10.932 6.733-2.212-3.708a1.268 1.268 0 0 0-1.725-.443l-2.163 1.272Z"
            />
            <circle cx="215.445" cy="35.445" r="21.183" fill="#546EDB" transform="rotate(-13.143 215.445 35.445)" />
            <path
                fill="url(#b)"
                d="M207.015 37.936a1.235 1.235 0 0 0-.085.955c.101.319.321.58.619.732l7.257 3.742a1.255 1.255 0 0 0 .973.076 1.26 1.26 0 0 0 .738-.636l7.43-14.936a1.246 1.246 0 0 0-.57-1.677l-2.257-1.104a1.268 1.268 0 0 0-1.687.571l-5.718 11.495-3.838-1.977a1.268 1.268 0 0 0-1.698.536l-1.164 2.223Z"
            />
            <g clipPath="url(#c)">
                <path
                    fill="#546EDB"
                    d="M50.43 36.191a24.3 24.3 0 0 0 .164-2.765c0-.49-.015-.981-.045-1.465l-4.694-1.48a19.581 19.581 0 0 0-1.661-5.472l3.114-3.726a24.645 24.645 0 0 0-2.462-3.486l-4.694 1.487a20.944 20.944 0 0 0-4.643-3.48l.267-4.8a25.929 25.929 0 0 0-4.108-1.409l-2.9 3.892a21.762 21.762 0 0 0-5.859-.152l-2.685-4.036a25.056 25.056 0 0 0-4.168 1.199l.008 4.808a20.887 20.887 0 0 0-4.828 3.234L6.63 16.808a24.941 24.941 0 0 0-2.655 3.357l2.907 3.884a19.766 19.766 0 0 0-1.958 5.378L.164 30.66C.051 31.571 0 32.495 0 33.426c0 .491.015.982.044 1.466l4.695 1.48c.29 1.927.853 3.761 1.661 5.472L3.285 45.57a24.65 24.65 0 0 0 2.463 3.487l4.694-1.487a20.943 20.943 0 0 0 4.643 3.48l-.267 4.8a26.257 26.257 0 0 0 4.094 1.416l2.9-3.892a21.76 21.76 0 0 0 5.859.152l2.684 4.036a25.063 25.063 0 0 0 4.168-1.199l-.008-4.808a20.887 20.887 0 0 0 4.828-3.234l4.606 1.733a24.942 24.942 0 0 0 2.655-3.357l-2.907-3.884a19.767 19.767 0 0 0 1.958-5.379l4.776-1.242Zm-25.133 6.895c-5.48 0-9.923-4.325-9.923-9.66s4.442-9.66 9.923-9.66c5.48 0 9.923 4.325 9.923 9.66-.007 5.335-4.442 9.66-9.923 9.66Z"
                />
                <path
                    fill="#fff"
                    d="M38.823 44.443a.37.37 0 0 1-.238-.086.35.35 0 0 1-.044-.506 16.072 16.072 0 0 0 3.864-10.424c0-1.82-.304-3.61-.913-5.314a.363.363 0 0 1 .23-.462.378.378 0 0 1 .475.224c.63 1.783.95 3.646.95 5.552a16.8 16.8 0 0 1-4.035 10.886.373.373 0 0 1-.29.13ZM37.395 21.644a.371.371 0 0 1-.253-.101 16.696 16.696 0 0 0-5.77-3.458.36.36 0 0 1-.222-.462.374.374 0 0 1 .475-.217 17.492 17.492 0 0 1 6.022 3.61.357.357 0 0 1 .014.513.36.36 0 0 1-.267.115ZM21.446 17.623a.362.362 0 0 1-.356-.274.359.359 0 0 1 .267-.44c1.395-.347 2.84-.52 4.294-.52.208 0 .371.158.371.36a.362.362 0 0 1-.37.361 17.12 17.12 0 0 0-4.117.498c-.03.008-.059.015-.089.015Z"
                />
                <path
                    fill="#546EDB"
                    d="M65.575 54.586c0-.188-.007-.376-.014-.556l2.432-1.906a12.474 12.474 0 0 0-.882-2.722l-3.145-.23a10.243 10.243 0 0 0-2.722-2.809l-.007-3.054a13.14 13.14 0 0 0-2.789-1.046l-2.15 2.252c-.371-.036-.75-.058-1.128-.058-.949 0-1.861.123-2.736.354l-2.463-1.899c-.927.39-1.802.888-2.603 1.473l.468 3.025a10.12 10.12 0 0 0-2.196 3.119l-3.063.693a12.31 12.31 0 0 0-.452 2.873l2.7 1.523a9.982 9.982 0 0 0 1.156 3.718l-1.35 2.758c.58.772 1.247 1.48 1.988 2.1l2.893-1.104a10.406 10.406 0 0 0 3.819 1.473l1.365 2.736c.163.007.326.007.49.007.815 0 1.616-.072 2.395-.216l.92-2.895a10.42 10.42 0 0 0 3.589-2.029l3.04.664a12.847 12.847 0 0 0 1.624-2.332l-1.765-2.52c.379-1.068.586-2.208.586-3.392ZM55.06 59.047c-2.418 0-4.383-1.913-4.383-4.266 0-2.354 1.965-4.267 4.383-4.267s4.383 1.913 4.383 4.267c0 2.353-1.965 4.266-4.383 4.266Z"
                />
            </g>
            <g clipPath="url(#d)">
                <path
                    fill="#8EA8DD"
                    d="M92.266 114.384c-1.887 1.901-4.812 1.901-6.699 0-1.886-1.902-1.886-4.85 0-6.751l8.02-8.082c1.886-1.902 4.81-1.902 6.697 0 1.887 1.902 1.887 4.849 0 6.751l-8.018 8.082ZM148.868 114.384c1.886 1.901 4.811 1.901 6.698 0 1.886-1.902 1.886-4.85 0-6.751l-8.019-8.082c-1.887-1.902-4.812-1.902-6.698 0-1.887 1.902-1.887 4.849 0 6.751l8.019 8.082Z"
                />
                <path
                    fill="#53CBA0"
                    d="M97.736 23.866c-1.415-2.187-2.17-4.564-2.17-6.941C95.661 7.51 106.51 0 119.717 0c13.302 0 24.151 7.511 24.151 16.83 0 2.282-.66 4.468-1.792 6.465l-3.585-2.092c.849-1.426 1.226-2.852 1.226-4.373 0-6.846-9.151-12.646-20-12.646s-20 5.8-20 12.645c0 1.617.472 3.233 1.415 4.755l-3.396 2.282Z"
                />
                <path fill="#55C99F" d="m143.628 20.486-8.202 12.885 16.357 10.577 8.202-12.885-16.357-10.577Z" />
                <path
                    fill="#6FE1B8"
                    d="M136.605 23.485c.378-.76.755-1.521 1.321-2.282 4.717-6.655 13.868-8.272 20.472-3.518 6.604 4.754 8.207 13.977 3.49 20.633a8.596 8.596 0 0 1-1.698 1.997l-23.585-16.83Z"
                />
                <path fill="#55C99F" d="M95.987 21.23 79.629 31.809l8.202 12.885 16.357-10.577-8.201-12.885Z" />
                <path
                    fill="#6FE1B8"
                    d="M163.704 81.734c5.964-24.208-8.671-48.705-32.69-54.716-24.018-6.012-48.324 8.739-54.289 32.947-5.964 24.207 8.672 48.704 32.69 54.716 24.019 6.011 48.325-8.74 54.289-32.947Z"
                />
                <path
                    fill="#fff"
                    d="M156.627 78.837c4.377-20.268-8.376-40.274-28.485-44.686-20.109-4.412-39.96 8.441-44.337 28.709-4.378 20.267 8.376 40.274 28.485 44.686 20.109 4.411 39.959-8.442 44.337-28.71Z"
                />
                <path
                    fill="#425AC2"
                    d="M121.604 38.128c0 .76-.661 1.426-1.415 1.426-.755 0-1.416-.666-1.416-1.426 0-.76.661-1.426 1.416-1.426.754.095 1.415.665 1.415 1.426ZM105.188 41.836c.377.666.188 1.521-.472 1.902-.661.38-1.51.19-1.887-.476-.377-.665-.189-1.521.472-1.901.66-.38 1.509-.19 1.887.475ZM92.83 53.34c.66.381.848 1.237.47 1.903-.376.665-1.226.855-1.886.475-.66-.38-.849-1.236-.472-1.902.378-.665 1.227-.855 1.887-.475ZM87.83 69.41c.754 0 1.414.665 1.414 1.426 0 .76-.66 1.426-1.415 1.426s-1.415-.665-1.415-1.426c0-.76.66-1.426 1.415-1.426ZM91.51 85.954c.661-.38 1.51-.19 1.887.476.378.665.19 1.52-.471 1.901-.66.38-1.51.19-1.887-.475-.472-.666-.189-1.522.472-1.902ZM102.829 98.41c.377-.666 1.226-.856 1.887-.476.66.38.849 1.237.472 1.902-.378.666-1.227.856-1.887.475-.661-.38-.849-1.236-.472-1.901ZM118.867 103.449c0-.76.661-1.426 1.415-1.426.755 0 1.415.666 1.415 1.426 0 .761-.66 1.426-1.415 1.426-.849 0-1.415-.665-1.415-1.426ZM135.188 99.836c-.377-.665-.188-1.521.472-1.902.66-.38 1.51-.19 1.887.476.377.665.189 1.521-.472 1.901-.66.381-1.509.191-1.887-.475ZM147.547 88.331c-.661-.38-.849-1.236-.472-1.901.377-.666 1.227-.856 1.887-.476.66.38.849 1.236.472 1.902-.378.665-1.227.856-1.887.475ZM152.642 72.167c-.755 0-1.415-.665-1.415-1.426 0-.76.66-1.426 1.415-1.426.754 0 1.415.665 1.415 1.426-.095.856-.661 1.426-1.415 1.426ZM148.962 55.718c-.66.38-1.51.19-1.887-.475-.377-.666-.189-1.522.472-1.902.66-.38 1.509-.19 1.887.475.377.666.188 1.522-.472 1.902ZM137.641 43.262c-.378.666-1.227.856-1.887.476-.661-.38-.849-1.236-.472-1.902.378-.666 1.227-.856 1.887-.475.566.38.849 1.236.472 1.901ZM105.276 60.277l-1.327 2.52 15.168 8.11 1.327-2.52-15.168-8.11ZM142.714 56.725 120.16 69.257l1.002 1.832 22.554-12.532-1.002-1.832Z"
                />
                <path
                    fill="#6FE1B8"
                    d="M126.699 70.836c0 3.613-2.925 6.56-6.51 6.56s-6.509-2.947-6.509-6.56c0-3.613 2.924-6.56 6.509-6.56 3.585 0 6.51 2.947 6.51 6.56ZM103.02 24.056c-.378-.76-.755-1.521-1.321-2.282-4.717-6.656-13.868-8.272-20.472-3.518-6.604 4.754-8.208 13.977-3.49 20.633a8.63 8.63 0 0 0 1.698 1.996l23.585-16.83Z"
                />
            </g>
            <g clipPath="url(#e)">
                <path
                    fill="#6FE1B8"
                    d="M181.294 139.052v4.813c-.127.507-.253 1.394-.507 2.407-.253 1.013-.633 2.154-1.013 3.294-.886 2.28-2.025 4.56-3.418 5.573l-2.279-.38c-9.243-1.773-20.132-7.094-23.804-8.867-.886-.38-1.266-.633-1.266-.633s.633-2.534 1.14-8.107c.126-.887.126-1.9.253-3.04.126-1.901.253-3.927.253-6.334v-7.347c0-.887 0-1.647-.127-2.534 0-1.013-.126-2.026-.126-3.166 0-.887-.127-1.774-.127-2.787.887-1.774 1.52-3.294 2.153-4.56 2.152-4.814 2.152-5.321 2.152-5.321l-.633-2.66-.886-7.474s-5.571-13.553-11.396-20.014c0-.253-.126-.253-.253-.38 2.153-1.013 17.6-6.46 17.6-6.46l1.519 1.14.253.253c2.912 2.534 8.99 8.36 13.548 17.228 0 .126.127.126.127.253 2.912 5.7 5.191 12.54 5.697 20.394.254 3.421.38 6.587.507 9.501v1.14c0 1.14.126 2.28.126 3.293.127 2.027.127 4.054.254 5.827.126 4.941.253 8.867.253 11.908v3.04Z"
                />
                <path
                    fill="#6FE1B8"
                    d="M204.717 153.872s.38-2.533-.38-2.66c-.126 0-.253 0-.633.127-1.646.507-5.824 2.28-9.622 3.293-2.153.507-4.179.887-5.698.634-.76-2.027-1.519-3.8-2.152-5.447-.507-1.394-1.14-2.534-1.646-3.674a20.899 20.899 0 0 0-.76-1.646l-.38-.76c-.633-1.267-1.14-2.407-1.646-3.421 0-.126-.127-.253-.127-.38-.126-.126-.126-.253-.253-.506-.126-.127-.126-.254-.253-.38v-5.447c0-3.294-.127-7.347-.253-12.287-.127-2.661-.254-5.574-.38-8.614-.127-2.66-.253-5.447-.507-8.361-.506-7.98-2.785-14.947-5.824-20.647 4.052-7.347 6.457-15.201 6.837-16.848 0-.253.127-.253.127-.253s1.646.38 3.798 1.14c3.039 1.013 7.091 2.407 9.117 3.927 0 .253 0 .506.126.76.38 2.153 1.14 6.333 1.9 9.88.886 3.927 1.012 8.107.506 12.16-.506 3.674-.886 7.348-1.013 11.021a41.701 41.701 0 0 0 .886 10.388c0 .253.127.38.127.633 0 .127.127.253.127.38.759 2.533 1.266 4.814 1.772 6.84 1.14 5.447 1.266 8.614 1.773 8.867l.76.254s.253.633.506 1.773c.253.634.38 1.52.633 2.66.633 2.914 1.393 7.221 1.519 12.288h2.533l-1.52 4.306Z"
                />
                <path
                    fill="#546EDB"
                    d="M175.977 155.139c-.127-.127-.127-.253-.127-.253 1.266-.76 2.406-2.661 3.419-5.447.38-1.014.759-2.154 1.013-3.294.253-1.013.506-1.773.506-2.407l.127-.38V136.011c0-3.673-.127-7.6-.254-11.907l-.379-9.12c-.127-3.547-.38-7.221-.633-10.768-.38-7.093-2.28-13.933-5.698-20.394 0-.127-.127-.127-.127-.253v-.127h.127c0 .127.126.127.126.254a53.512 53.512 0 0 1 5.698 20.52c.253 3.547.38 7.221.633 10.768l.38 9.12c.127 4.307.253 8.361.253 11.907v7.347l-.126.38c-.127.507-.254 1.394-.633 2.407-.38 1.14-.633 2.28-1.013 3.294-.76 2.913-1.9 4.813-3.292 5.7Z"
                />
                <path
                    fill="#4088DD"
                    d="M152.552 105.737c-.634 1.393-1.52 3.42-2.786 5.953 0 .507.127 1.014.127 1.52 0 .38 0 .76.126 1.267 0 .633.127 1.393.127 2.027l1.139 1.14-.886 2.533-1.899 4.56-1.266-.126-5.825-.634-1.772-3.166s-5.445-9.501-5.825-27.868c.887-6.84 2.406-11.654 3.926-14.948 2.025-4.56 4.051-6.207 4.051-6.207 5.825 6.46 11.396 20.014 11.396 20.014l.379 7.22.634 2.661c-.127.127-.127.633-1.646 4.054ZM207.882 121.064c-.76-1.773-2.659-5.953-5.951-10.26a37.443 37.443 0 0 0-5.824-6.081h-.253c0-1.393.126-2.66.253-4.053.127-1.647.38-3.42.633-5.067.633-4.94.506-10.007-.633-14.947-.633-3.167-1.393-6.588-1.646-8.488 0 0 .38.38 1.013.76.633.38 1.519.634 2.785.634 2.026.253 8.23 9.754 8.99 19.254l2.026 1.394s1.139 8.36 1.013 14.06c1.266 3.674-.127 9.501-2.406 12.794Z"
                />
                <path
                    fill="#F3777B"
                    d="M161.037 56.714c-.633-.126-1.013-.633-1.013-1.14v-1.52l-.38-2.787-.253-.886c0-.38.126-.634.506-.76l.253 1.646.887 5.447ZM181.928 50.38v.254l-.507 3.42v1.52c0 .634-.253 1.14-.886 1.14l.886-6.207.127-1.013c.253.253.38.633.38.887Z"
                />
                <path
                    fill="#FFD359"
                    d="M183.194 45.187v1.394h-2.785l-2.28 3.04s-1.012.126-2.658.253c-.38 0-.633.127-1.013.127-1.773.126-3.925.38-5.825.38h-2.025c-.38 0-.634 0-.887-.127h-.126l-3.799-2.027h-3.292l-.633-1.646s.38-.254.886-.507c1.14-.507 3.039-1.393 5.951-1.9 4.179-.887 10.256-1.267 17.853.76.253.127.38.253.633.253Z"
                />
                <path
                    fill="#FFD359"
                    d="M182.559 45.06c-8.357-2.153-14.814-1.52-18.993-.506-2.152.506-3.798 1.14-4.684 1.646 0 0-2.533-8.867 7.85-14.694a18.062 18.062 0 0 1 3.672-.38c4.051 0 7.976 1.267 10.762 3.674 1.772 5.827 1.393 10.26 1.393 10.26Z"
                />
                <path
                    fill="#000"
                    d="m182.561 46.707-.38 4.054h-.38v-.254c0-.38-.127-.633-.506-.76l-.127 1.014.506-3.927h.887v-.127ZM160.147 51.267l-.254-1.646c-.379.126-.506.38-.506.76l.127.886h-.38l-.38-2.913h.886l.507 2.913Z"
                />
                <path
                    fill="#404040"
                    d="M162.046 111.69c0 .127 0 .127 0 0 0 .507-.126.887-.126 1.267 0 .127 0 .38-.127.507v.38c0 .126 0 .253-.127.506 0 .254-.126.507-.126.634-.127.506-.254.886-.254.886l-4.304-2.153-1.267-.633s-.126.126-.379.253c-.127 0-.254.127-.254.253-.126 0-.126.127-.253.127-.506.253-1.013.507-1.773.76h-5.697l-2.406-6.714 2.153-6.587h1.899c1.519 0 3.418.127 4.431.254.127 0 .254.126.38.126 1.266.507 4.052 1.774 4.052 1.774l.633-.127c.506 0 1.139-.127 2.406-.253l.379 1.14c1.013 2.533 1.013 5.32.76 7.6Z"
                />
                <path
                    fill="#514C54"
                    d="m147.233 101.05-10.509 3.42v.887h-.38v1.393l7.217 2.28-6.458-.76v1.014l1.14 1.646-.127.887s3.925 2.66 9.243 2.66l-2.405-6.714 2.279-6.713Z"
                />
                <path
                    fill="#3C1C39"
                    d="m144.954 105.864-8.23-.38s-.126 0-.126-.127c0 0 0-.127.126-.127l8.23.38c.127.127.127.127 0 .254.127 0 .127 0 0 0Z"
                />
                <path
                    fill="#546EDB"
                    d="m208.137 121.824-1.393 2.534-8.483-.76-1.266-1.141s.76-.38 1.266-1.393c.127-.127.127-.253.253-.38v-.253c.127-.38.254-.76.254-1.14a35.939 35.939 0 0 0-6.458-11.654c-.759-.887-1.392-1.394-1.772-1.774-.38-.253-.507-.38-.507-.38l5.571-.633h.254c2.279 1.9 4.178 4.054 5.824 6.08 3.292 4.307 5.318 8.614 5.951 10.261.38.38.506.633.506.633Z"
                />
                <path
                    fill="#FFBCB9"
                    d="M198.893 119.164c0 .38-.127.76-.253 1.14v.253c-.127.127-.127.253-.254.38h-1.646c-3.798-.127-8.989-.887-14.054-1.773-.633-.127-1.139-.254-1.773-.254h-.253c-.506-.126-1.139-.253-1.646-.253-7.47-1.393-14.307-3.04-16.586-3.547-.507-.127-.76-.253-.76-.253.127-.507.253-1.394.38-2.28 0-.254.126-.634.126-.887.254-2.28.254-5.194-.506-7.727l15.827.887 6.584.38h.506l4.685.253 1.393.127 1.772 1.773c1.9 2.28 4.559 6.08 6.458 11.781Z"
                />
                <path
                    fill="#404040"
                    d="m212.313 104.09-11.016.254 11.016 3.293-.254.887-10.889-1.014 8.104 3.421-.253 1.266-7.218-1.393a37.438 37.438 0 0 0-5.824-6.08l-5.824.633s.126.127.506.38l-6.077-.38h-.507s-.253-.633.38-2.28c0-.127.127-.38.253-.507 2.786-1.013 16.46-3.673 16.46-3.673l10.636 2.153-.38 1.013 1.013.254v1.773h-.126Z"
                />
                <path
                    fill="#3C1C39"
                    d="m211.174 102.19-9.243-.76s-.126 0-.126-.127c0 0 0-.127.126-.127l9.243.76c.127.127.127.127 0 .254.127 0 .127 0 0 0Z"
                />
                <path
                    fill="#546EDB"
                    d="m158.376 117.897-4.431 8.488c-.253.506-.506.886-.76 1.393a9.819 9.819 0 0 1-2.912-.76c-1.772-.76-2.659-2.027-3.039-2.534l1.267.127 1.646-4.687.886-2.533-.38-.38-.76-.887s-.126-1.267-.126-2.027h3.165c.76-.253 1.393-.506 1.773-.76.633-.38 1.013-.633 1.013-.633l1.266.633.633 2.154.759 2.406Z"
                />
                <path
                    fill="#FFBCB9"
                    d="M179.015 118.658c-1.393.38-3.925 1.14-6.964 1.9-7.47 2.026-17.473 4.433-17.473 4.433l3.799-7.094-.127-.253-1.14-3.927 4.305 2.154s.127-.38.254-.887c-.127-.127 8.103 1.9 17.346 3.674ZM184.331 103.077c-.633 1.647-.38 2.28-.38 2.28l-6.584-.38 6.964-1.9Z"
                />
                <path
                    fill="#F3777B"
                    d="M184.331 103.077c-.633 1.647-.38 2.28-.38 2.28l-6.584-.38 6.964-1.9Z"
                    opacity=".5"
                />
                <path
                    fill="#F3777B"
                    d="m178.381 65.708-4.178 1.14-2.913-.38-8.989-1.267v-5.32l.126.127c1.013 1.52 2.786 3.546 6.078 5.447.127 0 .127.126.253.126h4.178c.127 0 .127-.126.254-.126 2.405-1.394 4.051-2.914 5.191-4.18v4.433Z"
                />
                <path
                    fill="#4088DD"
                    d="M181.166 66.722c0 .126 0 .126 0 0 0 .126-.126.253-.126.38-.127.38-.254.76-.38 1.266v.127c-.253.76-.507 1.773-1.013 2.913 0 .127-.127.38-.253.507 0 .127-.127.38-.127.507l-.38 1.14c-.126.253-.126.38-.253.633 0 .127-.127.254-.127.254-.126.253-.253.633-.379.886-.127.254-.254.634-.38.887v.127c-.127.253-.127.38-.254.633-.126.253-.253.507-.253.633a3.78 3.78 0 0 1-.506 1.014c0 .126-.127.253-.127.38a3.78 3.78 0 0 1-.506 1.013c-.127.253-.254.507-.38.633-.127.254-.253.507-.38.634-.38.633-.76 1.393-1.14 2.027-4.178-8.107-9.622-13.681-12.661-16.468l-.76-.76h.633l.254-1.267.506.127 1.519.253 10.383 1.267 4.178-1.14.633-.127.127 1.014 1.139.126 1.013.38Z"
                />
                <path
                    fill="#FFBCB9"
                    d="m181.798 46.707-.253 1.774-.253 2.153-.887 6.207-.126 1.267s-.38 1.393-2.026 3.293c-1.14 1.267-2.786 2.787-5.191 4.18-.127 0-.127.127-.253.127h-4.179c-.126 0-.126-.126-.253-.126-3.292-1.9-5.065-3.927-6.077-5.447l-.127-.127c-.76-1.14-1.013-1.9-1.013-1.9l-1.013-6.84-.506-2.914h2.025l3.926 2.154c3.292.506 12.408-.76 12.408-.76l2.279-3.04h1.519Z"
                />
                <path
                    fill="#F7C53E"
                    d="M163.566 44.554c-2.152.506-3.798 1.14-4.684 1.646 0 0-2.533-8.867 7.85-14.694a18.062 18.062 0 0 1 3.672-.38c-.127 0-7.724 6.714-6.838 13.428Z"
                />
                <path
                    fill="#131313"
                    d="M157.868 46.707c-.126-.126-.126-.126 0-.126.127 0 9.117-5.447 23.551-1.9v.126h-.127c-14.307-3.547-23.297 1.9-23.424 1.9Z"
                />
                <path
                    fill="#F3777B"
                    d="m181.798 46.707-.253 1.774c-.633.253-2.026 1.013-2.026 1.013l-2.532 1.9-.76.76-5.951-.633-5.064 1.14-3.292-1.647 1.392 7.474 5.318 5.573 1.393 1.14.127.38h-1.52c-.126 0-.126-.126-.253-.126-3.292-1.9-5.065-3.927-6.077-5.447l-.127-.127c-.76-1.14-1.013-1.9-1.013-1.9l-1.013-6.714-.127-.506-.379-2.407h2.025l3.926 2.154c3.292.506 12.408-.76 12.408-.76l2.279-3.04h1.519Z"
                    opacity=".5"
                />
                <path
                    fill="#E34E6F"
                    d="m171.417 65.581-.127.76-8.989-1.14v-5.32l.126.127c1.013 1.52 2.786 3.546 6.078 5.447.127 0 .127.126.253.126h2.659Z"
                />
                <path
                    fill="#546EDB"
                    d="M181.167 66.721c0 .127 0 .127 0 0 0 .127-.127.254-.127.38-.126.38-.253.76-.38 1.267v.127c-.253.76-.506 1.773-1.012 2.913 0 .127-.127.38-.254.507 0 .126-.126.38-.126.507l-.38 1.14c-.127.253-.127.38-.253.633 0 .127-.127.253-.127.253-.127.254-.253.634-.38.887-.126.253-.253.633-.38.887v.126c-.126.254-.126.38-.253.634-.127.253-.253.507-.253.633-.127.38-.253.634-.507 1.014 0 .126-.126.253-.126.38-.127.38-.253.633-.507 1.013-.126.253-.253.507-.379.633-.127.254-.254.507-.38.634-.38.633-.76 1.393-1.14 2.026 5.191-10.133 5.824-17.1 5.824-17.1l1.14.506ZM174.327 83.696c-4.178-8.107-9.622-13.68-12.661-16.468l-.76-.76h.633l.254-1.266.506.126 1.519.254-.633 1.52s6.964 6.587 11.142 16.594Z"
                />
                <path
                    fill="#546EDB"
                    d="M197.501 99.656c-.38.507-.886.887-1.519 1.014.127-1.647.38-3.42.633-5.067.633-4.94.506-10.007-.633-14.947-.633-3.167-1.393-6.588-1.646-8.488 0 0 .38.38 1.013.76 1.393 2.787 5.698 13.301 2.152 26.728ZM153.943 101.556c-.127-.126-.254-.126-.38-.126-1.013-.254-3.039-.254-4.432-.254l-.38-1.9c-.506-2.153-1.392-1.647-3.038-2.533-1.646-1.014-1.266-3.8-1.266-5.574-.254-3.167-1.52-5.953-3.292-8.614-1.013-1.646-2.279-3.04-3.546-4.56 2.026-4.56 4.052-6.207 4.052-6.207 5.824 6.46 11.395 20.014 11.395 20.014l.38 7.22.507 2.534Z"
                    opacity=".5"
                />
                <path
                    fill="#F3777B"
                    d="M179.014 118.658c-1.393.38-3.925 1.14-6.964 1.9l-13.801-2.914-.507-1.9-.633-2.153 3.419 1.773.76.38s0-.253.126-.506c0-.127 0-.254.127-.38 0 0 .253.126.76.253h.379c.127 0 .254 0 .38.127h.633c.127 0 .254.126.38.126.127 0 .253 0 .38.127.127 0 .38.127.507.127.633.126 1.392.253 2.152.506.253 0 .506.127.76.127h.253c.126 0 .38.127.506.127.127 0 .254 0 .38.126.38.127.633.127 1.013.254.127 0 .253 0 .38.126.127 0 .38.127.506.127l3.799.76c.126 0 .38.127.506.127l1.9.38c.126 0 .253 0 .379.126h.254c.126 0 .253 0 .379.127.38 0 .634.127.887.127Z"
                    opacity=".5"
                />
                <path
                    fill="#3C1C39"
                    d="m146.726 112.577-1.773-4.813 1.899-5.194h.127v.127l-1.899 5.193 1.646 4.687c.126 0 .126 0 0 0ZM194.334 104.343c-.254-2.407 1.013-3.673 1.013-3.673h.126v.126c-.126 0-1.266 1.14-1.139 3.547Z"
                />
                <path
                    fill="#A15236"
                    fillOpacity=".42"
                    d="M157.364 99.783c-.127 1.14.633 2.28 1.392 3.42l-.633.127s-2.785-1.267-4.051-1.773l-.507-2.407-.506-7.22s-5.571-13.555-11.396-20.015c0-.253-.126-.253-.253-.38 2.153-1.013 17.6-6.46 17.6-6.46l1.519 1.14c-1.519.887-3.039 2.66-4.432 3.42-1.519.887-1.772 1.52-2.532 3.04-1.013 1.774-1.519 3.42-1.772 5.447-.38 3.294 1.646 6.207 3.292 8.994 1.392 2.407 3.671 5.067 4.051 7.854.127.886.127 2.026-.38 2.66-.886 1.14-1.266.633-1.392 2.153Z"
                    opacity=".25"
                />
                <path
                    fill="#546EDB"
                    d="M193.701 99.53c3.672-10.388-1.266-26.602-1.393-27.235-4.558-3.167-10.382-3.42-10.382-3.42s5.951.38 10.509 3.547c0 0 1.266 4.18 2.152 9.627.633 4.813 1.14 11.907-.886 17.48Z"
                />
                <path
                    fill="#F3777B"
                    d="M198.893 119.164c0 .38-.127.76-.253 1.141v.253c-.127.127-.127.253-.254.38h-1.646c-3.798-.127-8.989-.887-14.054-1.774-.633-.126-1.139-.253-1.773-.253h-.253c-.506-.127-1.139-.253-1.646-.253-7.47-1.394-14.307-3.04-16.586-3.547-.507-.127-.76-.253-.76-.253.127-.507.253-1.394.38-2.281 3.925 1.014 14.687 3.547 29.881 5.447 0-1.646-2.279-10.893-2.659-12.414l1.393.127 1.772 1.774c1.9 2.153 4.559 5.953 6.458 11.653Z"
                    opacity=".25"
                />
                <path
                    fill="#546EDB"
                    d="m192.434 88.762-11.269-1.52-.38-3.42 11.016-.38.633 5.32c0-.126 0-.126 0 0Zm-11.142-1.646 11.142 1.52-.507-5.194-11.015.254.38 3.42ZM159.009 90.663l-2.532-4.56 12.534-1.774.507 2.533-10.509 3.8Zm-2.406-4.687 2.406 4.433 10.509-3.8-.507-2.407-12.408 1.774Z"
                />
                <path fill="#F3777B" d="m168.125 52.027 2.152 7.6v-8.106l-2.152.506Z" opacity=".5" />
                <path
                    fill="#584064"
                    d="M136.345 103.203c-.253-11.527 3.166-18.24 3.292-18.367h.127v.127s-3.545 6.713-3.419 18.24c.127 0 .127 0 0 0Z"
                    opacity=".5"
                />
                <path
                    fill="#584064"
                    d="m208.009 95.096-3.419-1.9c.253-8.74-5.824-16.847-5.824-16.974v-.126h.126c0 .126 6.078 8.233 5.825 16.974l3.292 1.9c.126 0 .126 0 0 .126.126 0 .126 0 0 0Z"
                />
                <path
                    fill="#E34E6F"
                    d="M188.508 114.351c-.126 0-.126 0 0 0l-2.912-5.701-9.749 4.054h-.127l-.126-.127-1.773-4.94-9.116 2.407c-.127 0-.127 0-.127-.127s0-.127.127-.127l9.243-2.406c.126 0 .126 0 .126.126l1.773 4.814 9.749-4.054c.127 0 .127 0 .127.127l2.912 5.7c0 .127 0 .127-.127.254Z"
                />
                <path
                    fill="#584064"
                    d="M166.607 50.508c0 .38-.254.76-.507.76s-.506-.38-.506-.76v-.127h.126c.254 0 .633 0 .887.127ZM175.595 50c0 .38-.253.76-.507.76-.253 0-.506-.253-.506-.633.253-.126.633-.126 1.013-.126Z"
                />
            </g>
            <defs>
                <clipPath id="c">
                    <path fill="#fff" d="M0 9.3h68v58H0z" />
                </clipPath>
                <clipPath id="d">
                    <path fill="#fff" d="M75 0h90v116H75z" />
                </clipPath>
                <clipPath id="e">
                    <path fill="#fff" d="M129 31h98v85h-98z" />
                </clipPath>
                <linearGradient id="a" x1="29.3" x2="26.406" y1="83.87" y2="107.639" gradientUnits="userSpaceOnUse">
                    <stop offset=".245" stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity=".26" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="b" x1="209.777" x2="219.889" y1="26.78" y2="48.486" gradientUnits="userSpaceOnUse">
                    <stop offset=".245" stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity=".26" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ModalWorkerSvg;
