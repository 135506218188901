export const ApproveIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.71019 11.29C9.52188 11.1017 9.26649 10.9959 9.00019 10.9959C8.73388 10.9959 8.47849 11.1017 8.29019 11.29C8.10188 11.4783 7.99609 11.7337 7.99609 12C7.99609 12.2663 8.10188 12.5217 8.29019 12.71L11.2902 15.71C11.3836 15.8027 11.4944 15.876 11.6163 15.9258C11.7381 15.9755 11.8686 16.0008 12.0002 16C12.1371 15.9957 12.2716 15.9632 12.3955 15.9047C12.5193 15.8463 12.6299 15.763 12.7202 15.66L19.7202 7.65999C19.8818 7.45932 19.9597 7.204 19.9377 6.94728C19.9157 6.69055 19.7955 6.45223 19.602 6.282C19.4086 6.11178 19.1569 6.02279 18.8995 6.0336C18.642 6.0444 18.3987 6.15416 18.2202 6.33999L12.0002 13.54L9.71019 11.29Z"
                fill="white"
            />
            <path
                d="M20.9998 11C20.7346 11 20.4802 11.1054 20.2927 11.2929C20.1052 11.4804 19.9998 11.7348 19.9998 12C19.9998 14.1217 19.157 16.1566 17.6567 17.6569C16.1564 19.1571 14.1215 20 11.9998 20C10.4199 19.9993 8.87558 19.5308 7.56158 18.6535C6.24757 17.7763 5.22274 16.5297 4.61629 15.0708C4.00985 13.6119 3.84896 12.0061 4.1539 10.4559C4.45885 8.90569 5.21597 7.48048 6.32981 6.36C7.07052 5.60938 7.95349 5.01404 8.92707 4.60881C9.90065 4.20358 10.9453 3.99661 11.9998 4C12.6393 4.004 13.2764 4.07777 13.8998 4.22C14.0303 4.26035 14.1676 4.27356 14.3034 4.2588C14.4392 4.24404 14.5705 4.20163 14.6892 4.13418C14.808 4.06673 14.9116 3.97567 14.9938 3.86662C15.076 3.75757 15.135 3.63284 15.1672 3.50011C15.1994 3.36739 15.204 3.22948 15.1808 3.0949C15.1576 2.96032 15.1071 2.83191 15.0324 2.71759C14.9577 2.60327 14.8604 2.50547 14.7464 2.43021C14.6324 2.35495 14.5043 2.30383 14.3698 2.28C13.593 2.09724 12.7979 2.00331 11.9998 2C10.0242 2.01026 8.0958 2.60552 6.45811 3.71065C4.82042 4.81578 3.54678 6.38126 2.79793 8.20953C2.04908 10.0378 1.85857 12.0469 2.25045 13.9834C2.64232 15.9198 3.59902 17.6968 4.99981 19.09C6.8565 20.9475 9.37347 21.9939 11.9998 22C14.652 22 17.1955 20.9464 19.0709 19.0711C20.9462 17.1957 21.9998 14.6522 21.9998 12C21.9998 11.7348 21.8945 11.4804 21.7069 11.2929C21.5194 11.1054 21.265 11 20.9998 11Z"
                fill="white"
            />
        </svg>
    );
};
