import {useContext} from "react";
import ModalsContext from "../../../store/modals-context";
import ModalCloseSvg from "../ModalSvg/ModalCloseSvg";
import findCity from "./svg/findCity.svg"
import {useDispatch} from "react-redux";
import {modalsSliceAction} from "../../../store/redux";
import {useMediaQuery} from "@mui/material";

const ModalCity = () => {
    const ctx = useContext(ModalsContext);
    const dispatch = useDispatch();
    const mobile = useMediaQuery('(max-width: 1024px)')

    return (
        <div className="modal modal__card3">
            <div className="closeModal" onClick={() => {
                ctx.closeModal()
                document.querySelector('body').classList.remove('noscroll')
            }}>
                <ModalCloseSvg/>
            </div>
            <div className="modal_block">
                <h2 className="modal_title_custom">
                    Ваш город: <br/> <span>{JSON.parse(localStorage.getItem("location"))?.city}</span>?
                </h2>
            </div>
            <img src={findCity} alt="location"/>
            <div className="buttons_city">
                <button onClick={() => {
                    localStorage.setItem('firstVisit', '1')
                    ctx.closeModal()
                    document.querySelector('body').classList.remove('noscroll')
                }
                }
                        style={{marginBottom: "15px"}} className="modal__btn" type="button">Да, верно
                </button>
                <button onClick={() => {
                    if(mobile){
                        ctx.toggleMenu()
                        ctx.closeModal()
                    }else{
                        ctx.closeModal()
                        dispatch(modalsSliceAction.changeHeaderCity(true))
                        document.querySelector('body').classList.remove('noscroll')
                    }
                }}
                    className="modal__btn modal_green" type="button">Изменить
                </button>
            </div>
        </div>
    )
}

export default ModalCity;