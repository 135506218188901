export const TabSearch = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_71_4439)">
                <path
                    d="M7.42886 1.94409C5.97995 1.94409 4.80151 3.12253 4.80151 4.57118C4.80151 6.01983 5.97995 7.1985 7.42886 7.1985C8.87753 7.1985 10.056 6.01983 10.056 4.57118C10.056 3.12253 8.87753 1.94409 7.42886 1.94409ZM7.78042 5.9779C7.78042 6.17196 7.62315 6.32946 7.42886 6.32946C7.23456 6.32946 7.07706 6.17196 7.07706 5.9779V4.57118C7.07706 4.37688 7.23456 4.21962 7.42886 4.21962C7.62315 4.21962 7.78042 4.37688 7.78042 4.57118V5.9779ZM7.42886 3.51626C7.23456 3.51626 7.07706 3.35899 7.07706 3.16469C7.07706 2.97063 7.23456 2.81313 7.42886 2.81313C7.62292 2.81313 7.78042 2.97063 7.78042 3.16469C7.78042 3.35899 7.62292 3.51626 7.42886 3.51626Z"
                    fill="white"
                />
                <path
                    d="M7.42866 0C4.90796 0 2.85718 2.05078 2.85718 4.57123C2.85718 7.09193 4.90796 9.14269 7.42866 9.14269C9.94913 9.14269 11.9999 7.09191 11.9999 4.57123C11.9999 2.05078 9.94913 0 7.42866 0ZM7.42866 7.90167C5.5921 7.90167 4.09819 6.40777 4.09819 4.57123C4.09819 2.7349 5.5921 1.24102 7.42866 1.24102C9.26499 1.24102 10.7589 2.73492 10.7589 4.57123C10.7589 6.40779 9.26499 7.90167 7.42866 7.90167Z"
                    fill="white"
                />
                <path
                    d="M1.67227 8.34436L3.65555 10.3276L2.39156 11.5916C1.86938 12.1361 0.930234 12.1361 0.408047 11.5916C-0.136172 11.0697 -0.135937 10.1303 0.408281 9.60834L1.67227 8.34436Z"
                    fill="white"
                />
                <path
                    d="M4.82585 9.15748L4.15296 9.8306L2.16968 7.84732L2.84257 7.17419C3.31296 8.00013 3.99991 8.68709 4.82585 9.15748Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_71_4439">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
