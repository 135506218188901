import CardSvg from "./svg/CardSvg";
import BankSvg from "./svg/BankSvg";
import { useContext } from "react";
import ModalsContext from "../../../store/modals-context";

const PaymentBody = (props) => {
    const modal = useContext(ModalsContext);

    return (
        <div>
            <h5 className="title">Пополнить кошелек</h5>
            <div className="modal_title__sub">Выберите удобный для Вас способ:</div>

            <div className="modal_payments__buttons">
                <button type="button" className="payment_button payment_online" onClick={() => props.setModalCheck(3)}>
                    <div className="icon">
                        <CardSvg />
                    </div>
                    <span>Онлайн</span>
                </button>

                {modal.orderId === 0 && (
                    <button
                        type="button"
                        className="payment_button payment_check"
                        onClick={() => props.setModalCheck(2)}>
                        <div className="icon">
                            <BankSvg />
                        </div>
                        <span>Расчетный счет</span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default PaymentBody;
