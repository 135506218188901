import { useContext } from "react";
import moment from "moment";

import RequestRatingStarSvg from "../svg/RequestRatingStarSvg";
import avatar from "../../../assets/images/stubs/avatar.svg";
import ModalsContext from "../../../store/modals-context";
import { catalogAction } from "../../../store/redux";
import { url } from "../../../store/host-store";
import {useDispatch, useSelector} from "react-redux";
import s from "../../responses/style.module.sass";

const OfferRequest = ({ item, dropRequest }) => {
    const modal = useContext(ModalsContext);

    const requestClickHandler = (id) => {
        modal.paymentRequest(id);
    };

    const dispatch = useDispatch();

    const selector = useSelector(state => state.notification.data)

    return (
        <div className={`block ${selector.model_id == item.id ? 'active' : ''}`}>
            <div className="top">
                <div className="img">
                    {item.user?.avatar === null ? (
                        <img className="request_avatar" src={avatar} alt="" />
                    ) : (
                        <img className="request_avatar" src={url + item.user?.avatar} alt="" />
                    )}
                </div>
                <div>
                    <p className="name">{item.user?.name}</p>
                    <div className="date">
                        <div className="request_id_number">ID {item.user.id}</div>
                        {item.user?.mark === null ? (
                            <div className="grade">
                                <span className="grade_text">Нет рейтинга</span>
                                <RequestRatingStarSvg />
                                <span className="grade_mark">{item?.user?.mark}</span>
                            </div>
                        ) : (
                            <div className="grade">
                                <span className="grade_text">Рейтинг</span>
                                <RequestRatingStarSvg />
                                <span className="grade_mark">{item?.user?.mark}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="request_top_right">
                    <div className="request_number_order">№{item.id}</div>
                    <div className="request_time_order">{moment(item.created).format("DD.MM.YYYY")}</div>
                    <div className="request_time_order_hours">{moment(item.created).format("HH:mm")}</div>
                </div>
            </div>
            <div>
                <p className={s.p_comment}>{item.about}</p>
            </div>
            <div className="bottom">
                <div className="timing">
                    <button
                        type="button"
                        className="btn"
                        onClick={() => {
                            requestClickHandler(item.id);
                            dispatch(catalogAction.setMoneyCheck(item.costs));
                            modal.setMoney(item.costs);
                        }}>
                        Принять
                        <span>{item.costs} руб.</span>
                    </button>

                    <div className="request_refuse__btn" onClick={() => dropRequest(item.id)}>
                        Отказаться
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfferRequest;
