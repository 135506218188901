import { useContext } from "react";
import { NavLink } from "react-router-dom";

import ModalsContext from "../../../store/modals-context";
import s from "./style.module.sass";
import {cityAlias} from "../../../App";

const Childrens = ({ childrens, currentSubcategory, type }) => {
    const ctx = useContext(ModalsContext);
    return (
        <div className={s.childrens}>
            <h3>{currentSubcategory.title}</h3>
            {childrens.map((category) => {
                return (
                    <NavLink
                        key={category.id}
                        className={s.childrens__item}
                        to={`/${cityAlias}/${category?.fullAlias}` }
                        onClick={ctx.toggleMenu}>
                        {category.title}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default Childrens;
