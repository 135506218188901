import ModalCloseSvg from "../../ModalSvg/ModalCloseSvg";
import { useContext, useEffect, useState } from "react";
import ModalsContext from "../../../../store/modals-context";
import style from "./main.module.sass";
import AuthContext from "../../../../store/auth-context";
import CabinetSocToggle from "../../../../cabinet/cabinetInfo/CabinetSocToggle";
import { link } from "../../../../store/host-store";
import { token } from "../../../../App";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const MainModalRequest = () => {
    const [arrayId, setArrayId] = useState([]);
    const modal = useContext(ModalsContext);
    const ctx = useContext(AuthContext);
    const [points, setPoints] = useState(false);
    const selector = useSelector((state) => state.catalog.moneyCheck);
    const cartName = useSelector((state) => state.catalog.cartName);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const promotion = window.location.href.indexOf("promotion") > -1;
    const advertiser = window.location.href.indexOf('advertiser') > -1;

    const clickToWallet = () => {
        modal.paymentModal();
    };


    useEffect(() => {
        //сюда логику оплаты
        if (promotion) {
            if(searchParams.get("item")){
                setArrayId(searchParams.get("item")?.replace(/, +/g, ",").split(",").map(Number));
                modal.setMoney(modal.promotion.price * searchParams.get("item")?.replace(/, +/g, ",").split(",").map(Number).length)
            }else{
                setArrayId(searchParams.get("extendItem")?.replace(/, +/g, ",").split(",").map(Number));
                modal.setMoney(modal.promotion.price * searchParams.get("extendItem")?.replace(/, +/g, ",").split(",").map(Number).length)
            }
            console.log(modal.promotion);
            // цена * кол-во объявлений
        }
    }, [promotion]);


    const contacts = window.location.href.indexOf('_') > -1;

    const payForContacts = () => {
        if(!promotion && !advertiser){
            fetch(`${link}/user/${contacts ? "pay-contact" : "pay-order"}`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({ object_id: modal.orderId, type: !points ? 1 : 2 }),
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        if (contacts) {
                            modal.contactsModal();
                        }
                        if (!contacts) {
                            modal.closeModal();
                            let newNum = modal.counterNewResponse;
                            newNum += 1;
                            modal.setCounterNewResponse(newNum);
                            modal.setNewResponses([...modal.newResponses, modal.orderId]);
                        }
                    }
                    console.log(result.data);
                });
        }else if(advertiser){
            let data = {
                type: !points ? 1 : 2,
                tariff_id: Number(modal.promotion.type),
            }
            if(modal.promotion.id.length > 1){
                data.advertisements = JSON.stringify(modal.promotion.id);
            }else{
                data.advertisement_id = modal.promotion.id[0];
            }

            fetch(`${link}/user/pay-advertisement`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data)

            })
                .then((res) => res.json())
                .then(res => console.log(res.data))
                .then(() => modal.closeModal())
                // .then(() => localStorage.removeItem("ads"))
                .then(() => navigate('/advertiser/ad'))

        }else if(promotion){

            let data = {
                type: !points ? 1 : 2,
                tariff_id: modal.promotion.id,
            }
            arrayId.length > 1 ? data.objects = JSON.stringify(arrayId) : data.object_id = arrayId[0]
            console.log(data)

            fetch(`${link}/user/${modal.promotion.type === 1 ? 'pay-show' : 'pay-fixation'}`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify(data)

            })
                .then((res) => res.json())
                .then(res => console.log(res.data))
                .then(() => modal.closeModal())
                .then(() => localStorage.removeItem("promo"))
                .then(() => navigate('/lc/promotion'))
        }
    };

    return (
        <div className="modal modal_payment">
            <div className="closeModal" onClick={modal.closeModal}>
                <ModalCloseSvg />
            </div>
            {(!promotion && !advertiser) && <h5 className="title">Открыть контакты</h5>}
            {(!promotion && !advertiser )&& <div className="modal_title__sub">по объявлению {cartName}</div>}

            {advertiser && <h5 className="title">Оплатить рекламу</h5>}

            {promotion && <h5 className="title">Продвинуть объявления</h5>}
            {promotion && <div className="modal_title__sub">Кол-во объявлений: {arrayId?.length}</div>}

            {ctx.userData.wallet?.points >= modal.money && (
                <div className={`cabinet__content cabinetInModal ${points !== null ? style.greenActive : ""}`}>
                    <div className={style.span_points}>Использовать для оплаты баллы:</div>
                    <CabinetSocToggle
                        toggle={() => setPoints((prevState) => !prevState)}
                        text={`Списать 
                    ${selector === null ? modal.money : selector}
                     баллов`}
                    />
                </div>
            )}

            <div className={style.price_order}>
                Итог: {points ? "0 ₽" : `${selector === null ? modal.money : selector} ₽`}
            </div>
            {ctx.userData.wallet?.money < modal.money ? (
                <div onClick={!points ? clickToWallet : payForContacts} className={style.price_add_to_wallet}>
                    <>
                        {(points && advertiser) ? "Купить рекламу" : !points ? "Пополнить кошелёк" : 'Открыть контакты'}
                        {/*{!advertiser && 'Пополнить кошелёк' }*/}
                    </>
                </div>
            ) : (
                <div onClick={payForContacts} className={style.price_add_to_wallet}>
                    {!promotion && !advertiser && 'Открыть контакты'}
                    {!promotion && advertiser && 'Купить рекламу'}
                    {promotion && 'Продвинуть объявления'}
                </div>
            )}
        </div>
    );
};

export default MainModalRequest;
