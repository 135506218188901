import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    data: {
        type: 0,
        seek: 0,
        id: 0,
        model_id: 0
    }
};

const notification = createSlice({
    name: "notification",
    initialState,
    reducers: {
        getNotificationData(state, action){
            state.data = action.payload
        },
        resetState(state){
            state.data = {
                type: 0,
                seek: 0,
                id: 0,
                model_id: 0
            }
        }
    }
});

export default notification;
