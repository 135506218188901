import styles from "../../cabinet/subscribe/subscribe.module.sass";
import {NavLink} from "react-router-dom";
import LaunchSettingsSvg from "./svg/LaunchSettingsSvg";

const LaunchBottomEmpty = () => {

  return(
      <>
              <div className={styles.promotion_bottom}>
                  <div>
                          <>
                              <h2 className={styles.promotion_h2}>Запускайте рекламу</h2>
                              <h3 className={styles.promotion_h3}>своих объявлений!</h3>

                              <LaunchSettingsSvg/>

                              <div className={styles.promotion_bottom_p}>
                                  <p className={styles.promotion_main_p}>Рекламируйте свои объявления у нас на сервисе</p>
                                  <p className={styles.promotion_main_p}>и получайте больше заказов</p>
                              </div>

                              <div style={{display: "flex", justifyContent: "center"}} className={`${styles.promotion_btn} ${styles.promotion_bottom_Btn}`}>
                                  <NavLink style={{marginBottom: '100px'}} className={styles.promotion_btn_save} to={`/advertiser/launch/ads`}>Начать</NavLink>
                              </div>
                          </>
                  </div>
              </div>
      </>
  )
}

export default LaunchBottomEmpty;