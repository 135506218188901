export const RatingZero = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.66096 9.74448L4.88891 8.00983L2.11686 9.74446C2.01616 9.80827 1.88626 9.8034 1.78928 9.73301C1.69258 9.66292 1.64823 9.54104 1.67741 9.42516L2.47047 6.25256L-0.00632215 4.1526C-0.0978847 4.07592 -0.133646 3.95118 -0.0967323 3.8376C-0.0598182 3.72374 0.0426036 3.64391 0.161627 3.63561L3.39432 3.40987L4.61712 0.376899C4.70696 0.155454 5.07087 0.155454 5.16071 0.376899L6.3835 3.40987L9.6162 3.63561C9.73522 3.64391 9.83764 3.72374 9.87456 3.8376C9.91147 3.95118 9.87571 4.07592 9.78415 4.1526L7.30733 6.2526L8.1004 9.4252C8.12958 9.54108 8.08524 9.66295 7.98852 9.73305C7.88956 9.80493 7.76003 9.80655 7.66096 9.74448Z"
                fill="#B0A9A9"
            />
        </svg>
    );
};
