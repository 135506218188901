import { useEffect, useState } from "react";

import { ApproveIcon } from "../../../components/icons/ApproveIcon";
import { RejectIcon } from "../../../components/icons/RejectIcon";
import PaginationPattern from "../../../UI/PaginationPattern";
import Loader from "../../../components/loader/Loader";
import getHost, { link } from "../../../store/host-store";
import { token } from "../../../App";
import moment from "moment";

const MoneyTab = ({ userId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        fetch(
            `${link}/bill/moderator-index?&filter[user_id]=${userId}&pagination[pageSize]=5&page=${page}&sort=-date`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                setLoading(false);
                if (result.data.success) {
                    setData(result.data.data);
                    setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                }
            });
    }, [page, rerender, userId]);

    const onApprove = (id, amount) => {
        const data = {
            amount: amount,
        };

        fetch(
            getHost({
                controller: "bill",
                action: "execute-bill",
                id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                method: "PATCH",
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setRerender((prev) => !prev);
                }
            });
    };

    const onDelete = (id) => {
        fetch(
            getHost({
                controller: "bill",
                action: "reject-bill",
                id,
            }),
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                method: "PATCH",
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setRerender((prev) => !prev);
                }
            });
    };

    return (
        <div className="admin_one-user_objects_tabs">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="admin__list-titles admin__list-titles_money">
                        <p>Номер счёта</p>
                        <p>Название счёта</p>
                        <p>Сумма</p>
                        <p>Статус</p>
                    </div>
                    <div className="admin_objects_wrapper">
                        {data.length > 0 ? (
                            data.map((el) => (
                                <div className="admin__objects_object_money" key={el.id}>
                                    <span>#{el.id}</span>
                                    <p>
                                        {el.appointment} №{el.id} от {el.date}
                                    </p>
                                    <p>{el.amount}</p>
                                    {el.bill_status === 1 ? (
                                        <p className="admin__done">Подтверждено</p>
                                    ) : el.bill_status === 2 ? (
                                        <p className="admin__rejected">Отклонено</p>
                                    ) : (
                                        <p className="admin__waiting">Ожидает</p>
                                    )}
                                    {el.bill_status === 3 && (
                                        <div className="admin__objects_object_money_btns">
                                            <button
                                                type="button"
                                                className="approve"
                                                title="одобрить"
                                                onClick={() => onApprove(el.id, el.amount)}>
                                                <ApproveIcon />
                                            </button>
                                            <button
                                                title="отклонить"
                                                type="button"
                                                className="reject"
                                                onClick={() => onDelete(el.id)}>
                                                <RejectIcon />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <>Нет счетов</>
                        )}
                        <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page} />
                    </div>
                </>
            )}
        </div>
    );
};

export default MoneyTab;
