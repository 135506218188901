import styles from '../subscribe.module.sass'
import {useEffect, useState} from "react";
import {link, url} from "../../../store/host-store";
import {token} from "../../../App";
import Loader from "../../../components/loader/Loader";


const SubscribeSubCategory = ({type, setSubcategoryId, subCategoryId, setEquipment}) => {
    const [subscribeSubCategories, setSubscribeSubCategories] = useState([]);
    const [checked, setChecked] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`${link}/category?&filter[depth]=1&filter[type]=${type}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result)
                if (result.data) {
                    console.log(result.data)
                    setSubscribeSubCategories(result.data)
                    setIsLoading(false)
                }
            });
    }, [type, subCategoryId]);

  return(
      <>
      {isLoading ? <Loader /> :
      <div className={styles.sub_category}>
          {subscribeSubCategories.map((item) => (
              <label className={styles.label_sub} key={item.id}>
                  <h3 className={`${styles.h3} ${item.id === checked ? styles.active_radio : ''}`}>{
                      item.title.length > 25 ? item.title.slice(0, 25) + '..' : item.title
                  }</h3>
                  <img className={styles.sub_image} src={url + item.image} alt=""/>
                  <input key={subCategoryId} type="radio" name='sub_category' className={styles.input} defaultChecked={subCategoryId === item.id} onChange={() => {
                      setChecked(item.id)
                      setSubcategoryId(item.id)
                      setEquipment(item.equipment)
                  }}/>
                      <span className={styles.checkmark}></span>
              </label>
          ))}
      </div>
      }
      </>
  )
}

export default SubscribeSubCategory;