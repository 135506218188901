import {useContext, useEffect} from "react";
import DeviceContext from "../store/device-context";
import {isAppstore} from "../App";
import Header from "../moderator/header/Header";

import Footer from "../components/footer/Footer";
import MobilePage from "./main/MobilePage";
import DesktopPage from "./main/DesktopPage";

const AdModeratorLayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const device = useContext(DeviceContext);
    localStorage.setItem('firstVisit', '1')
  return(
      <div className={isAppstore ? "copyBan" : undefined}>
        <Header />

        <main className="main">{device.isMobile ? <MobilePage /> : <DesktopPage />}</main>

        <Footer />
      </div>
  )
}

export default AdModeratorLayout;