import s from "../../moderator/main/style.module.sass";
import ModeratorCard from "../../moderator/main/moderInfo/ModeratorCard";
import AdsPageModeration from "./AdsPageModeration";
import {useContext} from "react";
import AdModeratorContext from "../../store/ad-moderator-context";
import CompanyModeration from "./CompanyModeration";


const DesktopPage = () => {
    const ctx = useContext(AdModeratorContext);

  return(
      <div className="container">
          <div className={s.wrapper}>
              <ModeratorCard />
              <div className={s.grid_container}>
                  {ctx.randomFetch === 'advertisement' && <AdsPageModeration />}
                  {ctx.randomFetch === 'organization' && <CompanyModeration />}
              </div>
          </div>
      </div>
  )
}

export default DesktopPage;