import React, {useEffect, useState} from "react";
import getHost from "./host-store";
import {token} from "../App";

const AdModeratorContext = React.createContext({
    setOpenMenu: () => {},
    currentObj: {},
    objectsAmount: 0,
    openMenu: false,
    setRerender: () => {},
    randomFetch: '',
    errorOrganization: '',
    setIsCorrectAdv: () => {},
    incorrectClick: () => {},
    correctClick: () => {},
    onPublicObject: () => {},
    onDelete: false,
    setOnDelete: () => {},
    loading: true,
    isCorrectAdv: { img: false, descr: false, link: false },
    isCorrectOrganization: {data: false},
})

export const AdModeratorContextProvide = (props) => {
    const [currentObj, setCurrentObj] = useState({});
    const [objectsAmount, setObjectsAmount] = useState(0);
    const [errorOrganization, setErrorOrganization] = useState(false)
    const [openMenu, setOpenMenu] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [loading, setLoading] = useState(true);
    const [randomFetch, setRandomFetch] = useState("");
    const [onFetch, setOnFetch] = useState(false);
    const [isCorrectOrganization, setIsCorrectOrganization] = useState({data: false});
    const [isCorrectAdv, setIsCorrectAdv] = useState({
        img: false,
        descr: false,
        link: false,
    });
    const [onDelete, setOnDelete] = useState(false);

    const incorrectClick = () => {
        if(randomFetch === 'advertisement'){
            fetch(
                getHost({
                    controller: "advertisement",
                    action: "reject",
                    id: currentObj.id,
                }),
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        console.log(result.data)
                        localStorage.removeItem("moderation_ad");
                        setRerender((prev) => !prev);
                    }
                    if(result.status == 404){
                        setOnDelete(true)
                    }
                })
                .catch((e) => console.log(e));
        }else if(randomFetch === 'organization'){
            fetch(
                getHost({
                    controller: "organization",
                    action: "reject",
                    id: currentObj.id,
                }),
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    console.log(result.data)
                    if (result.success) {
                        console.log(result.data)
                        setErrorOrganization(false)
                        localStorage.removeItem("moderation_ad");
                        setRerender((prev) => !prev);
                    }
                })
                .catch((e) => console.log(e));
        }
    }

    const correctClick = (field) => {
        if (field === "img") {
            setIsCorrectAdv({ ...isCorrectAdv, img: true });
        } else if (field === "descr") {
            setIsCorrectAdv({ ...isCorrectAdv, descr: true });
        }else if (field === "link"){
            setIsCorrectAdv({ ...isCorrectAdv, link: true });
        }else if (field === "data"){
            setIsCorrectOrganization({data: true})
        }
    }

    const onPublicObject = () => {
        if(randomFetch === 'advertisement') {
            setLoading(true)
            fetch(
                getHost({
                    controller: "advertisement",
                    action: "approve",
                    id: currentObj.id,
                }),
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        console.log(result.data)
                        localStorage.removeItem("moderation_ad");
                        setRerender((prev) => !prev);
                    }
                    if(result.status == 404){
                        setOnDelete(true)
                    }
                }).then(() => setLoading(false))
                .catch((e) => console.log(e));
        }else if(randomFetch === 'organization'){
            setLoading(true)
            fetch(
                getHost({
                    controller: "organization",
                    action: "approve",
                    id: currentObj.id,
                }),
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    console.log(result.data)
                    if (result.success) {
                        console.log(result.data)
                        localStorage.removeItem("moderation_ad");
                        setRerender((prev) => !prev);
                    }
                    if(result.data.status === 400){
                        setErrorOrganization(true)
                    }
                }).then(() => setLoading(false))
                .catch((e) => console.log(e));
        }
    }

    useEffect(() => {
        setLoading(true);
        setCurrentObj({});

        const lastFetch = localStorage.getItem("moderation_ad");
        setOnFetch((prev) => !prev);

        if (lastFetch) {
            setRandomFetch(lastFetch);
        } else {
            getRandomFetch();
        }
    }, [rerender]);

    function getRandomFetch() {
        const objects = ["organization", "advertisement"];
        setRandomFetch(objects[Math.floor(Math.random() * objects.length)]);
    }

    useEffect(() => {
        setLoading(true);
        localStorage.setItem("moderation_ad", randomFetch);
        setIsCorrectAdv({ img: false, descr: false, link: false });
        setIsCorrectOrganization({data: false})
        if(randomFetch === 'organization'){
            fetch(
                getHost({
                    controller: "organization",
                    action: "for-moderation",
                }),
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    window.scrollTo(0, 0);
                    if (result.success) {
                        console.log(result.data)
                        if (result.data.object === null) {
                            localStorage.removeItem("moderation_ad");
                            setRerender((prev) => !prev);
                        } else {
                            setLoading(false);
                            setObjectsAmount(result.data.objectsAmount);
                            setCurrentObj(result.data.object);
                        }
                    } else if (result.status === 404) {
                        setLoading(false);
                        setObjectsAmount(0);
                        setCurrentObj({});
                    }
                })
                .catch((e) => console.log(e));
        }else if(randomFetch === 'advertisement'){
            fetch(
                getHost({
                    controller: "advertisement",
                    action: "for-moderation",
                }),
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    window.scrollTo(0, 0);
                    console.log(result.data)
                    if (result.success) {
                        if (result.data.object === null) {
                            localStorage.removeItem("moderation_ad");
                            setRerender((prev) => !prev);
                        } else {
                            setLoading(false);
                            setObjectsAmount(result.data.objectsAmount);
                            setCurrentObj(result.data.object);
                        }
                    } else if (result.status === 404) {
                        setLoading(false);
                        setObjectsAmount(0);
                        setCurrentObj({});
                    }
                })
                .catch((e) => console.log(e));
        }

    }, [randomFetch, onFetch])

    return(
        <AdModeratorContext.Provider value={{
            setOpenMenu,
            openMenu,
            setRerender,
            randomFetch,
            setIsCorrectAdv,
            isCorrectAdv,
            loading,
            currentObj,
            objectsAmount,
            incorrectClick,
            correctClick,
            onPublicObject,
            errorOrganization,
            isCorrectOrganization,
            onDelete,
            setOnDelete
        }}>
            {props.children}
        </AdModeratorContext.Provider>
    )
}

export default AdModeratorContext;