import { DatePicker } from 'react-nice-dates'
import {ru} from "date-fns/locale";
import moment from "moment/moment";
import {useEffect} from "react";

const EditSearchTime = ({register, date, setDate, errors, ad, reset}) => {
    const modifiers = {
        disabled: ((date) => date > new Date(new Date().setDate(new Date().getDate() + 90))),
        highlight: (date => date < new Date(new Date().setDate(new Date().getDate() - 1))) // Highlights Tuesdays
    }

    const modifiersClassNames = {
        disabled: '-disabled',
    }

    useEffect(() => {
        setDate(new Date(moment(ad?.final_date).format('YYYY-MM-DD')));
        reset(ad);
    },[ad])

    return(
        <div className="create_ads_m_block create_ads_border create_ads_calendar">
            <div className="advers___title">Время:</div>

            <div className="create_search_time_flex">
                <div className="create_search_title">Срок выполнения до:</div>
            <DatePicker
                date={date}
                onDateChange={setDate}
                modifiers={modifiers}
                modifiersClassNames={modifiersClassNames}
                locale={ru}>
                {({ inputProps, focused }) => (
                    <input
                        className={'input' + (focused ? ' -focused' : '')}
                        {...inputProps}
                        placeholder={date}
                    />
                )}
            </DatePicker>

                <div className="create_search_ads_time">
                    <input defaultValue={moment(ad?.final_date).format('HH:MM')} type="time" {...register('time')}/>
                </div>

            </div>
            <p className="error">{errors.finalDate?.message}</p>
        </div>
    )
}

export default EditSearchTime;