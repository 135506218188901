import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    navLink: '',
    blockPage: false
}

const blockerPage = createSlice({
    name: 'blockerPage',
    initialState,
    reducers: {
        getUrl(state, action){
            state.navLink = action.payload;
        },
        blockTrue(state){
            state.blockPage = true
        },
        blockFalse(state){
            state.blockPage = false
        },
    }
})

export default blockerPage;