import s from "./style.module.sass";

const FormModal = ({ title, text, button, fn, isOpenModal, children }) => {
    return (
        <div className={s.wrapper}>
            <span className={s.overlay_close} onClick={() => isOpenModal(false)}></span>
            <form className={s.modal} onSubmit={fn}>
                <h3 className={s.title}>{title}</h3>
                {text && <p>{text}</p>}
                {children}
                <button type="submit" className="admin__btn-blue">
                    {button}
                </button>
            </form>
        </div>
    );
};

export default FormModal;
