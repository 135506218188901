import {createApi} from "@reduxjs/toolkit/query/react";
import {fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {link} from "../../host-store";
import {token} from "../../../App";

export const billsQuery = createApi({
    reducerPath: 'billsQuery',
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ["bills"],
    endpoints: (builder) => ({
        closingDocs: builder.query({
            query: ({startDate, endDate}) => ({
                url: `/bill/closing-docs?startDate=${startDate}&endDate=${endDate}`,
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            }),
            providesTags: ["bills"],
        })
    })
})

export const {useLazyClosingDocsQuery} = billsQuery