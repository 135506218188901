import { useState } from "react";

import PaginationPattern from "../../../UI/PaginationPattern";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import ResponseStatuses from "./ResponseStatuses";
import { link } from "../../../store/host-store";
import ResponseItem from "./ResponseItem";
import style from "../style.module.sass";
import { useContext } from "react";
import { token } from "../../../App";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const ResponsesList = ({ type }) => {
    const ctx = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [openSettings, setOpenSettings] = useState(-1); // айди объявления, настройки которого открыты
    const [archiveCount, setArchiveCount] = useState(0);
    const [activeCount, setActiveCount] = useState(0);
    const [responses, setResponses] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1); // Страница пагинации
    const [status, setStatus] = useState(0); // 0-active, 1-archive
    const PAGINATION = `&pagination[pageSize]=2&page=${page}`;

    const seekType = useSelector((state) => state.adsSearchLogic.statusAds);

    useEffect(() => {
        window.scrollTo(0, 0);

        //получили количество откликов в статусах
        fetch(`${link}/category/confirmed-order-client-status-list-by-type?type=${type}&seek=${seekType}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setActiveCount(result.data[0].amountOfActive);
                    setArchiveCount(result.data[0].amountOfArchive);
                } else {
                    if (result.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
            })
            .catch((error) => console.log(error));
    }, [type, seekType]);

    useEffect(() => {
        setLoading(true);
        setResponses([]);
        if (status === 0) {
            getResponses();
        }
        if (status === 1) {
            getArchive();
        }
    }, [page, type, status]);

    useEffect(() => {
        setPage(1);
    }, [type]);

    const getResponses = () => {
        fetch(
            `${link}/order?expand=object, object.city, user, bookings, object.user&filter[order.confirmed]=1&filter[object.seek]=${seekType}&filter[order.user_id]=${ctx.isUserId}&filter[type]=${type}${PAGINATION}`,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                setLoading(false);
                console.log(result)
                if (result.data.success) {
                    setResponses(result.data.data);
                    setPageCount(parseInt(result.headers.get("X-Pagination-Page-Count")));
                }
            })
            .catch((e) => console.log(e));

        //при смене табов закрыть мини-окно с настройками
        setOpenSettings(-1);
    };

    const getArchive = () => {
        fetch(
            `${link}/order/archive?filter[object.type]=${type}&filter[order.user_id]=${ctx.isUserId}&expand=object, object.user, user, bookings, object.city&filter[object.seek]=${seekType}${PAGINATION}`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((result) => {
                setLoading(false);
                if (result.data.success) {
                    setResponses(result.data.data);
                    setPageCount(parseInt(result.headers.get("X-Pagination-Page-Count")));
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <>
            <ResponseStatuses
                status={status}
                setStatus={setStatus}
                activeCount={activeCount}
                archiveCount={archiveCount}
                setPage={setPage}
            />

            <div className={style.response__list}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <ResponseItem
                            status={status}
                            responses={responses}
                            openSettings={openSettings}
                            setOpenSettings={setOpenSettings}
                        />
                        <PaginationPattern setChange={(e, value) => setPage(value)} pageCount={pageCount} page={page} />
                    </>
                )}
            </div>
        </>
    );
};

export default ResponsesList;
