import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import PaginationPattern from "../../../UI/PaginationPattern";
import FormModal from "../../../UI/Modal/FormModal/FormModal";
import getHost, { link } from "../../../store/host-store";
import Loader from "../../../components/loader/Loader";
import AuthContext from "../../../store/auth-context";
import { token } from "../../../App";
import s from "../stopWords/style.module.sass";
import CitySelects from "../../../cabinet/advertisement/create/CitySelects";
import {EditIcon} from "../../../components/icons/EditIcon";

const CitiesAdmin = () => {
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации
    const [getRegion, setRegion] = useState({});
    const [dataCities, setdataCities] = useState([]);
    const [page, setPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [defaultCity, setDefaultCity] = useState('');

    //необходимо для передачи параметра страницы в url
    const navigate = useNavigate();
    //получаем параметры текущей страницы
    const params = new URLSearchParams(window.location.search);

    const ctx = useContext(AuthContext);

    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [input, setInput] = useState("");
    const [inputNameCity, setInputNameCity] = useState("");

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть, то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            //иначе присваиваем стартовое значение 1
            setPage(1);
        }
    }, [params]);

    const regionChange = (value) => {
        setRegion(value)
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        if (page !== null) {
            let fetchLink = `${link}/city?filter[region_id]=${getRegion.value}&pagination[pageSize]=50&page=${page}`;

            fetch(fetchLink, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json().then((data) => ({ data, headers: res.headers })))

                .then((result) => {
                    setLoading(false);
                    if (result.data.success) {
                        console.log(result.data.data)
                        setdataCities(result.data.data);
                        setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [page, ctx.rerender, getRegion]);

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    const onDelete = (id) => {
        fetch(`${link}/city/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
            method: "DELETE",
        })
            .then((res) => res.json())

            .then((result) => {
                if (result.success) {
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };

    const onEdit = (e) => {
        e.preventDefault();
        const data = {
            name: inputNameCity,
            region_id: getRegion.value
        }
        fetch(`${link}/city/${openModalEdit}`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                method: "PUT",
                body: JSON.stringify(data),
            }
        ).then(res => res.json())
            .then(res => {
                if(res.success){
                    ctx.setRerender(prev => !prev)
                    setOpenModalEdit(false)
                }
                console.log(res.data)
            }
            )
    }

    const createWord = (e) => {
        e.preventDefault();
        const data = {
            name: input,
            region_id: getRegion.value
        };

        fetch(`${link}/city`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(data),
            }
        )
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    setOpenModal(false);
                    setInput("");
                    ctx.setRerender((prev) => !prev);
                }
            })
            .catch((e) => console.log(e));
    };


    return (
        <div className="admin__layout">
            {openModal && (
                <FormModal
                    title="Добавить новый город"
                    button="Добавить"
                    fn={createWord}
                    isOpenModal={setOpenModal}
                >
                    {<input value={input} onChange={(e) => setInput(e.target.value)} />}
                </FormModal>
            )}
            {openModalEdit && (
                <FormModal
                    title="Редактировать город"
                    button="Редактировать"
                    fn={onEdit}
                    isOpenModal={setOpenModalEdit}
                >
                    {<input value={inputNameCity} onChange={(e) => setInputNameCity(e.target.value)} />}
                </FormModal>
            )}
            <h3>Города</h3>
            <div className={s.top}>
                <div className="admin__search-wrapper">
                    <p>Выберите область:</p>
                    <CitySelects getCity={regionChange}/>
                </div>
                <button type="button" className="admin__btn-blue" onClick={() => setOpenModal(true)}>
                    Добавить город
                </button>
            </div>

            {loading ? (
                <Loader />
            ) : dataCities.length > 0 ? (
                <>
                    {dataCities.map((el) => {
                        return (
                            <div className={s.word} key={el.id}>
                                <p>{el.name}</p>
                                <div className={s.flex}>
                                    <button type='button'
                                            className={s.delete}
                                            onClick={() => {
                                                setOpenModalEdit(el.id)
                                                setInputNameCity(el.name)
                                            }}
                                    >
                                        <EditIcon />
                                    </button>
                                <button
                                    title="удалить"
                                    type="button"
                                    className={s.delete}
                                    onClick={() => onDelete(el.id)}>
                                    <DeleteIcon />
                                </button>
                                </div>
                            </div>
                        );
                    })}
                    <PaginationPattern
                        setChange={(e, value) => setChange(value)}
                        pageCount={pageCount}
                        page={page}
                    />
                </>
            ) : (
                <>Пусто</>
            )}
        </div>
    );
};

export default CitiesAdmin;
