import styles from '../subscribe.module.sass'
import SubscribeFirstSvg from "../svg/SubscribeFirstSvg";
import SubscribeSecondSvg from "../svg/SubscribeSecondSvg";
import {NavLink} from "react-router-dom";

const SubscribeTop = () => {
  return(
      <div className={styles.promotion_top}>
          <div className={styles.promotion_left}>
              <div className={styles.promotion_left_block}>
                  <SubscribeFirstSvg />
                  <span className={styles.promotion_number}>01</span>
                  <p className={`${styles.promotion_top_p} ${styles.prom_top_p}`}>
                      Выберите <br/> интересующие <br/> Вас категории
                  </p>
              </div>
              <div className={styles.promotion_left_block}>
                  <SubscribeSecondSvg />
                  <span className={styles.promotion_number}>02</span>
                  <p className={styles.promotion_top_p}>
                      Получайте заявки <br/>
                      на указанные Вами <br/> категории
                  </p>
              </div>
          </div>

            <div className={styles.promotion_btn}>
                <NavLink className={styles.promotion_btn_save} to={`/lc/subscribe/create`}>Создать подписку</NavLink>
            </div>
      </div>
  )
}

export default SubscribeTop;