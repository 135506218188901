import {useContext} from "react";
import AdModeratorContext from "../../store/ad-moderator-context";
import s from "../../moderator/main/object/components/style.module.sass";
import Loader from "../../components/loader/Loader";
import ImageAdv from "../../moderator/main/object/components/ImageAdv";
import DescriptionAds from "./components/DescriptionAds";
import LinkAds from "./components/LinkAds";

const AdObject = () => {
    const ctx = useContext(AdModeratorContext)

    return ctx.loading ? (
        <div className={s.cards_wrapper}>
            <Loader/>
        </div>
    ) : Object.keys(ctx.currentObj).length > 0 ? (
        <>
            <div className={s.cards_wrapper}>
                <ImageAdv
                    data={ctx.currentObj}
                    incorrectClick={ctx.incorrectClick}
                    correctClick={ctx.correctClick}
                    isActive={ctx.isCorrectAdv.img}
                />
                <DescriptionAds
                    object={ctx.currentObj}
                    incorrectClick={ctx.incorrectClick}
                    correctClick={ctx.correctClick}
                    isActive={ctx.isCorrectAdv.descr}
                />
                <LinkAds
                    object={ctx.currentObj}
                    incorrectClick={ctx.incorrectClick}
                    correctClick={ctx.correctClick}
                    isActive={ctx.isCorrectAdv.link}
                />
            </div>
        </>
    ) : (
        <div className={s.cards_wrapper_empty}>Нет реклам для модерации</div>
    );
}

export default AdObject;