import {PartnerPaymentBlock} from "./payment/PartnerPaymentBlock";
import {mockData} from "./payment/mockData";
import img4 from "./images/payment/4.jpg";

export const PartnerProgramPayment = () => {
  return(
      <div className="partner_program_payment">
        <h2 className="partner_program_h2">Используйте новую функцию, чтобы <br/> зарабатывать еще больше</h2>
        <p className="partner_program_payment_p">
          Мы заинтересованы в работе с лучшими специалистами в своем деле. Если вы знаете таких – приглашайте их в нашу команду. Как это сделать:
        </p>

        <div className="partner_program_payment_flex">
          {mockData.map(item => (
            <PartnerPaymentBlock key={item.id} number={item.id} dots={item.dots} title={item.title} description={item.description} img={item.img}/>
          ))}

          <div className="partner_program_block_sub">
            <img src={img4} alt=""/>

            <div className="partner_program_block_blue">
              <p>* Условия партнерской программы, Вы можете подробнее изучить в публичной оферте.</p>
            </div>
          </div>
        </div>
      </div>
  )
}