import {useGetAllDataWalletQuery} from "../../../../store/redux/queries/partnerProgram/partner-program-admin-query";
import Loader from "../../../../components/loader/Loader";

export const PartnerProgramWallet = () => {
    const {data, isLoading} = useGetAllDataWalletQuery()
    return(
        <>
            {isLoading ? <Loader/>
                :
                <div className="partner_program_statistic_money_block">
                    <div className="partner_program_block_moneyPoints">
                        <div className="partner_program_block_title_moneyPoints">Денег на кошельках:</div>
                        <div className="partner_program_block_data">{data.data.money}</div>
                    </div>
                    <div className="partner_program_block_moneyPoints">
                        <div className="partner_program_block_title_moneyPoints">Бонусов на кошельках:</div>
                        <div className="partner_program_block_data">{data.data.points}</div>
                    </div>
                    <div className="partner_program_block_moneyPoints">
                        <div className="partner_program_block_title_moneyPoints">Средств на вывод по партнёрской программе:</div>
                        <div className="partner_program_block_data">{data.data.affiliate_money}</div>
                    </div>
                </div>
            }
        </>
    )
}