import { useSelector } from "react-redux";
import style from "../style.module.sass";
import OfferResponse from "./ItemsTypes/OfferResponse";
import SeekResponse from "./ItemsTypes/SeekResponse";

const ResponseItem = ({ responses, openSettings, setOpenSettings, status }) => {
    const toggleSettings = (id) => {
        if (openSettings === -1) {
            setOpenSettings(id);
        } else {
            setOpenSettings(-1);
        }
    };

    const seekType = useSelector((state) => state.adsSearchLogic.statusAds);

    return (
        <>
            {responses.map((item) => {
                return (
                    <div key={item.id} className={style.response__item}>
                        {status === 0 ? (
                            //активные карточки
                            seekType ? (
                                <SeekResponse item={item} openSettings={openSettings} toggleSettings={toggleSettings} />
                            ) : (
                                <OfferResponse
                                    item={item}
                                    openSettings={openSettings}
                                    toggleSettings={toggleSettings}
                                />
                            )
                        ) : //архив
                        seekType ? (
                            <SeekResponse
                                item={item}
                                isArchive
                                openSettings={openSettings}
                                toggleSettings={toggleSettings}
                            />
                        ) : (
                            <OfferResponse
                                item={item}
                                isArchive
                                openSettings={openSettings}
                                toggleSettings={toggleSettings}
                            />
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default ResponseItem;
