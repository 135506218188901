export const TabOffer = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_71_4037)">
                <path
                    d="M4.69577 5.82844C5.44472 5.18348 6.56205 5.1885 7.30413 5.82844H10.9463C11.0698 5.82844 11.1905 5.84133 11.307 5.86594C11.3021 4.58695 10.8366 3.35438 9.99522 2.39273C9.7421 2.10352 9.46062 1.84477 9.15616 1.6193L8.92671 3.46805C8.90327 3.645 8.75233 3.77344 8.57866 3.77344C8.36451 3.77344 8.31601 3.58514 8.34381 3.3757L8.70006 1.2C8.42748 1.05422 7.79444 0.846094 7.50006 0.75C7.50508 0.82582 7.50006 0.758391 7.50006 3.06024C7.50006 3.97055 6.91014 4.5 6.00006 4.5C5.08998 4.5 4.50006 3.97055 4.50006 3.06024C4.50006 0.754406 4.49507 0.825328 4.50006 0.75C4.20569 0.846094 3.57264 1.05422 3.30006 1.2L3.60006 3.3757C3.62561 3.56813 3.66054 3.74484 3.46812 3.77039C3.2768 3.79606 3.09905 3.66148 3.07343 3.46805L2.84397 1.6193C2.53952 1.84477 2.25804 2.10352 2.00491 2.39273C1.16351 3.35438 0.698037 4.58695 0.693115 5.86594C0.8096 5.84133 0.930303 5.82844 1.05382 5.82844H4.69577Z"
                    fill="white"
                />
                <path
                    d="M10.9462 6.23145H7.5C7.74788 6.78112 7.79295 7.43756 7.5 7.98926H9.83864C9.71813 9.66785 8.31405 10.9968 6.6053 10.9968H5.3947C3.68595 10.9968 2.28188 9.66783 2.16136 7.98926H4.5C4.20736 7.43859 4.25187 6.78166 4.5 6.23145H1.05375C0.472734 6.23145 0 6.70418 0 7.2852V7.6377C0 7.83176 0.1575 7.98926 0.351562 7.98926H1.45685C1.57856 10.0558 3.29813 11.6999 5.3947 11.6999H6.6053C8.70187 11.6999 10.4214 10.0558 10.5431 7.98926H11.6484C11.8425 7.98926 12 7.83176 12 7.6377V7.2852C12 6.70418 11.5273 6.23145 10.9462 6.23145Z"
                    fill="white"
                />
                <path
                    d="M6.20647 8.33727C6.91605 8.22224 7.39802 7.55377 7.28299 6.8442C7.16797 6.13462 6.4995 5.65265 5.78992 5.76768C5.08035 5.8827 4.59837 6.55117 4.7134 7.26075C4.82843 7.97032 5.4969 8.4523 6.20647 8.33727Z"
                    fill="white"
                />
                <path
                    d="M6.00004 4.00781C6.52338 4.00781 6.9476 3.58357 6.9476 3.06026V0.947555C6.9476 0.424242 6.52338 0 6.00004 0C5.47673 0 5.05249 0.424242 5.05249 0.947555V3.06026C5.05249 3.58357 5.47673 4.00781 6.00004 4.00781Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_71_4037">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
