export const PartnerProgramVideoBlock = ({title, src, link, descr}) => {
  return(
      <div className="program_video_block">
          <div className="program_video_title">{title}</div>
          <div className="program_video_referral">
              <span className="program_video_img">
                    <img src={src} alt=""/>
              </span>

              <span className="program_video_link">
                    {link}
              </span>
          </div>
          <p className="program_video_descr">{descr}</p>
      </div>
  )
}