export const ArrowLeftIcon = () => {
    return (
        <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.74156 0.251051C4.39698 -0.0836838 3.83831 -0.0836838 3.49373 0.251051L0.258435 3.39391C-0.0861448 3.72864 -0.0861448 4.27136 0.258435 4.60609L3.49373 7.74895C3.83831 8.08368 4.39698 8.08368 4.74156 7.74895C5.08615 7.41421 5.08615 6.8715 4.74156 6.53676L2.13019 4L4.74156 1.46323C5.08615 1.1285 5.08615 0.585786 4.74156 0.251051Z"
                fill="white"
            />
        </svg>
    );
};
