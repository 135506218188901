import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchInput from "../../../UI/SearchInput/SearchInput";
import PaginationPattern from "../../../UI/PaginationPattern";
import Loader from "../../../components/loader/Loader";
import { link } from "../../../store/host-store";
import ObjectsList from "./ObjectsList";
import { token } from "../../../App";

const Objects = () => {
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации
    const [dataObjects, setDataObjects] = useState([]);
    const [page, setPage] = useState(null);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    //необходимо для передачи параметра страницы в url
    const navigate = useNavigate();
    //получаем параметры текущей страницы
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть, то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            //иначе присваиваем стартовое значение 1
            setPage(1);
        }
    }, [params]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (page !== null) {
            let fetchLink = `${link}/object/all?expand=user, city&pagination[pageSize]=100&page=${page}&sort=-id`;

            //если что-то вбито в поиске
            if (search.length > 0) {
                fetchLink += `&ObjectsSearch[query]=${search}`;
            }

            fetch(fetchLink, {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            })
                .then((res) => res.json().then((data) => ({ data, headers: res.headers })))

                .then((result) => {
                    setLoading(false);
                    if (result.data.success) {
                        console.log(result.data)
                        setDataObjects(result.data.data);
                        setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    }
                })
                .catch((e) => console.log(e));
        }
    }, [page, search]);

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    return (
        <div className="admin__layout">
            <h3>Объявления</h3>
            <div className="admin__search-wrapper">
                <SearchInput setSearch={setSearch} />
            </div>
            {loading ? (
                <Loader />
            ) : dataObjects.length > 0 ? (
                <>
                    <ObjectsList dataObjects={dataObjects} />
                    {pageCount > 1 && (
                        <PaginationPattern
                            setChange={(e, value) => setChange(value)}
                            pageCount={pageCount}
                            page={page}
                        />
                    )}
                </>
            ) : (
                <>Пусто</>
            )}
        </div>
    );
};

export default Objects;
