import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import noAvatar from "../../../../assets/images/stubs/avatar.svg"
import getHost, { link, url } from "../../../../store/host-store";
import PaginationPattern from "../../../../UI/PaginationPattern";
import { EyeIcon } from "../../../../components/icons/EyeIcon";
import Loader from "../../../../components/loader/Loader";
import DateRangePickerExample from "../CalendarFromTo";
import { token } from "../../../../App";
import {useDispatch, useSelector} from "react-redux";
import {moderatorsAction} from "../../../../store/redux";

const StatisticRegistrator = () => {
    const adModeratorPage = window.location.href.indexOf('/ad_statistic') > -1;
    const moderator = useSelector(state => state.moderators.whichModerator);
    const dispatch = useDispatch();
    const [page, setPage] = useState(null);
    const [dataUsers, setDataUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0); //количество страниц с бэка для пагинации
    const [loading, setLoading] = useState(true);

    //необходимо для передачи параметра страницы в url
    const navigate = useNavigate();
    //получаем параметры текущей страницы
    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть (!null) то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            setPage(1);
        }
    }, [params]);

    useEffect(() => {
        if(adModeratorPage){
            dispatch(moderatorsAction.changeModerator("ad_moderator"))
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);

        if (page !== null) {
            fetch(
                getHost({
                    controller: "account",
                    expand: "user",
                    filter: {
                        role: 'registrar',
                    },
                    pagination: {
                        pageSize: 30,
                        page,
                    },
                }),
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                    },
                }
            )
                .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
                .then((result) => {
                    setLoading(false);
                    if (result.data.success) {
                        setDataUsers(result.data.data);
                        setPageCount(Number(result.headers.get("X-Pagination-Page-Count")));
                    }
                });
        }
    }, [page, moderator]);

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    //поиск календарь
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getStatistic = () => {
        fetch(
            `${link}/user/registrar-statistics-uploading?dateStart=${moment(startDate).format("YYYY-MM-DD")}&dateEnd=${moment(
                endDate
            ).format("YYYY-MM-DD")}`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json())
            .then((result) => {
                window.open(result.data.link);
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className="admin__layout admin__statistic">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="admin__statistic_top">
                        <h3>Статистика работы модераторов</h3>
                        <div className="admin__statistic_navigation_calendar">
                            <DateRangePickerExample
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                            <button type="button" className="admin__btn-blue" onClick={getStatistic}>
                                Выгрузка по дате
                            </button>
                        </div>
                    </div>
                    {dataUsers.length > 0 ? (
                        <>
                            <div className="admin__list-titles admin__list-titles_statistic">
                                <p>ID</p>
                                <p>Фото</p>
                                <p>Имя</p>
                                <p>E-mail</p>
                                <p>Объявлений создано</p>
                            </div>
                            <div>
                                {dataUsers.map((el) => (
                                    <div className="admin__content-card admin__content-card_statistic" key={el.id}>
                                        <span>#{el.user.id}</span>
                                        {el.user.avatar === null ? (
                                            <img src={noAvatar} alt="avatar" />
                                        ) : el.user?.webpFilename ? (
                                            <img src={url + el.user.webpFilename} alt="avatar" />
                                        ) : (
                                            <img src={url + el.user.avatar} alt="avatar" />
                                        )}
                                        <p>{el.user.name}</p>
                                        <p>{el.user.email}</p>
                                        <p>{el.user.statistics?.objectsCreatedTotal}</p>
                                        <div className="admin__content-card-icons">
                                            <NavLink to={`${el.user.id}`}>
                                                <EyeIcon />
                                            </NavLink>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {pageCount > 1 && (
                                <PaginationPattern
                                    setChange={(e, value) => setChange(value)}
                                    pageCount={pageCount}
                                    page={page}
                                />
                            )}
                        </>
                    ) : (
                        <>Нет пользователей с ролью "модератор"</>
                    )}
                </>
            )}
        </div>
    );
};

export default StatisticRegistrator;
