import { useContext, useEffect, useState } from "react";
import "moment/locale/ru"
import object from "../../../assets/images/stubs/object-mob.png";
import AuthContext from "../../../store/auth-context";
import {frontLink, link, url} from "../../../store/host-store";
import {cityAlias, token} from "../../../App";
import "../../cabinet.sass";
import { useDispatch, useSelector } from "react-redux";
import {calendarAction, checkboxAction, promoAction, statusAction} from "../../../store/redux";
import ErrorSvg from "../../Svg/ErrorSvg";
import Loader from "../../../components/loader/Loader";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ModalsContext from "../../../store/modals-context";
import PaginationPattern from "../../../UI/PaginationPattern";
import AdsBottom from "./AdsBottom";
import AdsButtons from "./AdsButtons";
import AdsMobileOptions from "./AdsMobileOptions";
import AdvertisementPromotion from "../AdvertisementPromotion";
import {useNavigate} from "react-router-dom";
import s from "../../../components/catalogPages/CartProduct/style.module.sass";
import {ShareIcon} from "../../../components/icons/ShareIcon";

const AdsItems = ({ getIdItem, type, deactivateHandler, setActiveToggle, activeToggle, deleteHandler, getArr, fetchAd, search }) => {
    const ctx = useContext(AuthContext);
    const modal = useContext(ModalsContext);
    const [ads, setAds] = useState([]);
    const [isActive, setActive] = useState(0);
    const [loaded, setLoaded] = useState(true);
    const [page, setPage] = useState(1); // Страница пагинации
    const [pageCount, setPageCount] = useState(0);
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);

    useEffect(() => {
        //проверяем попали ли на страницу по кнопке назад, и есть ли в параметрах ?page=
        const isPageParam = params.get("page");
        //если есть, то переопределяем
        if (isPageParam !== null) {
            setPage(Number(isPageParam));
        } else {
            //иначе присваиваем стартовое значение 1
            setPage(1);
        }
    }, [params]);

    const clickItem = (id) => {
        setActive(id);
        getIdItem(id);
    };

    const showOptions = (id) => {
        setActive(id);
        setActiveToggle((prev) => !prev);
    };

    const status = useSelector((state) => +state.status.status);
    const getCheckboxesState = useSelector((state) => state.checkboxes);
    const seek = useSelector(state => state.adsSearchLogic.statusAds) // Я ищу/предлагаю

    let statusModeration = "&filter[object.status]=1";

    if (status === 1) {
        statusModeration = "&filter[object.status]=1";
    }
    if (status === 2) {
        statusModeration = "&filter[object.status][in][]=0&filter[object.status][in][]=2";
    }
    if (status === 3) {
        statusModeration = "&filter[object.status]=3";
    }

    const dispatch = useDispatch();

    console.log(ads)
    useEffect(() => {
        dispatch(calendarAction.cancelCalendar());
        dispatch(promoAction.resetCheckboxes());
    }, []);


    useEffect(() => {
        setLoaded(false);
        fetch(
            `${link}/object/account-search?ObjectsAccountSearch[query]=${search}&filter[type]=${type}&filter[user_id]=${ctx.isUserId}${statusModeration}
            &filter[seek]=${seek === 1 ? 1: 0}&expand=city, scheduleIsBusies&pagination[pageSize]=10&page=${page}`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: token,
                },
            }
        )
            .then((res) => res.json().then((data) => ({ data, headers: res.headers })))
            .then((res) => {
                console.log(res)
                if (res.data.success) {
                    setAds(res.data.data);
                    getArr(res.data.data);
                } else {
                    if (res.status === 401) {
                        localStorage.removeItem("token");
                        window.location.replace("/login");
                    }
                }
                dispatch(checkboxAction.resetStates(res.data.data.length));
                setPageCount(parseInt(res.headers.get("X-Pagination-Page-Count")));
                setPage(parseInt(res.headers.get("X-Pagination-Current-Page")));
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [type, ctx.rerender, ctx.isUserId, status, page, seek, fetchAd, search]);

    const changeCheckbox = (id) => {
        dispatch(checkboxAction.changeCheckboxes(id));
    };

    const getDataFromCalendar = (id) => {
        fetch(`${link}/object/${id}?expand=city, scheduleIsBusies`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res.data);
                dispatch(calendarAction.fromBackToFront(res.data.scheduleIsBusies));
            });
    };

    const changeDateBySvg = (id) => {
        dispatch(calendarAction.cancelCalendar());
        dispatch(calendarAction.calendarFromItem(id));
        dispatch(calendarAction.modalFetchBoolean(true));
        dispatch(calendarAction.calendarCheckbox(false));
        modal.calendarModal();
        getDataFromCalendar(id);
    };

    const promotionOneItem = async (id) => {
        await localStorage.setItem('promo', "item=" + id);
        await navigate('/lc/promotion/rate');
    }

    const promotionExtend = async (id) => {
        await localStorage.setItem('promo', "extendItem=" + id + '');
        await navigate('/lc/promotion/rate');
    }

    const getAway = (id) => {
        let array = [];
        array = ads.filter((el) => el.id !== id);
        console.log(array);
        setAds(array);
        const time = setTimeout(() => {
            dispatch(statusAction.statusRerender());
        }, 300);
        return () => clearTimeout(time);
    };

    const setChange = (value) => {
        setPage(value);
        //передаем значение страницы в url
        navigate(`?page=${value}`);
    };

    const onShare = (fullAlias, alias, title) => {
        if (navigator.share) {
            const data = {
                url: `${frontLink}/${cityAlias}/${fullAlias}/${alias}`,
                title: "CityWork",
                text: `Посмотри объявление "${title}" на CityWork`,
            };
            navigator.share(data);
        } else {
            console.log("your browser cant use sharing");
        }
    };

    return (
        <div className="loads_advers">
            {loaded ? (
                <TransitionGroup className="scroll_if_needed flex__advers_768">
                        {ads?.map((item) => (
                            <CSSTransition key={item.id} timeout={1000} classNames="item">
                                <div
                                    className={`cabinet_ads__item ${isActive === item.id ? "active" : ""} ${item.status === 0 ? "moderation_status_0" : ""} ${item.status === 2 ? "moderation_status_0 moderation_status_2" : ""} ${(item.activeShow !== null || item.activeFixation !== null) || (item.activeShow !== null && item.activeFixation !== null) ? "advers_promoted" : ""}`}
                                    onClick={() => clickItem(item.id)}>
                                    {item.status === 2 && (
                                        <div className="moderator_message_error">
                                            <div className="mess_moderator_err">
                                                <ErrorSvg />
                                                <span>
                                                    Описание объявления не соотвествует правилам сервиса. Исправьте
                                                    объявление.
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="adver_wrap__flex">

                                    <AdsMobileOptions item={item} status={status} showOptions={showOptions} deactivateHandler={deactivateHandler}
                                                      getAway={getAway} changeDateBySvg={changeDateBySvg} deleteHandler={deleteHandler} isActive={isActive}
                                                      activeToggle={activeToggle} promotionOneItem={promotionOneItem}/>

                                    <div className="img imgAdvers">
                                        {item.image === null ? (
                                            <img src={object} alt="object" />
                                        ) : (
                                            <img src={url + item.image.filename} alt="object" />
                                        )}
                                        <label key={item.id} className="container_checkbox">
                                            <input
                                                datatype={item.id}
                                                type="checkbox"
                                                className="advers__settings advers__settings_checkbox"
                                                checked={
                                                    getCheckboxesState.checkedArr.find((el) => el === item.id) ||
                                                    getCheckboxesState.isCheckAll
                                                }
                                                onChange={() => {
                                                    changeCheckbox(item.id);
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="ads__descr_preview">
                                        <h3 className="title">{item.name}</h3>
                                        <div className="advers__medium__flex">
                                            <p className="advers__about">{item.about.replaceAll(`<br />`, '').slice(0, 100)}</p>
                                            <div className="absolute_share">
                                    <div className="advers_share" onClick={() => onShare(item.product?.category.alias, item.alias, item.name)}>
                                        <ShareIcon />
                                    </div>
                                            </div>
                                            {seek === 0 &&
                                            <div className="advers_prices">
                                                <div className="advs_price">
                                                    {item.price_1 ? (
                                                        <>{item.price_1} ₽</>
                                                    ) : (
                                                        <span className="sz">не указано</span>
                                                    )}
                                                    <span>{item.price_1_name}</span>
                                                </div>
                                                <div className="advs_price price_sm">
                                                    {item.price_2 ? (
                                                        <>{item.price_2} ₽</>
                                                    ) : (
                                                        <span className="sz">не указано</span>
                                                    )}
                                                    <span>{item.price_2_name}</span>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <AdsBottom status={status} seek={seek} item={item}/>

                                    </div>
                                    <AdsButtons item={item} seek={seek} deactivateHandler={deactivateHandler}
                                                getAway={getAway} changeDateBySvg={changeDateBySvg} deleteHandler={deleteHandler} promotionOneItem={promotionOneItem}/>

                                    </div>
                                    {(item.activeShow !== null || item.activeFixation !== null) || (item.activeShow !== null && item.activeFixation !== null)
                                        ? <AdvertisementPromotion promotionExtend={promotionExtend} item={item}/> : ''}
                                </div>
                            </CSSTransition>

                        ))}
                        <div className="pagination_banks" style={{ paddingBottom: 0 }}>
                            <PaginationPattern
                                setChange={(e, value) => setChange(value)}
                                pageCount={pageCount}
                                page={page}
                            />
                        </div>
                </TransitionGroup>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default AdsItems;
