import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {link} from "../../store/host-store";
import {token} from "../../App";
import style from "./subscribe.module.sass";
import Loader from "../../components/loader/Loader";

const SubscribeEditIn = () => {
    const params = useParams();
    const [categoryName, setCategoryName] = useState();
    const [subCategories, setSubCategories] = useState([]);
    const [idCheckbox, setIdCheckbox] = useState([+params.id]);
    const [loadind, setLoading] = useState(true);
    const [city, setCity] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const {parent} = location.state


    useEffect(() => {
        window.scrollTo(0, 0);


        fetch(`${link}/category/${parent}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        }).then(res => res.json()).then(res => {
            console.log(res.data);
            setCategoryName(res.data.title)
        })

        // Вывод подкатегорий
        fetch(`${link}/category?&filter[parent_id][in][]=${parent}&pagination=0`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        }).then(res => res.json()).then(res => {
            console.log(res.data);
            setSubCategories(res.data)
            setLoading(false)
        })

        fetch(`${link}/subscription/edit?id=${params.id}`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        }).then(res => res.json()).then(res => {
            console.log(res.data);
            setCity(res.data.city_id)
            res.data.childrenCategories.map(item => setIdCheckbox(prev => [...prev, item.id]))
        })
    }, [params.id]);


    const check = (id) => {
        const exists = idCheckbox.includes(id)

        if(exists){
            setIdCheckbox(prevState => prevState.filter(item => item !==id));
        }else{
            setIdCheckbox(prevState => [...prevState, id]);
        }
    }

    const checkAll = () => {
        if(subCategories.length !== idCheckbox.length){
            setIdCheckbox(subCategories.map(item => item.id))
        }else{
            setIdCheckbox([]);
        }
    }

    const postData = () => {
        if(idCheckbox.length === 1){
            return alert('Выберите как минимум одну категорию')
        }
        let data = {
            category_ids: idCheckbox,
            city_id: city,
            subscription_id: Number(params.id),
        }
        console.log(data)

        fetch(`${link}/subscription/mass-create`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify(data)
        }).then(res => res.json()).then(res => {
            console.log(res.data)
            navigate('/lc/subscribe')
        })
    }

    return(
        <div className='cabinet__redesign_container'>
            <h3 className='cabinet__redesign_title'>Мои подписки</h3>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}
                className={style.promotion_block}>
                <div className={style.promotion_create_in}>
                    {loadind ? <Loader/> :
                        <div className={style.promotion_create_in_block}>
                            <div className={style.promotion_create_in_title}>
                                <h4>{categoryName}</h4>
                                <div onClick={checkAll}>Выделить все</div>
                            </div>

                            <div className={style.promotion_create_in_array}>
                                {subCategories.map(item => (
                                        <label className={style.container} key={item.id}>
                                            <span className={style.span_title}>{item?.title}</span>
                                            <input type="checkbox"
                                                   checked={idCheckbox.includes(item.id)}
                                                   onChange={() => check(item.id)}/>
                                            <span className={style.checkmarks}></span>
                                        </label>
                                    )
                                )}
                            </div>
                        </div>
                    }
                </div>

                <div className={style.subscribe_create_inBtn}>
                    <button onClick={postData}
                            type='button'>Сохранить</button>
                </div>
            </div>
        </div>
    )
}

export default SubscribeEditIn;