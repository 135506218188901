import moment from "moment";
import avatar from "../../assets/images/stubs/avatar.svg";
import { url } from "../../store/host-store";
import style from "./style.module.sass";
import {useSelector} from "react-redux";

const MobileReferral = ({ users }) => {
    const partnerProgram = useSelector(state => state.referralSlice.referralToggle)
    return (
        <>
            {users.length > 0 ? (
                users.map((el, i) => {
                    return (
                        <div className={style.bottom__mobile_card} key={el.id}>
                            <div className={style.bottom__titles}>
                                <p>№</p>
                                <p>Дата</p>
                                <p>ID-пользователя</p>
                                <p>Аватар</p>
                                <p>Имя пользователя</p>
                                {!partnerProgram && <p>Статус</p>}
                                {partnerProgram && <p>Баллы</p>}
                                {partnerProgram && <p>Деньги</p>}
                            </div>
                            <div className={style.bottom__card}>
                                <p>{i + 1}</p>
                                <p>{moment(el.referal_date).format("DD.MM.YYYY")}</p>
                                <p>{el.id}</p>
                                {el.avatar === null ? (
                                    <img alt="avatar" src={avatar} />
                                ) : (
                                    <img alt="avatar" src={url + el.avatar} />
                                )}
                                <p>{el.name}</p>

                                {!partnerProgram && <>
                                    {Number(el.referal_status) === 1 ? (
                                        <p className={style.bottom__card_null}>0 баллов</p>
                                    ) : Number(el.referal_status) === 2 ? (
                                        <p className={style.bottom__card_points}>+1000 баллов</p>
                                    ) : (
                                        <p>ошибка</p>
                                    )}
                                </>}
                                {partnerProgram && <p className="green">+{el?.referral_points} баллов</p>}
                                {partnerProgram && <p className="green">+{el?.affiliate_money} ₽</p>}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className={style.bottom__card_empty}>Пока никто не воспользовался Вашей реферальной ссылкой</div>
            )}
        </>
    );
};

export default MobileReferral;
