import {createSlice} from "@reduxjs/toolkit";

let initialState = {
    headerCityOpen: false
}

const modalsSlice = createSlice({
    name: "modalsSlice",
    initialState,
    reducers: {
        changeHeaderCity(state, action){
            state.headerCityOpen = action.payload;
        }
    }
})

export default modalsSlice;