export const TwoPeoplesIcon = () => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3643 5.23054C9.79147 5.70831 9.05795 5.9957 8.25943 5.9957C7.98303 5.9957 7.71442 5.96123 7.45761 5.89647C7.91684 6.6041 8.18445 7.44994 8.18445 8.35795V9.69158C8.18445 10.1904 8.00446 10.6472 7.70728 11H10.7071C11.42 11 12 10.4131 12 9.69158V8.35795C12 7.06 11.351 5.91269 10.3643 5.23054Z"
                fill="#fff"
            />
            <path
                d="M3.74066 5.28418C5.18033 5.28418 6.35156 4.09898 6.35156 2.64214C6.35156 1.18521 5.18033 0 3.74066 0C2.301 0 1.12976 1.18521 1.12976 2.64214C1.12976 4.09898 2.301 5.28418 3.74066 5.28418Z"
                fill="#fff"
            />
            <path
                d="M8.25934 5.28418C9.699 5.28418 10.8703 4.09898 10.8703 2.64214C10.8703 1.18521 9.699 0 8.25934 0C7.5629 0 6.92935 0.277379 6.46069 0.728652C6.83487 1.27174 7.05469 1.93137 7.05469 2.64214C7.05469 3.35282 6.83487 4.01245 6.46069 4.55553C6.92935 5.0068 7.5629 5.28418 8.25934 5.28418Z"
                fill="#fff"
            />
            <path
                d="M5.84555 5.23054C5.2728 5.70831 4.53928 5.9957 3.74066 5.9957C2.94205 5.9957 2.20853 5.7084 1.63568 5.23054C0.649017 5.91269 0 7.06 0 8.35795V9.69158C0 10.4131 0.579986 11 1.293 11H6.18823C6.90124 11 7.48123 10.4131 7.48123 9.69158V8.35795C7.48123 7.06 6.8323 5.91269 5.84555 5.23054Z"
                fill="#fff"
            />
        </svg>
    );
};
