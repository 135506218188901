import style from '../subscribe.module.sass'
import {useEffect} from "react";
import {link} from "../../../store/host-store";
import {token} from "../../../App";
import ObjectsTypes from "../../../UI/ObjectsTypes/ObjectsTypes";

const SubscribeCategory = ({toggleCategory, type, category, setCategory}) => {

    useEffect(() => {
        fetch(`${link}/category/account-list`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result)
                if (result.data) {
                    setCategory(result.data);
                    console.log(result.data)
                }
            });
    }, []);

  return(
      <div className={style.category_main}>
          <h4 className={style.create_title}>Выберите категорию:</h4>
          <ObjectsTypes category={category} type={type} toggleCategory={toggleCategory}/>
      </div>
  )
}

export default SubscribeCategory;