const AddCitySvg = () => {
  return(
      <>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" fill="none">
              <circle cx="24" cy="24.686" r="24" fill="#546EDB" fillOpacity=".62"/>
              <path fill="#fff" fillRule="evenodd" d="M14 23.574c0-1.032.84-1.869 1.875-1.869h6.25v-6.227c0-1.032.84-1.869 1.875-1.869 1.035 0 1.875.837 1.875 1.869v6.227h6.25c1.035 0 1.875.837 1.875 1.869 0 1.031-.84 1.868-1.875 1.868h-6.25v6.227c0 1.032-.84 1.869-1.875 1.869a1.872 1.872 0 0 1-1.875-1.868v-6.228h-6.25A1.872 1.872 0 0 1 14 23.574Z" clipRule="evenodd"/>
          </svg>
      </>
  )
}

export default AddCitySvg;