import "./Docs.sass";

const Agreement = () => {
    return (
    <div className="rules">
        <h5>Заявление-согласие на обработку предоставленной информации</h5>
        <p>
            Лицо (субъект данных), предоставляющее данные, находясь на сайте citywork.ru и/или в одноименном
            приложении «CityWork» на всех возможных платформах, дает согласие Компании, и в случае предоставления
            данных о третьих лицах гарантирует, что данное согласие получено от лиц (субъектов данных), чьи данные
            предоставляются Компании, на обработку, т.е. осуществление любых действий (операций) или совокупность
            действий (операций) совершаемых с использованием средств автоматизации или без использования таких
            средств, с предоставленными данными, включая сбор, запись, систематизацию, накопление, хранение,
            уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
            доступ), обезличивание, блокирование, удаление, уничтожение, обработку на бумажных носителях и/или
            посредством внесения данных в электронную базу данных, в целях предоставления Пользователям безопасных и
            эффективных Услуг, предоставление доступа к Услугам Компании, управления и совершенствования Услуг,
            информирования об Услугах и иной информации от имени Компании, а также защиты интересов Компании и в
            иных целях, не запрещённых применимым законодательством и/или согласованных с Лицом, предоставляющим
            данные. Компания запрашивает у Пользователя минимальный объём сведений, чтобы поддерживать связь с
            пользователем:
        </p>
        <ul>
            <li>имя Пользователя;</li>
            <li>мобильный телефон;</li>
            <li>адрес электронной почты.</li>
            <li>
                иную информацию, которую пользователь решит разместить в личном кабинете: фамилия, отчество, фото,
                возраст.
            </li>
        </ul>
        <p>
            В процессе обработки заказов Компания может обрабатывать иную личную информацию, для того чтобы увидеть
            каких специалистов рекомендуют знакомые, Пользователь может предоставить Компании доступ к контактам.
            При этом в случае направления любой информации в адрес лица, предоставившего данные, и/или лиц, чьи
            данные предоставлены, Компания оставляет за собой право выбирать любые каналы передачи указанной
            информации, а также любые способы фиксации факта передачи информации и содержания передаваемой
            информации. Лицо, передающее данные, выражает согласие с предоставлением Компании переданной информации
            третьим лицам, в том числе лицам, осуществляющим поддержку служб и услуг Компании, в необходимом для
            осуществления такой поддержки объёме, и/или компаниям, входящим в группу компаний, и/или иным лицам, в
            том числе специалистам сотовой связи права и обязанности по доступу к соответствующей информации
            установлены действующим законодательством. Согласие субъекта данных на обработку предоставленных данных,
            выраженное в настоящем Заявлении-согласии, действует бессрочно и, может быть, в любой момент отозвано
            субъектом данных путем письменного обращения в Компанию в соответствии с действующим законодательством.
        </p>
    </div>

);
};

export default Agreement;

// <div className="rules">
//     <h5>Заявление-согласие на обработку предоставленной информации</h5>
//     <p>
//         Лицо (субъект данных), предоставляющее данные, находясь в приложении citywork.ru и/или в одноименном
//         приложении «CityWork» на всех возможных платформах, дает согласие Компании, и в случае предоставления
//         данных о третьих лицах гарантирует, что данное согласие получено от лиц (субъектов данных), чьи данные
//         предоставляются Компании, на обработку, т.е. осуществление любых действий (операций) или совокупность
//         действий (операций) совершаемых с использованием средств автоматизации или без использования таких
//         средств, с предоставленными данными, включая сбор, запись, систематизацию, накопление, хранение,
//         уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление,
//         доступ), обезличивание, блокирование, удаление, уничтожение, обработку на бумажных носителях и/или
//         посредством внесения данных в электронную базу данных, в целях предоставления Пользователям безопасных и
//         эффективных Услуг, предоставление доступа к Услугам Компании, управления и совершенствования Услуг,
//         информирования об Услугах и иной информации от имени Компании, а также защиты интересов Компании и в
//         иных целях, не запрещённых применимым законодательством и/или согласованных с Лицом, предоставляющим
//         данные. Компания запрашивает у Пользователя минимальный объём сведений, чтобы поддерживать связь с
//         пользователем:
//     </p>
//     <ul>
//         <li>имя Пользователя;</li>
//         <li>мобильный телефон;</li>
//         <li>адрес электронной почты.</li>
//         <li>
//             иную информацию, которую пользователь решит разместить в личном кабинете: фамилия, отчество, фото,
//             возраст.
//         </li>
//     </ul>
//     <p>
//         В процессе обработки заказов Компания может обрабатывать иную личную информацию, для того чтобы увидеть
//         каких специалистов рекомендуют знакомые, Пользователь может предоставить Компании доступ к контактам.
//         При этом в случае направления любой информации в адрес лица, предоставившего данные, и/или лиц, чьи
//         данные предоставлены, Компания оставляет за собой право выбирать любые каналы передачи указанной
//         информации, а также любые способы фиксации факта передачи информации и содержания передаваемой
//         информации. Лицо, передающее данные, выражает согласие с предоставлением Компании переданной информации
//         третьим лицам, в том числе лицам, осуществляющим поддержку служб и услуг Компании, в необходимом для
//         осуществления такой поддержки объёме, и/или компаниям, входящим в группу компаний, и/или иным лицам, в
//         том числе специалистам сотовой связи права и обязанности по доступу к соответствующей информации
//         установлены действующим законодательством. Согласие субъекта данных на обработку предоставленных данных,
//         выраженное в настоящем Заявлении-согласии, действует бессрочно и, может быть, в любой момент отозвано
//         субъектом данных путем письменного обращения в Компанию в соответствии с действующим законодательством.
//     </p>
// </div>
