import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {link} from "../../host-store";
import {token} from "../../../App";

export const seoApi = createApi({
    reducerPath: 'seoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: link
    }),
    tagTypes: ['Seo'],
    endpoints: (builder) => ({
        getSeoInfo: builder.query({
            query: ({categoryId, cityId}) => {
                return `/category/seo-tags?id=${categoryId}&city_id=${cityId}`
            },
            providesTags: ['Seo']
        }),
        changeSeoInfo: builder.mutation({
            query: ({categoryId, body}) => ({
                    url: `/category/${categoryId}`,
                    method: 'PUT',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: token
                    },
                    body: JSON.stringify(body)

            }),
            invalidatesTags: ['Seo']
        }),
        getSeoTags: builder.query({
            query: (id) => {
                return `/category/seo-tags?id=${id}&city_id=${JSON.parse(localStorage.getItem("location"))?.city_id}`
            },
            providesTags: ['Seo']
        }),
        getSeoInformation: builder.query({
            query: (id) => {
                return `/category/${id}?city_id=${JSON.parse(localStorage.getItem("location"))?.city_id}`
            },
            providesTags: ['Seo']
        })
    })
})

export const {useGetSeoInfoQuery, useChangeSeoInfoMutation, useGetSeoTagsQuery, useGetSeoInformationQuery} = seoApi
