
const EditSeo = ({setSeoInfo, seoInfo, data}) => {
    console.log(data)

  return(
      <div className="admin__edit-category_labels-wrapper">
          <label>
              <p>Seo заголовок</p>
              <input defaultValue={data?.seoTitle} onChange={(e) => setSeoInfo({...seoInfo, seoTitle: e.target.value})}></input>
          </label>
          <label>
              <p>Seo Описание</p>
              <input defaultValue={data?.seoDescription} onChange={(e) => setSeoInfo({...seoInfo, seoDescription: e.target.value})}></input>
          </label>
      </div>
  )
}

export default EditSeo;