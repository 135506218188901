import LocationSvg from "./svg/LocationSvg";
import TimeSvg from "./svg/TimeSvg";
import moment from "moment/moment";
import TelegramSvg from "./svg/TelegramSvg";
import LikeSvg from "./svg/LikeSvg";
import LocationBigSvg from "./svg/LocationBigSvg";
import TimeBigSvg from "./svg/TimeBigSvg";

const AdsBottom = ({status, seek, item}) => {
    return(
        <>
            {status !== 2 && seek === 0 && (
                <div className="adver_bottom_info">
                    <div className="advers_bottom_info___block advs_b_info__address">
                        <LocationSvg />
                        {item.city.name}
                    </div>
                    <div className="advers_bottom_info___block advs_b_info__time">
                        <TimeSvg />
                        {moment(item.created).format("DD.MM.YYYY")}
                    </div>
                    <div className="advers_bottom_info___block advs_b_info__telegram">
                        <TelegramSvg />
                        {item.amountOfConfirmedOrders} <span> </span>
                    </div>
                    <div className="advers_bottom_info___block advs_b_info__like">
                        <LikeSvg />
                        {item.amountInFavourites} <span> </span>
                    </div>
                </div>
            )}

            {seek === 1 &&
                <div className="adver_bottom_info">
                    <div className="adver_bottom_location adv_seek_1">
                        <LocationBigSvg />
                        г. {item.city.name}
                    </div>
                    <div className="adver_bottom_time adv_seek_1">
                        <TimeBigSvg />
                        {moment(item.final_date).endOf("day").fromNow().replace('через', 'Осталось: ')}
                    </div>
                </div>
            }
        </>
    )
}

export default AdsBottom;