import React, { useState, useContext } from "react";
import { token } from "../../../App";
import { link } from "../../../store/host-store";
import AuthContext from "../../../store/auth-context";

function Promocode() {
    const [code, setCode] = useState('');
    const ctx = useContext(AuthContext)

    function applyCode(e) {
        e.preventDefault()

        const data = { code }
        fetch(`${link}/user/replenish-by-promo-code`, {
            headers: {
                Accept: "application/json",
                Authorization: token,
                "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(data)
        })
            .then((res) => res.json())

            .then((result) => {
                setCode('')
                if (result.success) {
                    ctx.setRefreshData(prev => !prev)
                } else {
                    alert(result.data.message)
                }
            })
            .catch((e) => console.log(e))
    }

    return (
        <div className="cabinet__promocode cabinet__right___block">
            <div className="cabinet__promocode_title">Промокод:</div>
            <div>
                <div className="promocode_promo">
                    <div className="promo_promocode_title">Введите:</div>
                    <input name="exl" onChange={e => setCode(e.target.value)} value={code} type="text" className="input_promocode_2" placeholder='G25JF3905' />
                </div>
                <button onClick={applyCode} type="button" className="promo-btn">Применить</button>
            </div>
            <div className="promocode_sub__text">
                Введите подарочный промокод, который Вам был предоставлен, после принятия будут начислены бонусы.
            </div>
        </div>
    )
}

export default Promocode;